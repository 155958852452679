import { createSlice } from '@reduxjs/toolkit';
import { TableState } from '../../components/table/model';

const initialState: TableState = {
  columns: [],
};

const connectionendpointListSlice = createSlice({
  name: 'connectionendpointTable',
  initialState,
  reducers: {
    hideColumn(state) {},
  },
});

export const connectionendpointsReducer = connectionendpointListSlice.reducer;
