import React from 'react';
import { useCallback, useEffect, useState, useContext, useRef } from 'react';
import {
  AcknowledgmentOverrideReportEmailFilterListRequest,
  useFetchAcknowledgmentOverrideReportEmailFiltersQuery,
  useUpdateAcknowledgmentOverrideReportEmailFiltersMutation
} from '../acknowledgmentoverridereportemailfilter-api';
import AuditLogsScreen from '../../../components/common/auditLogs';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Button,
  Card,
  Form,
  Input,
  message,
  Space,
  Table, Popconfirm,  GetRef, InputRef, Dropdown, Menu, Select
} from 'antd';
import {
  EditOutlined,
  MoreOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import { AcknowledgmentOverrideReportEmailFilterViewModel, PaginatedResult, PartnershipInformationViewModel } from '../../api/models';
import { DefaultPage, DefaultPageSize, DefaultPollingInterval } from '../../../core/defaults';
import Title from 'antd/lib/typography/Title';
import { getLink } from '../../../App-router';
import getSortBy from '../../../components/table/sorter';
import { DefaultTenantId } from '../../../core/defaults';
import { isNullEmpty } from '../../../core/utils';
import {
  useFetchPartnershipInformationsQuery,
} from '../../partnershipinformation/partnershipinformation-api';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

type FormInstance<T> = GetRef<typeof Form<T>>;
const statusArray = [{ label: 'Processed', value: 'Processed' }, { label: 'Accepted', value: 'Accepted' },{ label: 'Partially Accepted', value: 'Partially Accepted' }
  ,{ label: 'Rejected', value: 'Rejected' },{ label: 'Errored', value: 'Errored' },{ label: 'Declined', value: 'Declined' }]
const EditableContext = React.createContext<FormInstance<any> | null>(null);
interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof AcknowledgmentOverrideReportEmailFilterViewModel;
  inputType: string
  record: AcknowledgmentOverrideReportEmailFilterViewModel;
  handleSave: (record: AcknowledgmentOverrideReportEmailFilterViewModel) => void;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  title,
  editable,
  children,
  dataIndex,
  inputType,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (
    option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    let value = record[dataIndex]||null;
    if(dataIndex=='status'){
      if(!isNullEmpty(value)){
        value = record[dataIndex]?.split(',');
      }  
      else{
        value=null;
      }
    }
    form.setFieldsValue({ [dataIndex]:value});
  };
  const {
    data: partners = {},
    isFetching,
  }: {
    data?: PaginatedResult<PartnershipInformationViewModel> | undefined;
    isFetching?: boolean | undefined;
  } = useFetchPartnershipInformationsQuery({
    current: DefaultPage,
    pageSize: 100000,
    filter: '',
    sortBy: 'Name',
    searchText:'',
    type:'',
  });
  let partnersResults = partners.results??[];
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
      >
        {dataIndex=='status'? <Select
        style={{minWidth: 150}}
            mode="multiple"
            placeholder="Select..."
            optionFilterProp="children"
            filterOption={filterOption}
            onChange={save}
            options={statusArray}
            allowClear={true}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
          />:dataIndex=='partnerInformationId'?<Select
          loading={isFetching}
          showSearch
          onChange={save}
          allowClear={true}
          placeholder="Search..."
          optionFilterProp="children"
          filterOption={filterOption}     
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '')
              .toLowerCase()
              .localeCompare((optionB?.label ?? '').toLowerCase())
          }     
          options={partnersResults.map((item:any) => ({ label: item.partnerId, value: item.id }))}
        />:<Input ref={inputRef} type={inputType} onPressEnter={save} onBlur={save} />
        }
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

interface AcknowledgmentOverrideReportEmailFilterScreenParams {
  Id?: string | undefined;
  tenantId?: string | undefined;
    [key: string]: string | undefined;
  }

export default function AcknowledgmentOverrideReportEmailFiltersScreen(props: any) {
  const { tenantId = DefaultTenantId}: AcknowledgmentOverrideReportEmailFilterScreenParams =
    useParams<AcknowledgmentOverrideReportEmailFilterScreenParams>();

  const query = useQuery();
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const [showAuditLogs, setShowAuditLogs] = useState<boolean>(false);
  const [editTable, setEditTable] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<string>('0');

  const getCurrentState = (): AcknowledgmentOverrideReportEmailFilterListRequest => ({
    current: Number(query.get('p')) || DefaultPage,
    pageSize: Number(query.get('size')) || DefaultPageSize,
    filter: `acknowledgementReconciliationReportId:"${props.acknowledgementReconciliationReportId}"`,
    sortBy: query.get('sortBy') || 'EnteredByDate Desc',
  });

  const [pagination, setPagination] = useState<AcknowledgmentOverrideReportEmailFilterListRequest>(getCurrentState());
  const [form] = Form.useForm();
  const {
    data: pagedData = {},
    isFetching,
    error,
  }: {
    data?: PaginatedResult<AcknowledgmentOverrideReportEmailFilterViewModel> | undefined;
    isFetching?: boolean | undefined;
    error?: any;
  } = useFetchAcknowledgmentOverrideReportEmailFiltersQuery(pagination);

  const [filterData, setFilterData] = useState<AcknowledgmentOverrideReportEmailFilterViewModel[]>([]);
  const [count, setCount] = useState(0);
  const [updateAcknowledgmentOverrideReportEmailFilters, { isLoading: isUpdating, error: updateError }] = useUpdateAcknowledgmentOverrideReportEmailFiltersMutation();

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string; inputType: string })[] = [
    {
      title: 'Id',
      dataIndex: 'id',
      inputType:'',
      width: '10%',
    },
    {
      title: 'PartnerInformationId',
      dataIndex: 'partnerInformationId',
      width: '10%',
      inputType:'number',
      editable: editTable,
    },
    {
      title: 'TransactionSetIdentifier',
      dataIndex: 'transactionSetIdentifier',
      width: '20%',
      inputType:'text',
      editable: editTable,
    },
    {
      title: 'InterchangeSenderID',
      dataIndex: 'interchangeSenderID',
      width: '20%',
      inputType:'text',
      editable: editTable,
    },
    
    {
      title: 'InterchangeReceiverID',
      dataIndex: 'interchangeReceiverID',
      width: '20%',
      inputType:'text',
      editable: editTable,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '20%',
      inputType:'text',      
      editable: editTable,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      inputType:'',
      fixed:'right',
        render: (AcknowledgmentOverrideReportEmail: AcknowledgmentOverrideReportEmailFilterViewModel) => (
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            overlay={
              <Menu style={{ padding: '8px 8px' }}>                
                <Menu.Item icon={<InfoCircleOutlined />} key="2">
                <Link to="#" onClick={()=>setShowAuditLogs(true)}>
                    Audit Logs
                  </Link>
                </Menu.Item>
              </Menu>
            }
          >
            <MoreOutlined />
          </Dropdown>
        ),
    },
  ];
  const handleDelete = (key: React.Key) => {
    const newData = filterData.filter((item) => item.id !== key);
    setFilterData(newData);
  };

  const onCreateNew = useCallback(
    () => navigate(getLink('AcknowledgmentOverrideReportEmailFilterEdit', { ...params, Id: 'new', acknowledgementReconciliationReportId:props.acknowledgementReconciliationReportId, acknowledgementReconciliationReportName:props.acknowledgementReconciliationReportName })),
    [params, navigate]
  );

  const handleSave = (row: AcknowledgmentOverrideReportEmailFilterViewModel) => {
    const newData = [...filterData];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    row.status = (!isNullEmpty(row.status) && typeof row.status === 'object')? row.status.length>0?row.status.sort().join(','):null:row.status||null;
    row.partnerInformationId = (row.partnerInformationId);
    row.interchangeReceiverID = row.interchangeReceiverID?.trim() == '' ? undefined:row.interchangeReceiverID;
    row.interchangeSenderID = row.interchangeSenderID?.trim() == '' ? undefined:row.interchangeSenderID;
    row.transactionSetIdentifier = row.transactionSetIdentifier?.trim() == '' ? undefined:row.transactionSetIdentifier;
    
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setFilterData(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: AcknowledgmentOverrideReportEmailFilterViewModel) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        inputType: col.inputType,
        title: col.title,
        handleSave,
      }),
    };
  });
  const saveData = async () => {
    setEditTable(false);
    let status:boolean=false;
    try {
      filterData.forEach((row:any) => {
      if(isNullEmpty(row.interchangeReceiverID)
        && isNullEmpty(row.partnerInformationId)
        && isNullEmpty(row.interchangeSenderID) 
        && (isNullEmpty(row.status) || ( typeof row.status === 'object' && row.status.length<1))
        && isNullEmpty(row.transactionSetIdentifier)){
          message.error('Atleast one filter should be there.');
          status = true;
          return;
        }
        } 
      );
        if(!status){
          const requestData = {
          AcknowledgmentOverrideReportEmailFilters: filterData
        }
        const request =  updateAcknowledgmentOverrideReportEmailFilters(requestData);
        request.unwrap().then((response) => {
          if(response.filter(x=>x.id ===-200).length>0){
            message.error(
              `This combination already exists. Please try other values.`
            );
            return;
          }
          message.success(
            `Data is updated successfully.`
          );
        });}
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  useEffect(() => {
    setPagination(getCurrentState());
    setFilterData(pagedData.results||[]);
  }, [params, props, pagedData]);

  return (
    <div>
      <PageHeader
        extra={
          <Space>
          {editTable? <Space>      
          <Button htmlType="button" data-testid={'cancel'} onClick={() => setEditTable(false)}>
            Cancel Edit
          </Button>
            <Button htmlType="button" type='primary' data-testid={'create-new-AcknowledgmentOverrideReportEmailFilter'} onClick={() => saveData()}>
            Save Data
          </Button>
          </ Space>
          :
         filterData.length>0? <Button htmlType="button"  type='primary' data-testid={'create-new-AcknowledgmentOverrideReportEmailFilter'} onClick={() => setEditTable(true)}>
            Edit
          </Button>:null
          }
          <Button htmlType="button" data-testid={'create-new-AcknowledgmentOverrideReportEmailFilter'} onClick={onCreateNew}>
            Add New Acknowledgement Reconciliation Report Filter
          </Button>
          </Space>
        }
        title={
          <Title
            level={4}
            style={{
              marginBottom: 0,
              display: 'inline-block',
            }}
          >
            Acknowledgement Reconciliation Report Filter
          </Title>
        }
      >
        <Card>
          {error?.status && (
            <Alert message="Error" description={error.data} type="error" showIcon />
          )}          
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Form form={form} component={false}>
            <Table
            loading={isUpdating || isFetching}
              components={components}
              bordered
              dataSource={filterData}
              rowClassName={() => 'editable-row'}
              columns={columns as ColumnTypes}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => { 
                    setEntityId(record?.id||0);
                  },
                };
              }}
            />
            </Form>
          </Space>
        </Card>
        {showAuditLogs?<AuditLogsScreen entityId={entityId} entityName={'AcknowledgementReconciliationReportFilter'} showAuditLogs={setShowAuditLogs}/>:<></>}
      </PageHeader>
    </div>
  );
}
