import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  useCreatePartnerConnectionEndpointMutation,
  useFetchPartnerConnectionEndpointsQuery,
  useFetchSinglePartnerConnectionEndpointQuery,
  useUpdatePartnerConnectionEndpointMutation,
} from '../partnerconnectionendpoint-api';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {  PaginatedResult, PartnerConnectionEndpointViewModel, ConnectionEndPointViewModel, PartnerEDIX12ViewModel } from '../../api/models';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ConvertUTCtoCDT } from '../../../core/utils';
import {
  useFetchConnectionEndPointsQuery,
  ConnectionEndPointListRequest,
} from '../../connectionendpoint/connectionendpoint-api';

import {
  useFetchPartnerEDIX12sQuery,
} from '../../partneredix12/partneredix12-api';

import { getLink } from '../../../App-router';
import { DefaultTenantId, DefaultPage, DefaultPageSize, DefaultPollingInterval } from '../../../core/defaults';
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const { Option } = Select;

const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError =>
  error ? 'status' in error : false;

const getErrorMessage = (error: any): string | undefined => error.data;

interface PartnerConnectionEndpointScreenParams {
Id?: string | undefined;
tenantId?: string | undefined;
  [key: string]: string | undefined;
}

export default function PartnerConnectionEndpointEditScreen(props: any) {
  const { Id, tenantId = DefaultTenantId, codedecodetypeid}: PartnerConnectionEndpointScreenParams =
    useParams<PartnerConnectionEndpointScreenParams>();

  const isNewPartnerConnectionEndpoint = Id === 'new';

  const { data, isFetching } = useFetchSinglePartnerConnectionEndpointQuery(
    {
      Id: Number(Id)
    },
    { skip: isNewPartnerConnectionEndpoint }
  );

  const [createPartnerConnectionEndpoint, { isLoading: isCreating, error: createError }] = useCreatePartnerConnectionEndpointMutation();
  const [updatePartnerConnectionEndpoint, { isLoading: isUpdating, error: updateError }] = useUpdatePartnerConnectionEndpointMutation();
  const query = useQuery();
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [connectionEndpointsOptions, setConnectionEndpointsOptions] = useState<{ value: string; label: string }[]>([]);
  const [partneredix12Options, setPartnerEDIX12Options] = useState<{ value: string; label: string }[]>([]);

  const getCurrentState = (): ConnectionEndPointListRequest => ({
    current: Number(query.get('p')) || DefaultPage,
    pageSize: Number(query.get('size')) || DefaultPageSize,
    filter: query.get('q'),
    sortBy: query.get('sortBy') || 'EnteredByDate Desc',
  });

  const [pagination, setPagination] = useState<ConnectionEndPointListRequest>(getCurrentState());

  const {
    data: connectionEndpoints = {},
    error,
  }: {
    data?: PaginatedResult<ConnectionEndPointViewModel> | undefined;
    error?: any;
  } = useFetchConnectionEndPointsQuery(pagination, { pollingInterval: DefaultPollingInterval });
  let connectionEndpointsResults = connectionEndpoints.results??[];

  const {
    data: partneredix12 = {},
  }: {
    data?: PaginatedResult<PartnerEDIX12ViewModel> | undefined;
  } = useFetchPartnerEDIX12sQuery(pagination, { pollingInterval: DefaultPollingInterval });
  let partneredix12Results = partneredix12.results??[];

  
  const handleConnectionEndPointSearch = (value: string) => {
    let res: { value: string; label: string }[] = [];    
    if (!value || value===undefined) {
      res = [];
    } else {
      res = connectionEndpointsResults.map((item:any) => ({
        value: item.id,
        label: `${item.host}`,
      })).filter((x:any) => x.label.toUpperCase().indexOf(value.toUpperCase()) !== -1);
    }
    setConnectionEndpointsOptions(res);
  };

  const handlePartnerEDIX12Search = (value: string) => {
    let res: { value: string; label: string }[] = [];    
    if (!value || value===undefined) {
      res = [];
    } else {
      res = partneredix12Results.map((item:any) => ({
        value: item.id,
        label: `${item.sourceSCAC + ' - '+ item.sourceSender + ' - '+ item.sourceReceiver}`,
      })).filter((x:any) => x.label.toUpperCase().indexOf(value.toUpperCase()) !== -1);
    }
    setPartnerEDIX12Options(res);
  };


  const [form] = Form.useForm();

  React.useEffect(() => {
    form.resetFields();
  }, [data, form]);
  const [disabledSave, setDisabledSave] = useState(true);
  
  const handleFormChange = () => {
  const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
  setDisabledSave(hasErrors);
  }
  const onFinish = (values: any | PartnerConnectionEndpointViewModel) => {
    const requestData = {
      partnerconnectionendpoint: {
        id: isNewPartnerConnectionEndpoint ? 0 : Number(Id),
        connectionEndpointId: values.connectionEndpointId||0,
        partnerEDIX12Id: isNewPartnerConnectionEndpoint? Number(localStorage.getItem('partnerEDIX12Id')):values.partnerEDIX12Id,
        folderPath: values.folderPath,
        isActive: values.isActive==undefined?true:values.isActive,
        updatedBy:sessionStorage.getItem('userEmail')||'',
        enteredBy:isNewPartnerConnectionEndpoint?sessionStorage.getItem('userEmail')||'':'',
      },
    };

    const request = isNewPartnerConnectionEndpoint ? createPartnerConnectionEndpoint(requestData) : updatePartnerConnectionEndpoint(requestData);
    request.unwrap().then((response) => {
      if(response?.id ===-200){
        message.error(
          `This combination already exists. Please try other values.`
        );
        return;
      }
      message.success(
        `Partner Connection Endpoint is ${isNewPartnerConnectionEndpoint ? 'created' : 'saved'}`
      );
      goBack();
    });
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };

  const handleCancel = () => {
    navigate(
      getLink('PartnerConnectionEndpointList', {
        ...props,
        tenantId: tenantId
      })
    );
  };


    // Filter `option.label` match the user type `input`
    const filterOption = (
      input: string,
      option?: { label: string; value: string }
    ) => (
      option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div>
      <PageHeader
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="partnershipInformation">
              <Link to={getLink('PartnershipInformationList', { tenantId: tenantId })}>Partner Information</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="carrierName">
              <Link to={getLink('CarrierOnboardingList', {
                      ...props, tenantId,
                      partnerId: localStorage.getItem('partnerId')||'',
                      identifierValue: sessionStorage.getItem('identifierValue')||'',
                      carrierName: localStorage.getItem('carrierName')||'',
                      id: Number(localStorage.getItem('partnershipId')||0),
                    })}>{localStorage.getItem('carrierName')||''}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="splittingRule">
              <Link to={getLink('CarrierOnboardingEdit', { tenantId: tenantId,Id: Number(localStorage.getItem('splittingRuleID')||0),
                      partnershipId: Number(localStorage.getItem('partnershipId')||0) })}>{localStorage.getItem('splittingRuleID')||''}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{isNewPartnerConnectionEndpoint ? 'New Partner Connection Endpoint' : data?.connectionEndpointId}</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {isFetchBaseQueryErrorType(createError) && (
          <Alert message="Error" description={getErrorMessage(createError)} type="error" showIcon />
        )}
        {isFetchBaseQueryErrorType(updateError) && (
          <Alert message="Error" description={getErrorMessage(updateError)} type="error" showIcon />
        )}
        <Card
          title={
            <Title
              level={4}
              style={{
                marginBottom: 0,
                display: 'inline-block',
              }}
            >
              {isNewPartnerConnectionEndpoint ? 'New Partner Connection Endpoint' : 'Edit Partner Connection Endpoint'}
            </Title>
          }
        >
          <Spin spinning={isFetching || isCreating || isUpdating}>
            <Form
              {...layout}
              form={form}
              name="control-hooks"
              initialValues={data}
              onFinish={onFinish}
              onFieldsChange={handleFormChange}   
            >
              <Form.Item name="connectionEndpointId" label="ConnectionEndpoint">
                <Select
                  showSearch
                  placeholder="Search..."
                  optionFilterProp="children"
                  data-testid="connectionEndpointId"
                  onSearch={handleConnectionEndPointSearch}
                  filterOption={filterOption}
                  allowClear
                  options={connectionEndpointsResults.map((item:any) => ({ label: item.host, value: item.id }))}
                />
              </Form.Item>              
              <Form.Item name="partnerEDIX12Id" label="PartnerEDIX12" initialValue={isNewPartnerConnectionEndpoint? Number(localStorage.getItem('partnerEDIX12Id')):data?.partnerEDIX12Id}>
               <Input disabled={true} defaultValue={isNewPartnerConnectionEndpoint? Number(localStorage.getItem('partnerEDIX12Id')):data?.partnerEDIX12Id} data-testid="partnerEDIX12Id" suffix={
                        <Tooltip title="Valid partnerEDIX12Id">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
                </Form.Item>
              <Form.Item name="folderPath" label="FolderPath" rules={[{ required: true }]}>
                <Input placeholder='Please enter valid data' data-testid="folderPath" suffix={
                        <Tooltip title="Please enter valid data">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      } />
              </Form.Item>

              {!isNewPartnerConnectionEndpoint?
              <div>
                <Form.Item name="enteredBy" label="Entered By">
                <Space>
                  <span>{data?.enteredBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="enteredByDate" label="Entered By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.enteredByDate||'')}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updatedBy" label="Updated By">
                <Space>
                  <span>{data?.updatedBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updateByDate" label="Updated By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.updatedByDate||'')}</span>
                </Space>
              </Form.Item>      
              </div>:''
              }

              <Form.Item name="isActive" label="IsActive" initialValue={isNewPartnerConnectionEndpoint ?true : data?.isActive}>
                <Switch defaultChecked={data?.isActive} defaultValue={data?.isActive}/>
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                <Space>
                  <Button type="primary" htmlType="submit" disabled={disabledSave && !isNewPartnerConnectionEndpoint}>
                    {isNewPartnerConnectionEndpoint ? 'Add Partner Connection Endpoint' : 'Save Partner Connection Endpoint'}
                  </Button>
                  <Button htmlType="button" onClick={goBack}>
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </PageHeader>
    </div>
  );
}
