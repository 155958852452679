import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  CodeDecodeValueListRequest,
  useDeleteCodeDecodeValueMutation,
  useFetchCodeDecodeValuesQuery,
} from '../codedecodevalue-api';
import AuditLogsScreen from '../../../components/common/auditLogs';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Badge,
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Space,
  Table,
} from 'antd';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
  MinusCircleFilled,
  MoreOutlined,
  InfoCircleOutlined,
  WarningFilled,
} from '@ant-design/icons';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { CodeDecodeValueViewModel, PaginatedResult } from '../../api/models';
import { DefaultPage, DefaultPageSize, DefaultPollingInterval } from '../../../core/defaults';
import Title from 'antd/lib/typography/Title';
import { getLink } from '../../../App-router';
import { TableState } from '../../../components/table/model';
import getSortBy from '../../../components/table/sorter';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function CodeDecodeValuesScreen(props: any) {
  const query = useQuery();
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const [showAuditLogs, setShowAuditLogs] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<number>(0);
   
  console.log('CodeDecodeValuesScreen', params);
  const codedecodevalueTable: TableState = {
    columns: [
      {
        title: 'CodeDecodeValue',
        dataIndex: '',
        key: 'Id',
        render: (codedecodevalue: CodeDecodeValueViewModel) => (
          <>
              <Space>
                <span>{codedecodevalue.id}</span>
              </Space>
          </>
        ),
        sorter: true,
        fixed: 'left',
      },
      {
        title: 'Code',
        dataIndex: '',
        key: 'code',
        render: (codedecodevalue: CodeDecodeValueViewModel) => (
          <>
              <span>{codedecodevalue.code}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'Decode',
        dataIndex: '',
        key: 'decode',
        render: (codedecodevalue: CodeDecodeValueViewModel) => (
          <>
              <span>{codedecodevalue.decode}</span>
          </>
        ),
      },
      {
        title: 'Decode1',
        dataIndex: '',
        key: 'decode1',
        render: (codedecodevalue: CodeDecodeValueViewModel) => (
          <>
              <span>{codedecodevalue.decode1}</span>
          </>
        ),
      },
      {
        title: 'CodeDecodeType',
        key: 'codeDecodeType',
        dataIndex: '',
        render: (codedecodevalue: CodeDecodeValueViewModel) => (
          <>
              <span>{codedecodevalue.codeDecodeType}</span>
          </>
        ),
      },      
      {
        title: 'Actions',
        key: 'action',
        dataIndex: '',
        fixed:'right',
        render: (codedecodevalue: CodeDecodeValueViewModel) => (
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            overlay={
              <Menu style={{ padding: '8px 8px' }}>
                <Menu.Item icon={<EditOutlined />} key="1">
                  <Link
                    to={getLink('CodeDecodeValueEdit', {
                      tenantId: params.tenantId,
                      Id: codedecodevalue.id,
                      codedecodetypeid: params.codedecodetypeid
                    })}
                  >
                    Edit
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<InfoCircleOutlined />} key="3">
                <Link to="#" onClick={()=>setShowAuditLogs(true)}>
                    Audit Logs
                  </Link>
                </Menu.Item>
                <Menu.Item disabled={true}
                  icon={<DeleteOutlined />}
                  key="2"
                  onClick={() => {
                    confirm({
                      title: (
                        <span>
                          Delete code decode value <b>{codedecodevalue.code}</b>?
                        </span>
                      ),
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        handleDelete(codedecodevalue);
                      },
                    });
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
          >
            <MoreOutlined />
          </Dropdown>
        ),
      },
    ],
  };

  const getCurrentState = (): CodeDecodeValueListRequest => ({
    codeDecodeTypeId: Number(params.codedecodetypeid),
    current: Number(query.get('p')) || DefaultPage,
    pageSize: Number(query.get('size')) || DefaultPageSize,
    filter: query.get('q'),
    sortBy: query.get('sortBy') || 'EnteredByDate Desc',
  });

  const [pagination, setPagination] = useState<CodeDecodeValueListRequest>(getCurrentState());

  const {
    data: pagedData = {},
    isFetching,
    error,
  }: {
    data?: PaginatedResult<CodeDecodeValueViewModel> | undefined;
    isFetching?: boolean | undefined;
    error?: any;
  } = useFetchCodeDecodeValuesQuery(pagination, { pollingInterval: DefaultPollingInterval });

  useEffect(() => {
    setPagination(getCurrentState());
  }, [params]);

  const [deleteCodeDecodeValue, { isLoading: isDeleteLoading, error: deleteError }] =
    useDeleteCodeDecodeValueMutation();
  const { confirm } = Modal;

  const onCreateNew = useCallback(
    () => navigate(getLink('CodeDecodeValueEdit', { ...params, Id: 'new' })),
    [params, navigate]
  );

  const onBackToCodeDecodeType = () => {
    navigate(
      getLink('CodeDecodeTypeList', {
        ...params,
        tenantId: params.tenantId,
      })
    );
  };
  const handleTableChange = (
    paginationConfig: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<CodeDecodeValueViewModel> | SorterResult<CodeDecodeValueViewModel>[],
    extra: TableCurrentDataSource<CodeDecodeValueViewModel>
  ) => {
    const { columnKey, order } = sorter as SorterResult<CodeDecodeValueViewModel>;
    if (columnKey && order) {
      query.set('sortBy', getSortBy(columnKey.toString(), order));
    } else {
      query.set('sortBy', '');
    }

    navigate(
      getLink(
        'CodeDecodeValueList',
        { ...params },
        {
          ...query,
          sortBy: query.get('sortBy'),
          p: paginationConfig.current,
          size: paginationConfig.pageSize,
          q: pagination.filter || '',
        }
      )
    );
  };

  const handleSearch = (searchText: string) => {
    navigate(
      getLink(
        'CodeDecodeValueList',
        { ...params },
        {
          ...query,
          p: DefaultPage,
          q: searchText?.length ? `Code:*${searchText.replaceAll(" ", "\\ ")}*` : '',
        }
      )
    );
  };

  const handleDelete = async (codedecodevalue: CodeDecodeValueViewModel) => {
    try {
      await deleteCodeDecodeValue({
        Id: codedecodevalue.id ?? 0,
      }).unwrap();
      message.success(`Code Decode Value ${codedecodevalue.code} deleted`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <PageHeader
        extra={
            <Space>
                  <Button htmlType="button" data-testid={'create-new-codedecodevalue'} onClick={onCreateNew}>
                  New Code Decode Value
                  </Button>
                </Space>
        }
        title={
          <Title
            level={4}
            style={{
              marginBottom: 0,
              display: 'inline-block',
            }}
          >
            Code Decode Values
          </Title>
        }
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: params.tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: params.tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={getLink('CodeDecodeTypeList', { ...props, tenantId:params.tenantId })}>Code Decode Types</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="codedecodevalues">
              <Link to={getLink('CodeDecodeValueList', { tenantId: params.tenantId,codedecodetypeid: params.codedecodetypeid })}>CodeDecodeValues</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Card>
          {error?.status && (
            <Alert message="Error" description={error.data} type="error" showIcon />
          )}
          {deleteError && (
            <Alert message="Error" description={deleteError.toString()} type="error" showIcon />
          )}
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Input.Search
              placeholder="Search by Code"
              onSearch={handleSearch}
              enterButton
              style={{ width: 400 }}
              loading={isFetching}
              allowClear
            />
            <Table
            scroll={{ x: true }}
              dataSource={pagedData.results}
              onChange={handleTableChange}
              rowKey={'id'}
              pagination={{
                ...pagination,
                total: pagedData.totalCount,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ["10", "20", "50","100"],
                showSizeChanger: true,
                locale: { items_per_page: " / Page" }
              }}
              columns={codedecodevalueTable.columns}
              loading={isFetching}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => { 
                    setEntityId(record?.id||0);
                  },
                };
              }}
            />
          </Space>
        </Card>
        {showAuditLogs?<AuditLogsScreen entityId={entityId} entityName={'CodeDecodeValues'} showAuditLogs={setShowAuditLogs}/>:<></>}
      </PageHeader>
    </div>
  );
}
