import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  PartnerConnectionEndpointListRequest,
  useDeletePartnerConnectionEndpointMutation,
  useFetchPartnerConnectionEndpointsQuery,
} from '../partnerconnectionendpoint-api';
import AuditLogsScreen from '../../../components/common/auditLogs';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Button,
  Card,
  Dropdown,
  Menu,
  message,
  Modal,
  Space,
  Table,
  Tooltip
} from 'antd';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
  MinusCircleFilled,
  MoreOutlined,
  UserOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { PartnerConnectionEndpointViewModel, PaginatedResult } from '../../api/models';
import { DefaultPage, DefaultPageSize, DefaultPollingInterval } from '../../../core/defaults';
import Title from 'antd/lib/typography/Title';
import { getLink } from '../../../App-router';
import { TableState } from '../../../components/table/model';
import getSortBy from '../../../components/table/sorter';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function PartnerConnectionEndpointsScreen(props: any) {
  const query = useQuery();
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const [showAuditLogs, setShowAuditLogs] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<number>(0);
  // console.log('PartnerConnectionEndpointsScreen', props);
  const partnerconnectionendpointTable: TableState = {
    columns: [
      {
        title: 'Id',
        dataIndex: '',
        key: 'Id',
        render: (partnerconnectionendpoint: PartnerConnectionEndpointViewModel) => (
          <>
              <Space>
                <span>{partnerconnectionendpoint.id}</span>
              </Space>
          </>
        ),
        fixed: 'left',
      },
      {
        title: 'ConnectionEndpoint',
        dataIndex: '',
        key: 'connectionendpoint',
        render: (partnerconnectionendpoint: PartnerConnectionEndpointViewModel) => (
          <>
          <Tooltip title={partnerconnectionendpoint.connectionEndpoint}>
          <span>{partnerconnectionendpoint.connectionEndpoint}</span>
          </Tooltip>              
          </>
        )
      },
      {
        title: 'PartnerEDIX12',
        dataIndex: '',
        key: 'partneredix12',
        render: (partnerconnectionendpoint: PartnerConnectionEndpointViewModel) => (
          <>
              <span>{partnerconnectionendpoint.partnerEDIX12}</span>
          </>
        ),
      },
      {
        title: 'FolderPath',
        dataIndex: '',
        key: 'folderpath',
        render: (partnerconnectionendpoint: PartnerConnectionEndpointViewModel) => (
          <>
          <Tooltip title={partnerconnectionendpoint.folderPath}>
          <span>{partnerconnectionendpoint.folderPath}</span>
          </Tooltip>
          </>
        ),
      },
      {
        title: 'IsActive',
        key: 'isactive',
        dataIndex: '',
        render: (partnerconnectionendpoint: PartnerConnectionEndpointViewModel) => (
          <>
              <span>{partnerconnectionendpoint.isActive?'True':'False'}</span>
          </>
        ),
      },      
      {
        title: 'Actions',
        key: 'action',
        dataIndex: '',
        fixed:'right',
        render: (partnerconnectionendpoint: PartnerConnectionEndpointViewModel) => (
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            overlay={
              <Menu style={{ padding: '8px 8px' }}>
                <Menu.Item icon={<EditOutlined />} key="1">
                  <Link
                    to={getLink('PartnerConnectionEndpointEdit', {
                      tenantId: params.tenantId,
                      Id: partnerconnectionendpoint.id
                    })}
                  >
                    Edit
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<InfoCircleOutlined />} key="3">
                <Link to="#" onClick={()=>setShowAuditLogs(true)}>
                    Audit Logs
                  </Link>
                </Menu.Item>
                <Menu.Item disabled={true}
                  icon={<DeleteOutlined />}
                  key="2"
                  onClick={() => {
                    confirm({
                      title: (
                        <span>
                          Delete partner connection endpoint <b>{partnerconnectionendpoint.connectionEndpointId}</b>?
                        </span>
                      ),
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        handleDelete(partnerconnectionendpoint);
                      },
                    });
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
          >
            <MoreOutlined />
          </Dropdown>
        ),
      },
    ],
  };

  const getCurrentState = (): PartnerConnectionEndpointListRequest => ({
    current: Number(localStorage.getItem('pcePageIndex')) || DefaultPage,
    pageSize: Number(localStorage.getItem('pcePageSize')) || DefaultPageSize,
    // filter: query.get('q'),
    filter: props.partnerEDIX12Id>0 ? `partnerEDIX12Id:"${props.partnerEDIX12Id}"` : '', 
    sortBy: query.get('sortBy') || 'EnteredByDate Desc',
  });

  const [pagination, setPagination] = useState<PartnerConnectionEndpointListRequest>(getCurrentState());

  const {
    data: pagedData = {},
    isFetching,
    error,
  }: {
    data?: PaginatedResult<PartnerConnectionEndpointViewModel> | undefined;
    isFetching?: boolean | undefined;
    error?: any;
  } = useFetchPartnerConnectionEndpointsQuery(pagination);

  useEffect(() => {
    setPagination(getCurrentState());
  }, [params, props]);

  const [deletePartnerConnectionEndpoint, { isLoading: isDeleteLoading, error: deleteError }] =
    useDeletePartnerConnectionEndpointMutation();
  const { confirm } = Modal;

  const onCreateNew = useCallback(
    () => navigate(getLink('PartnerConnectionEndpointEdit', { ...params, Id: 'new' })),
    [params, navigate]
  );

  const handleTableChange = (
    paginationConfig: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<PartnerConnectionEndpointViewModel> | SorterResult<PartnerConnectionEndpointViewModel>[],
    extra: TableCurrentDataSource<PartnerConnectionEndpointViewModel>
  ) => {
    const { columnKey, order } = sorter as SorterResult<PartnerConnectionEndpointViewModel>;
    if (columnKey && order) {
      query.set('sortBy', getSortBy(columnKey.toString(), order));
    } else {
      query.set('sortBy', '');
    }
    localStorage.setItem('pcePageSize',(paginationConfig.pageSize||DefaultPageSize).toString());
    localStorage.setItem('pcePageIndex',paginationConfig.current?.toString()||'1');
    navigate(
      getLink('CarrierOnboardingEdit', {
        tenantId: params.tenantId,
        Id: localStorage.getItem('splittingRuleID'),
        partnershipId: Number(localStorage.getItem('partnershipId')||0)
      })
    );
  };

  const handleDelete = async (partnerconnectionendpoint: PartnerConnectionEndpointViewModel) => {
    try {
      await deletePartnerConnectionEndpoint({
        Id: partnerconnectionendpoint.id ?? 0,
      }).unwrap();
      message.success(`Partner Connection Endpoint ${partnerconnectionendpoint.connectionEndpointId} deleted`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <PageHeader
        extra={
            <Space>
                  <Button htmlType="button" data-testid={'create-new-partnerconnectionendpoint'} onClick={onCreateNew}>
                  New Partner Connection Endpoint
                  </Button>
                </Space>
        }
        title={
          <Title
            level={4}
            style={{
              marginBottom: 0,
              display: 'inline-block',
            }}
          >
            Partner Connection Endpoints
          </Title>
        }
      >
        <Card>
          {error?.status && (
            <Alert message="Error" description={error.data} type="error" showIcon />
          )}
          {deleteError && (
            <Alert message="Error" description={deleteError.toString()} type="error" showIcon />
          )}
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Table scroll={{ x: true }}
              dataSource={pagedData.results}
              onChange={handleTableChange}
              rowKey={'id'}
              pagination={{
                ...pagination,
                total: pagedData.totalCount,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ["10", "20", "50","100"],
                showSizeChanger: true,
                locale: { items_per_page: " / Page" }
              }}
              columns={partnerconnectionendpointTable.columns}
              loading={isFetching}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => { 
                    setEntityId(record?.id||0);
                  },
                };
              }}
            />
          </Space>
        </Card>
        {showAuditLogs?<AuditLogsScreen entityId={entityId} entityName={'PartnerConnectionEndpoint'} showAuditLogs={setShowAuditLogs}/>:<></>}
      </PageHeader>
    </div>
  );
}
