import { createSlice } from '@reduxjs/toolkit';
import { TableState } from '../../components/table/model';

const initialState: TableState = {
  columns: [],
};

const carrieronboardingListSlice = createSlice({
  name: 'carrieronboardingTable',
  initialState,
  reducers: {
    hideColumn(state) {},
  },
});

export const carrieronboardingsReducer = carrieronboardingListSlice.reducer;
