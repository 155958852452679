import { apiSlice } from '../api/api-slice';
import { PartnerEDIX12ViewModel, PaginatedResult } from '../api/models';

export interface PartnerEDIX12ListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
}

export interface EditPartnerEDIX12Request {
  partneredix12: PartnerEDIX12ViewModel;
}

export interface PartnerEDIX12Request {
  Id: number;
}

export interface DeletePartnerEDIX12Request {
  Id: number;
}

export const partnerEDIX12sApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchPartnerEDIX12s: builder.query<PaginatedResult<PartnerEDIX12ViewModel>, PartnerEDIX12ListRequest>({
        query({ current, pageSize, filter, sortBy }) {
          return `/partnerEDIX12s?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'PartnerEDIX12',
          ...(data?.results?.map(({ id }) => ({ type: 'PartnerEDIX12' as const, id: `${id}` })) || [])
        ] 
      }),
      fetchSinglePartnerEDIX12: builder.query<PartnerEDIX12ViewModel, PartnerEDIX12Request>({
        query({ Id }) {
          return `/partnerEDIX12s/${Id}`;
        },
        providesTags: (_data, _error, arg) => [{ type: 'PartnerEDIX12', id: `${arg.Id}` }]
      }),
      createPartnerEDIX12: builder.mutation<PartnerEDIX12ViewModel, EditPartnerEDIX12Request>({
        query: ({ partneredix12 }) => {
          return {
            url: `/partnerEDIX12s`,
            method: 'POST',
            body: partneredix12,
          };
        },
        invalidatesTags: ['PartnerEDIX12']
      }),
      updatePartnerEDIX12: builder.mutation<PartnerEDIX12ViewModel, EditPartnerEDIX12Request>({
        query: ({ partneredix12 }) => {
          return {
            url: `/partnerEDIX12s/${partneredix12.id}`,
            method: 'PUT',
            body: partneredix12,
          };
        },
        invalidatesTags: (_data, _error, arg) => [{ type: 'PartnerEDIX12', id: `${arg.partneredix12.id}` }]
      }),
      deletePartnerEDIX12: builder.mutation<void, DeletePartnerEDIX12Request>({
        query: ({ Id }) => ({
          url: `/partnerEDIX12s/${Id}`,
          method: 'DELETE',
          body: {},
        }),
        invalidatesTags: (_data, _error, arg) => ['PartnerEDIX12']
      })
    };
  },
});

export const {
  useFetchPartnerEDIX12sQuery,
  useFetchSinglePartnerEDIX12Query,
  useCreatePartnerEDIX12Mutation,
  useUpdatePartnerEDIX12Mutation,
  useDeletePartnerEDIX12Mutation,
} = partnerEDIX12sApi;
