import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  CustomerListRequest,
  useDeleteCustomerMutation,
  useFetchCustomersQuery,
} from '../customer-api';
import AuditLogsScreen from '../../../components/common/auditLogs';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Badge,
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Space,
  Table,
  Tooltip
} from 'antd';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
  MinusCircleFilled,
  MoreOutlined,
  UserOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { CustomerViewModel, PaginatedResult } from '../../api/models';
import { DefaultPage, DefaultPageSize, DefaultPollingInterval } from '../../../core/defaults';
import Title from 'antd/lib/typography/Title';
import { getLink } from '../../../App-router';
import { TableState } from '../../../components/table/model';
import getSortBy from '../../../components/table/sorter';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function CustomersScreen(props: any) {
  const query = useQuery();
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const [showAuditLogs, setShowAuditLogs] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<number>(0);
  // console.log('CustomersScreen', params);
  const customerTable: TableState = {
    columns: [
      {
        title: 'Customer',
        dataIndex: '',
        key: 'Id',
        render: (customer: CustomerViewModel) => (
          <>
              <Space>
                <span>{customer.id}</span>
              </Space>
          </>
        ),
        sorter: true,
        fixed: 'left',
      },
      {
        title: 'Name',
        dataIndex: '',
        key: 'name',
        render: (customer: CustomerViewModel) => (
          <>
              <Tooltip title={customer.name} >
            <span>{customer.name}</span>
            </Tooltip>
          </>
        ),
        sorter: true,
      },
      {
        title: 'Type',
        dataIndex: '',
        key: 'type',
        render: (customer: CustomerViewModel) => (
          <>
              <span>{customer.type}</span>
          </>
        ),
      },
      {
        title: 'Qualifier',
        key: 'qualifier',
        dataIndex: '',
        render: (customer: CustomerViewModel) => (
          <>
              <span>{customer.qualifier}</span>
          </>
        ),
      },      
      {
        title: 'Actions',
        key: 'action',
        dataIndex: '',
        fixed:'right',
        render: (customer: CustomerViewModel) => (
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            overlay={
              <Menu style={{ padding: '8px 8px' }}>
                <Menu.Item icon={<EditOutlined />} key="1">
                  <Link
                    to={getLink('CustomerEdit', {
                      tenantId: params.tenantId,
                      Id: customer.id,
                    })}
                  >
                    Edit
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<InfoCircleOutlined />} key="3">
                <Link to="#" onClick={()=>setShowAuditLogs(true)}>
                    Audit Logs
                  </Link>
                </Menu.Item>
                <Menu.Item disabled={true}
                  icon={<DeleteOutlined />}
                  key="2"
                  onClick={() => {
                    confirm({
                      title: (
                        <span>
                          Delete customer <b>{customer.name}</b>?
                        </span>
                      ),
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        handleDelete(customer);
                      },
                    });
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
          >
            <MoreOutlined />
          </Dropdown>
        ),
      },
    ],
  };

  const getCurrentState = (): CustomerListRequest => ({
    current: Number(query.get('p')) || DefaultPage,
    pageSize: Number(query.get('size')) || DefaultPageSize,
    filter: query.get('q'),
    sortBy: query.get('sortBy') || 'EnteredByDate Desc',
  });

  const [pagination, setPagination] = useState<CustomerListRequest>(getCurrentState());

  const {
    data: pagedData = {},
    isFetching,
    error,
  }: {
    data?: PaginatedResult<CustomerViewModel> | undefined;
    isFetching?: boolean | undefined;
    error?: any;
  } = useFetchCustomersQuery(pagination, { pollingInterval: DefaultPollingInterval });

  useEffect(() => {
    setPagination(getCurrentState());
  }, [params]);

  const [deleteCustomer, { isLoading: isDeleteLoading, error: deleteError }] =
    useDeleteCustomerMutation();
  const { confirm } = Modal;

  const onCreateNew = useCallback(
    () => navigate(getLink('CustomerEdit', { ...params, Id: 'new' })),
    [params, navigate]
  );

  const handleTableChange = (
    paginationConfig: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<CustomerViewModel> | SorterResult<CustomerViewModel>[],
    extra: TableCurrentDataSource<CustomerViewModel>
  ) => {
    const { columnKey, order } = sorter as SorterResult<CustomerViewModel>;
    if (columnKey && order) {
      query.set('sortBy', getSortBy(columnKey.toString(), order));
    } else {
      query.set('sortBy', '');
    }

    navigate(
      getLink(
        'CustomerList',
        { ...params },
        {
          ...query,
          sortBy: query.get('sortBy'),
          p: paginationConfig.current,
          size: paginationConfig.pageSize,
          q: pagination.filter || '',
        }
      )
    );
  };

  const handleSearch = (searchText: string) => {
    navigate(
      getLink(
        'CustomerList',
        { ...params },
        {
          ...query,
          p: DefaultPage,
          q: searchText?.length ? `Name:*${searchText.replaceAll(" ", "\\ ")}*` : '',
        }
      )
    );
  };

  const handleDelete = async (customer: CustomerViewModel) => {
    try {
      await deleteCustomer({
        Id: customer.id ?? 0,
      }).unwrap();
      message.success(`Customer ${customer.name} deleted`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <PageHeader
        extra={
          <Button htmlType="button" data-testid={'create-new-customer'} onClick={onCreateNew}>
            New Customer
          </Button>
        }
        title={
          <Title
            level={4}
            style={{
              marginBottom: 0,
              display: 'inline-block',
            }}
          >
            Customers
          </Title>
        }
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: params.tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: params.tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="customers">
              <Link to={getLink('CustomerList', { tenantId: params.tenantId })}>Customers</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Card>
          {error?.status && (
            <Alert message="Error" description={error.data} type="error" showIcon />
          )}
          {deleteError && (
            <Alert message="Error" description={deleteError.toString()} type="error" showIcon />
          )}
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Input.Search
              placeholder="Search by Name"
              onSearch={handleSearch}
              enterButton
              style={{ width: 400 }}
              loading={isFetching}
              allowClear
            />
            <Table
            scroll={{ x: true }}
              dataSource={pagedData.results}
              onChange={handleTableChange}
              rowKey={'id'}
              pagination={{
                ...pagination,
                total: pagedData.totalCount,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ["10", "20", "50","100"],
                showSizeChanger: true,
                locale: { items_per_page: " / Page" }
              }}
              columns={customerTable.columns}
              loading={isFetching}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => { 
                    setEntityId(record?.id||0);
                  },
                };
              }}
            />
          </Space>
        </Card>
        {showAuditLogs?<AuditLogsScreen entityId={entityId} entityName={'Customer'} showAuditLogs={setShowAuditLogs}/>:<></>}
      </PageHeader>
    </div>
  );
}
