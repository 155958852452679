import CodeDecodeValuesScreen from './screens/codedecodevalues-screen';
import CodeDecodeValueEditScreen from './screens/codedecodevalue-edit-screen';
import { RouteDefinition } from '../../core/route';

export const codedecodevalueRoutes: RouteDefinition[] = [
  {
    id: 'CodeDecodeValueEdit',
    title: 'Code Decode Value',
    path: 'codedecodevalues/:codedecodetypeid/:Id',
    element: <CodeDecodeValueEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'CodeDecodeValueList',
    title: 'Code Decode Values',
    path: 'codedecodevalues/:codedecodetypeid',
    element: <CodeDecodeValuesScreen />,
    showInNavigation: false,
  },
];
