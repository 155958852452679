import PartnerRatingsScreen from './screens/partnerratings-screen';
import PartnerRatingEditScreen from './screens/partnerrating-edit-screen';
import { RouteDefinition } from '../../core/route';

export const partnerratingRoutes: RouteDefinition[] = [
  {
    id: 'PartnerRatingEdit',
    title: 'Routing Rule',
    path: 'partnerrating/:Id',
    element: <PartnerRatingEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'PartnerRatingList',
    title: 'Routing Rules',
    path: 'partnerrating',
    element: <PartnerRatingsScreen />,
    showInNavigation: false,
  },
];
