import { apiSlice } from '../api/api-slice';
import { PartnerRatingViewModel, PartnerRatingListViewModel, PaginatedResult } from '../api/models';

export interface PartnerRatingListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;  
  partnerInformationId:number;
}

export interface EditPartnerRatingRequest {
  PartnerRating: PartnerRatingViewModel;
}

export interface PartnerRatingRequest {
  Id: number;
}

export interface DeletePartnerRatingRequest {
  Id: number;
}

export const routingRuleApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchPartnerRatings: builder.query<PaginatedResult<PartnerRatingListViewModel>, PartnerRatingListRequest>({
        query({ current, pageSize, filter, sortBy, partnerInformationId }) {
          return `/partnerRatings?partnerInformationId=${partnerInformationId}&pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'PartnerRating',
          ...(data?.results?.map(({ partnerInformationId }) => ({ type: 'PartnerRating' as const, id: `${partnerInformationId}` })) || [])
        ] 
      }),
      fetchSinglePartnerRating: builder.query<PartnerRatingViewModel, PartnerRatingRequest>({
        query({ Id }) {
          return `/partnerRatings/${Id}`;
        },
        providesTags: (_data, _error, arg) => [{ type: 'PartnerRating', id: `${arg.Id}` }]
      }),
      createPartnerRating: builder.mutation<PartnerRatingViewModel, EditPartnerRatingRequest>({
        query: ({ PartnerRating }) => {
          return {
            url: `/partnerRatings`,
            method: 'POST',
            body: PartnerRating,
          };
        },
        invalidatesTags: ['PartnerRating']
      })
    };
  },
});

export const {
  useFetchPartnerRatingsQuery,
  useFetchSinglePartnerRatingQuery,
  useCreatePartnerRatingMutation,
} = routingRuleApi;
