import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  useCreateCodeDecodeTypeMutation,
  useFetchSingleCodeDecodeTypeQuery,
  useUpdateCodeDecodeTypeMutation,
} from '../codedecodetype-api';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined, UserOutlined,QuestionCircleOutlined } from '@ant-design/icons';
import { Company, PaginatedResult, CodeDecodeTypeViewModel } from '../../api/models';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ConvertUTCtoCDT } from '../../../core/utils';
import { getLink } from '../../../App-router';
import { DefaultTenantId } from '../../../core/defaults';
const { Option } = Select;

const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError =>
  error ? 'status' in error : false;

const getErrorMessage = (error: any): string | undefined => error.data;

interface CodeDecodeTypeScreenParams {
Id?: string | undefined;
tenantId?: string | undefined;
  [key: string]: string | undefined;
}

export default function CodeDecodeTypeEditScreen(props: any) {
  const { Id, tenantId = DefaultTenantId}: CodeDecodeTypeScreenParams =
    useParams<CodeDecodeTypeScreenParams>();

  const isNewCodeDecodeType = Id === 'new';

  const { data, isFetching } = useFetchSingleCodeDecodeTypeQuery(
    {
      Id: Number(Id),
    },
    { skip: isNewCodeDecodeType }
  );

  const [createCodeDecodeType, { isLoading: isCreating, error: createError }] = useCreateCodeDecodeTypeMutation();
  const [updateCodeDecodeType, { isLoading: isUpdating, error: updateError }] = useUpdateCodeDecodeTypeMutation();

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [form] = Form.useForm();

  React.useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const onFinish = (values: any | CodeDecodeTypeViewModel) => {
    const requestData = {
      codedecodetype: {
        id: isNewCodeDecodeType ? 0 : Number(Id),
        type: values.type,
        description: values.description,
        updatedBy:sessionStorage.getItem('userEmail')||'',
        enteredBy:isNewCodeDecodeType?sessionStorage.getItem('userEmail')||'':'',
      },
    };

    const request = isNewCodeDecodeType ? createCodeDecodeType(requestData) : updateCodeDecodeType(requestData);
    request.unwrap().then((response) => {
      if(response?.id ===-200){
        message.error(
          `This combination already exists. Please try other values.`
        );
        return;
      }
      message.success(
        `Code Decode Type is ${isNewCodeDecodeType ? 'created' : 'saved'}`
      );
      navigate(
        getLink('CodeDecodeTypeList', {
          ...props,
          tenantId: tenantId,
        })
      );
    });
  };
  const [disabledSave, setDisabledSave] = useState(true);
  
  const handleFormChange = () => {
  const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
  setDisabledSave(hasErrors);
  }
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };

  const handleCancel = () => {
    navigate(
      getLink('CodeDecodeTypeList', {
        ...props,
        tenantId: tenantId,
      })
    );
  };

  return (
    <div>
      <PageHeader
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={getLink('CodeDecodeTypeList', { ...props, tenantId })}>Code Decode Types</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{isNewCodeDecodeType ? 'New Code Decode Type' : data?.type}</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {isFetchBaseQueryErrorType(createError) && (
          <Alert message="Error" description={getErrorMessage(createError)} type="error" showIcon />
        )}
        {isFetchBaseQueryErrorType(updateError) && (
          <Alert message="Error" description={getErrorMessage(updateError)} type="error" showIcon />
        )}
        <Card
          title={
            <Title
              level={4}
              style={{
                marginBottom: 0,
                display: 'inline-block',
              }}
            >
              {isNewCodeDecodeType ? 'New Code Decode Type' : 'Edit Code Decode Type'}
            </Title>
          }
        >
          <Spin spinning={isFetching || isCreating || isUpdating}>
            <Form
              {...layout}
              form={form}
              name="control-hooks"
              initialValues={data}
              onFinish={onFinish}
              onFieldsChange={handleFormChange}
            >
              <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                <Input placeholder='Please enter code decode type in the format as EDI210_MGFreightBill_ReferenceType valid upto 100 characters.' data-testid="type" maxLength={100} suffix={
                        <Tooltip title="Please enter code decode type in the format as EDI210_MGFreightBill_ReferenceType valid upto 100 characters.">
                          <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      } />
              </Form.Item>
              
              <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                <Input placeholder='Please enter valid description valid upto 1000 characters.' showCount data-testid="description" maxLength={1000} suffix={
                        <Tooltip title="Please enter valid description valid upto 1000 characters.">
                          <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>

              {!isNewCodeDecodeType?
              <div>
                <Form.Item name="enteredBy" label="Entered By">
                <Space>
                  <span>{data?.enteredBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="enteredByDate" label="Entered By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.enteredByDate||'')}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updatedBy" label="Updated By">
                <Space>
                  <span>{data?.updatedBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updateByDate" label="Updated By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.updateByDate||'')}</span>
                </Space>
              </Form.Item>      
              </div>:''
              }

              <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                <Space>
                  <Button type="primary" htmlType="submit" disabled={disabledSave && !isNewCodeDecodeType}>
                    {isNewCodeDecodeType ? 'Add Code Decode Type' : 'Save Code Decode Type'}
                  </Button>
                  <Button htmlType="button" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </PageHeader>
    </div>
  );
}
