import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/auth-slice';
import { apiSlice } from './features/api/api-slice';
import { monitorsReducer } from './features/monitors/monitor-store';
import { tenantSliceReducer } from './features/tenant/tenant-store';
import { partnersReducer } from './features/partners/partner-store';

import { customersReducer } from './features/customers/customer-store';
import { endsystemsReducer } from './features/endsystems/endsystem-store';
import { profilemapsReducer } from './features/profilemaps/profilemap-store';
import { sourcetypesReducer } from './features/sourcetypes/sourcetype-store';
import { targettypesReducer } from './features/targettypes/targettype-store';
import { transactiontypesReducer } from './features/transactiontype/transactiontype-store';
import { connectionendpointsReducer } from './features/connectionendpoint/connectionendpoint-store';
import { partneredix12sReducer } from './features/partneredix12/partneredix12-store';

import { carrieronboardingsReducer } from './features/carrieronboarding/carrieronboarding-store';
import { acknowledgmentoverridesReducer } from './features/acknowledgmentoverride/acknowledgmentoverride-store';
import { partnerconnectionendpointsReducer } from './features/partnerconnectionendpoint/partnerconnectionendpoint-store';
import { codedecodetypesReducer } from './features/codedecodetype/codedecodetype-store';
import { codedecodemapsReducer } from './features/codedecodemap/codedecodemap-store';
import { codedecodevaluesReducer } from './features/codedecodevalue/codedecodevalue-store';
import { alertsReducer } from './features/alerts/alert-store';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    monitors: monitorsReducer,
    partners: partnersReducer,
    customers: customersReducer,
    profileMaps: profilemapsReducer,
    endsystems: endsystemsReducer,
    sourceTypes: sourcetypesReducer,
    targetTypes: targettypesReducer,
    transactionTypes: transactiontypesReducer,
    connectionEndPoints: connectionendpointsReducer,
    partnerEdiX12s: partneredix12sReducer,
    carrieronboardings:carrieronboardingsReducer,
    acknowledgmentoverrides:acknowledgmentoverridesReducer,
    partnerconnectionendpoints:partnerconnectionendpointsReducer,
    codedecodetypes:codedecodetypesReducer,
    codedecodemaps:codedecodemapsReducer,
    codedecodevalues:codedecodevaluesReducer,
    tenant: tenantSliceReducer,
    alerts: alertsReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
