import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  PartnerListRequest,
  useDeletePartnerMutation,
  useFetchPartnersQuery,
} from '../partner-api';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Badge,
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Space,
  Table,
} from 'antd';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
  MinusCircleFilled,
  MoreOutlined,
  UserOutlined,
  WarningFilled,
} from '@ant-design/icons';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { PartnerViewModel, PaginatedResult } from '../../api/models';
import { DefaultPage, DefaultPageSize, DefaultPollingInterval } from '../../../core/defaults';
import Title from 'antd/lib/typography/Title';
import { getLink } from '../../../App-router';
import { TableState } from '../../../components/table/model';
import getSortBy from '../../../components/table/sorter';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function PartnersScreen(props: any) {
  const query = useQuery();
  const params: any = useParams<any>();
  const navigate = useNavigate();
  // console.log('PartnersScreen', params);
  const partnerTable: TableState = {
    columns: [
      {
        title: 'Partner',
        dataIndex: '',
        key: 'partnerID',
        render: (partner: PartnerViewModel) => (
          <>
            {partner.isActive ? (
              <Link to={getLink('MonitorList', { ...params }, { partnerId: partner.partnerId })}>
                <Space>
                  {partner.monitorsErrors ? (
                    <CloseCircleFilled style={{ color: 'red' }} />
                  ) : partner.monitorsWarning ? (
                    <WarningFilled style={{ color: 'orange' }} />
                  ) : (
                    <CheckCircleFilled style={{ color: 'green' }} />
                  )}
                  <span>{partner.companyName}</span>
                </Space>
              </Link>
            ) : (
              <Space>
                <MinusCircleFilled style={{ color: '#b1bac1c2' }} />
                <span>{partner.companyName}</span>
              </Space>
            )}
          </>
        ),
        sorter: true,
      },
      {
        title: 'Description',
        dataIndex: '',
        key: 'description',
        render: (partner: PartnerViewModel) => (
          <>
            {partner.isActive ? (
              <Link to={getLink('MonitorList', { ...params }, { partnerId: partner.partnerId })}>
                {partner.description}
              </Link>
            ) : (
              <span>{partner.description}</span>
            )}
          </>
        ),
        sorter: true,
      },
      {
        title: 'Monitors',
        dataIndex: '',
        key: 'monitorCount',
        render: (partner: PartnerViewModel) => (
          <>
            {partner.isActive ? (
              <Link to={getLink('MonitorList', { ...params }, { partnerId: partner.partnerId })}>
                {partner.monitorsCount ?? 0}
              </Link>
            ) : (
              <span>-</span>
            )}
          </>
        ),
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: '',
        render: (partner: PartnerViewModel) => (
          <>
            {partner.isActive ? (
              <Space>
                {(partner.monitorsOk ?? 0) > 0 && (
                  <Badge count={partner.monitorsOk} style={{ backgroundColor: '#87d068' }}></Badge>
                )}
                {(partner.monitorsWarning ?? 0) > 0 && (
                  <Badge
                    count={partner.monitorsWarning}
                    style={{ backgroundColor: 'orange' }}
                  ></Badge>
                )}
                {(partner.monitorsErrors ?? 0) > 0 && (
                  <Badge count={partner.monitorsErrors} style={{ backgroundColor: 'red' }}></Badge>
                )}
              </Space>
            ) : (
              <span>-</span>
            )}
          </>
        ),
      },
      {
        title: '',
        key: 'action',
        dataIndex: '',
        render: (partner: PartnerViewModel) => (
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            overlay={
              <Menu style={{ padding: '8px 8px' }}>
                <Menu.Item icon={<EditOutlined />} key="1">
                  <Link
                    to={getLink('PartnerEdit', {
                      tenantId: params.tenantId,
                      partnerId: partner.partnerId,
                    })}
                  >
                    Edit
                  </Link>
                </Menu.Item>
                <Menu.Item
                  icon={<DeleteOutlined />}
                  key="2"
                  onClick={() => {
                    confirm({
                      title: (
                        <span>
                          Delete partner <b>{partner.description}</b>?
                        </span>
                      ),
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        handleDelete(partner);
                      },
                    });
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
          >
            <MoreOutlined />
          </Dropdown>
        ),
      },
    ],
  };

  const getCurrentState = (): PartnerListRequest => ({
    tenantId: params.tenantId,
    current: Number(query.get('p')) || DefaultPage,
    pageSize: Number(query.get('size')) || DefaultPageSize,
    filter: query.get('q'),
    sortBy: query.get('sortBy'),
  });

  const [pagination, setPagination] = useState<PartnerListRequest>(getCurrentState());

  const {
    data: pagedData = {},
    isFetching,
    error,
  }: {
    data?: PaginatedResult<PartnerViewModel> | undefined;
    isFetching?: boolean | undefined;
    error?: any;
  } = useFetchPartnersQuery(pagination, { pollingInterval: DefaultPollingInterval });

  useEffect(() => {
    setPagination(getCurrentState());
  }, [params]);

  const [deletePartner, { isLoading: isDeleteLoading, error: deleteError }] =
    useDeletePartnerMutation();
  const { confirm } = Modal;

  const onCreateNew = useCallback(
    () => navigate(getLink('PartnerEdit', { ...params, partnerId: 'new' })),
    [params, navigate]
  );

  const handleTableChange = (
    paginationConfig: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<PartnerViewModel> | SorterResult<PartnerViewModel>[],
    extra: TableCurrentDataSource<PartnerViewModel>
  ) => {
    const { columnKey, order } = sorter as SorterResult<PartnerViewModel>;
    if (columnKey && order) {
      query.set('sortBy', getSortBy(columnKey.toString(), order));
    } else {
      query.set('sortBy', '');
    }

    navigate(
      getLink(
        'PartnerList',
        { ...params },
        {
          ...query,
          sortBy: query.get('sortBy'),
          p: paginationConfig.current,
          size: paginationConfig.pageSize,
          q: pagination.filter || '',
        }
      )
    );
  };

  const handleSearch = (searchText: string) => {
    navigate(
      getLink(
        'PartnerList',
        { ...params },
        {
          ...query,
          p: DefaultPage,
          q: searchText?.length ? `Description:*${searchText.split(' ')[0]}*` : '',
        }
      )
    );
  };

  const handleDelete = async (partner: PartnerViewModel) => {
    try {
      await deletePartner({
        tenantId: params.tenantId,
        partnerId: partner.partnerId ?? 0,
      }).unwrap();
      message.success(`Partner ${partner.description} deleted`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <PageHeader
        extra={
          <Button type="primary" data-testid={'create-new-partner'} onClick={onCreateNew}>
            New Partner
          </Button>
        }
        title={
          <Title
            level={4}
            style={{
              marginBottom: 0,
              display: 'inline-block',
            }}
          >
            Partners
          </Title>
        }
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: params.tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="partners">
              <Link to={getLink('PartnerList', { tenantId: params.tenantId })}>Partners</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Card>
          {error?.status && (
            <Alert message="Error" description={error.data} type="error" showIcon />
          )}
          {deleteError && (
            <Alert message="Error" description={deleteError.toString()} type="error" showIcon />
          )}
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Input.Search
              placeholder="Search"
              onSearch={handleSearch}
              enterButton
              style={{ width: 400 }}
              loading={isFetching}
              allowClear
            />
            <Table
              dataSource={pagedData.results}
              onChange={handleTableChange}
              rowKey={'partnerId'}
              pagination={{
                ...pagination,
                total: pagedData.totalCount,
                showTotal: (total) => `Total ${total} items`,
              }}
              columns={partnerTable.columns}
              loading={isFetching}
            />
          </Space>
        </Card>
      </PageHeader>
    </div>
  );
}
