import CustomersScreen from './screens/customers-screen';
import CustomerEditScreen from './screens/customer-edit-screen';
import { RouteDefinition } from '../../core/route';

export const customerRoutes: RouteDefinition[] = [
  {
    id: 'CustomerEdit',
    title: 'Customer',
    path: 'customers/:Id',
    element: <CustomerEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'CustomerList',
    title: 'Customers',
    path: 'customers',
    element: <CustomersScreen />,
    showInNavigation: true,
  },
];
