import { apiSlice } from '../api/api-slice';
import { PartnershipInformationViewModel, PaginatedResult } from '../api/models';

export interface PartnershipInformationListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
  type?:string|null;
  searchText?:string|null;
}

export interface EditPartnershipInformationRequest {
  partnershipinformation: PartnershipInformationViewModel;
}
export interface DeletePartnershipInformationRequest {
  Id: number;
}
export interface PartnershipInformationRequest {
  Id: number;
}

export const partnershipInformationApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchPartnershipInformations: builder.query<PaginatedResult<PartnershipInformationViewModel>, PartnershipInformationListRequest>({
        query({ current, pageSize, filter, sortBy }) {
          return `/PartnershipInformations?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'PartnershipInformation',
          ...(data?.results?.map(({ id }) => ({ type: 'PartnershipInformation' as const, id: `${id}` })) || [])
        ] 
      }),
      fetchSinglePartnershipInformation: builder.query<PartnershipInformationViewModel, PartnershipInformationRequest>({
        query({ Id }) {
          return `/partnershipInformations/${Id}`;
        },
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (_data, _error, arg) => [{ type: 'PartnershipInformation', id: `${arg.Id}` }]
      }),
      createPartnershipInformation: builder.mutation<PartnershipInformationViewModel, EditPartnershipInformationRequest>({
        query: ({ partnershipinformation: partnershipinformation }) => {
          return {
            url: `/partnershipInformations`,
            method: 'POST',
            body: partnershipinformation,
          };
        },
        invalidatesTags: ['PartnershipInformation']
      }),
      updatePartnershipInformation: builder.mutation<PartnershipInformationViewModel, EditPartnershipInformationRequest>({
        query: ({ partnershipinformation: partnershipinformation }) => {
          return {
            url: `/partnershipInformations/${partnershipinformation.id}`,
            method: 'PUT',
            body: partnershipinformation,
          };
        },
        invalidatesTags: (_data, _error, arg) => [{ type: 'PartnershipInformation', id: `${arg.partnershipinformation.id}` }]
      }),
      deletePartnershipInformation: builder.mutation<void, DeletePartnershipInformationRequest>({
        query: ({ Id }) => ({
          url: `/partnershipInformations/${Id}`,
          method: 'DELETE',
          body: {},
        }),
        invalidatesTags: (_data, _error, arg) => ['PartnershipInformation']
      })
    };
  },
});

export const {
  useFetchPartnershipInformationsQuery,
  useFetchSinglePartnershipInformationQuery,
  useCreatePartnershipInformationMutation,
  useUpdatePartnershipInformationMutation,
  useDeletePartnershipInformationMutation,
} = partnershipInformationApi;
