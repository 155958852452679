import OutboundtransactionsScreen from './screens/outboundtransactions-screen';
import { RouteDefinition } from '../../core/route';

export const outboundtransactionRoutes: RouteDefinition[] = [
  {
    id: 'OutboundTransactionList',
    title: 'Acknowledgement Reconciliation',
    path: 'OutboundTransactionList',
    element: <OutboundtransactionsScreen />,
    showInNavigation: true,
  },
];
