import TransactionTypesScreen from './screens/transactiontypes-screen';
import TransactionTypeEditScreen from './screens/transactiontype-edit-screen';
import { RouteDefinition } from '../../core/route';

export const transactiontypeRoutes: RouteDefinition[] = [
  {
    id: 'TransactionTypeEdit',
    title: 'Transaction Type',
    path: 'transactiontypes/:Id',
    element: <TransactionTypeEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'TransactionTypeList',
    title: 'Transaction Types',
    path: 'transactiontypes',
    element: <TransactionTypesScreen />,
    showInNavigation: true,
  },
];
