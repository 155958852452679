import TargetTypesScreen from './screens/targettypes-screen';
import TargetTypeEditScreen from './screens/targettype-edit-screen';
import { RouteDefinition } from '../../core/route';

export const targettypeRoutes: RouteDefinition[] = [
  {
    id: 'TargetTypeEdit',
    title: 'Target Type',
    path: 'targettypes/:Id',
    element: <TargetTypeEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'TargetTypeList',
    title: 'Target Types',
    path: 'targettypes',
    element: <TargetTypesScreen />,
    showInNavigation: true,
  },
];
