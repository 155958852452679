import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tenant } from '../api/models';

interface TenantState {
  name: string | null;
  tenantId: string | null;
  rwGeoLocationTimezoneId: number | null;
  timezoneStandardName: string | null;
  isDisabled: boolean | null;
}

const initialState = { name: null, tenantId: null, rwGeoLocationTimezoneId: null, timezoneStandardName: null, isDisabled: null } as TenantState;

const tenantStore = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setTenant(state, action: PayloadAction<Tenant>) {
      state.name = action.payload.name;
      state.tenantId = action.payload.tenantId;
      state.rwGeoLocationTimezoneId = action.payload.rwGeoLocationTimezoneId;
      state.timezoneStandardName = action.payload.timezoneStandardName;
      state.isDisabled = action.payload.isDisabled;
    },
  },
});

export const { setTenant } = tenantStore.actions;
export const tenantSliceReducer = tenantStore.reducer;
