import { createSlice } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import getAlertDuration from './components/alert-duration';
import getAlertStatusBadge from './components/alert-status-badge';
import getAlertStartDate from './components/alert-start-date';

interface Column {
  title: string;
  dataIndex: string;
  key: string;
  render?(item: any) : ReactNode
}

interface TableState {
  columns: Column[];
}

const initialState: TableState = {
  columns: [
    {
			title: 'Time',
			dataIndex: 'startDt',
			key: 'startDt',
      render: getAlertStartDate
    },
		{
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: getAlertStatusBadge
    },
    {
      title: 'Partner',
      dataIndex: 'partnerName',
      key: 'partnerName'
    },
    {
      title: 'Monitor',
      dataIndex: 'partnerMonitorName',
      key: 'partnerMonitorName'
    },
		{
      title: 'Duration',
      dataIndex: '',
      key: '',
			render: getAlertDuration
    },
  ],
};

const alertListSlice = createSlice({
  name: 'alertTable',
  initialState,
  reducers: {
    hideColumn(state) {},
  },
});

export const alertsReducer = alertListSlice.reducer;
