import PartnerOnboardingsScreen from './screens/partnerdataonboardings-screen';
import { RouteDefinition } from '../../core/route';

export const partnerdataonboardingRoutes: RouteDefinition[] = [
  {
    id: 'PartnerDataOnboarding',
    title: 'Partner Onboarding Export/Import',
    path: 'PartnerDataOnboarding',
    element: <PartnerOnboardingsScreen />,
    showInNavigation: true,
  },
];
