import { apiSlice } from '../api/api-slice';
import { TransactionTypeViewModel, PaginatedResult } from '../api/models';

export interface TransactionTypeListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
}

export interface EditTransactionTypeRequest {
  transactiontype: TransactionTypeViewModel;
}

export interface TransactionTypeRequest {
  Id: number;
}

export interface DeleteTransactionTypeRequest {
  Id: number;
}

export const transactiontypesApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchTransactionTypes: builder.query<PaginatedResult<TransactionTypeViewModel>, TransactionTypeListRequest>({
        query({ current, pageSize, filter, sortBy }) {
          return `/transactiontypes?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'TransactionType',
          ...(data?.results?.map(({ id }) => ({ type: 'TransactionType' as const, id: `${id}` })) || [])
        ] 
      }),
      fetchSingleTransactionType: builder.query<TransactionTypeViewModel, TransactionTypeRequest>({
        query({ Id }) {
          return `/transactiontypes/${Id}`;
        },
        providesTags: (_data, _error, arg) => [{ type: 'TransactionType', id: `${arg.Id}` }]
      }),
      createTransactionType: builder.mutation<TransactionTypeViewModel, EditTransactionTypeRequest>({
        query: ({ transactiontype }) => {
          return {
            url: `/transactiontypes`,
            method: 'POST',
            body: transactiontype,
          };
        },
        invalidatesTags: ['TransactionType']
      }),
      updateTransactionType: builder.mutation<TransactionTypeViewModel, EditTransactionTypeRequest>({
        query: ({ transactiontype }) => {
          return {
            url: `/transactiontypes/${transactiontype.id}`,
            method: 'PUT',
            body: transactiontype,
          };
        },
        invalidatesTags: (_data, _error, arg) => [{ type: 'TransactionType', id: `${arg.transactiontype.id}` }]
      }),
      deleteTransactionType: builder.mutation<void, DeleteTransactionTypeRequest>({
        query: ({ Id }) => ({
          url: `/transactiontypes/${Id}`,
          method: 'DELETE',
          body: {},
        }),
        invalidatesTags: (_data, _error, arg) => ['TransactionType']
      })
    };
  },
});

export const {
  useFetchTransactionTypesQuery,
  useFetchSingleTransactionTypeQuery,
  useCreateTransactionTypeMutation,
  useUpdateTransactionTypeMutation,
  useDeleteTransactionTypeMutation,
} = transactiontypesApi;
