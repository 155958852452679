import { createSlice } from '@reduxjs/toolkit';
import { TableState } from '../../components/table/model';

const initialState: TableState = {
  columns: [],
};

const acknowledgmentoverrideListSlice = createSlice({
  name: 'acknowledgmentoverrideTable',
  initialState,
  reducers: {
    hideColumn(state) {},
  },
});

export const acknowledgmentoverridesReducer = acknowledgmentoverrideListSlice.reducer;
