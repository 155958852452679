import { RouteDefinition } from '../../core/route';
import Telemetry from './screens/telemetry-screen';

export const telemetryRoutes: RouteDefinition[] = [
  {
    id: 'TelemetryEvents',
    title: 'Telemetry Events',
    path: 'telemetryEvents',
    element: <Telemetry />,
    showInNavigation: false,
  },
];
