import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import Logo from '../../assets/logo.svg';
import HomeBackgroundImage from '../../assets/home_bg.jpg';														   
import { useFetchTenantsQuery } from '../../features/tenant/tenant-api';
import { Col, Dropdown, Layout, Menu, MenuProps, Row, Select } from 'antd';
import { RouteDefinition } from '../../core/route';
import { useNavigate, useParams, Link, Outlet, useLocation } from 'react-router-dom';
import { getLink } from '../../App-router';
import { MenuInfo } from 'rc-menu/lib/interface';
import { DefaultLinkName, DefaultTenantId } from '../../core/defaults';
import { useAppDispatch } from '../../hooks';
import { setTenant } from '../../features/tenant/tenant-store';
import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import SubMenu from 'antd/lib/menu/SubMenu';
import Sidenav from "./Sidenav";

const { Header, Footer, Content, Sider } = Layout;
const { Option } = Select;

interface AppLayoutProps {
  children?: any | undefined;
  getRoutes?(): RouteDefinition[];
}

export default function AppLayout(props: AppLayoutProps) {
  const auth0 = useAuth0();
  const routes: RouteDefinition[] = props.getRoutes ? props.getRoutes()[0].children! : [];
  const params: any = useParams<any>();
  const { tenantId }: { tenantId: string } = params;
  // console.log('AppLayout Params', params);
  const [selectedMenuItem, setSelectedMenuItem] = useState(DefaultLinkName);
  const location = useLocation()

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data: tenants = [], isFetching } = useFetchTenantsQuery();

  // console.log('AppLayout Tenants', tenants);

  const handleMenuClick = useCallback(
    (e: MenuInfo) => {
      const linkParams = {
        ...params,
        tenantId: params.tenantId ?? DefaultTenantId,
      };
      const menuLink = getLink(e.key, linkParams);
      setSelectedMenuItem(e.key);
      navigate(menuLink);
    },
    [navigate]
  );

  useEffect(() => {
    if (!isFetching && tenants.length > 0) {
      // If no tenant id parameter provided in the url, get the first available tenant and redirect
      if (!tenantId) {
        dispatch(setTenant(tenants[0]));
        navigate(getLink(DefaultLinkName, { ...params, tenantId: tenants[0].tenantId }));
      }
    }
  }, [tenants, isFetching]);

  const onTenantChange = (value: string) => {
    dispatch(setTenant(tenants.find((x) => x.tenantId === value)!));
    const linkParams = {
      ...params,
      tenantId: value,
    };
    const menuLink = getLink(DefaultLinkName, linkParams);
    setSelectedMenuItem(DefaultLinkName);
    navigate(menuLink);
  };

  const handleSettingsTimezones = (e: any) => {
    const settingsTimezonesLink = getLink('SettingsTimezones', { ...params });
    navigate(settingsTimezonesLink);
  };

  const items: MenuProps['items'] = [
    {
				
      key: '0',
      label: (<a onClick={handleSettingsTimezones}>Timezones</a>),
      disabled: (tenantId == null || tenantId == undefined || tenantId.length == 0)
    }
  ];

  return !isFetching ? (
    <Layout className="layout">
      <Header style={{
        height: 'auto',
        lineHeight: 1.5,
        backgroundColor: '#ffffff'
      }}>
        <Row gutter={16} style={{ padding: '1rem 0' }} justify="space-between" align="middle">
          <Col>
            <Link to={getLink('home', { tenantId: params.tenantId===undefined? tenants[0].tenantId : params.tenantId })} style={{ backgroundColor: '#ffffff', padding: '0.25rem 0.5rem', borderRadius: '0.25rem', display: 'block' }}>
              <img src={Logo} alt="Logo" />
            </Link>
          </Col>
          <Col flex="auto">
            {tenantId && (
              <Menu
                mode="horizontal"
                onClick={handleMenuClick}
                defaultSelectedKeys={['2']}
                selectedKeys={[selectedMenuItem]}
              >
                {routes
                  .filter((x) => x.showInNavigation)
                  .map((route, i) => (
                     location.pathname.includes('partners') || location.pathname.includes('monitors') || location.pathname.includes('alerts')
                     ||location.pathname.includes('telemetryEvents') || location.pathname.includes('timezones')?
                    <Menu.Item key={route.id}>{route.title}</Menu.Item>:''
                  ))}
              </Menu>
            )}
          </Col>
          <Col hidden={location.pathname.includes('cob')}>
            <Select
              className="form-select"
              style={{ width: 240 }}
              disabled={isFetching}
              onChange={onTenantChange}
              value={tenantId}
            >
              {tenants.map((tenant, index) => (
                <Option key={index} value={tenant.tenantId || ''}>
                  {tenant.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col hidden={location.pathname.includes('cob')}>
            <Dropdown menu={{ items }} trigger={['click']}>
				    <a onClick={(e) => e.preventDefault()}>
              <SettingOutlined style={{ color: '#000000', fontSize: '24px' }} />
              </a>
            </Dropdown>
          </Col>
          <Col>
            <LogoutOutlined style={{ color: '#000000', fontSize: '24px' }}
              onClick={() => auth0.logout({ logoutParams: { returnTo: window.location.origin } })}
            />
          </Col>
        </Row>
      </Header>
      <Layout style={{ padding: '16px 50px', background: `#ffffff url(${HomeBackgroundImage}) bottom right/cover no-repeat` }}>
      <Content
        className="site-layout-background" style={{ minHeight: '88vh' }}
      >
        <Outlet />
      </Content>
      </Layout>
      <Footer style={{ textAlign: 'center' }} />
    </Layout>
  ) : (
    <></>
  );
}

AppLayout.propTypes = {
  children: PropTypes.any,
};
