import SourceTypesScreen from './screens/sourcetypes-screen';
import SourceTypeEditScreen from './screens/sourcetype-edit-screen';
import { RouteDefinition } from '../../core/route';

export const sourcetypeRoutes: RouteDefinition[] = [
  {
    id: 'SourceTypeEdit',
    title: 'Source Type',
    path: 'sourcetypes/:Id',
    element: <SourceTypeEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'SourceTypeList',
    title: 'Source Types',
    path: 'sourcetypes',
    element: <SourceTypesScreen />,
    showInNavigation: true,
  },
];
