export enum CompanyType {
  Company = 'Company',
  Carrier = 'Carrier',
  Customer = 'Customer',
  PayTo = 'PayTo',
  ThirdPartyBillTo = 'ThirdPartyBillTo',
}

export enum CompanyStatusType {
  Disabled = 'Disabled',
  Active = 'Active',
  OutOfService = 'OutOfService',
}

export enum CompanyStateType {
  Empty = 'Empty',
  Lead = 'Lead',
  New = 'New',
}

export interface Company {
  id?: number;
  companyType?: CompanyType;
  name?: string | undefined;
  addressId?: number;
  cityId?: number;
  cityName?: string | undefined;
  stateCode?: string | undefined;
  parentCompanyId?: number;
  notes?: number;
  primaryContactId?: number;
  gpid?: number;
  legacyId?: number;
  companyStatusType?: CompanyStatusType;
  lastCompanyCallId?: number;
  accessControlId?: number;
  companyLogoId?: number;
  companyTankTypeId?: number;
  companyScore?: number;
  firmId?: number;
  companyStateType?: CompanyStateType;
}

export enum MonitorStatusEnum {
  Ok = 'Ok',
  Warning = 'Warning',
  Error = 'Error',
}

export interface MonitorViewModel {
  monitorId?: number;
  partnerId?: number;
  description?: string | undefined;
  frequency?: number;
  isDisabled?: boolean;
  lastRun?: Date;
  rwConnectTenantIds?: string[] | undefined;
  sqlClause?: string | undefined;
  jsonClause?: string | undefined;
  thresholdWindows?: MonitorThresholdWindow[] | undefined;
  status?: MonitorStatusEnum;
  notificationList?: string[];
  rwConnectEnvironment?: string | undefined;
  monitorSourceId: number;
}

export interface MonitorThresholdWindow {
  thresholdWindowId?: number;
  monitorId?: number;
  startDayTime?: Date,
  endDayTime?: Date,
  maxThreshold?: number,
  minThreshold?: number,
  rangeTimeframe?: number,
  alertLevelId?: AlertLevelEnum,
  isDeleted?: boolean | undefined;
  thresholdExclusive?: number;
}

export interface AlertViewModel {
  partnerAlertEventId: number;
  partnerMonitorName: string;
  partnerMonitorId: number;
  partnerName: string;
  partnerId: number;
  alertLevelId: AlertLevelEnum;
  alertValue: number;
  startDt: Date;
  closedDt?: Date;
  notified: boolean;
  status: string;
}

export enum AlertLevelEnum {
  Ok = "Ok",
  Warning = "Warning",
  Error = "Error"
}

export interface PartnerViewModel {
  tenantId?: string;
  partnerId?: number;
  companyId?: number;
  companyName?: string;
  description?: string | undefined;
  isActive?: boolean;
  monitors?: MonitorViewModel[] | undefined;
  readonly monitorsCount?: number;
  readonly monitorsOk?: number;
  readonly monitorsWarning?: number;
  readonly monitorsErrors?: number;
}

export interface CustomerViewModel {
  tenantId?: string;
  id?: number;
  name?: string;
  type?: string | undefined;
  qualifier?: string | undefined;

  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updateByDate?: string| undefined;
}

export interface DocumentTypeViewModel {
  tenantId?: string;
  id?: number;
  name: string;
  format: string;
  version: string;
  standard: string;
}

export interface AuditLogsViewModel {
  changeDateTime?: string;
  auditType: string;
  fieldChanged: string;
  oldValue: string;
  newValue: string;
  user: string;
}

export interface CustomerIdentifierTypeViewModel {
  tenantId?: string;
  id?: number;
  name: string;
  description: string;
}
export interface PartnershipInformationViewModel {
  tenantId?: string;
  id?: number;
  name: string;
  type: string;
  partnerId: string;
  identifierType: string;
  identifierValue: string;
  
  partner_VAN: string;
  notes: string;
  partnerRating?: number;

  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updateByDate?: string| undefined;
}

export interface OutboundTransactionViewModel {
  tenantId?: string;
  id?: number;
  partnerName: string;
  interchangeSenderId: string;
  interchangeReceiverId: string;
  transactionSetIdentifierCode: string;
  groupControlNumber: string;
  interchangeControlNumber: string;
  groupFunctionalIdentifierCode: string;
  transactionSetControlNumber: string;
  referenceValue: string;
  status: string;
  interchangeAcknowledgmentRequested: string;
  interchangeAcknowledgmentCode: string;
  documentId: string;
  enteredByDate:string;
}
export interface OutboundTransactionFiltersViewModel {
  tenantId?: string;
  interchangeSenders: string[];
  interchangeReceiver: string[];
  transactionTypes: string[];
  statuses: string[];
  referenceValues: string[];
}

export interface PartnerDataOnboardingViewModel {
  key: React.Key;
  documentType: string;
  format: string;
  standard : string;
  version : string;
  transactionType : string;
  source : string;
  target : string;
  splittingRulePriority : string;
  endSystem : string;
  endSystemInstance : string;
  endSystemDescription : string;
  testFlag : string;
  customerName : string;
  customerType : string;
  customerQualifier : string;
  profileMapName : string;
  customerIdentifierType : string;
  customerIdentifierValue : string;
  sequence : string;
  partnerType : string;
  partnerName : string;
  partnerId : string;
  partnerIdentifierType : string;
  partnerIdentifierValue : string;
  carrierName : string;
  eDIX12Exists : boolean;
  sourceSCAC : string;
  sourceSender : string;
  sourceReceiver : string;
  documentEncoding : string;
  transactionSetIdentifierCode : string;
  interchangeAckOverdueTime : string;
  interchangeAckOverdueTimeMinutes : string;
  interchangeAuthorizationInformation : string;
  interchangeAuthorizationInformationQualifier : string;
  interchangeSecurityInformationQualifier : string;
  interchangeSecurityInformation : string;
  interchangeSenderQualifier : string;
  interchangeSenderID : string;
  interchangeReceiverQualifier : string;
  interchangeReceiverID : string;
  interchangeStandard : string;
  interchangeVersionNumber : string;
  interchangeAcknowledgmentRequested : string;
  interchangeTestIndicator : string;
  groupAckOverdueTimeMinutes : string;
  groupAckOverdueTime : string;
  groupExpectAck : string;
  groupFunctionalIdentifierCode : string;
  groupSenderID : string;
  groupReceiverID : string;
  groupResponsibleAgencyCode : string;
  groupVersionNumber : string;
  transactionSCAC : string;
  elementSeparator : string;
  componentElementSeparator : string;
  segmentTerminator : string;
  fileFormat : string;
  isActive : boolean;
  protocol : string;
  serviceType : string;
  host : string;
  serviceName : string;
  processId : string;
  environmentKey : string;
  description : string;
  isPCEActive : boolean;
  folderPath : string;
  acknowledgmentOverride : boolean;
  isAckOverrideActive : boolean;

  ack_Exists : boolean,
  ack_TransactionType : string,
  ack_InterchangeSenderQualifier : string,
  ack_InterchangeSenderID : string,
  ack_InterchangeReceiverQualifier: string,
  ack_InterchangeReceiverID : string,
  ack_GroupSenderID : string,
  ack_GroupReceiverID : string,
  
  userName : string;
  result : string;
}
export interface PartnerDataOnboardingFiltersViewModel {
  tenantId?: string;
  interchangeSenders: string[];
  interchangeReceiver: string[];
  transactionTypes: string[];
  statuses: string[];
}

export interface AcknowledgmentOverrideReportEmailViewModel {
  tenantId?: string;
  id?: number;
  name?: string;
  receipientEmail?: string | undefined;
  frequency?: string | undefined;
  startDate?: string| undefined;
  lastExecutionDate?: string | undefined;
  isActive?: boolean | true;

  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updatedByDate?: string| undefined;
}

export interface AcknowledgmentOverrideReportEmailFilterViewModel {
  tenantId?: string;
  id: number;
  acknowledgementReconciliationReportId?: number | undefined;
  partnerInformationId: string| undefined;
  transactionSetIdentifier: string| undefined;
  interchangeSenderID: string| undefined;
  interchangeReceiverID: string| undefined;
  status: any | undefined;

  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updatedByDate?: string| undefined;
}

export interface EndSystemViewModel {
  tenantId?: string;
  id?: number;
  name?: string;
  endSystemInstance?: string | undefined;
  description?: string | undefined;

  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updatedByDate?: string| undefined;
}
export interface ProfileMapViewModel {
  tenantId?: string;
  id?: number;
  name?: string;
  isBatched?: boolean;
  batchSize?: number;

  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updatedByDate?: string| undefined;
}
export interface PartnerControlNumberViewModel {
  tenantId?: string;
  id?: number;
  key?: string;
  controlNumber?: string;

  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updatedByDate?: string| undefined;
}

export interface SourceTypeViewModel {
  tenantId?: string;
  id?: number;
  name?: string;
  fullName?: string;
  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updatedByDate?: string| undefined;
}
export interface TargetTypeViewModel {
  tenantId?: string;
  id?: number;
  name?: string;
  fullName?: string;
  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updatedByDate?: string| undefined;
}
export interface TransactionTypeViewModel {
  tenantId?: string;
  id?: number;
  name?: string;  
  description?: string;
  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updatedByDate?: string| undefined;
}

export interface ConnectionEndPointViewModel {
  tenantId?: string;
  id?: number;
  protocol?: string;
  serviceType?: string;
  host?: string;
  serviceName?: string
  processID?: string
  environmentKey?: string
  description?: string
  isActive?: boolean

  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updatedByDate?: string| undefined;
}

export interface CodeDecodeTypeViewModel {
  tenantId?: string;
  id?: number;
  type?: string;
  description?: string
  
  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updateByDate?: string| undefined;
}
export interface CarrierOnboardingExcelImport {
  tenantId?: string;
  id?: number;
  type?: string;
  description?: string
  
  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updateByDate?: string| undefined;
}

export interface CodeDecodeMapViewModel {
  tenantId?: string;
  id?: number;
  codeDecodeValueId: number;
  codeDecodeTypeId: number;
  mapId: number;
  customerId: number;

  codeDecodeValueName?: string;
  mapName?: string;
  customerName?: string;

  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updatedByDate?: string| undefined;

}

export interface CarrierOnboardingViewModel {
  tenantId?: string;
  id?: number;
  documentTypeId: number;
  sourceTypeId: number;
  targetTypeId: number;
  partnershipId?:number;
  target: string;
  source: string;
  carrierName: string;
  partnerId: string;
  identifierType: string;
  identifierValue: string;
  testFlag?: string;
  priority?: number;
  documentType: string;
  format: string;
  version: string;
  standard: string;

  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updateByDate?: string| undefined;
}

export interface SplittingRuleViewModel {
  tenantId?: string;
  id?: number;
  documentTypeId: number;
  sourceTypeId: number;
  targetTypeId: number;
  testFlag?: string;
  priority?: number;
  partnershipId?:number;
  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updateByDate?: string| undefined;
}


export interface RoutingRuleListViewModel {
  tenantId?: string;
  id?: number;
  splittingRuleId: number;
  customerId: number;
  customer?: string;
  profileMapId: number;
  profileMap?: string;
  transactionTypeId: number;
  transactionType?: string;
  endSystemId: number;
  endSystem?: string;
  endSystemInstance: string;
  identifierTypeId: number;
  identifierType?: string;
  identifierValue: string;
  sequence: number;

  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updateByDate?: string| undefined;
}

export interface RoutingRuleViewModel {
  tenantId?: string;
  id?: number;
  splittingRuleId: number;
  customerId: number;
  profileMapId: number;
  transactionTypeId: number;
  endSystemId?: number;
  identifierTypeId: number;
  identifierValue?: string;
  sequence: number;

  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updateByDate?: string| undefined;
}

export interface PartnerRatingListViewModel {
  tenantId?: string;
  id?:number;
  partnerInformationId?: string;
  responsiveness: number;
  documentationClarity?: number;
  cooperationAndSupport?: number;
  integrationCompletionTime?: number;
  integrationEfficiency?: number;
  overallRating?: number;
  notes?:string;

  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updateByDate?: string| undefined;
}

export interface PartnerRatingViewModel {
  tenantId?: string;
  partnerInformationId?: string;
  responsiveness: number;
  documentationClarity?: number;
  cooperationAndSupport?: number;
  integrationEfficiency?: number;
  integrationCompletionTime?: number;  
  overallRating?: number;
  notes?:string;

  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updateByDate?: string| undefined;
}

export interface PartnerContactInformationListViewModel {
  tenantId?: string;
  id?: number;
  partnerInformationId: number;
  partnerInformationName?: string;
  contactType?: string;
  name?: string;
  email?: string;
  phone?: string;
  note: string;
  primary: boolean;
}

export interface PartnerContactInformationViewModel {
  tenantId?: string;
  id?: number;
  partnerInformationId: number;
  contactType?: string;
  name?: string;
  email?: string;
  phone?: string;
  note: string;
  primary: boolean;

  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updatedByDate?: string| undefined;
}

export interface CodeDecodeValueViewModel {
  tenantId?: string;
  id?: number;
  code?: string;
  decode?: string;
  decode1?: string;
  codeDecodeTypeId?: number;
  codeDecodeType?: string

  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updatedByDate?: string| undefined;
}
export interface AcknowledgmentOverrideViewModel {
  tenantId?: string;
  id?: number;
  transactionType?: string;
  interchangeSenderQualifier?: string;
  interchangeSenderID?: string;
  interchangeReceiverQualifier?: string
  interchangeReceiverID?: string;
  groupSenderID?: string;
  groupReceiverID?: string;
  acknowledgmentOverride?: boolean;  
  isActive?: boolean

  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updatedByDate?: string| undefined;
}
export interface PartnerConnectionEndpointViewModel {
  tenantId?: string;
  id?: number;
  connectionEndpointId?: number;
  partnerEDIX12Id?: number;
  connectionEndpoint?: string;
  partnerEDIX12?: string;
  folderPath?: string;
  isActive?: boolean

  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updatedByDate?: string| undefined;
}
export interface PartnerEDIX12ViewModel {
  tenantId?: string;
  id?: number;
  partnerID?: number;
  routingRuleID?: number;
  carrierName?: string;
  sourceSCAC?: string;
  sourceSender?: string;
  sourceReceiver?: string;
  documentEncoding?: string;
  transactionSetIdentifierCode?: string;
  interchangeAckOverdueTime?: string;
  interchangeAckOverdueTimeMinutes?: string;
  interchangeAuthorizationInformationQualifier?: string;
  interchangeAuthorizationInformation?: string;
  interchangeSecurityInformationQualifier?: string;
  interchangeSecurityInformation?: string;
  interchangeSenderQualifier?: string;
  interchangeSenderID?: string;
  interchangeReceiverQualifier?: string;
  interchangeReceiverID?: string;
  interchangeStandard?: string;
  interchangeVersionNumber?: string;
  interchangeAcknowledgmentRequested?: string;
  isInterchangeAcknowledgmentRequested?: boolean;
  interchangeTestIndicator?: string;
  groupAckOverdueTimeMinutes?: string;
  groupAckOverdueTime?: string;
  groupExpectAck?: string;
  groupFunctionalIdentifierCode?: string;
  groupSenderID?: string;
  groupReceiverID?: string;
  groupResponsibleAgencyCode?: string;
  groupVersionNumber?: string;
  transactionSCAC?: string;
  elementSeparator?: string;
  componentElementSeparator?: string;
  segmentTerminator?: string;
  fileFormat?: string;
  isActive?: boolean;

  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updatedByDate?: string| undefined;
}

export interface CarrierViewModel {
  tenantId?: string;
  id?: number;
  format?: string;
  standard?: string | undefined;
  transactionType?: string | undefined;
  source?: string;
  target?: string | undefined;
  splittingRulePriority?: number;
  endSystem?: string;
  endSystemInstance?: string | undefined;
  endSystemDescription?: string | undefined;
  testFlag?: string;
  customerName?: string | undefined;
  customerType?: string | undefined;
  customerQualifier?: number;
  profileMapName?: string;
  customerIdentifierType?: string | undefined;
  customerIdentifierValue?: string | undefined;
  sequence?: string;
  userName?: string | undefined;

  enteredBy?: string| undefined;
  enteredByDate?: string| undefined;
  updatedBy?: string| undefined;
  updatedByDate?: string| undefined;
}
export interface Permission {
  id?: string;
  name?: string | undefined;
  description?: string | undefined;
  category?: string | undefined;
}

export interface Tenant {
  name: string;
  shortName: string;
  notes: string,
  tenantId: string,
  rwGeoLocationTimezoneId: number,
  timezoneStandardName: string,
  isDisabled: boolean,
  enteredBy: string,
  enteredByDt: Date,
  updatedBy: string,
  updatedByDt: Date
}

export interface PaginatedResult<TModel> {
  totalCount?: number;
  pageSize?: number;
  pageNumber?: number;
  results?: TModel[] | undefined;
}

export interface TelemetryEventViewModel {
  telemetryEventId: number;
  partnerMonitorId: number;
  telemetryTime: Date;
  alertLevel: string;
  value: number | undefined;
  errorMessage: string | undefined;
  duration: number | undefined;
}
export interface Timezone {
  id: number;
  description: string;
  daylightName: string
  standardName: string
  utcOffset: number;
  supportsDaylightSavingTime: boolean
}
