import React, { useEffect, useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Breadcrumb, Input, TablePaginationConfig, Card, Alert, Button, Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined, MonitorOutlined, UserOutlined } from '@ant-design/icons/lib/icons';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { getLink } from '../../../App-router';
import { TableState } from '../../../components/table/model';
import getAlertStatusBadge from '../../alerts/components/alert-status-badge';
import {
  DefaultPage,
  DefaultPageSize,
  DefaultPollingInterval,
  DefaultTenantId,
} from '../../../core/defaults';
import { TablePageRequest, useFetchTelemetryEventsQuery } from '../telemetry-api';
import { AlertLevelEnum, PaginatedResult, TelemetryEventViewModel } from '../../api/models';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import getSortBy from '../../../components/table/sorter';
import getAlertStartDate from '../../alerts/components/alert-start-date';
import getAlertDuration from '../../alerts/components/alert-duration';
import moment from 'moment';

interface TelemetryScreenParams {
  monitorId: string | undefined;
  tenantId?: string | undefined;
  partnerId: string | undefined;
  [key: string]: string | undefined;
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function TelemetryScreen(props: any) {
  const query = useQuery();
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const urlQuery = new URLSearchParams(useLocation().search);

  const { Search } = Input;

  const getCurrentState = (): TablePageRequest => ({
    current: Number(query.get('p')) || DefaultPage,
    pageSize: Number(query.get('size')) || DefaultPageSize,
    filter: `PartnerMonitorId: ${urlQuery.get('monitorId')}`,
    sortBy: query.get('sortBy'),
  });

  const [pagination, setPagination] = useState<TablePageRequest>(getCurrentState());

  const {
    data: pagedData = {},
    isFetching,
    error,
  }: {
    data?: PaginatedResult<TelemetryEventViewModel> | undefined;
    isFetching?: boolean | undefined;
    error?: any;
  } = useFetchTelemetryEventsQuery(pagination, { pollingInterval: DefaultPollingInterval });

  const { monitorId, tenantId = DefaultTenantId } = useParams<TelemetryScreenParams>();

  useEffect(() => {
    setPagination(getCurrentState());
  }, [params]);

  const renderValue = (event: TelemetryEventViewModel) => {
    if (event.alertLevel == AlertLevelEnum.Error && event.errorMessage) {
      return <>{event.errorMessage}</>;
    } else {
      return <>{event.value!.toString()}</>;
    }
  };

  const telemetryEventsTable: TableState = {
    columns: [
      {
        title: 'Time',
        dataIndex: 'telemetryTime',
        key: 'telemetryTime',
        render: (date: any) => date && getAlertStartDate(date),
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: 'alertLevel',
        key: 'alertLevel',
        render: getAlertStatusBadge,
      },
      {
        title: 'Value',
        dataIndex: '',
        key: '',
        render: renderValue,
      },
      {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration',
        render: (duration: any) => duration && `${duration}ms`,
      },
    ],
  };

  const handleTableChange = (
    paginationConfig: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<TelemetryEventViewModel> | SorterResult<TelemetryEventViewModel>[],
    extra: TableCurrentDataSource<TelemetryEventViewModel>
  ) => {
    const { columnKey, order } = sorter as SorterResult<TelemetryEventViewModel>;
    if (columnKey && order) {
      query.set('sortBy', getSortBy(columnKey.toString(), order));
    } else {
      query.set('sortBy', '');
    }

    navigate(
      getLink(
        'TelemetryEvents',
        { ...params },
        {
          ...query,
          monitorId: query.get('monitorId'),
          partnerId: query.get('partnerId'),
          sortBy: query.get('sortBy'),
          p: paginationConfig.current,
          size: paginationConfig.pageSize,
          q: pagination.filter || '',
        }
      )
    );
  };

  function onSearch(searchText: string) {
    navigate(
      getLink(
        'TelemetryEvents',
        { ...params },
        {
          ...query,
          monitorId: query.get('monitorIdId'),
          partnerId: query.get('partnerId'),
          p: DefaultPage,
          q: searchText,
        }
      )
    );
  }

  return (
    <PageHeader
      title={
        <Title
          level={4}
          style={{
            marginBottom: 0,
            display: 'inline-block',
          }}
        >
          Telemetry Events
        </Title>
      }
      breadcrumb={
        <Breadcrumb>
          <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: params.tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
          <Breadcrumb.Item>
            <MonitorOutlined />
            <Link
              to={getLink(
                'MonitorList',
                { ...props, tenantId: tenantId },
                { partnerId: Number(urlQuery.get('partnerId')) }
              )}
            >
              Monitors
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Telemetry Events</Breadcrumb.Item>
        </Breadcrumb>
      }
    >
      {error?.status && <Alert message="Error" description={error.data} type="error" showIcon />}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
        <Search
          data-testid="search-monitors"
          placeholder="Search"
          onSearch={onSearch}
          style={{ width: 463 }}
        />
      </div>
      <Table
        dataSource={pagedData.results}
        onChange={handleTableChange}
        rowKey={'monitorId'}
        pagination={{
          ...pagination,
          total: pagedData.totalCount,
          showTotal: (total: number) => `Total ${total} items`,
        }}
        columns={telemetryEventsTable.columns}
        loading={isFetching}
      />
    </PageHeader>
  );
}
