import AcknowledgmentOverridesScreen from './screens/acknowledgmentoverrides-screen';
import AcknowledgmentOverrideEditScreen from './screens/acknowledgmentoverride-edit-screen';
import { RouteDefinition } from '../../core/route';

export const acknowledgmentoverrideRoutes: RouteDefinition[] = [
  {
    id: 'AcknowledgmentOverrideEdit',
    title: 'Acknowledgment Override',
    path: 'acknowledgmentoverrides/:Id',
    element: <AcknowledgmentOverrideEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'AcknowledgmentOverrideList',
    title: 'Acknowledgment Override',
    path: 'acknowledgmentoverrides',
    element: <AcknowledgmentOverridesScreen />,
    showInNavigation: true,
  },
];
