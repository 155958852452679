import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  useCreatePartnerEDIX12Mutation,
  useFetchSinglePartnerEDIX12Query,
  useUpdatePartnerEDIX12Mutation,
} from '../partneredix12-api';
import { PageHeader } from '@ant-design/pro-layout';
import type { CollapseProps } from 'antd';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip,
  Collapse,
  Row,
  Col
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { PartnerEDIX12ViewModel } from '../../api/models';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { getLink } from '../../../App-router';
import { DefaultTenantId } from '../../../core/defaults';
import { ConvertUTCtoCDT } from '../../../core/utils';
const { Option } = Select;

const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError =>
  error ? 'status' in error : false;

const getErrorMessage = (error: any): string | undefined => error.data;

interface PartnerEDIX12ScreenParams {
Id?: string | undefined;
tenantId?: string | undefined;
  [key: string]: string | undefined;
}

export default function PartnerEDIX12EditScreen(props: any) {
  const { Id, tenantId = DefaultTenantId}: PartnerEDIX12ScreenParams =
    useParams<PartnerEDIX12ScreenParams>();

  const isNewPartnerEDIX12 = Id === 'new';

  const { data, isFetching } = useFetchSinglePartnerEDIX12Query(
    {
      Id: Number(Id),
    },
    { skip: isNewPartnerEDIX12 }
  );
  const [identifierValue, setIdentifierValue] = useState<string>(localStorage.getItem('identifierValue')||'');
  const [createPartnerEDIX12, { isLoading: isCreating, error: createError }] = useCreatePartnerEDIX12Mutation();
  const [updatePartnerEDIX12, { isLoading: isUpdating, error: updateError }] = useUpdatePartnerEDIX12Mutation();  
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [form] = Form.useForm();
  React.useEffect(() => {
    form.resetFields();
  }, [data, form]);
  const collapseFieldGroups: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Partner Identification',
      children: <div>
        <Form.Item name="partnerID" label="PartnerID" rules={[{ required: true }]} initialValue={localStorage.getItem('customerId')}>
                <Input disabled={true} defaultValue={localStorage.getItem('customerId')||''} data-testid="partnerID" suffix={
                        <Tooltip title="">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              
              <Form.Item name="routingRuleID" label="RoutingRuleID" 
              initialValue={localStorage.getItem('transactionType')=="204"||localStorage.getItem('transactionType')=="211"
              ||localStorage.getItem('transactionType')=="300"?isNewPartnerEDIX12? Number(localStorage.getItem('routingRuleID')):data?.routingRuleID:null}>
                <Input disabled={true} 
                defaultValue={isNewPartnerEDIX12? Number(localStorage.getItem('routingRuleID')):data?.routingRuleID} data-testid="routingRuleID" 
                suffix={
                        <Tooltip title="">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>

              <Form.Item name="carrierName" label="CarrierName" rules={[{ required: true }]} initialValue={localStorage.getItem('carrierName')}>
                <Input disabled={true} defaultValue={localStorage.getItem('carrierName')||''} data-testid="carrierName" suffix={
                        <Tooltip title="">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              <Form.Item name="sourceScac" label="SourceSCAC" rules={[{ required: true }]} initialValue={localStorage.getItem('partnerId')}>
                <Input disabled={true} defaultValue={localStorage.getItem('partnerId')||''} data-testid="sourceScac" suffix={
                        <Tooltip title="">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              
              <Form.Item name="sourceSender" label="SourceSender" rules={[{ required: true }]}
              initialValue={(localStorage.getItem('transactionType') == "204"||localStorage.getItem('transactionType')=="211"
              ||localStorage.getItem('transactionType')=="300") && identifierValue.length>0 ? identifierValue : localStorage.getItem('source')}>
                <Input disabled={true}  data-testid="sourceSender" suffix={
                        <Tooltip title="">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              <Form.Item name="sourceReceiver" label="SourceReceiver" rules={[{ required: true }]} initialValue={localStorage.getItem('target')}>
                <Input disabled={true} defaultValue={localStorage.getItem('target')||''} data-testid="sourceReceiver" suffix={
                        <Tooltip title="">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              <Form.Item name="transactionSetIdentifierCode" label="TransactionSetIdentifierCode" 
              initialValue={localStorage.getItem('transactionType')=="204"||localStorage.getItem('transactionType')=="211"
              ||localStorage.getItem('transactionType')=="300"?localStorage.getItem('transactionType'):"997"}>
                <Input disabled={true} data-testid="transactionSetIdentifierCode" suffix={
                        <Tooltip title="">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
      </div>,
    },
    {
      key: '2',
      label: 'Interchange Information',
      children: <div>
        
        <Form.Item name="interchangeAuthorizationInformationQualifier" label="InterchangeAuthorizationInformationQualifier" initialValue={isNewPartnerEDIX12?  '00' : data?.interchangeAuthorizationInformationQualifier}>
                <Input placeholder='Please enter valid input like 00.' maxLength={2} data-testid="interchangeAuthorizationInformationQualifier" defaultValue={'00'} suffix={
                        <Tooltip title="Please enter valid input like 00.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              
              <Form.Item name="interchangeSecurityInformationQualifier" label="InterchangeSecurityInformationQualifier" initialValue={isNewPartnerEDIX12? '00': data?.interchangeSecurityInformationQualifier} rules={[{ required: true }]}>
                <Input placeholder='Please enter valid input consists of two characters like 00 or ZZ' maxLength={2} data-testid="interchangeSecurityInformationQualifier" defaultValue={'00'} suffix={
                        <Tooltip title="Please enter valid input consists of two characters like 00 or ZZ">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              <Form.Item name="interchangeSenderQualifier" label="InterchangeSenderQualifier" rules={[{ required: true }]} initialValue={isNewPartnerEDIX12? 'ZZ': data?.interchangeSenderQualifier}>
                <Input placeholder='Please enter valid input consists of two characters like ZZ or 02' maxLength={2} data-testid="interchangeSenderQualifier" suffix={
                        <Tooltip title="Please enter valid input consists of two characters like ZZ or 02.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>

              <Form.Item name="interchangeSenderID" label="InterchangeSenderID" rules={[{ required: true }]}>
                <Input placeholder='Please enter valid data consists of four or MAX 15 characters like AACT' data-testid="interchangeSenderID" maxLength={15} suffix={
                        <Tooltip title="Please enter valid data consists of four or MAX 15 characters like AACT">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              
              <Form.Item name="interchangeReceiverQualifier" label="InterchangeReceiverQualifier" rules={[{ required: true }]} initialValue={isNewPartnerEDIX12? '02': data?.interchangeReceiverQualifier}>
                <Input placeholder='Please enter valid data consists of two characters like ZZ or 02' maxLength={2} data-testid="interchangeReceiverQualifier" suffix={
                        <Tooltip title="Please enter valid data consists of two characters like ZZ or 02">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              <Form.Item name="interchangeReceiverID" label="InterchangeReceiverID" rules={[{ required: true }]}>
                <Input placeholder='Please enter valid data consists of four or more characters like AACT' data-testid="interchangeReceiverID" suffix={
                        <Tooltip title="Please enter valid data consists of four or more characters like AACT">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              
              <Form.Item name="interchangeStandard" rules={[{ required: true }]} label="InterchangeStandard" initialValue={isNewPartnerEDIX12? 'U': data?.interchangeStandard}>
                <Input placeholder='Please enter valid input like U' data-testid="interchangeStandard" defaultValue={'U'} suffix={
                        <Tooltip title="Please enter valid input like U">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              <Form.Item name="interchangeVersionNumber" label="InterchangeVersionNumber" initialValue={isNewPartnerEDIX12? '00401': data?.interchangeVersionNumber} rules={[{ required: true }]}>
                <Input placeholder='Please enter valid input like 00401' data-testid="interchangeVersionNumber" defaultValue={'00401'} suffix={
                        <Tooltip title="Please enter valid input like 00401">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              <Form.Item name="isInterchangeAcknowledgmentRequested" rules={[{ required: true }]} label="InterchangeAcknowledgmentRequested" 
              initialValue={isNewPartnerEDIX12 && (localStorage.getItem('transactionType')=="204"||localStorage.getItem('transactionType')=="211"
              ||localStorage.getItem('transactionType')=="300")?true:data?.isInterchangeAcknowledgmentRequested}>
                <Switch />
              </Form.Item>
              <Form.Item name="interchangeTestIndicator" label="InterchangeTestIndicator" rules={[{ required: true }]}>
              <Select
                  placeholder="Select..."
                  optionFilterProp="children"
                  data-testid="interchangeTestIndicator"
                  options={[{label:'P', value:'P'},{label:'T', value:'T'}]}
                />
              </Form.Item>
      </div>,
    },
    {
      key: '3',
      label: 'Functional Group Information',
      children: <div>
        <Form.Item name="groupFunctionalIdentifierCode" label="GroupFunctionalIdentifierCode" rules={[{ required: true }]}>
                <Select
                  allowClear
                  placeholder="Select..."
                  optionFilterProp="children"
                  data-testid="interchangeTestIndicator"
                  options={[{label:'SM', value:'SM'},{label:'FA', value:'FA'},{label:'RO', value:'RO'},{label:'QM', value:'QM'}]}
                />

              </Form.Item>
              <Form.Item name="groupSenderID" label="GroupSenderID">
                <Input placeholder='Please enter valid input like AACT' data-testid="groupSenderID" suffix={
                        <Tooltip title="Please enter valid input like AACT">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      } />
              </Form.Item>
              
              <Form.Item name="groupReceiverID" label="GroupReceiverID">
                <Input placeholder='Please enter valid input like ABCD' data-testid="groupReceiverID" suffix={
                        <Tooltip title="Please enter valid input like ABCD">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              <Form.Item rules={[{ required: true }]} name="groupResponsibleAgencyCode" label="GroupResponsibleAgencyCode" initialValue={isNewPartnerEDIX12?'X': data?.groupResponsibleAgencyCode}>
                <Input placeholder='Please enter valid input like X' data-testid="groupResponsibleAgencyCode" defaultValue={'X'} suffix={
                        <Tooltip title="Please enter valid input like X">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>

              <Form.Item rules={[{ required: true }]} name="groupVersionNumber" label="GroupVersionNumber" initialValue={isNewPartnerEDIX12?'004010':data?.groupVersionNumber}>
                <Input placeholder='Please enter valid input like 004010' data-testid="groupVersionNumber" defaultValue={'004010'} suffix={
                        <Tooltip title="Please enter valid input like 004010">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              
      </div>,
    },
    {
      key: '4',
      label: 'Envelope Information',
      children: <div>
        <Form.Item name="elementSeparator" rules={[{ required: true }]} label="ElementSeparator" initialValue={isNewPartnerEDIX12?'*':data?.elementSeparator}>
                <Input placeholder='Please enter valid input like *' data-testid="elementSeparator" defaultValue={'*'} suffix={
                        <Tooltip title="Please enter valid input like *">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      } />
              </Form.Item>
              
              <Form.Item name="componentElementSeparator" rules={[{ required: true }]} label="ComponentElementSeparator" initialValue={isNewPartnerEDIX12?'>':data?.componentElementSeparator}>
                <Input placeholder='Please enter valid input like' data-testid="componentElementSeparator" defaultValue={'>'} suffix={
                        <Tooltip title="Please enter valid input like >">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              <Form.Item name="segmentTerminator" rules={[{ required: true }]} label="SegmentTerminator" initialValue={isNewPartnerEDIX12?'~':data?.segmentTerminator}>
                <Input placeholder='Please enter valid input like ~' data-testid="segmentTerminator" defaultValue={'~'} suffix={
                        <Tooltip title="Please enter valid input like ~">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              
              <Form.Item name="fileFormat" rules={[{ required: true }]} label="FileFormat" initialValue={isNewPartnerEDIX12?'String':data?.fileFormat}>
              <Select
                  optionFilterProp="children"
                  data-testid="fileFormat"
                  defaultValue={'String'}
                  options={[{label:"String", value:"String"},{label:"NewLine", value:"NewLine"}]}
                />
              </Form.Item>
              <Form.Item name="isActive" rules={[{ required: true }]} label="IsActive" initialValue={isNewPartnerEDIX12?true:data?.isActive}>
                <Switch />
              </Form.Item>
      </div>,
    },
  ];

  const onFinish = (values: any | PartnerEDIX12ViewModel) => {
    const requestData = {
      partneredix12: {
        id: isNewPartnerEDIX12 ? 0 : Number(Id),
        partnerID: values.partnerID||data?.partnerID,
        routingRuleID: values.routingRuleID, 
        carrierName: values.carrierName,
        sourceSCAC: values.sourceScac,
        sourceSender: values.sourceSender,
        sourceReceiver: values.sourceReceiver,
        transactionSetIdentifierCode: values.transactionSetIdentifierCode,
        interchangeAuthorizationInformationQualifier: values.interchangeAuthorizationInformationQualifier,
        interchangeSecurityInformationQualifier: values.interchangeSecurityInformationQualifier??'00',
        interchangeSenderQualifier: values.interchangeSenderQualifier??'',
        interchangeSenderID: values.interchangeSenderID??'',
        interchangeReceiverQualifier: values.interchangeReceiverQualifier??'02',
        interchangeReceiverID: values.interchangeReceiverID??'',
        interchangeStandard: values.interchangeStandard??'U',
        interchangeVersionNumber: values.interchangeVersionNumber??'00401',
        interchangeAcknowledgmentRequested: values.isInterchangeAcknowledgmentRequested==true? "1":"0",
        interchangeTestIndicator: values.interchangeTestIndicator??'0',
        groupFunctionalIdentifierCode: values.groupFunctionalIdentifierCode??'',
        groupSenderID: values.groupSenderID??null,
        groupReceiverID: values.groupReceiverID||null,
        groupResponsibleAgencyCode: values.groupResponsibleAgencyCode??'X',
        groupVersionNumber: values.groupVersionNumber??'004010',
        elementSeparator: values.elementSeparator??'*',
        componentElementSeparator: values.componentElementSeparator??'>',
        segmentTerminator: values.segmentTerminator??'~',
        fileFormat: values.fileFormat??'String',
        isActive: values.isActive??true,
        updatedBy:sessionStorage.getItem('userEmail')||'',
        enteredBy:isNewPartnerEDIX12?sessionStorage.getItem('userEmail')||'':'',
      },
    };

    const request = isNewPartnerEDIX12 ? createPartnerEDIX12(requestData) : updatePartnerEDIX12(requestData);
    request.unwrap().then((response) => {
      if(response?.id ===-200){
        message.error(
          `This combination already exists. Please try other values.`
        );
        return;
      }
      localStorage.setItem('partnerEDIX12Id', response?.id?.toString()||'');
      message.success(
        `Partner EDIX12 is ${isNewPartnerEDIX12 ? 'created' : 'saved'}`
      );
      goBack();
    });
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };
  const [disabledSave, setDisabledSave] = useState(true);
  
  const handleFormChange = () => {
  const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
  setDisabledSave(hasErrors);
  }
  return (
    <div>
      <PageHeader
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="partnershipInformation">
              <Link to={getLink('PartnershipInformationList', { tenantId: tenantId })}>Partner Information</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="carrierName">
              <Link to={getLink('CarrierOnboardingList', {
                      ...props, tenantId,
                      partnerId: localStorage.getItem('partnerId')||'',
                      identifierValue: sessionStorage.getItem('identifierValue')||'',
                      carrierName: localStorage.getItem('carrierName')||'',
                      id: Number(localStorage.getItem('partnershipId')||0),
                    })}>{localStorage.getItem('carrierName')||''}</Link>
            </Breadcrumb.Item>
            {/* <Breadcrumb.Item key="splittingRule">
              <Link to={getLink('CarrierOnboardingEdit', { tenantId: tenantId,Id: Number(localStorage.getItem('splittingRuleID')||0),
                      partnershipId: Number(localStorage.getItem('partnershipId')||0) })}>{localStorage.getItem('splittingRuleID')||''}</Link>
            </Breadcrumb.Item> */}
            <Breadcrumb.Item>{isNewPartnerEDIX12 ? 'New Partner EDIX12' : data?.id}</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {isFetchBaseQueryErrorType(createError) && (
          <Alert message="Error" description={getErrorMessage(createError)} type="error" showIcon />
        )}
        {isFetchBaseQueryErrorType(updateError) && (
          <Alert message="Error" description={getErrorMessage(updateError)} type="error" showIcon />
        )}
        <Card
          title={
            <Title
              level={4}
              style={{
                marginBottom: 0,
                display: 'inline-block',
              }}
            >
              {isNewPartnerEDIX12 ? 'New Partner EDIX12' : 'Edit Partner EDIX12'}
            </Title>
          }
        >
          <Spin spinning={isFetching || isCreating || isUpdating}>
            <Form
              {...layout}
              form={form}
              name="control-hooks"
              initialValues={data}
              onFinish={onFinish}
              onFieldsChange={handleFormChange}   
            >
              <Collapse items={collapseFieldGroups} defaultActiveKey={['1','2','3','4']} />
              {!isNewPartnerEDIX12?
              <div>
                <Form.Item name="enteredBy" label="Entered By">
                <Space>
                  <span>{data?.enteredBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="enteredByDate" label="Entered By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.enteredByDate||'')}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updatedBy" label="Updated By">
                <Space>
                  <span>{data?.updatedBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updateByDate" label="Updated By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.updatedByDate||'')}</span>
                </Space>
              </Form.Item>      
              </div>:''
              }
              <Row style={{marginTop:'20px'}}>
              <Col span={22} style={{display: 'flex', justifyContent: 'flex-end'}}>
              <Form.Item wrapperCol={{ offset: 8, span: 12 }}>
                <Space>
                  <Button type="primary" htmlType="submit" disabled={disabledSave && !isNewPartnerEDIX12}>
                    {isNewPartnerEDIX12 ? 'Add Partner EDIX12' : 'Save Partner EDIX12'}
                  </Button>
                  <Button htmlType="button" onClick={goBack}>
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Card>
      </PageHeader>
    </div>
  );
}
