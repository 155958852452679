import { createSlice } from '@reduxjs/toolkit';
import { TableState } from '../../components/table/model';

const initialState: TableState = {
  columns: [],
};

const codedecodetypeListSlice = createSlice({
  name: 'codedecodetypeTable',
  initialState,
  reducers: {
    hideColumn(state) {},
  },
});

export const codedecodetypesReducer = codedecodetypeListSlice.reducer;
