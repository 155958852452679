import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  useCreateAcknowledgmentOverrideMutation,
  useFetchSingleAcknowledgmentOverrideQuery,
  useUpdateAcknowledgmentOverrideMutation,
} from '../acknowledgmentoverride-api';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined, UserOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {  PaginatedResult, AcknowledgmentOverrideViewModel, TransactionTypeViewModel } from '../../api/models';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import {
  useFetchTransactionTypesQuery,
} from '../../transactiontype/transactiontype-api';

import { getLink } from '../../../App-router';
import { DefaultTenantId } from '../../../core/defaults';
import { ConvertUTCtoCDT } from '../../../core/utils';
const { Option } = Select;

const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError =>
  error ? 'status' in error : false;

const getErrorMessage = (error: any): string | undefined => error.data;

interface AcknowledgmentOverrideScreenParams {
Id?: string | undefined;
tenantId?: string | undefined;
codedecodetypeid?: string | undefined;
  [key: string]: string | undefined;
}

export default function AcknowledgmentOverrideEditScreen(props: any) {
  const { Id, tenantId = DefaultTenantId, codedecodetypeid}: AcknowledgmentOverrideScreenParams =
    useParams<AcknowledgmentOverrideScreenParams>();

  const isNewAcknowledgmentOverride = Id === 'new';

  const { data, isFetching } = useFetchSingleAcknowledgmentOverrideQuery(
    {
      Id: Number(Id)
    },
    { skip: isNewAcknowledgmentOverride }
  );

  
  // Fetch Transaction Types - START

  const {
    data: transactionTypeValues = {},
  }: {
    data?: PaginatedResult<TransactionTypeViewModel> | undefined;
  } = useFetchTransactionTypesQuery({
      current:1,
      pageSize:1000,
      filter:'',
      sortBy: 'Name Asc',          
  });
  let transactionTypesResults = transactionTypeValues.results??[];

// Fetch Transaction Types - END

  
  const [createAcknowledgmentOverride, { isLoading: isCreating, error: createError }] = useCreateAcknowledgmentOverrideMutation();
  const [updateAcknowledgmentOverride, { isLoading: isUpdating, error: updateError }] = useUpdateAcknowledgmentOverrideMutation();
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [form] = Form.useForm();

  React.useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const onFinish = (values: any | AcknowledgmentOverrideViewModel) => {
      const requestData = {
        acknowledgmentoverride: {
          id: isNewAcknowledgmentOverride ? 0 : Number(Id),
          transactionType: values.transactionType,
          interchangeSenderQualifier: values.interchangeSenderQualifier||'',
          interchangeSenderID: values.interchangeSenderID||'',
          interchangeReceiverQualifier: values.interchangeReceiverQualifier||'',
          interchangeReceiverID: values.interchangeReceiverID||'',
          groupSenderID: values.groupSenderID||'',
          groupReceiverID: values.groupReceiverID||'',
          acknowledgmentOverride: values.acknowledgmentOverride==undefined?true:values.acknowledgmentOverride,
          isActive: values.isActive==undefined?true:values.isActive,
          updatedBy:sessionStorage.getItem('userEmail')||'',
          enteredBy:isNewAcknowledgmentOverride?sessionStorage.getItem('userEmail')||'':'',
        },
      };
      const request = isNewAcknowledgmentOverride ? createAcknowledgmentOverride(requestData) : updateAcknowledgmentOverride(requestData);
      request.unwrap().then((response) => {
        if(response?.id ===-200){
          message.error(
            `This combination already exists. Please try other values.`
          );
          return;
        }
        message.success(
          `Acknowledgment Override is ${isNewAcknowledgmentOverride ? 'created' : 'saved'}`
        );
        goBack();
      });
  };
  const [disabledSave, setDisabledSave] = useState(true);
  
  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSave(hasErrors);
  }
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (
    option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };

  const handleCancel = () => {
    navigate(
      getLink('AcknowledgmentOverrideList', {
        ...props,
        tenantId: tenantId
      })
    );
  };

  return (
    <div>
      <PageHeader
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
            <Link to={getLink('AcknowledgmentOverrideList', { ...props, tenantId })}>Acknowledgment Override</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{isNewAcknowledgmentOverride ? 'New Acknowledgment Override' : data?.id}</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {isFetchBaseQueryErrorType(createError) && (
          <Alert message="Error" description={getErrorMessage(createError)} type="error" showIcon />
        )}
        {isFetchBaseQueryErrorType(updateError) && (
          <Alert message="Error" description={getErrorMessage(updateError)} type="error" showIcon />
        )}
        <Card
          title={
            <Title
              level={4}
              style={{
                marginBottom: 0,
                display: 'inline-block',
              }}
            >
              {isNewAcknowledgmentOverride ? 'New Acknowledgment Override' : 'Edit Acknowledgment Override'}
            </Title>
          }
        >
          <Spin spinning={isFetching || isCreating || isUpdating}>
            <Form
              {...layout}
              form={form}
              name="control-hooks"
              initialValues={data}
              onFinish={onFinish}
              onFieldsChange={handleFormChange}   
            >
              <Form.Item name="transactionType" label="TransactionType" rules={[{ required: true }]} >
              <Select
                          showSearch
                          placeholder="Search..."
                          optionFilterProp="children"
                          data-testid="transactionTypeId"
                          filterOption={filterOption}
                          options={transactionTypesResults.map((item:any) => ({ label: item.name, value: item.name }))}
                        />
              </Form.Item>
              
              <Form.Item name="interchangeSenderQualifier" label="InterchangeSenderQualifier" rules={[{ required: true }]}  >
                <Input placeholder='Valid data is like ZZ or 02.' data-testid="interchangeSenderQualifier" maxLength={2} suffix={
                  <Tooltip title="Valid data is like ZZ or 02.">
                    <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                } />
              </Form.Item>

              <Form.Item name="interchangeSenderID" label="InterchangeSenderID" rules={[{ required: true }]} >
                <Input placeholder='Valid data is like FXFE, WYXC.' data-testid="interchangeSenderID" suffix={
                  <Tooltip title="Valid data is like FXFE, WYXC.">
                    <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }/>
              </Form.Item>
              <Form.Item name="interchangeReceiverQualifier" label="InterchangeReceiverQualifier" rules={[{ required: true }]} >
                <Input placeholder='Valid data is like ZZ or 02.' maxLength={2} data-testid="interchangeReceiverQualifier" suffix={
                  <Tooltip title="Valid data is like ZZ or 02.">
                    <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }/>
              </Form.Item>

              <Form.Item name="interchangeReceiverID" label="InterchangeReceiverID" rules={[{ required: true }]} >
                <Input placeholder='Valid data is like FXFE, WYXC.' data-testid="interchangeReceiverID" suffix={
                  <Tooltip title="Valid data is like FXFE, WYXC.">
                    <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }/>
              </Form.Item>
              <Form.Item name="groupSenderID" label="GroupSenderID" rules={[{ required: true }]} >
                <Input placeholder='Please enter valid input like AACT' data-testid="groupSenderID" suffix={
                  <Tooltip title="Please enter valid input like AACT">
                    <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }/>
              </Form.Item>
              <Form.Item name="groupReceiverID" label="GroupReceiverID" rules={[{ required: true }]} >
                <Input placeholder='Please enter valid input like AACT' data-testid="groupReceiverID" suffix={
                  <Tooltip title="Please enter valid input like AACT">
                    <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }/>
              </Form.Item>
              <Form.Item name="acknowledgmentOverride" label="AcknowledgmentOverride" rules={[{ required: true }]} 
              initialValue={isNewAcknowledgmentOverride? true : data?.acknowledgmentOverride}>
              <Switch />
              </Form.Item>
              <Form.Item name="isActive" label="Active" initialValue={isNewAcknowledgmentOverride?true : data?.isActive}>
                <Switch />
              </Form.Item>

              {!isNewAcknowledgmentOverride?
              <div>
                <Form.Item name="enteredBy" label="Entered By">
                <Space>
                  <span>{data?.enteredBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="enteredByDate" label="Entered By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.enteredByDate||'')}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updatedBy" label="Updated By">
                <Space>
                  <span>{data?.updatedBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updateByDate" label="Updated By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.updatedByDate||'')}</span>
                </Space>
              </Form.Item>      
              </div>:''
              }

              <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                <Space>
                  <Button type="primary" htmlType="submit" disabled={disabledSave && !isNewAcknowledgmentOverride}>
                    {isNewAcknowledgmentOverride ? 'Add Acknowledgment Override' : 'Save Acknowledgment Override'}
                  </Button>
                  <Button htmlType="button" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </PageHeader>
    </div>
  );
}
