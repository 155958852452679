import moment from 'moment';
import React from 'react';

const getAlertStartDate = (startDt: string) => {
    const startDate = moment.utc(startDt).toDate();

    const options: Intl.DateTimeFormatOptions = { 
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };

    return (<>{startDate.toLocaleDateString('en-US', options)}</>);
}

export default getAlertStartDate;