import { apiSlice } from '../api/api-slice';
import { CodeDecodeTypeViewModel, PaginatedResult } from '../api/models';

export interface CodeDecodeTypeListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
}

export interface EditCodeDecodeTypeRequest {
  codedecodetype: CodeDecodeTypeViewModel;
}

export interface CodeDecodeTypeRequest {
  Id: number;
}

export interface DeleteCodeDecodeTypeRequest {
  Id: number;
}

export const codedecodetypesApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchCodeDecodeTypes: builder.query<PaginatedResult<CodeDecodeTypeViewModel>, CodeDecodeTypeListRequest>({
        query({ current, pageSize, filter, sortBy }) {
          return `/codedecodetypes?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'CodeDecodeType',
          ...(data?.results?.map(({ id }) => ({ type: 'CodeDecodeType' as const, id: `${id}` })) || [])
        ] 
      }),
      fetchSingleCodeDecodeType: builder.query<CodeDecodeTypeViewModel, CodeDecodeTypeRequest>({
        query({ Id }) {
          return `/codedecodetypes/${Id}`;
        },
        providesTags: (_data, _error, arg) => [{ type: 'CodeDecodeType', id: `${arg.Id}` }]
      }),
      createCodeDecodeType: builder.mutation<CodeDecodeTypeViewModel, EditCodeDecodeTypeRequest>({
        query: ({ codedecodetype }) => {
          return {
            url: `/codedecodetypes`,
            method: 'POST',
            body: codedecodetype,
          };
        },
        invalidatesTags: ['CodeDecodeType']
      }),
      updateCodeDecodeType: builder.mutation<CodeDecodeTypeViewModel, EditCodeDecodeTypeRequest>({
        query: ({ codedecodetype }) => {
          return {
            url: `/codedecodetypes/${codedecodetype.id}`,
            method: 'PUT',
            body: codedecodetype,
          };
        },
        invalidatesTags: (_data, _error, arg) => [{ type: 'CodeDecodeType', id: `${arg.codedecodetype.id}` }]
      }),
      deleteCodeDecodeType: builder.mutation<void, DeleteCodeDecodeTypeRequest>({
        query: ({ Id }) => ({
          url: `/codedecodetypes/${Id}`,
          method: 'DELETE',
          body: {},
        }),
        invalidatesTags: (_data, _error, arg) => ['CodeDecodeType']
      })
    };
  },
});

export const {
  useFetchCodeDecodeTypesQuery,
  useFetchSingleCodeDecodeTypeQuery,
  useCreateCodeDecodeTypeMutation,
  useUpdateCodeDecodeTypeMutation,
  useDeleteCodeDecodeTypeMutation,
} = codedecodetypesApi;
