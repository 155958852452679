import COBLayout from './cob-screen';
import { RouteObject, RouteProps, createBrowserRouter, generatePath } from 'react-router-dom';
import { customerRoutes } from '../customers/customer-router';
import { endsystemRoutes } from '../endsystems/endsystem-router';
import { profilemapRoutes } from '../profilemaps/profilemap-router';
import { connectionendpointRoutes } from '../connectionendpoint/connectionendpoint-router';
import { partneredix12Routes } from '../partneredix12/partneredix12-router';
import { routingruleRoutes } from '../routingrules/routingrule-router';
import { sourcetypeRoutes } from '../sourcetypes/sourcetype-router';
import { targettypeRoutes } from '../targettypes/targettype-router';
import { codedecodetypeRoutes } from '../codedecodetype/codedecodetype-router';
import { codedecodevalueRoutes } from '../codedecodevalue/codedecodevalue-router';
import { transactiontypeRoutes } from '../transactiontype/transactiontype-router';
import { codedecodemapRoutes } from '../codedecodemap/codedecodemap-router';
import { acknowledgmentoverrideRoutes } from '../acknowledgmentoverride/acknowledgmentoverride-router';
import { partnerconnectionendpointRoutes } from '../partnerconnectionendpoint/partnerconnectionendpoint-router';
import { carrieronboardingRoutes } from '../carrieronboarding/carrieronboarding-router';
import { outboundtransactionRoutes } from '../outboundtransaction/outboundtransaction-router';
import { partnershipInformationRoutes } from '../partnershipinformation/partnershipInformation-router';
import { partnercontactinformationRoutes } from '../partnershipinformationcontact/partnercontactinformation-router';
import { partnerratingRoutes } from '../partnershipinformationrating/partnerrating-router';
import { partnercontrolnumberRoutes } from '../partnercontrolnumber/partnercontrolnumber-router';
import { partnerdataonboardingRoutes } from '../partnerdataonboarding/partnerdataonboarding-router';
import { acknowledgmentoverridereportemailRoutes } from '../acknowledgmentoverridereportemail/acknowledgmentoverridereportemail-router';
import { acknowledgmentoverridereportemailfilterRoutes } from '../acknowledgmentoverridereportemailfilter/acknowledgmentoverridereportemailfilter-router';

import NotFound from '../../components/common/NotFound';

export const cobRoutes= createBrowserRouter([
  {
    id: 'cob',
    path: 'cob',
    element: <COBLayout getRoutes={() => cobRoutes.routes}/>,
    children: [
      ...carrieronboardingRoutes,
      ...partnershipInformationRoutes,
      ...partnerdataonboardingRoutes,
      ...partnercontrolnumberRoutes,
      ...acknowledgmentoverrideRoutes,
      ...customerRoutes,
      ...endsystemRoutes,
      ...profilemapRoutes,
      ...connectionendpointRoutes,
      ...partneredix12Routes,
      ...routingruleRoutes,
      ...sourcetypeRoutes,
      ...targettypeRoutes,      
      ...transactiontypeRoutes,
      ...codedecodetypeRoutes,
      ...codedecodevalueRoutes,
      ...codedecodemapRoutes,
      ...partnerconnectionendpointRoutes,
      ...outboundtransactionRoutes,
      ...acknowledgmentoverridereportemailRoutes,
      ...acknowledgmentoverridereportemailfilterRoutes,
      ...partnercontactinformationRoutes,
      ...partnerratingRoutes,
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);
