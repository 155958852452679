import { apiSlice } from '../api/api-slice';
import { AcknowledgmentOverrideViewModel, PaginatedResult } from '../api/models';

export interface AcknowledgmentOverrideListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
}

export interface EditAcknowledgmentOverrideRequest {
  acknowledgmentoverride: AcknowledgmentOverrideViewModel;
}

export interface AcknowledgmentOverrideRequest {
  Id: number;
}

export interface DeleteAcknowledgmentOverrideRequest {
  Id: number;
}

export const acknowledgmentoverridesApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchAcknowledgmentOverrides: builder.query<PaginatedResult<AcknowledgmentOverrideViewModel>, AcknowledgmentOverrideListRequest>({
        query({current, pageSize, filter, sortBy }) {
          return `/acknowledgmentoverrides?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'AcknowledgmentOverride',
          ...(data?.results?.map(({ id }) => ({ type: 'AcknowledgmentOverride' as const, id: `${id}` })) || [])
        ] 
      }),
      fetchSingleAcknowledgmentOverride: builder.query<AcknowledgmentOverrideViewModel, AcknowledgmentOverrideRequest>({
        query({ Id }) {
          return `/acknowledgmentoverrides/${Id}`;
        },
        providesTags: (_data, _error, arg) => [{ type: 'AcknowledgmentOverride', id: `${arg.Id}` }]
      }),
      createAcknowledgmentOverride: builder.mutation<AcknowledgmentOverrideViewModel, EditAcknowledgmentOverrideRequest>({
        query: ({ acknowledgmentoverride }) => {
          return {
            url: `/acknowledgmentoverrides`,
            method: 'POST',
            body: acknowledgmentoverride,
          };
        },
        invalidatesTags: ['AcknowledgmentOverride']
      }),
      updateAcknowledgmentOverride: builder.mutation<AcknowledgmentOverrideViewModel, EditAcknowledgmentOverrideRequest>({
        query: ({ acknowledgmentoverride }) => {
          return {
            url: `/acknowledgmentoverrides/${acknowledgmentoverride.id}`,
            method: 'PUT',
            body: acknowledgmentoverride,
          };
        },
        invalidatesTags: (_data, _error, arg) => [{ type: 'AcknowledgmentOverride', id: `${arg.acknowledgmentoverride.id}` }]
      }),
      deleteAcknowledgmentOverride: builder.mutation<void, DeleteAcknowledgmentOverrideRequest>({
        query: ({ Id }) => ({
          url: `/acknowledgmentoverrides/${Id}`,
          method: 'DELETE',
          body: {},
        }),
        invalidatesTags: (_data, _error, arg) => ['AcknowledgmentOverride']
      })
    };
  },
});

export const {
  useFetchAcknowledgmentOverridesQuery,
  useFetchSingleAcknowledgmentOverrideQuery,
  useCreateAcknowledgmentOverrideMutation,
  useUpdateAcknowledgmentOverrideMutation,
  useDeleteAcknowledgmentOverrideMutation,
} = acknowledgmentoverridesApi;
