import { apiSlice } from '../api/api-slice';
import { CarrierOnboardingViewModel, PaginatedResult, SplittingRuleViewModel} from '../api/models';

export interface CarrierOnboardingListRequest {
  pageSize: number;
  carrierName: string | '';
  partnerId: string | '';
  identifierValue: string | '';
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
}
export interface EditCarrierOnboardingRequest {
  carrieronboarding: SplittingRuleViewModel;
}

export interface CarrierOnboardingRequest {
  Id: number;
}

export interface DeleteCarrierOnboardingRequest {
  Id: number;
}

export const carrieronboardingsApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      
      fetchCarrierOnboardings: builder.query<PaginatedResult<CarrierOnboardingViewModel>, CarrierOnboardingListRequest>({
        query({ current, pageSize, carrierName:carrierName, partnerId: partnerId, identifierValue: identifierValue, filter, sortBy }) {
          return `/SplittingRules?pageNumber=${current}&pageSize=${pageSize}${carrierName || carrierName!='null'? '&carrierName=' + carrierName : ''}${partnerId || partnerId!='null' ? '&partnerId=' + partnerId : ''}${identifierValue || identifierValue!='null' ? '&identifierValue=' + identifierValue : ''}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'CarrierOnboarding',
          ...(data?.results?.map(({ id }) => ({ type: 'CarrierOnboarding' as const, id: `${id}` })) || [])
        ] 
      }),
      fetchSingleCarrierOnboarding: builder.query<CarrierOnboardingViewModel, CarrierOnboardingRequest>({
        query({ Id }) {
          return `/SplittingRules/${Id}`;
        },
        providesTags: (_data, _error, arg) => [{ type: 'CarrierOnboarding', id: `${arg.Id}` }]
      }),

      createCarrierOnboarding: builder.mutation<CarrierOnboardingViewModel, EditCarrierOnboardingRequest>({
        query: ({ carrieronboarding }) => {
          return {
            url: `/SplittingRules`,
            method: 'POST',
            body: carrieronboarding,
          };
        },
        invalidatesTags: ['CarrierOnboarding']
      }),
      updateCarrierOnboarding: builder.mutation<CarrierOnboardingViewModel, EditCarrierOnboardingRequest>({
        query: ({ carrieronboarding }) => {
          return {
            url: `/SplittingRules/${carrieronboarding.id}`,
            method: 'PUT',
            body: carrieronboarding,
          };
        },
        invalidatesTags: (_data, _error, arg) => [{ type: 'CarrierOnboarding', id: `${arg.carrieronboarding.id}` }]
      }),
      deleteCarrierOnboarding: builder.mutation<void, DeleteCarrierOnboardingRequest>({
        query: ({ Id }) => ({
          url: `/SplittingRules/${Id}`,
          method: 'DELETE',
          body: {},
        }),
        invalidatesTags: (_data, _error, arg) => ['CarrierOnboarding']
      })
    };
  },
});

export const {
  useFetchCarrierOnboardingsQuery,
  useFetchSingleCarrierOnboardingQuery,
  useCreateCarrierOnboardingMutation,
  useUpdateCarrierOnboardingMutation,
  useDeleteCarrierOnboardingMutation,
} = carrieronboardingsApi;
