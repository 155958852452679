import { apiSlice } from '../api/api-slice';
import { DocumentTypeViewModel, PaginatedResult } from '../api/models';

export interface DocumentTypeListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
}

export const DocumentTypesApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchDocumentTypes: builder.query<PaginatedResult<DocumentTypeViewModel>, DocumentTypeListRequest>({
        query({ current, pageSize, filter, sortBy }) {
          return `/DocumentTypes?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'DocumentType',
          ...(data?.results?.map(({ id }) => ({ type: 'DocumentType' as const, id: `${id}` })) || [])
        ] 
      }),
    };
  },
});

export const {
  useFetchDocumentTypesQuery,
} = DocumentTypesApi;
