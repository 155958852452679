import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  useCreatePartnerControlNumberMutation,
  useFetchSinglePartnerControlNumberQuery,
  useUpdatePartnerControlNumberMutation,
} from '../partnercontrolnumber-api';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { PartnerControlNumberViewModel } from '../../api/models';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ConvertUTCtoCDT } from '../../../core/utils';
import { getLink } from '../../../App-router';
import { DefaultTenantId } from '../../../core/defaults';
const { Option } = Select;

const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError =>
  error ? 'status' in error : false;

const getErrorMessage = (error: any): string | undefined => error.data;

interface PartnerControlNumberScreenParams {
Id?: string | undefined;
tenantId?: string | undefined;
  [key: string]: string | undefined;
}

export default function PartnerControlNumberEditScreen(props: any) {
  const { Id, tenantId = DefaultTenantId}: PartnerControlNumberScreenParams =
    useParams<PartnerControlNumberScreenParams>();

  const isNewPartnerControlNumber = Id === 'new';

  const { data, isFetching } = useFetchSinglePartnerControlNumberQuery(
    {
      Id: Number(Id),
    },
    { skip: isNewPartnerControlNumber }
  );

  const [createPartnerControlNumber, { isLoading: isCreating, error: createError }] = useCreatePartnerControlNumberMutation();
  const [updatePartnerControlNumber, { isLoading: isUpdating, error: updateError }] = useUpdatePartnerControlNumberMutation();

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [form] = Form.useForm();

  React.useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const onFinish = (values: any | PartnerControlNumberViewModel) => {
    const requestData = {
      partnercontrolnumber: {
        id: isNewPartnerControlNumber ? 0 : Number(Id),
        key: values.key,
        controlNumber: values.controlNumber||"0",
        updatedBy:sessionStorage.getItem('userEmail')||'',
        enteredBy:isNewPartnerControlNumber?sessionStorage.getItem('userEmail')||'':'',
      },
    };
     
    const request = isNewPartnerControlNumber ? createPartnerControlNumber(requestData) : updatePartnerControlNumber(requestData);
    request.unwrap().then((response) => {
      if(response?.id ===-200){
        message.error(
          `This combination already exists. Please try other values.`
        );
        return;
      }
      message.success(
        `PartnerControlNumber is ${isNewPartnerControlNumber ? 'created' : 'saved'}`
      );
      navigate(
        getLink('PartnerControlNumberList', {
          ...props,
          tenantId: tenantId,
        })
      );
    });
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };

  const handleCancel = () => {
    navigate(
      getLink('PartnerControlNumberList', {
        ...props,
        tenantId: tenantId,
      })
    );
  };
  const [disabledSave, setDisabledSave] = useState(true);
  
  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSave(hasErrors);
  }
  return (
    <div>
      <PageHeader
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={getLink('PartnerControlNumberList', { ...props, tenantId })}>Partner Control Number</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{isNewPartnerControlNumber ? 'New Partner Control Number' : data?.key}</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {isFetchBaseQueryErrorType(createError) && (
          <Alert message="Error" description={getErrorMessage(createError)} type="error" showIcon />
        )}
        {isFetchBaseQueryErrorType(updateError) && (
          <Alert message="Error" description={getErrorMessage(updateError)} type="error" showIcon />
        )}
        <Card
          title={
            <Title
              level={4}
              style={{
                marginBottom: 0,
                display: 'inline-block',
              }}
            >
              {isNewPartnerControlNumber ? 'New Partner Control Number' : 'Edit Partner Control Number'}
            </Title>
          }
        >
          <Spin spinning={isFetching || isCreating || isUpdating}>
            <Form
              {...layout}
              form={form}
              name="profile-map"
              initialValues={data}
              onFinish={onFinish}
              onFieldsChange={handleFormChange}
            >
              <Form.Item name="key" label="Key" rules={[{ required: true }]} >
                <Input placeholder='Please provide valid key with length upto 100 for eg. EDIX12_GS_SM_RWTORR_MAEU' data-testid="key" maxLength={500} suffix={
                        <Tooltip title="Please provide valid key with length upto 100 for eg. EDIX12_GS_SM_RWTORR_MAEU">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              
              <Form.Item name="controlNumber" label="Control Number" rules={[{ required: true}]}>
                <Input placeholder='Please enter the control number.' type='number' min={1} data-testid="controlNumber" suffix={
                        <Tooltip title="Please enter the control number.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>              

              {!isNewPartnerControlNumber?
              <div>
                <Form.Item name="enteredBy" label="Entered By">
                <Space>
                  <span>{data?.enteredBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="enteredByDate" label="Entered By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.enteredByDate||'')}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updatedBy" label="Updated By">
                <Space>
                  <span>{data?.updatedBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updateByDate" label="Updated By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.updatedByDate||'')}</span>
                </Space>
              </Form.Item>      
              </div>:''
              }

              <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                <Space>
                  <Button type="primary" htmlType="submit" disabled={disabledSave && !isNewPartnerControlNumber}>
                    {isNewPartnerControlNumber ? 'Add Partner Control Number' : 'Save Partner Control Number'}
                  </Button>
                  <Button htmlType="button" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </PageHeader>
    </div>
  );
}
