import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '@auth0/auth0-spa-js';

interface AuthState {
  token?: string;
  user?: User;
  hasAccess: boolean;
}

const initAuthState: AuthState = {
  hasAccess: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initAuthState,
  reducers: {
    login: (state, action: PayloadAction<AuthState | undefined>) => {
      state.user = action.payload?.user;
      state.token = action.payload?.token;
      state.hasAccess = true;
    },
    logout: (state) => {
      state.token = '';
      state.hasAccess = false;
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
