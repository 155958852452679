import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  useCreateRoutingRuleMutation,
  useFetchSingleRoutingRuleQuery,
  useUpdateRoutingRuleMutation,
} from '../routingrule-api';

import {
  useFetchCustomersQuery,
} from '../../customers/customer-api';

import {
  useFetchTransactionTypesQuery,
} from '../../transactiontype/transactiontype-api';
import {
  useFetchEndSystemsQuery,
} from '../../endsystems/endsystem-api';

import {
  useFetchCustomerIdentifierTypesQuery,
} from '../../api/customeridentifiertype-api';

import {
  useFetchProfileMapsQuery,
} from '../../profilemaps/profilemap-api';

import { PageHeader } from '@ant-design/pro-layout';
import type { CollapseProps } from 'antd';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip,
  Collapse,
  Row,
  Col
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined, UserOutlined,QuestionCircleOutlined } from '@ant-design/icons';
import { Company, PaginatedResult, RoutingRuleViewModel,  ProfileMapViewModel,CustomerViewModel, EndSystemViewModel, TransactionTypeViewModel,CustomerIdentifierTypeViewModel } from '../../api/models';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { getLink } from '../../../App-router';
import { DefaultTenantId } from '../../../core/defaults';
import { ConvertUTCtoCDT } from '../../../core/utils';
const { Option } = Select;

const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError =>
  error ? 'status' in error : false;

const getErrorMessage = (error: any): string | undefined => error.data;

interface RoutingRuleScreenParams {
Id?: string | undefined;
tenantId?: string | undefined;
  [key: string]: string | undefined;
}

export default function Rule997EditScreen(props: any) {
  const { Id, tenantId = DefaultTenantId}: RoutingRuleScreenParams =
    useParams<RoutingRuleScreenParams>();

    const isNew997Rule = Id === 'new';
    const { data, isFetching } = useFetchSingleRoutingRuleQuery(
      {
        Id: Number(Id),
      },
      { skip: isNew997Rule }
    );

  const [createRoutingRule, { isLoading: isCreating, error: createError }] = useCreateRoutingRuleMutation();
  const [updateRoutingRule, { isLoading: isUpdating, error: updateError }] = useUpdateRoutingRuleMutation();

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [formRoutingRule] = Form.useForm();

  React.useEffect(() => {
    formRoutingRule.resetFields();
  }, [data,formRoutingRule]);

// Fetch Customers Types - START
  const {
    data: customers = {},
  }: {
    data?: PaginatedResult<CustomerViewModel> | undefined;
  } = useFetchCustomersQuery({
    current:1,
    pageSize:1000,
    filter:'',
    sortBy: 'Name Asc',
        
});
  let customersResults = customers.results??[];
// Fetch Customers Types - END


const {
  data: profileMaps = {},
  error,
}: {
  data?: PaginatedResult<ProfileMapViewModel> | undefined;
  error?: any;
} = useFetchProfileMapsQuery({
  current:1,
  pageSize:1000,
  filter:'',
  sortBy: 'Name Asc',        
});
let profileMapsResults = profileMaps.results??[];

// Fetch Transaction Types - START

const {
  data: transactionTypeValues = {},
}: {
  data?: PaginatedResult<TransactionTypeViewModel> | undefined;
} = useFetchTransactionTypesQuery({
    current:1,
    pageSize:1000,
    filter:'',
    sortBy: 'Name Asc',
        
});
let transactionTypesResults = transactionTypeValues.results??[];
// Fetch Transaction Types - END

// Fetch EndSystems Types - START
const {
data: endSystemTypeValues = {},
}: {
data?: PaginatedResult<EndSystemViewModel> | undefined;
} = useFetchEndSystemsQuery({
  current:1,
  pageSize:1000,
  filter:'',
  sortBy: 'Name Asc',
      
});
let endSystemTypesResults = endSystemTypeValues.results??[];
// Fetch EndSystems Types - END


// Fetch CustomerIdentifier Types - START
const {
  data: customerIdentifierTypes = {},
}: {
  data?: PaginatedResult<CustomerIdentifierTypeViewModel> | undefined;
} = useFetchCustomerIdentifierTypesQuery({
  current:1,
  pageSize:1000,
  filter:'',
  sortBy: 'Name Asc',
      
});
let customerIdentifierTypesResults = customerIdentifierTypes.results??[];

// Fetch CustomerIdentifier Types - END
  const onFinish = (values: any | RoutingRuleViewModel) => {
     let profileMapId= profileMapsResults.filter(x=>x.name?.toLowerCase()=='edi997tocosmosdatabase')[0].id||0;
     let    transactionTypeId= transactionTypesResults.filter(x=>x.name=='997')[0].id||0;
     let    endSystemId= endSystemTypesResults.filter(x=>x.name?.toLowerCase()=='cosmos')[0].id||0;
     let    identifierTypeId= customerIdentifierTypesResults.filter(x=>x?.name.toLowerCase()==='na')[0]?.id||0;
     let    customerId = customersResults.filter(x=>x.name?.toLowerCase()==='carrier edi default')[0]?.id||0;
    const requestData = {
      routingrule: {
            id: values.id === undefined?0:values.id,
            splittingRuleId:Number(localStorage.getItem('splittingRuleID')),
            customerId: customerId,
            profileMapId: profileMapId,
            transactionTypeId: Number(transactionTypeId),
            endSystemId: endSystemId,
            identifierTypeId: identifierTypeId,
            identifierValue: '',
            sequence: 1,
            updatedBy:sessionStorage.getItem('userEmail')||'',
            enteredBy:isNew997Rule?sessionStorage.getItem('userEmail')||'':'',
      },
    };

    const request = isNew997Rule ? createRoutingRule(requestData) : updateRoutingRule(requestData);
    request.unwrap().then((response) => {
      if(response?.id ===-200){
        message.error(
          `This combination already exists. Please try other values.`
        );
        return;
      }
      message.success(
        `997 Inbound Rule is  ${isNew997Rule ? 'created' : 'saved'}`
      );
      goBack();
    });
  };


 // Filter `option.label` match the user type `input`
 const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (
  option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };

  return (
    <div>
      <PageHeader
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="partnershipInformation">
              <Link to={getLink('PartnershipInformationList', { tenantId: tenantId })}>Partner Information</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="carrierName">
              <Link to={getLink('CarrierOnboardingList', {
                      ...props, tenantId,
                      partnerId: localStorage.getItem('partnerId')||'',
                      identifierValue: localStorage.getItem('identifierValue')||'',
                      carrierName: localStorage.getItem('carrierName')||'',
                      id: Number(localStorage.getItem('partnershipId')||0),
                    })}>{localStorage.getItem('carrierName')||''}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="splittingRule">
              <Link to={getLink('CarrierOnboardingEdit', { tenantId: tenantId,Id: Number(localStorage.getItem('splittingRuleID')||0),
                      partnershipId: Number(localStorage.getItem('partnershipId')||0) })}>{localStorage.getItem('splittingRuleID')||''}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{isNew997Rule ? 'New 997 Rule' : data?.id}</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {isFetchBaseQueryErrorType(createError) && (
          <Alert message="Error" description={getErrorMessage(createError)} type="error" showIcon />
        )}
        {isFetchBaseQueryErrorType(updateError) && (
          <Alert message="Error" description={getErrorMessage(updateError)} type="error" showIcon />
        )}
        <Card
          title={
            <Title
              level={4}
              style={{
                marginBottom: 0,
                display: 'inline-block',
              }}
            >
             {isNew997Rule ? 'New 997 Rule' : 'Edit 997 Rule'}
            </Title>
          }
        >
          <Spin spinning={isFetching || isCreating || isUpdating}>
          <Form 
                      {...layout}
                      form={formRoutingRule}
                      name="997-rule-hooks-1"
                      initialValues={data}
                      onFinish={onFinish}
                    > 
                      <Form.Item name="id" label="id" hidden>
                        <Input data-testid="id"  />
                      </Form.Item>
                      <Form.Item label="Customer" initialValue={'Carrier EDI Default'}>
                        <Input defaultValue={'Carrier EDI Default'}  disabled  suffix={
                        <Tooltip title="Valid customer id like Carrier EDI Default.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
                      </Form.Item>        
                      <Form.Item name="profileMapId" label="ProfileMap" hidden={true}>
                      <Input data-testid="sequence" value="EDI997ToCosmosDB" />
                      </Form.Item>
                      <Form.Item name="transactionTypeId" label="TransactionType" hidden={true}>
                      <Input data-testid="sequence" value="997" />
                      </Form.Item>
                      <Form.Item name="endSystemId" label="EndSystem" hidden={true}>
                      <Input data-testid="sequence" value="" />
                      </Form.Item>
                      <Form.Item name="identifierTypeId" label="Identifier Type" hidden={true}>
                      <Input data-testid="sequence" value="" />
                      </Form.Item>
                      <Form.Item name="identifierValue" label="IdentifierValue" hidden={true}>
                      <Input data-testid="sequence" value="" />
                      </Form.Item>
                      <Form.Item name="sequence" label="Sequence" hidden={true}>
                        <Input data-testid="sequence" value="" />
                      </Form.Item>
                      {!isNew997Rule?
                    <div>
                      <Form.Item name="enteredBy" label="Entered By">
                      <Space>
                        <span>{data?.enteredBy}</span>
                      </Space>
                    </Form.Item>
                    <Form.Item name="enteredByDate" label="Entered By Date">
                      <Space>
                        <span>{ConvertUTCtoCDT(data?.enteredByDate||'')}</span>
                      </Space>
                    </Form.Item>
                    <Form.Item name="updatedBy" label="Updated By">
                      <Space>
                        <span>{data?.updatedBy}</span>
                      </Space>
                    </Form.Item>
                    <Form.Item name="updateByDate" label="Updated By Date">
                      <Space>
                        <span>{ConvertUTCtoCDT(data?.updateByDate||'')}</span>
                      </Space>
                    </Form.Item>      
                    </div>:''
                    }
                      <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                      <Space>
                        <Button type="primary" htmlType="submit" disabled={!isNew997Rule}>
                        {isNew997Rule ? 'Add 997 Rule' : 'Save 997 Rule'}
                        </Button>
                        <Button htmlType="button" onClick={goBack}>
                          Cancel
                        </Button>
                      </Space>
                    </Form.Item>                    
                    </Form>
          </Spin>
        </Card>
      </PageHeader>
    </div>
  );
}
