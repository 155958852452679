import CodeDecodeTypesScreen from './screens/codedecodetypes-screen';
import CodeDecodeTypeEditScreen from './screens/codedecodetype-edit-screen';
import { RouteDefinition } from '../../core/route';

export const codedecodetypeRoutes: RouteDefinition[] = [
  {
    id: 'CodeDecodeTypeEdit',
    title: 'Code Decode Type',
    path: 'codedecodetypes/:Id',
    element: <CodeDecodeTypeEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'CodeDecodeTypeList',
    title: 'Code Decode Types',
    path: 'codedecodetypes',
    element: <CodeDecodeTypesScreen />,
    showInNavigation: true,
  },
];
