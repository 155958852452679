export const ConvertUTCtoCDT = (inputDate:string) => {
    var utc = new Date(inputDate);
    // Get dates for January and July
    let dateJan = new Date(utc.getFullYear(), 0, 1);
    let dateJul = new Date(utc.getFullYear(), 6, 1);
  
    // Get timezone offset
    let timezoneOffset = Math.max(dateJan.getTimezoneOffset(), dateJul.getTimezoneOffset());
    var timelagging = 5; // 5 or 6 =: DEFAULT TO 5.
    if (utc.getTimezoneOffset() > timezoneOffset) {
      // Adjust date by 5 hours
      timelagging = 6
    }
  
    return new Date(utc.getTime()-((1 * 60 * 60 * 1000) * timelagging)).toLocaleString();
}

export const isNullEmpty =(value:string)=>{
  return (value == null || value == undefined || (typeof value === "string" && value.trim().length === 0));
}