import { RouteDefinition } from '../../core/route';
import AlertScreen from './screens/alerts-screen';

export const alertRoutes: RouteDefinition[] = [
  {
    id: 'AlertList',
    title: 'Alerts',
    path: 'alerts',
    element: <AlertScreen />,
    showInNavigation: true,
  },
];
