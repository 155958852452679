import AppLayout from './components/common/AppLayout';
import { RouteDefinition } from './core/route';
import NotFound from './components/common/NotFound';
import { RouteObject, RouteProps, createBrowserRouter, generatePath } from 'react-router-dom';
import { monitorRoutes } from './features/monitors/monitor-router';
import { partnerRoutes } from './features/partners/partner-router';

import { alertRoutes } from './features/alerts/alert-router';
import { telemetryRoutes } from './features/telemetry/telemetry-router';
import { settingsRoutes } from './features/settings/settings.router';
import { cobRoutes } from './features/cob/cob-router';
import { homeRoutes } from './features/home/home-router';


export const appRoutes = createBrowserRouter([
  {
    id: 'Root',
    path: '/:tenantId',
    element: <AppLayout getRoutes={() => appRoutes.routes} />,
    children: [
      ...partnerRoutes,
      ...homeRoutes,
      ...cobRoutes.routes,
      ...monitorRoutes,
      ...alertRoutes,
      ...telemetryRoutes,
      ...settingsRoutes,
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    id: 'DefaultNotFound',
    path: '',
    element: <AppLayout getRoutes={() => appRoutes.routes} />,
    children: [
      {
        id: 'NotFound',
        path: '',
        element: <NotFound />,
      },
    ],
  },
]);

const findLink = (name: string, routes: RouteObject[] | undefined): string[] => {
  if (!routes) return [];
  const routeParts: string[] = [];
  for (const route of routes) {
    const innerMatch = findLink(name, route.children);
    if (route.id === name) {
      routeParts.push(route.path!);
      return routeParts;
    } else if (innerMatch.length > 0) {
      routeParts.push(route.path!);
      routeParts.push(...innerMatch);
    }
  }
  return routeParts;
};

export const getLink = (name: string, params: any, query: any | undefined = undefined) => {
  const linkTemplate = findLink(name, appRoutes.routes);
  if (linkTemplate.length === 0) throw new Error(`${name} route was not found in the router`);

  const link = `${generatePath(linkTemplate.join('/'), params)}${
    query ? '?' + new URLSearchParams(query).toString() : ''
  }`;
  return link;
};

export const getPath = (match: any) => {
  return null;
};
