import { apiSlice } from '../api/api-slice';
import { AlertViewModel, PaginatedResult } from '../api/models';

export interface TablePageRequest {
  tenantId: string | null;
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
}

export const alertsApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchAlerts: builder.query<PaginatedResult<AlertViewModel>, TablePageRequest>({
        query({ tenantId, current, pageSize, filter, sortBy }) {
          return `/tenants/${tenantId}/alerts?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
      }),
    };
  },
});

export const { useFetchAlertsQuery } = alertsApi;
