import { apiSlice } from '../api/api-slice';
import { ConnectionEndPointViewModel, PaginatedResult } from '../api/models';

export interface ConnectionEndPointListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
}

export interface EditConnectionEndPointRequest {
  connectionendpoint: ConnectionEndPointViewModel;
}

export interface ConnectionEndPointRequest {
  Id: number;
}

export interface DeleteConnectionEndPointRequest {
  Id: number;
}

export const connectionendpointsApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchConnectionEndPoints: builder.query<PaginatedResult<ConnectionEndPointViewModel>, ConnectionEndPointListRequest>({
        query({ current, pageSize, filter, sortBy }) {
          return `/connectionendpoints?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'ConnectionEndPoint',
          ...(data?.results?.map(({ id }) => ({ type: 'ConnectionEndPoint' as const, id: `${id}` })) || [])
        ] 
      }),
      fetchSingleConnectionEndPoint: builder.query<ConnectionEndPointViewModel, ConnectionEndPointRequest>({
        query({ Id }) {
          return `/connectionendpoints/${Id}`;
        },
        providesTags: (_data, _error, arg) => [{ type: 'ConnectionEndPoint', id: `${arg.Id}` }]
      }),
      createConnectionEndPoint: builder.mutation<ConnectionEndPointViewModel, EditConnectionEndPointRequest>({
        query: ({ connectionendpoint }) => {
          return {
            url: `/connectionendpoints`,
            method: 'POST',
            body: connectionendpoint,
          };
        },
        invalidatesTags: ['ConnectionEndPoint']
      }),
      updateConnectionEndPoint: builder.mutation<ConnectionEndPointViewModel, EditConnectionEndPointRequest>({
        query: ({ connectionendpoint }) => {
          return {
            url: `/connectionendpoints/${connectionendpoint.id}`,
            method: 'PUT',
            body: connectionendpoint,
          };
        },
        invalidatesTags: (_data, _error, arg) => [{ type: 'ConnectionEndPoint', id: `${arg.connectionendpoint.id}` }]
      }),
      deleteConnectionEndPoint: builder.mutation<void, DeleteConnectionEndPointRequest>({
        query: ({ Id }) => ({
          url: `/connectionendpoints/${Id}`,
          method: 'DELETE',
          body: {},
        }),
        invalidatesTags: (_data, _error, arg) => ['ConnectionEndPoint']
      })
    };
  },
});

export const {
  useFetchConnectionEndPointsQuery,
  useFetchSingleConnectionEndPointQuery,
  useCreateConnectionEndPointMutation,
  useUpdateConnectionEndPointMutation,
  useDeleteConnectionEndPointMutation,
} = connectionendpointsApi;
