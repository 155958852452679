import CodeDecodeMapsScreen from './screens/codedecodemaps-screen';
import CodeDecodeMapEditScreen from './screens/codedecodemap-edit-screen';
import { RouteDefinition } from '../../core/route';

export const codedecodemapRoutes: RouteDefinition[] = [
  {
    id: 'CodeDecodeMapEdit',
    title: 'Code Decode Map',
    path: 'codedecodemaps/:Id',
    element: <CodeDecodeMapEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'CodeDecodeMapList',
    title: 'Code Decode Maps',
    path: 'codedecodemaps',
    element: <CodeDecodeMapsScreen />,
    showInNavigation: true,
  },
];
