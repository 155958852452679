import { apiSlice } from '../api/api-slice';
import { CustomerIdentifierTypeViewModel, PaginatedResult } from '../api/models';

export interface CustomerIdentifierTypeListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
}

export const customerIdentifierTypesApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchCustomerIdentifierTypes: builder.query<PaginatedResult<CustomerIdentifierTypeViewModel>, CustomerIdentifierTypeListRequest>({
        query({ current, pageSize, filter, sortBy }) {
          return `/CustomerIdentifierTypes?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'CustomerIdentifierType',
          ...(data?.results?.map(({ id }) => ({ type: 'CustomerIdentifierType' as const, id: `${id}` })) || [])
        ] 
      }),
    };
  },
});

export const {
  useFetchCustomerIdentifierTypesQuery,
} = customerIdentifierTypesApi;
