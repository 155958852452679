import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  useCreateCodeDecodeValueMutation,
  useFetchSingleCodeDecodeValueQuery,
  useUpdateCodeDecodeValueMutation,
} from '../codedecodevalue-api';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined, UserOutlined,QuestionCircleOutlined } from '@ant-design/icons';
import { Company, PaginatedResult, CodeDecodeValueViewModel } from '../../api/models';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ConvertUTCtoCDT } from '../../../core/utils';
import { getLink } from '../../../App-router';
import { DefaultTenantId } from '../../../core/defaults';
const { Option } = Select;

const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError =>
  error ? 'status' in error : false;

const getErrorMessage = (error: any): string | undefined => error.data;

interface CodeDecodeValueScreenParams {
Id?: string | undefined;
tenantId?: string | undefined;
codedecodetypeid?: string | undefined;
  [key: string]: string | undefined;
}
export default function CodeDecodeValueEditScreen(props: any) {
  const { Id, tenantId = DefaultTenantId, codedecodetypeid}: CodeDecodeValueScreenParams =
    useParams<CodeDecodeValueScreenParams>();

  const isNewCodeDecodeValue = Id === 'new';

  const { data, isFetching } = useFetchSingleCodeDecodeValueQuery(
    {
      Id: Number(Id),
      codedecodetypeid:Number(codedecodetypeid)
    },
    { skip: isNewCodeDecodeValue }
  );

  const [createCodeDecodeValue, { isLoading: isCreating, error: createError }] = useCreateCodeDecodeValueMutation();
  const [updateCodeDecodeValue, { isLoading: isUpdating, error: updateError }] = useUpdateCodeDecodeValueMutation();
  const params: any = useParams<any>();
  // console.log(params);
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  
  const handleFormChange = () => {
  const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
  setDisabledSave(hasErrors);
  }
  React.useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const onFinish = (values: any | CodeDecodeValueViewModel) => {
    const requestData = {
      codedecodevalue: {
        id: isNewCodeDecodeValue ? 0 : Number(Id),
        code: values.code,
        decode: values.decode,
        decode1: values.decode1,
        codeDecodeTypeId: params?.codedecodetypeid||'0',
        updatedBy:sessionStorage.getItem('userEmail')||'',
        enteredBy:isNewCodeDecodeValue?sessionStorage.getItem('userEmail')||'':'',
      },
    };

    const request = isNewCodeDecodeValue ? createCodeDecodeValue(requestData) : updateCodeDecodeValue(requestData);
    request.unwrap().then((response) => {
      if(response?.id ===-200){
        message.error(
          `This combination already exists. Please try other values.`
        );
        return;
      }
      message.success(
        `Code Decode Value is ${isNewCodeDecodeValue ? 'created' : 'saved'}`
      );
      navigate(
        getLink('CodeDecodeValueList', {
          ...props,
          tenantId: tenantId,
          codedecodetypeid: codedecodetypeid
        })
      );
    });
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };

  const handleCancel = () => {
    navigate(
      getLink('CodeDecodeValueList', {
        ...props,
        tenantId: tenantId,
        codedecodetypeid: codedecodetypeid
      })
    );
  };

  return (
    <div>
      <PageHeader
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={getLink('CodeDecodeTypeList', { ...props, tenantId:params.tenantId })}>Code Decode Types</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={getLink('CodeDecodeValueList', { ...props, tenantId, codedecodetypeid: codedecodetypeid })}>Code Decode Values</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{isNewCodeDecodeValue ? 'New Code Decode Value' : data?.code}</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {isFetchBaseQueryErrorType(createError) && (
          <Alert message="Error" description={getErrorMessage(createError)} type="error" showIcon />
        )}
        {isFetchBaseQueryErrorType(updateError) && (
          <Alert message="Error" description={getErrorMessage(updateError)} type="error" showIcon />
        )}
        <Card
          title={
            <Title
              level={4}
              style={{
                marginBottom: 0,
                display: 'inline-block',
              }}
            >
              {isNewCodeDecodeValue ? 'New Code Decode Value' : 'Edit Code Decode Value'}
            </Title>
          }
        >
          <Spin spinning={isFetching || isCreating || isUpdating}>
            <Form
              {...layout}
              form={form}
              name="control-hooks"
              initialValues={data}
              onFinish={onFinish}
              onFieldsChange={handleFormChange}  
            >
              <Form.Item name="code" label="Code" rules={[{ required: true }]}>
                <Input placeholder='Please enter valid code' data-testid="code" maxLength={100} suffix={
                        <Tooltip title="Please enter valid code">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      } />
              </Form.Item>
              
              <Form.Item name="decode" label="Decode" rules={[{ required: true }]}>
                <Input placeholder='Please enter valid decode' data-testid="decode" maxLength={100} suffix={
                        <Tooltip title="Please enter valid decode">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>

              <Form.Item name="decode1" label="Decode1">
                <Input placeholder='Please enter addtional decode value for the provided code.' data-testid="decode1" maxLength={1000} suffix={
                        <Tooltip title="Please enter addtional decode value for the provided code.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>

              {!isNewCodeDecodeValue?
              <div>
                <Form.Item name="enteredBy" label="Entered By">
                <Space>
                  <span>{data?.enteredBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="enteredByDate" label="Entered By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.enteredByDate||'')}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updatedBy" label="Updated By">
                <Space>
                  <span>{data?.updatedBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updateByDate" label="Updated By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.updatedByDate||'')}</span>
                </Space>
              </Form.Item>      
              </div>:''
              }
              <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                <Space>
                  <Button type="primary" htmlType="submit" disabled={disabledSave && !isNewCodeDecodeValue}>
                    {isNewCodeDecodeValue ? 'Add Code Decode Value' : 'Save Code Decode Value'}
                  </Button>
                  <Button htmlType="button" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </PageHeader>
    </div>
  );
}
