import ProfileMapsScreen from './screens/profilemaps-screen';
import ProfileMapEditScreen from './screens/profilemap-edit-screen';
import { RouteDefinition } from '../../core/route';

export const profilemapRoutes: RouteDefinition[] = [
  {
    id: 'ProfileMapEdit',
    title: 'Profile Map',
    path: 'profilemaps/:Id',
    element: <ProfileMapEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'ProfileMapList',
    title: 'Profile Maps',
    path: 'profilemaps',
    element: <ProfileMapsScreen />,
    showInNavigation: true,
  },
];
