import PartnershipInformationsScreen from './screens/partnershipInformations-screen';
import PartnershipInformationEditScreen from './screens/partnershipInformation-edit-screen';
import { RouteDefinition } from '../../core/route';

export const partnershipInformationRoutes: RouteDefinition[] = [
  {
    id: 'PartnershipInformationEdit',
    title: 'Partner Information',
    path: 'partnershipInformations/:Id',
    element: <PartnershipInformationEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'PartnershipInformationList',
    title: 'Partner Information',
    path: 'partnerInfo',
    element: <PartnershipInformationsScreen />,
    showInNavigation: true,
  },
];
