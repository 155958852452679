import React, { useState, useCallback } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import RoutingRulesScreen from '../../routingrules/screens/routingrules-screen'

import {
  useCreateCarrierOnboardingMutation,
  useFetchSingleCarrierOnboardingQuery,
  useUpdateCarrierOnboardingMutation,
} from '../carrieronboarding-api';

import {
  useFetchTargetTypesQuery,
} from '../../targettypes/targettype-api';

import {
  useFetchSourceTypesQuery,
} from '../../sourcetypes/sourcetype-api';


import {
  useFetchDocumentTypesQuery,
} from '../../api/documenttype-api';


import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Card,
  Form,
  Input,
  message,
  Select,
  Spin,
  Button,
  Space,
  Tooltip,
  Row,
  Col,
  Descriptions
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined,QuestionCircleOutlined } from '@ant-design/icons';
import { PaginatedResult,SourceTypeViewModel, TargetTypeViewModel,
   DocumentTypeViewModel, SplittingRuleViewModel, RoutingRuleListViewModel,
   RoutingRuleViewModel } from '../../api/models';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { getLink } from '../../../App-router';
import { DefaultTenantId} from '../../../core/defaults';
import { ConvertUTCtoCDT } from '../../../core/utils';

const { Option } = Select;

const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError =>
  error ? 'status' in error : false;

const getErrorMessage = (error: any): string | undefined => error.data;

interface CarrierOnboardingScreenParams {
Id?: string | undefined;
tenantId?: string | undefined;
  [key: string]: string | undefined;
}
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function CarrierOnboardingEditScreen(props: any) {
  const { partnershipId, Id, tenantId = DefaultTenantId, splittingRuleId}: CarrierOnboardingScreenParams =
    useParams<CarrierOnboardingScreenParams>();

  const isNewCarrierOnboarding = Id === 'new';

  const { data, isFetching } = useFetchSingleCarrierOnboardingQuery(
    {
      Id: Number(Id)
    },
    { skip: isNewCarrierOnboarding }
  );

  const [createCarrierOnboarding, { isLoading: isCreating, error: createError }] = useCreateCarrierOnboardingMutation();
  const [updateCarrierOnboarding, { isLoading: isUpdating, error: updateError }] = useUpdateCarrierOnboardingMutation();

  const query = useQuery();
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);
  
  const [sourceTypesOptions, setSourceTypesOptions] = useState<{ value: string; label: string }[]>([]);
  const [targetTypesOptions, setTargetTypesOptions] = useState<{ value: string; label: string }[]>([]);
  const [testFlagRequired, setTestFlagRequired] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
// Fetch Source Types - START

const {
  data: sourceTypeValues = {},
}: {
  data?: PaginatedResult<SourceTypeViewModel> | undefined;
} = useFetchSourceTypesQuery({
    current:1,
    pageSize:1000,
    filter:'',
    sortBy: 'Name Asc',
        
});
let sourceTypesResults = sourceTypeValues.results?.filter((a, i) => sourceTypeValues.results?.findIndex((s) => a.id === s.id) === i)??[];
// Fetch Source Types - END

// Fetch Target Types - START

const {
  data: targetTypeValues = {},
}: {
  data?: PaginatedResult<TargetTypeViewModel> | undefined;
} = useFetchTargetTypesQuery({
    current:1,
    pageSize:1000,
    filter:'',
    sortBy: 'Name Asc',
        
});

let targetTypesResults = targetTypeValues.results?.filter((a, i) => targetTypeValues.results?.findIndex((s) => a.id === s.id) === i)??[];
// Fetch Target Types - END
// Fetch Document Types - START
const {
  data: documentTypes = {},
}: {
  data?: PaginatedResult<DocumentTypeViewModel> | undefined;
} = useFetchDocumentTypesQuery({
  current:1,
  pageSize:1000,
  filter:'',
  sortBy: 'Name Asc'
});
let documentTypesResults = documentTypes.results??[];
// Fetch Document Types - END

 const [form] = Form.useForm();

 React.useEffect(() => {
  //  form.resetFields();
   let identifierValue = localStorage.getItem('identifierValue')||'';
  if(Number(localStorage.getItem('splittingRuleID')) != Number(Id)){
    localStorage.clear();
    localStorage.setItem('splittingRuleID', Id||'0');   
    localStorage.setItem('partnershipId', partnershipId?.toString()||'0');
   }   
   localStorage.setItem('identifierValue', identifierValue);
   localStorage.setItem('carrierName', data?.carrierName.trimStart()||'');
   localStorage.setItem('partnerId', data?.partnerId.trimStart()||'');
   localStorage.setItem('source', data?.source.trimStart()||'');
   localStorage.setItem('target', data?.target.trimStart()||'');
   localStorage.setItem('testFlag', data?.testFlag||'');
   localStorage.setItem('documentType', data?.documentType.trimStart()||'');
   setTestFlagRequired(data?.testFlag ==''?false:true);

 }, [data, form]);
 

  const handleTargetTypesSearch = (value: string) => {
     
    let res: { value: string; label: string }[] = [];  
    console.log(typeof value);  
    if(typeof value == "string"){
      if (!value || value===undefined) {
        res = [];
      } else {
        res = targetTypesResults.map((item:any) => ({
          value: item.id,
          label: `${item.code +' - '+ item.decode}`,
        })).filter((x:any) => x.label.toUpperCase().indexOf(value.toUpperCase()) !== -1);
      }
      setTargetTypesOptions(res);
    }    
  };


  const params: any = useParams<any>();
  // console.log(params);
  const onFinishSplittingRule = (values: any | SplittingRuleViewModel) => {
    // fetching the item from localstorage....
    // let partnershipId = localStorage.getItem('partnershipId');
    const requestData = {
      carrieronboarding: {
        id: isNewCarrierOnboarding ? 0 : Number(Id),
        documentTypeId: values.documentTypeId,
        sourceTypeId: values.sourceTypeId,
        targetTypeId: values.targetTypeId,
        testFlag: values.testFlag||'',
        priority: values.priority,
        partnershipId: Number(params.partnershipId),        
        updatedBy:sessionStorage.getItem('userEmail')||'',
        enteredBy:isNewCarrierOnboarding?sessionStorage.getItem('userEmail')||'':'',
      },
    };

    const request = isNewCarrierOnboarding ? createCarrierOnboarding(requestData) : updateCarrierOnboarding(requestData);
    request.unwrap().then((item) => {
      if(item.id ===-200){
        message.error(
          `This combination already exists. Please try other values.`
        );
        return;
      }
      message.success(
        `CarrierOnboarding is ${isNewCarrierOnboarding ? 'created' : 'saved'}`
      );
      navigate(
        getLink('CarrierOnboardingEdit', { tenantId: params.tenantId, Id: item.id??requestData.carrieronboarding.id, partnershipId: params.partnershipId })
      );
      setEdit(false);
    });
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };
  const [disabledSave, setDisabledSave] = useState(true);
  
  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSave(hasErrors);
  }
  const handleCancel = () => {
    if(edit){
      setEdit(false);
    }
    else{
    goBack();
    }
  };

  const handleEdit = (status:boolean | false) => {
    setEdit(status);
  };
    // Filter `option.label` match the user type `input`
    const filterOption = (
      input: string,
      option?: { label: string; value: string }
    ) => (
      option?.label ?? "").toLowerCase().includes(input.toLowerCase());

      const [showPartnerEDIX12, setShowPartnerEDIX12] = useState(false);
      const [routingRuleID, setRoutingRuleID] = useState(0);
      const [confirmLoading, setConfirmLoading] = useState(false);

  return (
    <div>
      <PageHeader
      
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="partnerinfo">
              <Link to={getLink('PartnershipInformationList', { ...params })}>Partner Information</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="carrierName">
              <Link to={getLink('CarrierOnboardingList', { tenantId: params.tenantId,
                      id:Number(params.partnershipId),
                      partnerId: sessionStorage.getItem('partnerId'),
                      identifierValue:sessionStorage.getItem('identifierValue'),
                      carrierName:sessionStorage.getItem('carrierName') })}>{sessionStorage.getItem('carrierName')}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{isNewCarrierOnboarding ? 'New Splitting Rule' : data?.id}</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {isFetchBaseQueryErrorType(createError) && (
          <Alert message="Error" description={getErrorMessage(createError)} type="error" showIcon />
        )}
        {isFetchBaseQueryErrorType(updateError) && (
          <Alert message="Error" description={getErrorMessage(updateError)} type="error" showIcon />
        )}
        <Card            
      extra={
        <Space>
        <Button htmlType="button" type='primary' data-testid={'create-new-997rule'} onClick={()=>{handleEdit(true);}} disabled={isNewCarrierOnboarding || edit}>
          Edit 
        </Button>
        </Space>
      }
          title={
            <Title
              level={4}
              style={{
                marginBottom: 0,
                display: 'inline-block',
              }}
            >
              {isNewCarrierOnboarding ? 'New Splitting Rule' : edit?'Edit Splitting Rule':'Splitting Rule'}
            </Title>
          }
        >
          <Spin spinning={isFetching || isCreating || isUpdating}>
          {isNewCarrierOnboarding||edit?
            <Form
              layout='inline'
              form={form}
              name="control-hooks"
              initialValues={data}
              onFinish={onFinishSplittingRule}       
              onFieldsChange={handleFormChange}       
            > 
            <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={8} >
              <Form.Item  name="documentTypeId"  label="Document Type"  rules={[{ required: true }]} >
              <Select
                  showSearch
                  placeholder="Search..."
                  optionFilterProp="children"
                  data-testid="documentTypeId"
                  filterOption={filterOption}
                  options={documentTypesResults.map((item:any) => ({ label: item.name +' - '+ item.format +' - '+ item.version +' - '+ item.standard, value: item.id }))}
                  />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item name="sourceTypeId" label="Source" rules={[{ required: true }]}>
              <Select
                  showSearch
                  placeholder="Search..."
                  allowClear={true}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  options={sourceTypesResults.map((item:any) => ({ label: item.name, value: item.id }))}
                />
              </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
              <Form.Item name="targetTypeId" label="Target" rules={[{ required: true }]}>
              <Select
                  showSearch
                  allowClear={true}
                  placeholder="Search..."
                  optionFilterProp="children"
                  onSearch={handleTargetTypesSearch}
                  filterOption={filterOption}
                  options={targetTypesResults.map((item:any) => ({ label: item.name, value: item.id }))}
                />
              </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
              <Form.Item name="priority" label="Priority" rules={[{ required: true }]} >
                <Input placeholder='Please enter valid data' type='number' min='1' data-testid="priority" suffix={
                    <Tooltip title="Please enter valid data">
                     <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Tooltip>
                  }/>
              </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
              <Form.Item name="testFlag" label="Test Flag" rules={[{ required: testFlagRequired }]}>
              <Select
                  showSearch
                  placeholder="Search..."
                  optionFilterProp="children"
                  data-testid="testFlag"
                  filterOption={filterOption}
                  onChange={(value:any)=>{setTestFlagRequired(value==''?false:true)}}
                  options={[{label:'P', value:'P'},{label:'T', value:'T'},{label:'NA', value:''}]}
                />
              </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
              <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                <Space>
                  <Button disabled={disabledSave && !isNewCarrierOnboarding} type="primary" htmlType="submit" >
                    {isNewCarrierOnboarding ? 'Add Splitting Rule' : 'Save Splitting Rule'}
                  </Button>
                  <Button type="primary" htmlType="button" onClick={() => handleCancel()}>
                    Cancel
                  </Button>
                  
                </Space>
                
              </Form.Item>
              </Col>
              </Row>
              
            </Form>
            :
              <>
              <Descriptions title="">
            <Descriptions.Item label="Document Type">{data?.documentType}</Descriptions.Item>
            <Descriptions.Item label="Source">{data?.source}</Descriptions.Item>
            <Descriptions.Item label="Target">{data?.target}</Descriptions.Item>
            <Descriptions.Item label="Priority">{data?.priority}</Descriptions.Item>
            <Descriptions.Item label="Test Flag">{data?.testFlag}</Descriptions.Item>
            <Descriptions.Item label="Entered By">{data?.enteredBy}</Descriptions.Item>                     
            <Descriptions.Item label="Updated By">{data?.updatedBy}</Descriptions.Item>            
            <Descriptions.Item label="Entered By Date">{ConvertUTCtoCDT(data?.enteredByDate||'')}</Descriptions.Item>   
            <Descriptions.Item label="Updated By Date">{ConvertUTCtoCDT(data?.updateByDate||'')}</Descriptions.Item>
          </Descriptions></>
                }
          </Spin>
        </Card>
        
        {!isNewCarrierOnboarding?
        <div>
          <RoutingRulesScreen splittingRuleID = {isNewCarrierOnboarding?0:Number(Id)} />
        </div>:''
        }
        
      </PageHeader>
    </div>
  );
}
