import { apiSlice } from '../api/api-slice';
import { CodeDecodeValueViewModel, PaginatedResult } from '../api/models';

export interface CodeDecodeValueListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
  codeDecodeTypeId: number;
}

export interface EditCodeDecodeValueRequest {
  codedecodevalue: CodeDecodeValueViewModel;
}

export interface CodeDecodeValueRequest {
  Id: number;
  codedecodetypeid: number;
}

export interface DeleteCodeDecodeValueRequest {
  Id: number;
}

export const codedecodevaluesApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchCodeDecodeValues: builder.query<PaginatedResult<CodeDecodeValueViewModel>, CodeDecodeValueListRequest>({
        query({ codeDecodeTypeId, current, pageSize, filter, sortBy }) {
          return `/codedecodevalues?codeDecodeTypeId=${codeDecodeTypeId}&pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'CodeDecodeValue',
          ...(data?.results?.map(({ id }) => ({ type: 'CodeDecodeValue' as const, id: `${id}` })) || [])
        ] 
      }),
      fetchSingleCodeDecodeValue: builder.query<CodeDecodeValueViewModel, CodeDecodeValueRequest>({
        query({ Id }) {
          return `/codedecodevalues/${Id}`;
        },
        providesTags: (_data, _error, arg) => [{ type: 'CodeDecodeValue', id: `${arg.Id}` }]
      }),
      createCodeDecodeValue: builder.mutation<CodeDecodeValueViewModel, EditCodeDecodeValueRequest>({
        query: ({ codedecodevalue }) => {
          return {
            url: `/codedecodevalues`,
            method: 'POST',
            body: codedecodevalue,
          };
        },
        invalidatesTags: ['CodeDecodeValue']
      }),
      updateCodeDecodeValue: builder.mutation<CodeDecodeValueViewModel, EditCodeDecodeValueRequest>({
        query: ({ codedecodevalue }) => {
          return {
            url: `/codedecodevalues/${codedecodevalue.id}`,
            method: 'PUT',
            body: codedecodevalue,
          };
        },
        invalidatesTags: (_data, _error, arg) => [{ type: 'CodeDecodeValue', id: `${arg.codedecodevalue.id}` }]
      }),
      deleteCodeDecodeValue: builder.mutation<void, DeleteCodeDecodeValueRequest>({
        query: ({ Id }) => ({
          url: `/codedecodevalues/${Id}`,
          method: 'DELETE',
          body: {},
        }),
        invalidatesTags: (_data, _error, arg) => ['CodeDecodeValue']
      })
    };
  },
});

export const {
  useFetchCodeDecodeValuesQuery,
  useFetchSingleCodeDecodeValueQuery,
  useCreateCodeDecodeValueMutation,
  useUpdateCodeDecodeValueMutation,
  useDeleteCodeDecodeValueMutation,
} = codedecodevaluesApi;
