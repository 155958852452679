import { apiSlice } from '../api/api-slice';
import { AuditLogsViewModel, PaginatedResult } from '../api/models';

export interface AuditLogsListRequest {
  entityId: number;
  entityName?: string | null;
}

export const AuditLogsApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchAuditLogs: builder.query<AuditLogsViewModel[], AuditLogsListRequest>({
        query({ entityId, entityName }) {
          return `/auditLog/${entityId}/${entityName}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'AuditLog',
          ...(data?.map(({ }) => ({ type: 'AuditLog' as const})) || [])
        ] 
      }),
    };
  },
});

export const {
  useFetchAuditLogsQuery,
} = AuditLogsApi;
