import { apiSlice } from '../api/api-slice';
import { PartnerContactInformationViewModel, PartnerContactInformationListViewModel, PaginatedResult } from '../api/models';

export interface PartnerContactInformationListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;  
  partnerInformationId:number;
}

export interface EditPartnerContactInformationRequest {
  partnercontactinformation: PartnerContactInformationViewModel;
}

export interface PartnerContactInformationRequest {
  Id: number;
}

export interface DeletePartnerContactInformationRequest {
  Id: number;
}

export const routingRuleApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchPartnerContactInformations: builder.query<PaginatedResult<PartnerContactInformationListViewModel>, PartnerContactInformationListRequest>({
        query({ current, pageSize, filter, sortBy, partnerInformationId }) {
          return `/partnershipInformationContacts?partnerInformationId=${partnerInformationId}&pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'PartnerContactInformation',
          ...(data?.results?.map(({ id }) => ({ type: 'PartnerContactInformation' as const, id: `${id}` })) || [])
        ] 
      }),
      fetchSinglePartnerContactInformation: builder.query<PartnerContactInformationViewModel, PartnerContactInformationRequest>({
        query({ Id }) {
          return `/partnershipInformationContacts/${Id}`;
        },
        providesTags: (_data, _error, arg) => [{ type: 'PartnerContactInformation', id: `${arg.Id}` }]
      }),
      createPartnerContactInformation: builder.mutation<PartnerContactInformationViewModel, EditPartnerContactInformationRequest>({
        query: ({ partnercontactinformation }) => {
          return {
            url: `/partnershipInformationContacts`,
            method: 'POST',
            body: partnercontactinformation,
          };
        },
        invalidatesTags: ['PartnerContactInformation']
      }),
      updatePartnerContactInformation: builder.mutation<PartnerContactInformationViewModel, EditPartnerContactInformationRequest>({
        query: ({ partnercontactinformation }) => {
          return {
            url: `/partnershipInformationContacts/${partnercontactinformation.id}`,
            method: 'PUT',
            body: partnercontactinformation,
          };
        },
        invalidatesTags: (_data, _error, arg) => [{ type: 'PartnerContactInformation', id: `${arg.partnercontactinformation.id}` }]
      }),
      deletePartnerContactInformation: builder.mutation<void, DeletePartnerContactInformationRequest>({
        query: ({ Id }) => ({
          url: `/partnershipInformationContacts/${Id}`,
          method: 'DELETE',
          body: {},
        }),
        invalidatesTags: (_data, _error, arg) => ['PartnerContactInformation']
      })
    };
  },
});

export const {
  useFetchPartnerContactInformationsQuery,
  useFetchSinglePartnerContactInformationQuery,
  useCreatePartnerContactInformationMutation,
  useUpdatePartnerContactInformationMutation,
  useDeletePartnerContactInformationMutation,
} = routingRuleApi;
