import { createSlice } from '@reduxjs/toolkit';
import { TableState } from '../../components/table/model';

const initialState: TableState = {
  columns: [],
};

const codedecodevalueListSlice = createSlice({
  name: 'codedecodevalueTable',
  initialState,
  reducers: {
    hideColumn(state) {},
  },
});

export const codedecodevaluesReducer = codedecodevalueListSlice.reducer;
