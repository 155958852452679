import { apiSlice } from '../api/api-slice';
import { MonitorViewModel, PaginatedResult, AlertViewModel } from '../api/models';

export interface TablePageRequest {
  tenantId: string | null;
  pageSize: number;
  partnerId: number;
  filter?: string | null;
  current: number;
  sortBy?: string | null;
}

export interface CreateOrEditMonitorRequest {
  tenantId: string;
  monitor: MonitorViewModel;
}

export interface MonitorRequest {
  tenantId: string;
  monitorId: number;
}

export const monitorsApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchMonitors: builder.query<PaginatedResult<MonitorViewModel>, TablePageRequest>({
        query({ tenantId, current, pageSize, partnerId, filter, sortBy }) {
          return `/tenants/${tenantId}/monitors?pageNumber=${current}&pageSize=${pageSize}&partnerId=${partnerId}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        providesTags: (data, _error, arg) => 
        [
          'Monitor',
          ...(data?.results?.map(({ monitorId }) => ({ type: 'Monitor' as const, id: `${arg.tenantId}-${monitorId}` })) || [])
        ] 
      }),
      fetchSingleMonitor: builder.query<MonitorViewModel, MonitorRequest>({
        query({ tenantId, monitorId }) {
          return `/tenants/${tenantId}/monitors/${monitorId}`;
        },
        providesTags: (_data, _error, arg) => [{ type: 'Monitor', id: `${arg.tenantId}-${arg.monitorId}` }]
      }),
      createMonitor: builder.mutation<MonitorViewModel, CreateOrEditMonitorRequest>({
        query: ({ tenantId, monitor }) => {
          return {
            url: `/tenants/${tenantId}/monitors`,
            method: 'POST',
            body: monitor,
          };
        },
        invalidatesTags: ['Monitor']
      }),
      updateMonitor: builder.mutation<MonitorViewModel, CreateOrEditMonitorRequest>({
        query: ({ tenantId, monitor }) => {
          return {
            url: `/tenants/${tenantId}/monitors/${monitor.monitorId}`,
            method: 'PUT',
            body: monitor,
          };
        },
        invalidatesTags: (_data, _error, arg) => [{ type: 'Monitor', id: `${arg.tenantId}-${arg.monitor.monitorId}` }]
      }),
      testMonitor: builder.mutation<AlertViewModel, CreateOrEditMonitorRequest>({
        query: ({ tenantId, monitor }) => {
          return {
            url: `/tenants/${tenantId}/monitors/test`,
            method: 'POST',
            body: monitor,
          };
        },
      }),
      deleteMonitor: builder.mutation<void, CreateOrEditMonitorRequest>({
        query: ({ tenantId, monitor }) => ({
          url: `/tenants/${tenantId}/monitors/${monitor.monitorId}`,
          method: 'DELETE',
          body: {},
        }),
        invalidatesTags: (_data, _error, arg) => [{ type: 'Monitor', id: `${arg.tenantId}-${arg.monitor.monitorId}` }]
      })
    };
  },
});

export const { 
  useFetchMonitorsQuery, 
  useFetchSingleMonitorQuery, 
  useCreateMonitorMutation, 
  useTestMonitorMutation, 
  useDeleteMonitorMutation,
  useUpdateMonitorMutation
} = monitorsApi;
