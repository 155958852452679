import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  useCreateRoutingRuleMutation,
  useFetchSingleRoutingRuleQuery,
  useUpdateRoutingRuleMutation,
} from '../routingrule-api';

import {
  useFetchCustomersQuery,
} from '../../customers/customer-api';

import {
  useFetchTransactionTypesQuery,
} from '../../transactiontype/transactiontype-api';
import {
  useFetchEndSystemsQuery,
} from '../../endsystems/endsystem-api';

import {
  useFetchCustomerIdentifierTypesQuery,
} from '../../api/customeridentifiertype-api';

import {
  useFetchProfileMapsQuery,
} from '../../profilemaps/profilemap-api';

import { PageHeader } from '@ant-design/pro-layout';
import type { CollapseProps } from 'antd';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip,
  Collapse,
  Row,
  Col
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined, UserOutlined,QuestionCircleOutlined } from '@ant-design/icons';
import { Company, PaginatedResult, RoutingRuleViewModel,  ProfileMapViewModel,CustomerViewModel, EndSystemViewModel, TransactionTypeViewModel,CustomerIdentifierTypeViewModel } from '../../api/models';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { getLink } from '../../../App-router';
import { DefaultTenantId } from '../../../core/defaults';
import { ConvertUTCtoCDT } from '../../../core/utils';
const { Option } = Select;

const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError =>
  error ? 'status' in error : false;

const getErrorMessage = (error: any): string | undefined => error.data;

interface RoutingRuleScreenParams {
Id?: string | undefined;
tenantId?: string | undefined;
  [key: string]: string | undefined;
}

export default function RoutingRuleEditScreen(props: any) {
  const { Id, tenantId = DefaultTenantId}: RoutingRuleScreenParams =
    useParams<RoutingRuleScreenParams>();

  const isNewRoutingRule = Id === 'new';

  const { data, isFetching } = useFetchSingleRoutingRuleQuery(
    {
      Id: Number(Id),
    },
    { skip: isNewRoutingRule }
  );

  const [createRoutingRule, { isLoading: isCreating, error: createError }] = useCreateRoutingRuleMutation();
  const [updateRoutingRule, { isLoading: isUpdating, error: updateError }] = useUpdateRoutingRuleMutation();  
  const [transactionTypesOptions, setTransactionTypesOptions] = useState<{ value: string; label: string }[]>([]);
  const [endSystemTypesOptions, setEndSystemsOptions] = useState<{ value: string; label: string }[]>([]);
  const [profileMapOptions, setProfileMapOptions] = useState<{ value: string; label: string }[]>([]);

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [formRoutingRule] = Form.useForm();

  React.useEffect(() => {
    
    formRoutingRule.resetFields();
    if(!isNewRoutingRule){
      let NAMatch:CustomerIdentifierTypeViewModel = customerIdentifierTypesResults.filter(x=>x?.id===data?.identifierTypeId)[0];
      if (NAMatch && NAMatch?.name==='NA') {
        sethideIdentifierValue(true);
        } else {
          sethideIdentifierValue(false);
        }
      }

  }, [data, formRoutingRule]);

  
  const {
    data: profileMaps = {},
    error,
  }: {
    data?: PaginatedResult<ProfileMapViewModel> | undefined;
    error?: any;
  } = useFetchProfileMapsQuery({
    current:1,
    pageSize:1000,
    filter:'',
    sortBy: 'Name Asc',        
});
  let profileMapsResults = profileMaps.results?.filter(item => item.name?.toLowerCase() !== 'edi997tocosmosdatabase')??[];

  // Fetch Transaction Types - START

  const {
    data: transactionTypeValues = {},
  }: {
    data?: PaginatedResult<TransactionTypeViewModel> | undefined;
  } = useFetchTransactionTypesQuery({
      current:1,
      pageSize:1000,
      filter:'',
      sortBy: 'Name Asc',          
  });
  let transactionTypesResults = transactionTypeValues.results?.filter(item => item.name !== '997')??[];
  // console.log(transactionTypesResults);
// Fetch Transaction Types - END

// Fetch EndSystems Types - START
const {
  data: endSystemTypeValues = {},
}: {
  data?: PaginatedResult<EndSystemViewModel> | undefined;
} = useFetchEndSystemsQuery({
    current:1,
    pageSize:1000,
    filter:'',
    sortBy: 'Name Asc',
        
});
let endSystemTypesResults = endSystemTypeValues.results??[];
// Fetch EndSystems Types - END

// Fetch Customers Types - START
  const {
    data: customers = {},
  }: {
    data?: PaginatedResult<CustomerViewModel> | undefined;
  } = useFetchCustomersQuery({
    current:1,
    pageSize:1000,
    filter:'',
    sortBy: 'Name Asc',
        
});
  let customersResults = customers.results??[];
// Fetch Customers Types - END


// Fetch CustomerIdentifier Types - START
const {
  data: customerIdentifierTypes = {},
}: {
  data?: PaginatedResult<CustomerIdentifierTypeViewModel> | undefined;
} = useFetchCustomerIdentifierTypesQuery({
  current:1,
  pageSize:1000,
  filter:'',
  sortBy: 'Name Asc',
      
});
let customerIdentifierTypesResults = customerIdentifierTypes.results??[];

// Fetch CustomerIdentifier Types - END

// Fetch ProfileMaps Types - START
  const handleProfileMapSearch = (value: string) => {
    let res: { value: string; label: string }[] = [];    
    if (!value || value===undefined) {
      res = [];
    } else {
      res = profileMapsResults.map((item:any) => ({
        value: item.id,
        label: `${item.name}`,
      })).filter((x:any) => x.label.toUpperCase().indexOf(value.toUpperCase()) !== -1);
    }
    setProfileMapOptions(res);
  };
 // Fetch ProfileMaps Types - END

 
 const handleEndSystemsSearch = (value: string) => {
   
  let res: { value: string; label: string }[] = [];  
  console.log(typeof value);  
  if(typeof value == "string"){
    if (!value || value===undefined) {
      res = [];
    } else {
      res = endSystemTypesResults.map((item:any) => ({
        value: item.id,
        label: `${item.code +' - '+ item.decode}`,
      })).filter((x:any) => x.label.toUpperCase().indexOf(value.toUpperCase()) !== -1);
    }
    setEndSystemsOptions(res);
  }    
};

const handleTransactionTypesSearch = (value: string) => {
   
  let res: { value: string; label: string }[] = [];  
  console.log(typeof value);  
  if(typeof value == "string"){
    if (!value || value===undefined) {
      res = [];
    } else {
      res = transactionTypesResults.map((item:any) => ({
        value: item.id,
        label: `${item.code +' - '+ item.decode}`,
      })).filter((x:any) => x.label.toUpperCase().indexOf(value.toUpperCase()) !== -1);
    }
    setTransactionTypesOptions(res);
  }    
};

  const onFinish = (values: any | RoutingRuleViewModel) => {
    let NAMatch:CustomerIdentifierTypeViewModel = customerIdentifierTypesResults.filter(x=>x?.id === values.identifierTypeId)[0];
    const requestData = {
      routingrule: {
            id: values.id === undefined?0:values.id,
            splittingRuleId:Number(localStorage.getItem('splittingRuleID')),
            customerId: values.customerId,
            profileMapId: values.profileMapId,
            transactionTypeId: values.transactionTypeId,
            endSystemId: values.endSystemId,            
            identifierTypeId: values.identifierTypeId,
            identifierValue: (NAMatch && NAMatch?.name.toLowerCase()==='na') || values.identifierValue===null || values.identifierValue===undefined?'':values.identifierValue,
            sequence: values.sequence,
            updatedBy:sessionStorage.getItem('userEmail')||'',
            enteredBy:isNewRoutingRule?sessionStorage.getItem('userEmail')||'':'',
      },
    };

    const request = isNewRoutingRule ? createRoutingRule(requestData) : updateRoutingRule(requestData);
    request.unwrap().then((response) => {
      if(response?.id ===-200){
        message.error(
          `This combination already exists. Please try other values.`
        );
        return;
      }
      message.success(
        `Routing Rule is ${isNewRoutingRule ? 'created' : 'saved'}`
      );
      goBack();
    });
    
  };
  const [disabledSave, setDisabledSave] = useState(true);
  
const handleFormChange = () => {
const hasErrors = formRoutingRule.getFieldsError().some(({ errors }) => errors.length);
setDisabledSave(hasErrors);
}
 // Filter `option.label` match the user type `input`
 const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (
  option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };
  const [hideIdentifierValue, sethideIdentifierValue] = useState<boolean>(false);
  const handleIdentifierTypeChange = (value: number) => {
    let NAMatch:CustomerIdentifierTypeViewModel = customerIdentifierTypesResults.filter(x=>x?.id===value)[0];
    if (NAMatch && NAMatch?.name==='NA') {
      sethideIdentifierValue(true);
      } else {
        sethideIdentifierValue(false);
      }
  };
  return (
    <div>
      <PageHeader
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="partnershipInformation">
              <Link to={getLink('PartnershipInformationList', { tenantId: tenantId })}>Partner Information</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="carrierName">
              <Link to={getLink('CarrierOnboardingList', {
                      ...props, tenantId,
                      partnerId: localStorage.getItem('partnerId')||'',
                      identifierValue: sessionStorage.getItem('identifierValue')||'',
                      carrierName: localStorage.getItem('carrierName')||'',
                      id: Number(localStorage.getItem('partnershipId')||0),
                    })}>{localStorage.getItem('carrierName')||''}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="splittingRule">
              <Link to={getLink('CarrierOnboardingEdit', { tenantId: tenantId,Id: Number(localStorage.getItem('splittingRuleID')||0),
                      partnershipId: Number(localStorage.getItem('partnershipId')||0) })}>{localStorage.getItem('splittingRuleID')||''}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{isNewRoutingRule ? 'New Routing Rule' : data?.id}</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {isFetchBaseQueryErrorType(createError) && (
          <Alert message="Error" description={getErrorMessage(createError)} type="error" showIcon />
        )}
        {isFetchBaseQueryErrorType(updateError) && (
          <Alert message="Error" description={getErrorMessage(updateError)} type="error" showIcon />
        )}
        <Card
          title={
            <Title
              level={4}
              style={{
                marginBottom: 0,
                display: 'inline-block',
              }}
            >
              {isNewRoutingRule ? 'New Routing Rule' : 'Edit Routing Rule'}
            </Title>
          }
        >
          <Spin spinning={isFetching || isCreating || isUpdating}>
          <Form 
                      {...layout}
                      form={formRoutingRule}
                      name="routing-rule-hooks"
                      initialValues={data}
                      onFinish={onFinish}
                      onFieldsChange={handleFormChange} 
                    > 
                      <Form.Item name="id" label="id" hidden>
                        <Input data-testid="id"  />
                      </Form.Item>
                      <Form.Item name="customerId" label="Customer" rules={[{ required: true }]}>
                      <Select
                          showSearch
                          placeholder="Search..."
                          optionFilterProp="children"
                          data-testid="customerId"
                          filterOption={filterOption}
                          options={customersResults.map((item:any) => ({ label: item.name, value: item.id }))}
                        />
                      </Form.Item>        
                      <Form.Item name="profileMapId" label="ProfileMap" rules={[{ required: true }]}>
                      <Select
                          showSearch
                          placeholder="Search..."
                          optionFilterProp="children"
                          data-testid="profileMapId"
                          onSearch={handleProfileMapSearch}
                          filterOption={filterOption}
                          options={profileMapsResults.map((item:any) => ({ label: item.name, value: item.id }))}
                        />
                      </Form.Item>
                      <Form.Item name="transactionTypeId" label="TransactionType" rules={[{ required: true }]}>
                      <Select
                          showSearch
                          placeholder="Search..."
                          optionFilterProp="children"
                          data-testid="transactionTypeId"
                          onSearch={handleTransactionTypesSearch}
                          filterOption={filterOption}
                          options={transactionTypesResults.map((item:any) => ({ label: item.name, value: item.id }))}
                        />
                      </Form.Item>
                      <Form.Item name="endSystemId" label="EndSystem" rules={[{ required: true }]}>
                      <Select
                          showSearch
                          placeholder="Search..."
                          optionFilterProp="children"
                          data-testid="endSystemId"
                          onSearch={handleEndSystemsSearch}
                          filterOption={filterOption}
                          options={endSystemTypesResults.map((item:any) => ({ label: item.name+' - '+ item?.endSystemInstance, value: item.id }))}
                        />
                      </Form.Item>
                      <Form.Item name="identifierTypeId" label="Identifier Type" rules={[{ required: true }]}>
                      <Select
                          showSearch
                          placeholder="Search..."
                          optionFilterProp="children"
                          data-testid="identifierTypeId"
                          onChange={handleIdentifierTypeChange}
                          filterOption={filterOption}
                          options={customerIdentifierTypesResults.map((item:any) => ({ label: item.name, value: item.id }))}
                        />
                      </Form.Item>
                      <Form.Item name="identifierValue"  label="IdentifierValue" rules={[{ required: hideIdentifierValue?false:true }]}>
                        <Input placeholder='Valid data is like FXFE, WYXC.' data-testid="identifierValue" disabled={hideIdentifierValue}  suffix={
                        <Tooltip title="Valid data is like FXFE, WYXC.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
                      </Form.Item>
                      <Form.Item name="sequence" label="Sequence" rules={[{ required: true }]}>
                        <Input type='number' placeholder='Please enter valid number like 1,2,3 etc.' data-testid="sequence" suffix={
                        <Tooltip title="Please enter valid number like 1,2,3 etc.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
                      </Form.Item>
                      {!isNewRoutingRule?
                    <div>
                      <Form.Item name="enteredBy" label="Entered By">
                      <Space>
                        <span>{data?.enteredBy}</span>
                      </Space>
                    </Form.Item>
                    <Form.Item name="enteredByDate" label="Entered By Date">
                      <Space>
                        <span>{ConvertUTCtoCDT(data?.enteredByDate||'')}</span>
                      </Space>
                    </Form.Item>
                    <Form.Item name="updatedBy" label="Updated By">
                      <Space>
                        <span>{data?.updatedBy}</span>
                      </Space>
                    </Form.Item>
                    <Form.Item name="updateByDate" label="Updated By Date">
                      <Space>
                        <span>{ConvertUTCtoCDT(data?.updateByDate||'')}</span>
                      </Space>
                    </Form.Item>      
                    </div>:''
                    }
                      <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                      <Space>
                        <Button type="primary" htmlType="submit" disabled={disabledSave && !isNewRoutingRule}>
                          {isNewRoutingRule ? 'Add Routing Rule' : 'Save Routing Rule'}
                        </Button>
                        <Button htmlType="button" onClick={goBack}>
                          Cancel
                        </Button>
                      </Space>
                    </Form.Item>                    
                    </Form>
          </Spin>
        </Card>
      </PageHeader>
    </div>
  );
}
