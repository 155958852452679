import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  useCreateProfileMapMutation,
  useFetchSingleProfileMapQuery,
  useUpdateProfileMapMutation,
} from '../profilemap-api';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ProfileMapViewModel } from '../../api/models';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ConvertUTCtoCDT } from '../../../core/utils';
import { getLink } from '../../../App-router';
import { DefaultTenantId } from '../../../core/defaults';
const { Option } = Select;

const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError =>
  error ? 'status' in error : false;

const getErrorMessage = (error: any): string | undefined => error.data;

interface ProfileMapScreenParams {
Id?: string | undefined;
tenantId?: string | undefined;
  [key: string]: string | undefined;
}

export default function ProfileMapEditScreen(props: any) {
  const { Id, tenantId = DefaultTenantId}: ProfileMapScreenParams =
    useParams<ProfileMapScreenParams>();

  const isNewProfileMap = Id === 'new';

  const { data, isFetching } = useFetchSingleProfileMapQuery(
    {
      Id: Number(Id),
    },
    { skip: isNewProfileMap }
  );

  const [createProfileMap, { isLoading: isCreating, error: createError }] = useCreateProfileMapMutation();
  const [updateProfileMap, { isLoading: isUpdating, error: updateError }] = useUpdateProfileMapMutation();
  const [IsBatched, setIsBatched] = useState<boolean>(false);

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [form] = Form.useForm();

  React.useEffect(() => {
    form.resetFields();
    setIsBatched(data?.isBatched||false);
  }, [data, form]);

  const onFinish = (values: any | ProfileMapViewModel) => {
    const requestData = {
      profilemap: {
        id: isNewProfileMap ? 0 : Number(Id),
        name: values.name,
        isBatched: values.isBatched == undefined?false:values.isBatched,
        batchSize: values.isBatched?values.batchSize:0,
        updatedBy:sessionStorage.getItem('userEmail')||'',
        enteredBy:isNewProfileMap?sessionStorage.getItem('userEmail')||'':'',
      },
    };
     
    const request = isNewProfileMap ? createProfileMap(requestData) : updateProfileMap(requestData);
    request.unwrap().then((response) => {
      if(response?.id ===-200){
        message.error(
          `This combination already exists. Please try other values.`
        );
        return;
      }
      message.success(
        `ProfileMap is ${isNewProfileMap ? 'created' : 'saved'}`
      );
      navigate(
        getLink('ProfileMapList', {
          ...props,
          tenantId: tenantId,
        })
      );
    });
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };

  const handleCancel = () => {
    navigate(
      getLink('ProfileMapList', {
        ...props,
        tenantId: tenantId,
      })
    );
  };
  const [disabledSave, setDisabledSave] = useState(true);
  
  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSave(hasErrors);
  }

  const handleIsBatchedChange = (event:any) => {     
    setIsBatched(event);
  };
  return (
    <div>
      <PageHeader
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={getLink('ProfileMapList', { ...props, tenantId })}>Profile Maps</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{isNewProfileMap ? 'New Profile Map' : data?.name}</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {isFetchBaseQueryErrorType(createError) && (
          <Alert message="Error" description={getErrorMessage(createError)} type="error" showIcon />
        )}
        {isFetchBaseQueryErrorType(updateError) && (
          <Alert message="Error" description={getErrorMessage(updateError)} type="error" showIcon />
        )}
        <Card
          title={
            <Title
              level={4}
              style={{
                marginBottom: 0,
                display: 'inline-block',
              }}
            >
              {isNewProfileMap ? 'New Profile Map' : 'Edit Profile Map'}
            </Title>
          }
        >
          <Spin spinning={isFetching || isCreating || isUpdating}>
            <Form
              {...layout}
              form={form}
              name="profile-map"
              initialValues={data}
              onFinish={onFinish}
              onFieldsChange={handleFormChange}
            >
              <Form.Item name="name" label="Name" rules={[{ required: true }]} >
                <Input placeholder='Please provide valid name with length upto 100 for eg. EDI210ToMercuryGateFreightBill_Dedicated_Base' data-testid="name" maxLength={100} suffix={
                        <Tooltip title="Please provide valid name with length upto 100 for eg. EDI210ToMercuryGateFreightBill_Dedicated_Base">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              
              <Form.Item name="isBatched" label="IsBatched" rules={[{ required: IsBatched }]} >
              <Switch onChange={handleIsBatchedChange} />
              </Form.Item>

              <Form.Item name="batchSize" label="BatchSize" rules={[{ required: IsBatched?true:false }]} hidden={!IsBatched}>
                <Input placeholder='Please enter the number of transactions per batch.' type='number' min={IsBatched?2:0} data-testid="batchsize" suffix={
                        <Tooltip title="Please enter the number of transactions per batch.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              

              {!isNewProfileMap?
              <div>
                <Form.Item name="enteredBy" label="Entered By">
                <Space>
                  <span>{data?.enteredBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="enteredByDate" label="Entered By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.enteredByDate||'')}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updatedBy" label="Updated By">
                <Space>
                  <span>{data?.updatedBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updateByDate" label="Updated By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.updatedByDate||'')}</span>
                </Space>
              </Form.Item>      
              </div>:''
              }

              <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                <Space>
                  <Button type="primary" htmlType="submit" disabled={disabledSave && !isNewProfileMap}>
                    {isNewProfileMap ? 'Add Profile Map' : 'Save Profile Map'}
                  </Button>
                  <Button htmlType="button" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </PageHeader>
    </div>
  );
}
