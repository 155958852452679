import { createSlice } from '@reduxjs/toolkit';
import { TableState } from '../../components/table/model';

const initialState: TableState = {
  columns: [],
};

const partneredix12ListSlice = createSlice({
  name: 'partneredix12Table',
  initialState,
  reducers: {
    hideColumn(state) {},
  },
});

export const partneredix12sReducer = partneredix12ListSlice.reducer;
