import { createSlice } from '@reduxjs/toolkit';
import { TableState } from '../../components/table/model';

const initialState: TableState = {
  columns: [],
};

const codedecodemapListSlice = createSlice({
  name: 'codedecodemapTable',
  initialState,
  reducers: {
    hideColumn(state) {},
  },
});

export const codedecodemapsReducer = codedecodemapListSlice.reducer;
