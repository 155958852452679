import { RouteDefinition } from '../../core/route';
import MonitorScreen from './screens/monitor-screen';
import MonitorsScreen from './screens/monitors-screen';

export const monitorRoutes: RouteDefinition[] = [
  {
    id: 'MonitorItem',
    path: 'monitors/:monitorId',
    element: <MonitorScreen />,
  },
  {
    id: 'MonitorList',
    title: 'Monitors',
    path: 'monitors',
    element: <MonitorsScreen />,
    showInNavigation: false,
  },
];
