import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  useCreateCustomerMutation,
  useFetchSingleCustomerQuery,
  useUpdateCustomerMutation,
} from '../customer-api';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined, UserOutlined,QuestionCircleOutlined } from '@ant-design/icons';
import { PaginatedResult, CustomerViewModel } from '../../api/models';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { getLink } from '../../../App-router';
import { DefaultTenantId } from '../../../core/defaults';
import { ConvertUTCtoCDT } from '../../../core/utils';
const { Option } = Select;

const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError =>
  error ? 'status' in error : false;

const getErrorMessage = (error: any): string | undefined => error.data;

interface CustomerScreenParams {
Id?: string | undefined;
tenantId?: string | undefined;
  [key: string]: string | undefined;
}

export default function CustomerEditScreen(props: any) {
  const { Id, tenantId = DefaultTenantId}: CustomerScreenParams =
    useParams<CustomerScreenParams>();

  const isNewCustomer = Id === 'new';
console.log('isNewCustomer', isNewCustomer);
  const { data, isFetching } = useFetchSingleCustomerQuery(
    {
      Id: Number(Id),
    },
    { skip: isNewCustomer }
  );

  const [createCustomer, { isLoading: isCreating, error: createError }] = useCreateCustomerMutation();
  const [updateCustomer, { isLoading: isUpdating, error: updateError }] = useUpdateCustomerMutation();

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [form] = Form.useForm();

  React.useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const onFinish = (values: any | CustomerViewModel) => {
    const requestData = {
      customer: {
        id: isNewCustomer ? 0 : Number(Id),
        name: values.name,
        type: values.type,
        qualifier: values.qualifier,
        updatedBy:sessionStorage.getItem('userEmail')||'',
        enteredBy:isNewCustomer?sessionStorage.getItem('userEmail')||'':'',
      },
    };

    const request = isNewCustomer ? createCustomer(requestData) : updateCustomer(requestData);
    request.unwrap().then((response) => {
      if(response?.id ===-200){
        message.error(
          `This combination already exists. Please try other values.`
        );
        return;
      }
      message.success(
        `Customer is ${isNewCustomer ? 'created' : 'saved'}`
      );
      navigate(
        getLink('CustomerList', {
          ...props,
          tenantId: tenantId,
        })
      );
    });
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };
  const [disabledSave, setDisabledSave] = useState(true);
  
  const handleFormChange = () => {
  const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
  setDisabledSave(hasErrors);
  }
  const handleCancel = () => {
    navigate(
      getLink('CustomerList', {
        ...props,
        tenantId: tenantId,
      })
    );
  };

  return (
    <div>
      <PageHeader
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={getLink('CustomerList', { ...props, tenantId })}>Customers</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{isNewCustomer ? 'New Customer' : data?.name}</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {isFetchBaseQueryErrorType(createError) && (
          <Alert message="Error" description={getErrorMessage(createError)} type="error" showIcon />
        )}
        {isFetchBaseQueryErrorType(updateError) && (
          <Alert message="Error" description={getErrorMessage(updateError)} type="error" showIcon />
        )}
        <Card
          title={
            <Title
              level={4}
              style={{
                marginBottom: 0,
                display: 'inline-block',
              }}
            >
              {isNewCustomer ? 'New Customer' : 'Edit Customer'}
            </Title>
          }
        >
          <Spin spinning={isFetching || isCreating || isUpdating}>
            <Form
              {...layout}
              form={form}
              name="control-hooks"
              initialValues={data}
              onFinish={onFinish}
              onFieldsChange={handleFormChange}   
            >
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input placeholder='Please enter customer name with max length upto 100 characters' data-testid="customer-name" maxLength={100} suffix={
                        <Tooltip title="Please enter customer name with max length upto 100 characters for e.g. MercuryGate LI - Ashley Furniture">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      } />
              </Form.Item>
              <Form.Item name="type" label="Type" rules={[{ required: true }]} initialValue={'Default'}>
                  <Select placeholder='Type' defaultValue="Default"
                  options={[{label:'Default', value:'Default'},{label:'Carrier', value:'Carrier'}]} />
              </Form.Item>
              <Form.Item name="qualifier"  label="Qualifier" rules={[{ required: true }]}>
                <Input placeholder='Please provide valid qualifier for e.g. RWASHLEY' data-testid="qualifier" maxLength={100}  suffix={
                        <Tooltip title="Please provide valid qualifier for e.g. RWASHLEY">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>

              {!isNewCustomer?
              <div>
                <Form.Item name="enteredBy" label="Entered By">
                <Space>
                  <span>{data?.enteredBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="enteredByDate" label="Entered By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.enteredByDate||'')}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updatedBy" label="Updated By">
                <Space>
                  <span>{data?.updatedBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updateByDate" label="Updated By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.updateByDate||'')}</span>
                </Space>
              </Form.Item>      
              </div>:''
              }

              <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                <Space>
                  <Button type="primary" htmlType="submit" disabled={disabledSave && !isNewCustomer}>
                    {isNewCustomer ? 'Add Customer' : 'Save Customer'}
                  </Button>
                  <Button htmlType="button" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </Form>
            
          </Spin>
        </Card>
      </PageHeader>
    </div>
  );
}
