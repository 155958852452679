import EndSystemsScreen from './screens/endsystems-screen';
import EndSystemEditScreen from './screens/endsystem-edit-screen';
import { RouteDefinition } from '../../core/route';

export const endsystemRoutes: RouteDefinition[] = [
  {
    id: 'EndSystemEdit',
    title: 'End System',
    path: 'endsystems/:Id',
    element: <EndSystemEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'EndSystemList',
    title: 'End Systems',
    path: 'endsystems',
    element: <EndSystemsScreen />,
    showInNavigation: true,
  },
];
