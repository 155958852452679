import { apiSlice } from '../api/api-slice';
import { PartnerViewModel, PaginatedResult } from '../api/models';

export interface PartnerListRequest {
  tenantId: string | null;
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
}

export interface EditPartnerRequest {
  tenantId: string;
  partner: PartnerViewModel;
}

export interface PartnerRequest {
  tenantId: string;
  partnerId: number;
}

export interface DeletePartnerRequest {
  tenantId: string;
  partnerId: number;
}

export const partnersApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchPartners: builder.query<PaginatedResult<PartnerViewModel>, PartnerListRequest>({
        query({ tenantId, current, pageSize, filter, sortBy }) {
          return `/tenants/${tenantId}/partners?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        providesTags: (data, _error, arg) => 
        [
          'Partner',
          ...(data?.results?.map(({ partnerId }) => ({ type: 'Partner' as const, id: `${arg.tenantId}-${partnerId}` })) || [])
        ] 
      }),
      fetchSinglePartner: builder.query<PartnerViewModel, PartnerRequest>({
        query({ tenantId, partnerId }) {
          return `/tenants/${tenantId}/partners/${partnerId}`;
        },
        providesTags: (_data, _error, arg) => [{ type: 'Partner', id: `${arg.tenantId}-${arg.partnerId}` }]
      }),
      createPartner: builder.mutation<PartnerViewModel, EditPartnerRequest>({
        query: ({ tenantId, partner }) => {
          return {
            url: `/tenants/${tenantId}/partners`,
            method: 'POST',
            body: partner,
          };
        },
        invalidatesTags: ['Partner']
      }),
      updatePartner: builder.mutation<PartnerViewModel, EditPartnerRequest>({
        query: ({ tenantId, partner }) => {
          return {
            url: `/tenants/${tenantId}/partners/${partner.partnerId}`,
            method: 'PUT',
            body: partner,
          };
        },
        invalidatesTags: (_data, _error, arg) => [{ type: 'Partner', id: `${arg.tenantId}-${arg.partner.partnerId}` }]
      }),
      deletePartner: builder.mutation<void, DeletePartnerRequest>({
        query: ({ tenantId, partnerId }) => ({
          url: `/tenants/${tenantId}/partners/${partnerId}`,
          method: 'DELETE',
          body: {},
        }),
        invalidatesTags: (_data, _error, arg) => ['Partner']
      })
    };
  },
});

export const {
  useFetchPartnersQuery,
  useFetchSinglePartnerQuery,
  useCreatePartnerMutation,
  useUpdatePartnerMutation,
  useDeletePartnerMutation,
} = partnersApi;
