import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  RoutingRuleListRequest,
  useDeleteRoutingRuleMutation,
  useFetchRoutingRulesQuery,
  useCreateRoutingRuleMutation,
  useUpdateRoutingRuleMutation,
} from '../routingrule-api';
import AuditLogsScreen from '../../../components/common/auditLogs';
import {
  useFetchCustomersQuery,
} from '../../customers/customer-api';

import {
  useFetchTransactionTypesQuery,
} from '../../transactiontype/transactiontype-api';
import {
  useFetchEndSystemsQuery,
} from '../../endsystems/endsystem-api';

import {
  useFetchCustomerIdentifierTypesQuery,
} from '../../api/customeridentifiertype-api';

import {
  useFetchProfileMapsQuery,
} from '../../profilemaps/profilemap-api';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Button,
  Card,
  message,
  Modal,
  Space,
  Table,
  Menu,
  Dropdown,
  Tooltip,
  Form
} from 'antd';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, MoreOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { RoutingRuleViewModel, PaginatedResult, RoutingRuleListViewModel,  ProfileMapViewModel,CustomerViewModel, EndSystemViewModel, TransactionTypeViewModel,CustomerIdentifierTypeViewModel } from '../../api/models';
import { DefaultPage, DefaultPageSize } from '../../../core/defaults';
import Title from 'antd/lib/typography/Title';
import { getLink } from '../../../App-router';
import { TableState } from '../../../components/table/model';
import getSortBy from '../../../components/table/sorter';
import PartnerEDIX12sScreen from '../../partneredix12/screens/partneredix12s-screen'

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function RoutingRulesScreen(props: any) {
  const query = useQuery();
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const [showAuditLogs, setShowAuditLogs] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<number>(0);

  // console.log('RoutingRulesScreen', props);
  const routingRuleTable: TableState = {
    columns: [
      {
        title: 'Id',
        dataIndex: '',
        key: 'Id',
        render: (routingRule: RoutingRuleListViewModel) => (
          <>
              <Space>
                <span>{routingRule.id}</span>
              </Space>
          </>
        ),
        fixed: 'left',
      },
      {
        title: 'SplittingRuleId',
        dataIndex: '',
        key: 'splittingRuleId',
        render: (routingRule: RoutingRuleListViewModel) => (
          <>
              <span>{routingRule.splittingRuleId}</span>
          </>
        )
      },
      {
        title: 'Customer',
        dataIndex: '',
        key: 'customer',
        render: (routingRule: RoutingRuleListViewModel) => (
          <>
            <Tooltip title={routingRule.customer} >
            <span>{routingRule.customer}</span>
            </Tooltip>
          </>
        ),
      },
      {
        title: 'ProfileMap',
        dataIndex: '',
        key: 'profileMap',
        render: (routingRule: RoutingRuleListViewModel) => (
          <>
          <Tooltip title={routingRule.profileMap} >
            <span>{routingRule.profileMap}</span>
            </Tooltip>
          </>
        ),
      },
      {
        title: 'TransactionType',
        dataIndex: '',
        key: 'TransactionType',
        render: (routingRule: RoutingRuleListViewModel) => (
          <>
              <span>{routingRule.transactionType}</span>
          </>
        ),
      },
      {
        title: 'EndSystemType',
        dataIndex: '',
        key: 'endSystemType',
        render: (routingRule: RoutingRuleListViewModel) => (
          <>
              <span>{routingRule.endSystem}</span>
          </>
        ),
      },
      {
        title: 'EndSystemInstance',
        dataIndex: '',
        key: 'endSystemInstance',
        render: (routingRule: RoutingRuleListViewModel) => (
          <>
              <span>{routingRule.endSystemInstance}</span>
          </>
        ),
      },
      {
        title: 'IdentifierType',
        dataIndex: '',
        key: 'identifierType',
        render: (routingRule: RoutingRuleListViewModel) => (
          <>
              <span>{routingRule.identifierType}</span>
          </>
        ),
      },
      {
        title: 'IdentifierValue',
        dataIndex: '',
        key: 'identifierValue',
        render: (routingRule: RoutingRuleListViewModel) => (
          <>
              <span>{routingRule.identifierValue}</span>
          </>
        ),
      },          
      {
        title: 'Sequence',
        dataIndex: '',
        key: 'sequence',
        render: (routingRule: RoutingRuleListViewModel) => (
          <>
              <span>{routingRule.sequence}</span>
          </>
        ),
      },
      {
        title: 'Actions',
        key: 'action',
        dataIndex: '',
        fixed:'right',
        render: (routingRule: RoutingRuleListViewModel) => (
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            overlay={
              <Menu style={{ padding: '8px 8px' }}>
                <Menu.Item icon={<EditOutlined />} key="1">
                  <Link
                    to={getLink((routingRule?.transactionType=='997'? '997RuleEdit':'RoutingRuleEdit'), {
                      tenantId: params.tenantId,
                      Id: routingRule.id,
                    })}
                  >
                    Edit
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<InfoCircleOutlined />} key="3">
                <Link to="#" onClick={()=>setShowAuditLogs(true)}>
                    Audit Logs
                  </Link>
                </Menu.Item>
                <Menu.Item disabled={true}
                  icon={<DeleteOutlined />}
                  key={routingRule.id}
                  onClick={() => {
                    Modal.confirm({
                      title: (
                        <span>
                          Delete routing rule <b>{routingRule.id}</b>?
                        </span>
                      ),
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        handleRoutingRuleDelete(routingRule);
                      },
                    });
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
          >
            <MoreOutlined />
          </Dropdown>
        ),
      },
    ],
  };

  const getCurrentState = (): RoutingRuleListRequest => ({
    current: Number(localStorage.getItem('routingRulePageIndex')) || DefaultPage,
    pageSize: Number(localStorage.getItem('routingRulePageSize')) || DefaultPageSize,
    // filter: query.get('q'),
    filter:'',
    sortBy: query.get('sortBy') || 'EnteredByDate Desc',
    splittingRuleId: props.splittingRuleID
  });
  const [createRoutingRule, { isLoading: isCreating, error: createError }] = useCreateRoutingRuleMutation();
  const [updateRoutingRule, { isLoading: isUpdating, error: updateError }] = useUpdateRoutingRuleMutation();

  const [pagination, setPagination] = useState<RoutingRuleListRequest>(getCurrentState());
  const [showPartnerEDIX12, setShowPartnerEDIX12] = useState(false);
  const [routingRuleID, setRoutingRuleID] = useState(0);

  const {
    data: pagedData = {},
    isFetching,
    error,
  }: {
    data?: PaginatedResult<RoutingRuleListViewModel> | undefined;
    isFetching?: boolean | undefined;
    error?: any;
  } = useFetchRoutingRulesQuery(pagination);

  useEffect(() => {
    // localStorage.setItem('splittingRuleID', props.splittingRuleID)
    if(localStorage.getItem('routingRuleID')){
      setRoutingRuleID(Number(localStorage.getItem('routingRuleID')));
      setShowPartnerEDIX12(true);
    }
    setPagination(getCurrentState());
  }, [params,props]);

  const [deleteRoutingRule, { isLoading: isDeleteLoading, error: deleteError }] =
    useDeleteRoutingRuleMutation();

  const onCreateNew = useCallback(
    () => navigate(getLink('RoutingRuleEdit', { ...params, Id: 'new' })),
    [params, navigate]
  );
  const onCreateNew997Rule = useCallback(
    () => navigate(getLink('997RuleEdit', { ...params, Id: 'new'})),
    [params, navigate]
  );
  const handleTableChange = (
    paginationConfig: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<RoutingRuleViewModel> | SorterResult<RoutingRuleViewModel>[],
    extra: TableCurrentDataSource<RoutingRuleViewModel>
  ) => {
    const { columnKey, order } = sorter as SorterResult<RoutingRuleViewModel>;
    if (columnKey && order) {
      query.set('sortBy', getSortBy(columnKey.toString(), order));
    } else {
      query.set('sortBy', '');
    }
    // debugger;
    localStorage.setItem('routingRulePageIndex',paginationConfig.current?.toString()||'1');
    localStorage.setItem('routingRulePageSize',(paginationConfig.pageSize||DefaultPageSize).toString());
    navigate(
      getLink('CarrierOnboardingEdit', {
        tenantId: params.tenantId,
        Id: props.splittingRuleID,
        partnershipId: Number(localStorage.getItem('partnershipId')||0)
      })
    );
  };


  const handleDelete = async (routingrule: RoutingRuleViewModel) => {
    try {
      await deleteRoutingRule({
        Id: routingrule.id ?? 0,
      }).unwrap();
      message.success(`Routing Rule ${routingrule.id} deleted`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRoutingRuleDelete = async (routingrule: RoutingRuleListViewModel) => {
    try {
      await deleteRoutingRule({
        Id: routingrule.id ?? 0,
      }).unwrap();
      message.success(`Routing Rule ${routingrule.id}} deleted`);
    } catch (error) {
      console.error(error);
    }
  };


  // Start 997 Ib Rule 
  const [formRoutingRule] = Form.useForm();
  React.useEffect(() => {
    formRoutingRule.resetFields();
  }, [formRoutingRule]);

// Fetch Customers Types - START
  const {
    data: customers = {},
  }: {
    data?: PaginatedResult<CustomerViewModel> | undefined;
  } = useFetchCustomersQuery({
    current:1,
    pageSize:1000,
    filter:'',
    sortBy: 'Name Asc',
        
});
  let customersResults = customers.results??[];
// Fetch Customers Types - END


const {
  data: profileMaps = {},
}: {
  data?: PaginatedResult<ProfileMapViewModel> | undefined;
  error?: any;
} = useFetchProfileMapsQuery({
  current:1,
  pageSize:1000,
  filter:'',
  sortBy: 'Name Asc',        
});
let profileMapsResults = profileMaps.results??[];

// Fetch Transaction Types - START

const {
  data: transactionTypeValues = {},
}: {
  data?: PaginatedResult<TransactionTypeViewModel> | undefined;
} = useFetchTransactionTypesQuery({
    current:1,
    pageSize:1000,
    filter:'',
    sortBy: 'Name Asc',
        
});
let transactionTypesResults = transactionTypeValues.results??[];
// Fetch Transaction Types - END

// Fetch EndSystems Types - START
const {
data: endSystemTypeValues = {},
}: {
data?: PaginatedResult<EndSystemViewModel> | undefined;
} = useFetchEndSystemsQuery({
  current:1,
  pageSize:1000,
  filter:'',
  sortBy: 'Name Asc',
      
});
let endSystemTypesResults = endSystemTypeValues.results??[];
// Fetch EndSystems Types - END


// Fetch CustomerIdentifier Types - START
const {
  data: customerIdentifierTypes = {},
}: {
  data?: PaginatedResult<CustomerIdentifierTypeViewModel> | undefined;
} = useFetchCustomerIdentifierTypesQuery({
  current:1,
  pageSize:1000,
  filter:'',
  sortBy: 'Name Asc',
      
});
let customerIdentifierTypesResults = customerIdentifierTypes.results??[];

// Fetch CustomerIdentifier Types - END
  const on997RuleClick = (values: any | RoutingRuleViewModel) => {
    //  debugger;
     let profileMapId= profileMapsResults.filter(x=>x.name?.toLowerCase()=='edi997tocosmosdatabase')[0].id||0;
     let    transactionTypeId= transactionTypesResults.filter(x=>x.name=='997')[0].id||0;
     let    endSystemId= endSystemTypesResults.filter(x=>x.name?.toLowerCase()=='cosmos')[0].id||0;
     let    identifierTypeId= customerIdentifierTypesResults.filter(x=>x?.name.toLowerCase()==='na')[0]?.id||0;
     let    customerId = customersResults.filter(x=>x.name?.toLowerCase()==='carrier edi default')[0]?.id||0;
    const requestData = {
      routingrule: {
            id: 0,
            splittingRuleId:Number(localStorage.getItem('splittingRuleID')),
            customerId: customerId,
            profileMapId: profileMapId,
            transactionTypeId: Number(transactionTypeId),
            endSystemId: endSystemId,
            identifierTypeId: identifierTypeId,
            identifierValue: '',
            sequence: 1,
            updatedBy:sessionStorage.getItem('userEmail')||'',
            enteredBy:sessionStorage.getItem('userEmail')||'',
      },
    };

    const request = createRoutingRule(requestData)
    request.unwrap().then((response) => {
      if(response?.id ===-200){
        message.error(
          `This combination already exists. Please try other values.`
        );
        return;
      }
      message.success(
        `997 Inbound Rule is created.`
      );
    });
  };
  // End 997 Ib Rule

  return (
    <div>
      <PageHeader
        extra={
          <Space>
          <Button htmlType="button" data-testid={'create-new-997rule'} onClick={on997RuleClick}>
            New 997 Inbound Rule
          </Button>     
          <Button htmlType="button" data-testid={'create-new-routingrule'} onClick={onCreateNew}>
            New Routing Rule
          </Button>
                      </Space>
                      
        }
        title={
          <Title
            level={4}
            style={{
              marginBottom: 0,
              display: 'inline-block',
            }}
          >
            Routing Rules
          </Title>
        }
      >
        <Card>
          {error?.status && (
            <Alert message="Error" description={error.data} type="error" showIcon />
          )}
          {deleteError && (
            <Alert message="Error" description={deleteError.toString()} type="error" showIcon />
          )}
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Table scroll={{ x: true }}
              dataSource={pagedData.results}
              onChange={handleTableChange}
              rowKey={'id'}
              onRow={(record:any, rowIndex) => {
                return {
                  onClick: event => {
                    // console.log(record);
                    localStorage.setItem('partnerEDIX12Id', '0');
                    setEntityId(record?.id||0);
                    setShowPartnerEDIX12(record.transactionType =='997' || localStorage.getItem('documentType')?.toLowerCase()=='json'?false:true);
                    setRoutingRuleID(Number(record.id));
                    localStorage.setItem('customerId', record?.customerId.toString());
                    localStorage.setItem('routingRuleID', record.id?record.id.toString():'');
                    localStorage.setItem('transactionType', record?.transactionType?record?.transactionType:'');
                    localStorage.setItem('identifierValue', record?.identifierValue ===undefined || record?.identifierValue === null ? '': record?.identifierValue);
                  },
                };
              }}
              pagination={{
                ...pagination,
                total: pagedData.totalCount,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ["10", "20", "50","100"],
                showSizeChanger: true,
                locale: { items_per_page: " / Page" }
              }}
              columns={routingRuleTable.columns}
              loading={isFetching}
            />
          </Space>
        </Card>
        { showPartnerEDIX12?
        <div>
          <PartnerEDIX12sScreen routingRuleID = {localStorage.getItem('routingRuleID')} />
        </div>:''
        }
        {showAuditLogs?<AuditLogsScreen entityId={entityId} entityName={'RoutingRule'} showAuditLogs={setShowAuditLogs}/>:<></>}
      </PageHeader>
    </div>
  );
}
