import { createSlice } from '@reduxjs/toolkit';
import { TableState } from '../../components/table/model';

const initialState: TableState = {
  columns: [],
};

const customerListSlice = createSlice({
  name: 'customerTable',
  initialState,
  reducers: {
    hideColumn(state) {},
  },
});

export const customersReducer = customerListSlice.reducer;
