import React, { useCallback, useState } from 'react';
import {  useEffect} from 'react';
import {
useCreatePartnerEDIX12Mutation,
} from '../partneredix12-api';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import { ConvertUTCtoCDT } from '../../../core/utils';
import {
Alert,
Breadcrumb,
Button,
Card,
message,
Space,
Spin,
Form,
Input,
Select,
Switch,
Tooltip
} from 'antd';
import {
HomeOutlined,
QuestionCircleOutlined
} from '@ant-design/icons';

import {
useCreatePartnerConnectionEndpointMutation,
useFetchSinglePartnerConnectionEndpointQuery,
} from '../../partnerconnectionendpoint/partnerconnectionendpoint-api';
import {
  useFetchConnectionEndPointsQuery,
} from '../../connectionendpoint/connectionendpoint-api';
import { PartnerEDIX12ViewModel, ConnectionEndPointViewModel, PaginatedResult } from '../../api/models';
import { DefaultTenantId, DefaultPage, DefaultPageSize } from '../../../core/defaults';
import Title from 'antd/lib/typography/Title';
import { getLink } from '../../../App-router';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

const useQuery = () => {
return new URLSearchParams(useLocation().search);
};

const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError =>
error ? 'status' in error : false;

const getErrorMessage = (error: any): string | undefined => error.data;

interface PartnerConnectionEndpointScreenParams {
Id?: string | undefined;
tenantId?: string | undefined;
  [key: string]: string | undefined;
}

export default function OBRule997Screen(props: any) {
const { Id, tenantId = DefaultTenantId}: PartnerConnectionEndpointScreenParams =
  useParams<PartnerConnectionEndpointScreenParams>();

const isNewPartnerConnectionEndpoint = Id === 'new';

const navigate = useNavigate();
const goBack = useCallback(() => navigate(-1), [navigate]);
const { data, isFetching } = useFetchSinglePartnerConnectionEndpointQuery(
{
  Id: Number(Id)
},
{ skip: isNewPartnerConnectionEndpoint }
);

const {
  data: connectionendpoints = {},
  error,
}: {
  data?: PaginatedResult<ConnectionEndPointViewModel> | undefined;
  isFetching?: boolean | undefined;
  error?: any;
} = useFetchConnectionEndPointsQuery({
  current: DefaultPage,
  pageSize: 1000,
  filter: '',
  sortBy: 'EnteredByDate Desc',
});

const query = useQuery();
const params: any = useParams<any>();

//TODO: need to fill source and target from splitting rule as well here.
let identifierValue = localStorage.getItem('identifierValue')||'';
let source = localStorage.getItem('source')||'';
let target = localStorage.getItem('target')||'';
let documentType = localStorage.getItem('documentType')||'';
console.log('documentType:', documentType);
const [createPartnerConnectionEndpoint, { isLoading: isCreatingPCE, error: createErrorPCE }] = useCreatePartnerConnectionEndpointMutation();
const [createPartnerEDIX12, { isLoading: isCreating, error: createError }] = useCreatePartnerEDIX12Mutation();
const [form] = Form.useForm();
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 10 },
};

useEffect(() => {    
  form.resetFields();
}, [data, form]);


const onFinish = (values: any | PartnerEDIX12ViewModel) => {
  const requestData = {
    partneredix12: {
      id: 0,
      partnerID: Number(localStorage.getItem('customerId'))||0,
      carrierName: localStorage.getItem('carrierName')||'',
      sourceSCAC: localStorage.getItem('partnerId')||'',
      sourceSender: localStorage.getItem('source')||'',
      sourceReceiver: localStorage.getItem('target')||'',
      transactionSetIdentifierCode: "997",
      interchangeAuthorizationInformationQualifier: '00',
      interchangeSecurityInformationQualifier: '00',
      interchangeSenderQualifier: 'ZZ',
      interchangeSenderID: localStorage.getItem('target')||'',
      interchangeReceiverQualifier: '02',
      interchangeReceiverID: localStorage.getItem('source')||'',
      interchangeStandard: 'U',
      interchangeVersionNumber: '00401',
      interchangeAcknowledgmentRequested: "0",
      interchangeTestIndicator: localStorage.getItem('testFlag')||'',
      groupFunctionalIdentifierCode: 'FA',
      groupResponsibleAgencyCode: 'X',
      groupVersionNumber: '004010',
      elementSeparator: '*',
      componentElementSeparator: '>',
      segmentTerminator: '~',
      fileFormat: 'String',
      isActive: true,
      updatedBy:sessionStorage.getItem('userEmail')||'',
      enteredBy:sessionStorage.getItem('userEmail')||'',
    },
  };

  const request = createPartnerEDIX12(requestData);
  request.unwrap().then((response) => {
    if(response?.id ===-200){
      message.error(
        `This combination already exists. Please try other values.`
      );
      return;
    }
    localStorage.setItem('partnerEDIX12Id', response?.id?.toString()||'');

    const requestData = {
      partnerconnectionendpoint: {
        id:0,
        connectionEndpointId: connectionendpoints?.results!=undefined?connectionendpoints?.results[0].id:0,
        partnerEDIX12Id: Number(response?.id),
        folderPath: values.folderPath||'',
        isActive: values.isActive==null || values.isActive==undefined?true:values.isActive,
        updatedBy:sessionStorage.getItem('userEmail')||'',
        enteredBy:sessionStorage.getItem('userEmail')||'',
      },
    };

    const requestPce = createPartnerConnectionEndpoint(requestData);
    requestPce.unwrap().then((responsePce:any) => {
      
      if(responsePce?.id ===-200){
        message.error(
          `This combination already exists. Please try other values.`
        );
        return;
      }
      message.success(
        `Partner Connection Endpoint is saved.`
      );
      goBack();
    });
    message.success( `997 outbound rule is created`);
  });
};

return (
  <>
    <PageHeader
      breadcrumb={
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={getLink('home', { tenantId: tenantId })}>
            <HomeOutlined />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>              
            <Link to={getLink('cob', { tenantId: tenantId })}>
            Dashboard
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item key="partnershipInformation">
            <Link to={getLink('PartnershipInformationList', { tenantId: tenantId })}>Partner Information</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item key="carrierName">
            <Link to={getLink('CarrierOnboardingList', {
                    ...props, tenantId,
                    partnerId: localStorage.getItem('partnerId')||'',
                    identifierValue: localStorage.getItem('identifierValue')||'',
                    carrierName: localStorage.getItem('carrierName')||'',
                    id: Number(localStorage.getItem('partnershipId')||0),
                  })}>{localStorage.getItem('carrierName')||''}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item key="splittingRule">
            <Link to={getLink('CarrierOnboardingEdit', { tenantId: tenantId,Id: Number(localStorage.getItem('splittingRuleID')||0),
                    partnershipId: Number(localStorage.getItem('partnershipId')||0) })}>{localStorage.getItem('splittingRuleID')||''}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>997 Outbound Rule</Breadcrumb.Item>
        </Breadcrumb>
      }
    >
      {isFetchBaseQueryErrorType(createError) && (
        <Alert message="Error" description={getErrorMessage(createError)} type="error" showIcon />
      )}
      <Card
        title={
          <Title
            level={4}
            style={{
              marginBottom: 0,
              display: 'inline-block',
            }}
          >
            997 Outbound Rule
          </Title>
        }
      >
        <Spin spinning={isFetching || isCreating}>
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            initialValues={data}
            onFinish={onFinish}
          >
            <Form.Item name="folderPath" label="FolderPath" rules={[{ required: true }]}>
              <Input placeholder='Please enter valid data' data-testid="folderPath" suffix={
                      <Tooltip title="Please enter valid data">
                        <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                      </Tooltip>
                    } />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
              <Space>
                <Button type="primary" htmlType="submit">
                  Save Rule
                </Button>
                <Button htmlType="button" onClick={goBack}>
                  Cancel
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </PageHeader>
  </>
);
}
