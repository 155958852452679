import CarrierOnboardingsScreen from './screens/carrieronboardings-screen';
import CarrierOnboardingEditScreen from './screens/carrieronboarding-edit-screen';
import { RouteDefinition } from '../../core/route';

export const carrieronboardingRoutes: RouteDefinition[] = [
  {
    id: 'CarrierOnboardingEdit',
    title: 'Partner Onboarding',
    path: 'carrieronboardings/:partnershipId/:Id',
    element: <CarrierOnboardingEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'CarrierOnboardingList',
    title: 'Partner Onboarding',
    path: 'partneronboardings/:id/:partnerId?/:carrierName?/:identifierValue?',
    element: <CarrierOnboardingsScreen />,
    showInNavigation: false,
  },
];
