import { apiSlice } from '../api/api-slice';
import { SourceTypeViewModel, PaginatedResult } from '../api/models';

export interface SourceTypeListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
}

export interface EditSourceTypeRequest {
  sourcetype: SourceTypeViewModel;
}

export interface SourceTypeRequest {
  Id: number;
}

export interface DeleteSourceTypeRequest {
  Id: number;
}

export const sourcetypesApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchSourceTypes: builder.query<PaginatedResult<SourceTypeViewModel>, SourceTypeListRequest>({
        query({ current, pageSize, filter, sortBy }) {
          return `/sourcetypes?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'SourceType',
          ...(data?.results?.map(({ id }) => ({ type: 'SourceType' as const, id: `${id}` })) || [])
        ] 
      }),
      fetchSingleSourceType: builder.query<SourceTypeViewModel, SourceTypeRequest>({
        query({ Id }) {
          return `/sourcetypes/${Id}`;
        },
        providesTags: (_data, _error, arg) => [{ type: 'SourceType', id: `${arg.Id}` }]
      }),
      createSourceType: builder.mutation<SourceTypeViewModel, EditSourceTypeRequest>({
        query: ({ sourcetype }) => {
          return {
            url: `/sourcetypes`,
            method: 'POST',
            body: sourcetype,
          };
        },
        invalidatesTags: ['SourceType']
      }),
      updateSourceType: builder.mutation<SourceTypeViewModel, EditSourceTypeRequest>({
        query: ({ sourcetype }) => {
          return {
            url: `/sourcetypes/${sourcetype.id}`,
            method: 'PUT',
            body: sourcetype,
          };
        },
        invalidatesTags: (_data, _error, arg) => [{ type: 'SourceType', id: `${arg.sourcetype.id}` }]
      }),
      deleteSourceType: builder.mutation<void, DeleteSourceTypeRequest>({
        query: ({ Id }) => ({
          url: `/sourcetypes/${Id}`,
          method: 'DELETE',
          body: {},
        }),
        invalidatesTags: (_data, _error, arg) => ['SourceType']
      })
    };
  },
});

export const {
  useFetchSourceTypesQuery,
  useFetchSingleSourceTypeQuery,
  useCreateSourceTypeMutation,
  useUpdateSourceTypeMutation,
  useDeleteSourceTypeMutation,
} = sourcetypesApi;
