import React from 'react';
import { useEffect, useState, useMemo } from 'react';
import {
  PartnerDataOnboardingListRequest,
  PartnerDataOnboardingFiltersRequest,
  useImportPartnerOnboardingMutation,
  useFetchPartnerOnboardingQuery,  
  useFetchPartnerOnboardingFiltersQuery,
} from '../partnerdataonboarding-api';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Card,
  Select,
  Space,
  Table,
  Button,
  Row,
  Col,
  message,
  Upload,
  Modal,
  Anchor
} from 'antd';
import {
  HomeOutlined,
  DownloadOutlined,
  UploadOutlined
} from '@ant-design/icons';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { PartnerDataOnboardingViewModel, PaginatedResult, CarrierOnboardingViewModel } from '../../api/models';
import { DefaultPage, DefaultPageSize } from '../../../core/defaults';
import Title from 'antd/lib/typography/Title';
import { getLink } from '../../../App-router';
import { TableState } from '../../../components/table/model';
import getSortBy from '../../../components/table/sorter';

import { RcFile } from "antd/lib/upload/interface";
import * as XLSX from 'xlsx';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function PartnerDataOnboardingsScreen(props: any) {
  const query = useQuery();
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [showUploadList, setShowUploadList] = useState<boolean>(true);
  const [downLoading, setDownLoading] = useState<boolean>(false);
  const [isImportCompleted, setIsImportCompleted] = useState<boolean>(false);
  const [createImportPartnerOnboarding, { isLoading: isCreating, error: createError }] = useImportPartnerOnboardingMutation();
  const [partnerType, setPartnerType] = useState<string>('');
  const [partnerName, setPartnerName] = useState<string>('');
  const [partnerId, setPartnerId] = useState<string>('');
  const [partnerNames, setPartnerNames] = useState<any>([]);
  const [partnerIds, setPartnerIds] = useState<any>([]);
  const [partnerItems, setPartnerItems] = useState<any>([]);
  const [partnerResponse, setPartnerResponse] = useState<any>([]);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [errorCount, setErrorCount] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const partneronboardingTable: TableState = {
    columns: [
      {
        title: 'PartnerType',
        key: 'partnerType',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.partnerType}</span>
          </>
        ),
        sorter: true,
      }, 
      {
        title: 'PartnerName',
        dataIndex: '',
        key: 'partnerName',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.partnerName}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'PartnerId',
        dataIndex: '',
        key: 'partnerId',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.partnerId}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'DocumentType',
        dataIndex: '',
        key: 'documentType',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <Space>
                <span>{partneronboarding.documentType}</span>
              </Space>
          </>
        ),
        sorter: true,
      },
      {
        title: 'Format',
        dataIndex: '',
        key: 'format',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.format}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'Standard',
        dataIndex: '',
        key: 'standard',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.standard}</span>
          </>
        ),
        sorter: true,
      },
      { 
        title: 'Version',
        dataIndex: '',
        key: 'version',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.version}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'TransactionType',
        key: 'transactionType',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.transactionType}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'Source',
        dataIndex: '',
        key: 'source',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.source}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'Target',
        dataIndex: '',
        key: 'target',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.target}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'SplittingRulePriority',
        key: 'splittingRulePriority',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.splittingRulePriority}</span>
          </>
        ),
        sorter: true,
      }, 
      {
        title: 'EndSystem',
        dataIndex: '',
        key: 'endSystem',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.endSystem}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'EndSystemInstance',
        dataIndex: '',
        key: 'endSystemInstance',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.endSystemInstance}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'EndSystemDescription',
        key: 'endSystemDescription',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.endSystemDescription}</span>
          </>
        ),
        sorter: true,
      }, 
      {
        title: 'TestFlag',
        key: 'testFlag',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
          <span>{partneronboarding.testFlag}</span>              
          </>
        ),
        sorter: true,
      },
      {
        title: 'CustomerName',
        key: 'customerName',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.customerName}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'CustomerType',
        dataIndex: '',
        key: 'customerType',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <Space>
                <span>{partneronboarding.customerType}</span>
              </Space>
          </>
        ),
        sorter: true,
      },
      {
        title: 'CustomerQualifier',
        dataIndex: '',
        key: 'customerQualifier',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.customerQualifier}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'ProfileMapName',
        dataIndex: '',
        key: 'profileMapName',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.profileMapName}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'CustomerIdentifierType',
        dataIndex: '',
        key: 'customerIdentifierType',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.customerIdentifierType}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'CustomerIdentifierValue',
        key: 'customerIdentifierValue',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.customerIdentifierValue}</span>
          </>
        ),
        sorter: true,
      },      


      {
        title: 'Sequence',
        dataIndex: '',
        key: 'sequence',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.sequence}</span>
          </>
        ),
        sorter: true,
      },      
      {
        title: 'PartnerIdentifierType',
        dataIndex: '',
        key: 'partnerIdentifierType',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.partnerIdentifierType}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'PartnerIdentifierValue',
        key: 'partnerIdentifierValue',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.partnerIdentifierValue}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'CarrierName',
        key: 'carrierName',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
          <span>{partneronboarding.carrierName}</span>              
          </>
        ),
        sorter: true,
      },
      {
        title: 'SourceSCAC',
        key: 'sourceSCAC',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.sourceSCAC}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'SourceSender',
        dataIndex: '',
        key: 'sourceSender',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <Space>
                <span>{partneronboarding.sourceSender}</span>
              </Space>
          </>
        ),
        sorter: true,
      },
      {
        title: 'SourceReceiver',
        dataIndex: '',
        key: 'sourceReceiver',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.sourceReceiver}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'DocumentEncoding',
        dataIndex: '',
        key: 'documentEncoding',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.documentEncoding}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'TransactionSetIdentifierCode',
        dataIndex: '',
        key: 'transactionSetIdentifierCode',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.transactionSetIdentifierCode}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'InterchangeAckOverdueTime',
        key: 'interchangeAckOverdueTime',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.interchangeAckOverdueTime}</span>
          </>
        ),
        sorter: true,
      },  
      {
        title: 'Interchange AckOverdueTime Minutes',
        dataIndex: '',
        key: 'interchangeAckOverdueTimeMinutes',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.interchangeAckOverdueTimeMinutes}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'Interchange Authorization Information',
        dataIndex: '',
        key: 'interchangeAuthorizationInformation',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.interchangeAuthorizationInformation}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'Interchange AuthorizationInformation Qualifier',
        key: 'interchangeAuthorizationInformationQualifier',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.interchangeAuthorizationInformationQualifier}</span>
          </>
        ),
        sorter: true,
      }, 
      {
        title: 'InterchangeSecurityInformationQualifier',
        dataIndex: '',
        key: 'interchangeSecurityInformationQualifier',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.interchangeSecurityInformationQualifier}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'InterchangeSecurityInformation',
        dataIndex: '',
        key: 'interchangeSecurityInformation',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.interchangeSecurityInformation}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'InterchangeSenderQualifier',
        key: 'interchangeSenderQualifier',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.interchangeSenderQualifier}</span>
          </>
        ),
        sorter: true,
      }, 
      {
        title: 'InterchangeSenderID',
        key: 'interchangeSenderID',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
          <span>{partneronboarding.interchangeSenderID}</span>              
          </>
        ),
        sorter: true,
      },
      {
        title: 'InterchangeReceiverQualifier',
        key: 'interchangeReceiverQualifier',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.interchangeReceiverQualifier}</span>
          </>
        ),
        sorter: true,
      },

      {
        title: 'InterchangeReceiverID',
        dataIndex: '',
        key: 'interchangeReceiverID',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.interchangeReceiverID}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'InterchangeStandard',
        dataIndex: '',
        key: 'interchangeStandard',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.interchangeStandard}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'InterchangeVersionNumber',
        key: 'interchangeVersionNumber',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.interchangeVersionNumber}</span>
          </>
        ),
        sorter: true,
      }, 
      {
        title: 'InterchangeAcknowledgmentRequested',
        dataIndex: '',
        key: 'interchangeAcknowledgmentRequested',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.interchangeAcknowledgmentRequested}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'InterchangeTestIndicator',
        dataIndex: '',
        key: 'interchangeTestIndicator',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.interchangeTestIndicator}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'GroupAckOverdueTimeMinutes',
        key: 'groupAckOverdueTimeMinutes',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.groupAckOverdueTimeMinutes}</span>
          </>
        )
      }, 
      {
        title: 'GroupAckOverdueTime',
        key: 'groupAckOverdueTime',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
          <span>{partneronboarding.groupAckOverdueTime}</span>              
          </>
        ),
      },
      {
        title: 'GroupExpectAck',
        key: 'groupExpectAck',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.groupExpectAck}</span>
          </>
        )
      },


      {
        title: 'GroupFunctionalIdentifierCode',
        dataIndex: '',
        key: 'groupFunctionalIdentifierCode',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <Space>
                <span>{partneronboarding.groupFunctionalIdentifierCode}</span>
              </Space>
          </>
        ),
        sorter: true,
      },
      {
        title: 'GroupSenderID',
        dataIndex: '',
        key: 'groupSenderID',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.groupSenderID}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'GroupReceiverID',
        dataIndex: '',
        key: 'groupReceiverID',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.groupReceiverID}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'GroupResponsibleAgencyCode',
        dataIndex: '',
        key: 'groupResponsibleAgencyCode',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.groupResponsibleAgencyCode}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'GroupVersionNumber',
        key: 'groupVersionNumber',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.groupVersionNumber}</span>
          </>
        ),
        sorter: true,
      },      


      {
        title: 'TransactionSCAC',
        dataIndex: '',
        key: 'transactionSCAC',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.transactionSCAC}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'ElementSeparator',
        dataIndex: '',
        key: 'elementSeparator',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.elementSeparator}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'ComponentElementSeparator',
        key: 'componentElementSeparator',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.componentElementSeparator}</span>
          </>
        ),
        sorter: true,
      }, 
      {
        title: 'SegmentTerminator',
        dataIndex: '',
        key: 'segmentTerminator',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.segmentTerminator}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'FileFormat',
        dataIndex: '',
        key: 'fileFormat',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.fileFormat}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'IsActive',
        key: 'isActive',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.isActive?'Yes':'No'}</span>
          </>
        ),
        sorter: true,
      }, 
      {
        title: 'Protocol',
        key: 'protocol',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
          <span>{partneronboarding.protocol}</span>              
          </>
        ),
        sorter: true,
      },
      {
        title: 'Host',
        key: 'host',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.host}</span>
          </>
        ),
        sorter: true,
      },


      {
        title: 'ServiceName',
        dataIndex: '',
        key: 'serviceName',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.serviceName}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'ProcessId',
        dataIndex: '',
        key: 'processId',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.processId}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'EnvironmentKey',
        key: 'environmentKey',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.environmentKey}</span>
          </>
        ),
        sorter: true,
      },      
      {
        title: 'Description',
        dataIndex: '',
        key: 'description',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.description}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'Partner Connection Endpoint Active',
        dataIndex: '',
        key: 'isPCEActive',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.isPCEActive?'Yes':'No'}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'FolderPath',
        key: 'folderPath',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.folderPath}</span>
          </>
        ),
        sorter: true,
      }, 
      {
        title: 'ack_Exists',
        dataIndex: '',
        key: 'ack_Exists',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.ack_Exists?'Yes':'No'}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'ack_TransactionType',
        dataIndex: '',
        key: 'ack_TransactionType',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.ack_TransactionType}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'ack_InterchangeSenderQualifier',
        dataIndex: '',
        key: 'ack_InterchangeSenderQualifier',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.ack_InterchangeSenderQualifier}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'ack_InterchangeSenderID',
        dataIndex: '',
        key: 'ack_InterchangeSenderID',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.ack_InterchangeSenderID}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'ack_InterchangeReceiverQualifier',
        dataIndex: '',
        key: 'ack_InterchangeReceiverQualifier',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.ack_InterchangeReceiverQualifier}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'ack_InterchangeReceiverID',
        dataIndex: '',
        key: 'ack_InterchangeReceiverID',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.isAckOverrideActive}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'ack_GroupSenderID',
        dataIndex: '',
        key: 'ack_GroupSenderID',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.ack_GroupSenderID}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'ack_GroupReceiverID',
        dataIndex: '',
        key: 'ack_GroupReceiverID',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.ack_GroupReceiverID}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'AcknowledgmentOverride',
        dataIndex: '',
        key: 'acknowledgmentOverride',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.acknowledgmentOverride?'Yes':'No'}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'IsAcknowledgmentOverrideActive',
        dataIndex: '',
        key: 'isAckOverrideActive',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.isAckOverrideActive?'Yes':'No'}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'UserName',
        key: 'userName',
        dataIndex: '',
        render: (partneronboarding: PartnerDataOnboardingViewModel) => (
          <>
              <span>{partneronboarding.userName}</span>
          </>
        )
      },
      // {
      //   title: 'Actions',
      //   key: 'action',
      //   dataIndex: '',
      //   fixed:'right',
      //   render: (partnercontrolnumber: PartnerDataOnboardingViewModel) => (
      //     <Dropdown
      //       trigger={['click']}
      //       placement="bottomRight"
      //       overlay={
      //         <Menu style={{ padding: '8px 8px' }}>
      //           <Menu.Item icon={<InfoCircleOutlined />} key="1">
      //           <Link to="#" onClick={()=>setShowAuditLogs(true)}>
      //               Audit Logs
      //             </Link>
      //           </Menu.Item>
                
      //         </Menu>
      //       }
      //     >
      //       <MoreOutlined />
      //     </Dropdown>
      //   ),
      // },
    ],
  };

  const getCurrentState = (): PartnerDataOnboardingListRequest => ({
    current: Number(query.get('p')) || DefaultPage,
    pageSize: Number(query.get('size')) || DefaultPageSize,
    filter: query.get('q'),
    sortBy: query.get('sortBy')
  });

  const getFiltersState = (): PartnerDataOnboardingFiltersRequest => ({
    partnerName: partnerName||'',
    partnerId: partnerId||'',
    partnerType: partnerType||''
  });
  const [pagination, setPagination] = useState<PartnerDataOnboardingListRequest>(getCurrentState());

  const {
    data: pagedData = {},
    isFetching,
    error,
  }: {
    data?: PaginatedResult<PartnerDataOnboardingViewModel> | undefined;
    isFetching?: boolean | undefined;
    error?: any;
  } = useFetchPartnerOnboardingQuery(pagination); 
  const pobFilters = useFetchPartnerOnboardingFiltersQuery({});
  
  useEffect(() => {
    setPagination(getCurrentState());
    if(pobFilters.data!=undefined){
      const partners =pobFilters.data!=undefined ? (partnerItems.length>=pobFilters?.data.length ? partnerItems : pobFilters?.data):[];
      // if(partnerItems.length>=pobFilters?.data.length){console.log(partners);}

      setPartnerItems(partners);
      setPartnerNames(partners.map((item:any) => ({ label: item.partnerName, value: item.partnerName })));
      setPartnerIds(partners.map((item:any) => ({ label: item.partnerId, value: item.partnerId })));
    }
    if(partnerType.toLowerCase() =='customer' ){
      setPartnerItems([]);setPartnerNames([]);setPartnerIds([]);
      }
  }, [params,query, partnerItems, partnerNames, partnerIds ]);

  const handleTableChange = (
    paginationConfig: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<PartnerDataOnboardingViewModel> | SorterResult<PartnerDataOnboardingViewModel>[],
    extra: TableCurrentDataSource<PartnerDataOnboardingViewModel>
  ) => {
    const { columnKey, order } = sorter as SorterResult<PartnerDataOnboardingViewModel>;
    if (columnKey && order) {
      query.set('sortBy', getSortBy(columnKey.toString(), order));
    } else {
      query.set('sortBy', '');
    }
     
    navigate(
      getLink(
        'PartnerDataOnboarding',
        { ...params },
        {
          ...query,
          sortBy: query.get('sortBy'),
          p: paginationConfig.current,
          size: paginationConfig.pageSize,
          q: pagination.filter || '',
        }
      )
    );
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (
    option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const handlePartnerNameChange = (searchText: string) => {
      setPartnerName(searchText||'');
      let matchedPartner = partnerItems.filter((x:any)=>x.partnerName==searchText)[0];
      let partnerId =searchText!=undefined? matchedPartner?.partnerId||'':'';
      setPartnerId(partnerId);
      setPartnerType(matchedPartner?.partnerType||'');
      navigate(
        getLink(
          'PartnerDataOnboarding',
          { ...params },
          {
            ...query,
            p: DefaultPage,
            size: DefaultPageSize,
            q: `partnerId:*${partnerId ===undefined || partnerId ===null?'':partnerId?.replaceAll(" ", "\\ ")}* AND partnerType:*${matchedPartner?.partnerType ===undefined || matchedPartner?.partnerType ===null?'':matchedPartner?.partnerType}*`,
          }
        )
      );
    };
    
    const handlePartnerIdChange = (value: string) => {
      setPartnerId(value||'');
      let partner:any = partnerItems.filter((x:any)=>x.partnerId==value)[0];
      let partnerName =value!=undefined? partner?.partnerName||'':'';
      setPartnerName(partnerName);
      setPartnerType(partner?.partnerType||'');
      navigate(
        getLink(
          'PartnerDataOnboarding',
          { ...params },
          {
            ...query,
            p: DefaultPage,
            size: DefaultPageSize,
            q: `partnerId:*${value ===undefined || value ===null?'':value?.replaceAll(" ", "\\ ")}* AND partnerType:*${partner?.partnerType ===undefined || partner?.partnerType ===null?'':partner?.partnerType}*`,
          }
        )
      );
    };

    const handlePartnerTypeChange = (value: string) => { 
      setPartnerType(value||'');
      setPartnerName('');
      setPartnerId('');      
      navigate(
        getLink(
          'PartnerDataOnboarding',
          { ...params },
          {
            ...query,
            p: DefaultPage,
            size: DefaultPageSize,
            q: `partnerType:*${value ===undefined || value ===null?'':value}*`,
          }
        )
      );
    };

  const validateFileType = ({ type, name }: RcFile, allowedTypes?: string) => {
    if (!allowedTypes) {
      return true;
    }
    if (type) {
      return allowedTypes.includes(type);
    }
  };
  const uploadProps = useMemo(
    () => ({
      beforeUpload: (file: RcFile) => {
        const isAllowedType = validateFileType(file, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        if (!isAllowedType) {
          setFileList((state) => [...state]);
          message.error(`${file.name} is not excel file`);
          return false;
        }
        setFileList((state) => [...state, file]);
        return false;
      },
        progress: {
      strokeColor: {
      '0%': '#108ee9',
      '100%': '#87d068',
    },
    strokeWidth: 3,
    format: (percent:any) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    }),
    fileList
  );

  const onImport = () => {
    setUploading(true);
    setShowUploadList(false);
    const formData = new FormData();
    formData.append("file", fileList[0]);
    const request = createImportPartnerOnboarding(formData);
    request.unwrap().then((response:any) => {
      setUploading(false);
      setFileList([]);
      if(response){
        setIsImportCompleted(true);
        if(response.length>0 ){
          let total = response.length||0;
          let successResults = response.filter((x:PartnerDataOnboardingViewModel)=>x.result.toLowerCase()=='success');
          let successCount = successResults.length||0;
          let errorCount = (total-successCount);
          setErrorCount(errorCount);
          setResponseMessage(`Data import is completed along with `+successCount+ ' success and ' +errorCount +' errors. ');
          setPartnerResponse(response);
          const selectedData = successResults.map(({ partnerType, partnerId, partnerName }:any) => 
            ({ partnerType, partnerId, partnerName }));
          const uniquePartners:any=[];
          selectedData.forEach((selected:any) => {
            let mainPartner = pobFilters.data.find((item:any) => item.partnerId === selected.partnerId);
            if (!mainPartner) {
              // If newItem is not already in the array, create a new array with the updated item list
            let responsePartner = uniquePartners.find((item:any) => item.partnerId === selected.partnerId);
            if (!responsePartner) {
              uniquePartners.push(selected);
              console.log(selected);
            }              
            } else {
              // Handle case where newItem is already in the array (optional)
              console.log(`${selected} already exists in the list.`);
          }
          });
          const newPartners = [...pobFilters.data, ...uniquePartners];
          console.log('after' + newPartners.length);
          setPartnerItems(newPartners);
        }
        return;
      }
      else {
        setResponseMessage(`data is not imported due to some error.`);
        setIsImportCompleted(true);
      }      
    }).catch(function(error) {
      setResponseMessage('There is issue in columns order, data types or file is empty.');
      setUploading(false);
      setFileList([]);
      setIsImportCompleted(true);
      setPartnerResponse([]);
      console.log(error); // this is the part you need that catches 400 request
    });
  };
  const onExportResult = () =>  {
    let results = partnerResponse||[];
    if(results.length>0 ){
      const worksheet = XLSX.utils.json_to_sheet(results);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "POBExcelResult.xlsx");
    }
  };
  const onModalCancel = () =>  {
      setIsImportCompleted(false);
  };
  const onExport = () =>  {
    setDownLoading(true);
    let filterItems:any=[];
    selectedRowKeys.forEach(function(value) {
      //Loop through partners array checking each userObject
      pagedData?.results?.filter(function(partnerItem:PartnerDataOnboardingViewModel) {
        //If partnerItem's key:value is same as selectedRowKeys's key:value, they stay in partners array
        if(partnerItem.key === value){
          filterItems.push(partnerItem);}
      })
    });
    if(filterItems.length==0){
      let emptyJson:any={
        key: '',
        documentType:'',
        format:'',
        standard:'',
        version :'',
        transactionType :'',
        source :'',
        target :'',
        splittingRulePriority :'',
        endSystem :'',
        endSystemInstance :'',
        endSystemDescription :'',
        testFlag :'',
        customerName :'',
        customerType :'',
        customerQualifier :'',
        profileMapName :'',
        customerIdentifierType :'',
        customerIdentifierValue :'',
        sequence :'',
        partnerType :'',
        partnerName :'',
        partnerId :'',
        partnerIdentifierType :'',
        partnerIdentifierValue :'',
        carrierName :'',
        sourceSCAC :'',
        sourceSender :'',
        sourceReceiver :'',
        documentEncoding :'',
        transactionSetIdentifierCode :'',
        interchangeAckOverdueTime :'',
        interchangeAckOverdueTimeMinutes :'',
        interchangeAuthorizationInformation :'',
        interchangeAuthorizationInformationQualifier :'',
        interchangeSecurityInformationQualifier :'',
        interchangeSecurityInformation :'',
        interchangeSenderQualifier :'',
        interchangeSenderID :'',
        interchangeReceiverQualifier :'',
        interchangeReceiverID :'',
        interchangeStandard :'',
        interchangeVersionNumber :'',
        interchangeAcknowledgmentRequested :'',
        interchangeTestIndicator :'',
        groupAckOverdueTimeMinutes :'',
        groupAckOverdueTime :'',
        groupExpectAck :'',
        groupFunctionalIdentifierCode :'',
        groupSenderID :'',
        groupReceiverID :'',
        groupResponsibleAgencyCode :'',
        groupVersionNumber :'',
        transactionSCAC :'',
        elementSeparator :'',
        componentElementSeparator :'',
        segmentTerminator :'',
        fileFormat :'',
        isActive :'',
        protocol :'',
        serviceType :'',
        host :'',
        serviceName :'',
        processId :'',
        environmentKey :'',
        description :'',
        isPCEActive :'',
        folderPath :'',
        acknowledgmentOverride :'',
        isAckOverrideActive :'',

        ack_Exists :'',
        ack_TransactionType :'',
        ack_InterchangeSenderQualifier :'',
        ack_InterchangeSenderID :'',
        ack_InterchangeReceiverQualifier:'',
        ack_InterchangeReceiverID :'',
        ack_GroupSenderID :'',
        ack_GroupReceiverID :'',

        userName :'',
        result :''};        
        filterItems.push(emptyJson)
    }

    let results = filterItems;
      const worksheet = XLSX.utils.json_to_sheet(results);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "POBExcel.xlsx");
      setDownLoading(false);
      setSelectedRowKeys([]);
  };
  return (
    <div>
      <PageHeader
        title={
          <Title
            level={4}
            style={{
              marginBottom: 0,
              display: 'inline-block',
            }}
          >
            Partner Onboarding Export/Import
          </Title>
        }
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: params.tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: params.tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="Partner Onboarding Export/Import">
              <Link to={getLink('PartnerDataOnboarding', { tenantId: params.tenantId })}>Partner Onboarding Export/Import</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={
          <Space>
            <Button htmlType="button" onClick={onExport} loading={downLoading} icon={<DownloadOutlined />}>
            Download as Excel
          </Button>
            <Upload showUploadList={showUploadList} maxCount={1} {...uploadProps}>
              <Button disabled={uploading} icon={<UploadOutlined />}>Upload Excel</Button>
            </Upload>
            <Button
            type='primary'
            onClick={onImport}
            disabled={fileList.length === 0}
            loading={uploading}
          >
            {uploading ? 'Uploading' : 'Start Upload'}
          </Button>
          </Space>
        }
      >
        <Card>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={8}>
              <Space size="large">
                <div style={{minWidth: 150}} >
                Partner Type
                </div>
              <Select
                style={{minWidth: 150}}
                  showSearch
                  placeholder="Search..."
                  optionFilterProp="children"
                  filterOption={filterOption}
                  value={partnerType ===undefined || partnerType ===null?'':partnerType}
                  onChange={handlePartnerTypeChange}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  allowClear={true}
                  options={[{label:'Carrier', value:'Carrier'}, {label:'Customer', value:'Customer'}]}
                  />
                  </Space>
              </Col>
              <Col className="gutter-row" span={8}>
              <Space size="large">
                <div style={{minWidth: 150}} >
                  Partner Name
                </div>
              <Select
                style={{minWidth: 250}}
                  showSearch
                  placeholder="Search..."
                  optionFilterProp="children"
                  filterOption={filterOption}
                  value={partnerName ===undefined || partnerName ===null?'':partnerName}
                  onChange={handlePartnerNameChange}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  allowClear={true}
                  loading={isFetching}
                  options={partnerNames}
                  />
                  </Space>
              </Col>                          
              <Col className="gutter-row" span={8}>
              <Space size="large">
              <div style={{minWidth: 150}}>
                  Partner ID
                </div>
              <Select
              style={{minWidth: 150}}
                  showSearch
                  placeholder="Search..."
                  optionFilterProp="children"
                  value={partnerId ===undefined || partnerId ===null?'':partnerId}
                  filterOption={filterOption}
                  onChange={handlePartnerIdChange}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  allowClear={true}
                  loading={isFetching}
                  options={partnerIds}
                  />
                  </Space>
              </Col>              
          </Row>
        </Card>

        <Card>
          {error?.status && (
            <Alert message="Error" description={error.data} type="error" showIcon />
          )}          
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Table scroll={{ x: true }}
              dataSource={pagedData.results}
              onChange={handleTableChange}
              pagination={{
                ...pagination,
                total: pagedData.totalCount,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ["10", "20", "50","100"],
                showSizeChanger: true,
                locale: { items_per_page: " / Page" }
              }}
              rowSelection={rowSelection}
              columns={partneronboardingTable.columns}
              loading={isFetching}
            />
          </Space>
        </Card>
        {isImportCompleted?<Card>
        <Modal
        title=""
        centered
        open
        onCancel={() => onModalCancel() }
        footer={null}
        width={1000}
      >
        {partnerResponse.length>0?
        <p>
        <Alert
        message=""
        description={responseMessage}
        type={errorCount>0?'error':'success'}
      />
      <a onClick={onExportResult}  >Please click here to dowload results. </a>
      </p>:
      <Alert
        message=""
        description={responseMessage}
        type="error"
      />
        }
      </Modal>
        </Card>:<></>}
      </PageHeader>
    </div>
  );
}
