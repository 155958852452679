import PartnersScreen from './screens/partners-screen';
import PartnerEditScreen from './screens/partner-edit-screen';
import { RouteDefinition } from '../../core/route';

export const partnerRoutes: RouteDefinition[] = [
  {
    id: 'PartnerEdit',
    title: 'Partner',
    path: 'partners/:partnerId',
    element: <PartnerEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'PartnerList',
    title: 'Partners',
    path: 'partners',
    element: <PartnersScreen />,
    showInNavigation: true,
  },
];
