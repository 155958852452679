import AcknowledgmentOverrideReportEmailFiltersScreen from './screens/acknowledgmentoverridereportemailfilters-screen';
import AcknowledgmentOverrideReportEmailFilterEditScreen from './screens/acknowledgmentoverridereportemailfilter-edit-screen';
import { RouteDefinition } from '../../core/route';

export const acknowledgmentoverridereportemailfilterRoutes: RouteDefinition[] = [
  {
    id: 'AcknowledgmentOverrideReportEmailFilterEdit',
    title: 'AcknowledgementReconciliation Report Filter',
    path: 'acknowledgmentoverridereportemailfilters/:Id/:acknowledgementReconciliationReportId/:acknowledgementReconciliationReportName',
    element: <AcknowledgmentOverrideReportEmailFilterEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'AcknowledgmentOverrideReportEmailFilterList',
    title: 'AcknowledgementReconciliation Report Filters',
    path: 'acknowledgmentoverridereportemailfilters',
    element: <AcknowledgmentOverrideReportEmailFiltersScreen />,
    showInNavigation: false,
  },
];
