import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store';
import { logout } from '../../auth/auth-slice';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_API_URL,
  prepareHeaders(headers, { getState }) {
    const token:any = (getState() as RootState).auth.token;
    let isTokenValid =  !(Date.now() >= (JSON.parse(atob(token.split('.')[1]))).exp * 1000)
    if (token && isTokenValid) {
      const decodedToken = JSON.parse(atob(token.split('.')[1])); // Decode JWT token
      // console.log('tokenExpiresAt', decodedToken.exp);
      // console.log('user', JSON.stringify(decodedToken));
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRedirect: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  
  let result = await baseQuery(args, api, extraOptions);
  
  if (result.error && result.error.status === 401) {
    api.dispatch(logout());
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: 'api',
  tagTypes: ['Partner', 'Monitor', 'TelemetryEvent','Customer','EndSystem','ProfileMap','SourceType'
  ,'TargetType','TransactionType','Carrier','ConnectionEndPoint','PartnerEDIX12','Carriers','CodeDecodeType','CodeDecodeValue'
  ,'CodeDecodeMap','AcknowledgmentOverride','PartnerConnectionEndpoint' ,'CarrierOnboarding','DocumentType' ,'RoutingRule','CustomerIdentifierType'
  ,'OutboundTransaction','PartnershipInformation','PartnerContactInformation','PartnerRating', 'AuditLog','CarrierOnboardingExcelImport','PartnerControlNumber','AcknowledgmentOverrideReportEmail','AcknowledgmentOverrideReportEmailFilter'],
  baseQuery: baseQueryWithRedirect,
  refetchOnReconnect: true,
  endpoints(builder) {
    return {};
  },
});