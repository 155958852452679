import AcknowledgmentOverrideReportEmailsScreen from './screens/acknowledgmentoverridereportemails-screen';
import AcknowledgmentOverrideReportEmailEditScreen from './screens/acknowledgmentoverridereportemail-edit-screen';
import { RouteDefinition } from '../../core/route';

export const acknowledgmentoverridereportemailRoutes: RouteDefinition[] = [
  {
    id: 'AcknowledgmentOverrideReportEmailEdit',
    title: 'Acknowledgement Reconciliation Report',
    path: 'acknowledgmentoverridereportemails/:Id',
    element: <AcknowledgmentOverrideReportEmailEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'AcknowledgmentOverrideReportEmailList',
    title: 'Acknowledgement Reconciliation Report',
    path: 'acknowledgmentoverridereportemails',
    element: <AcknowledgmentOverrideReportEmailsScreen />,
    showInNavigation: true,
  },
];
