import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  useCreatePartnerContactInformationMutation,
  useFetchSinglePartnerContactInformationQuery,
  useUpdatePartnerContactInformationMutation,
} from '../partnercontactinformation-api';

import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip,
  Row,
  Col
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined,QuestionCircleOutlined } from '@ant-design/icons';
import { PartnerContactInformationViewModel} from '../../api/models';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { getLink } from '../../../App-router';
import { DefaultTenantId } from '../../../core/defaults';
const { Option } = Select;

const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError =>
  error ? 'status' in error : false;

const getErrorMessage = (error: any): string | undefined => error.data;

interface PartnerContactInformationScreenParams {
Id?: string | undefined;
tenantId?: string | undefined;
  [key: string]: string | undefined;
}

export default function PartnerContactInformationEditScreen(props: any) {
  const { Id, tenantId = DefaultTenantId}: PartnerContactInformationScreenParams =
    useParams<PartnerContactInformationScreenParams>();

  const isNewPartnerContactInformation = Id === 'new';

  const { data, isFetching } = useFetchSinglePartnerContactInformationQuery(
    {
      Id: Number(Id),
    },
    { skip: isNewPartnerContactInformation }
  );

  const [createPartnerContactInformation, { isLoading: isCreating, error: createError }] = useCreatePartnerContactInformationMutation();
  const [updatePartnerContactInformation, { isLoading: isUpdating, error: updateError }] = useUpdatePartnerContactInformationMutation();  
  
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [formPartnerContactInformation] = Form.useForm();

  React.useEffect(() => {
    formPartnerContactInformation.resetFields();
  }, [data, formPartnerContactInformation]);


  const onFinish = (values: any | PartnerContactInformationViewModel) => {
    const requestData = {
      partnercontactinformation: {
            id: values.id === undefined?0:values.id,
            partnerInformationId:Number(localStorage.getItem('partnerInformationId')),
            contactType: values.contactType||null,
            name: values.name||null,
            email: values.email||null,
            phone: values.phone||null,            
            note: values.note||null,
            primary: values.primary ===undefined?false:values.primary,
            updatedBy:sessionStorage.getItem('userEmail')||'',
            enteredBy:isNewPartnerContactInformation?sessionStorage.getItem('userEmail')||'':'',
      },
    };

    const request = isNewPartnerContactInformation ? createPartnerContactInformation(requestData) : updatePartnerContactInformation(requestData);
    request.unwrap().then((response) => {
      if(response?.id ===-200){
        message.error(
          `This combination already exists. Please try other values.`
        );
        return;
      }
      message.success(
        `Partner Contact Information is ${isNewPartnerContactInformation ? 'created' : 'saved'}`
      );
      navigate(getLink('PartnershipInformationEdit', { tenantId: tenantId,
        Id: localStorage.getItem('partnerInformationId')}))
    });
  };
 // Filter `option.label` match the user type `input`
 const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (
  option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };
  const [disabledSave, setDisabledSave] = useState(true);
  
  const handleFormChange = () => {
  const hasErrors = formPartnerContactInformation.getFieldsError().some(({ errors }) => errors.length);
  setDisabledSave(hasErrors);
  }
  return (
    <div>
      <PageHeader
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={getLink('PartnershipInformationList', { ...props, tenantId })}>Partner Information</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={getLink('PartnershipInformationEdit', { ...props, tenantId, Id:Number(localStorage.getItem('partnerInformationId')||'0') })}>{localStorage.getItem('carrierName')||''}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{isNewPartnerContactInformation ? 'New Partner Contact Information' : data?.id}</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {isFetchBaseQueryErrorType(createError) && (
          <Alert message="Error" description={getErrorMessage(createError)} type="error" showIcon />
        )}
        {isFetchBaseQueryErrorType(updateError) && (
          <Alert message="Error" description={getErrorMessage(updateError)} type="error" showIcon />
        )}
        <Card
          title={
            <Title
              level={4}
              style={{
                marginBottom: 0,
                display: 'inline-block',
              }}
            >
              {isNewPartnerContactInformation ? 'New Partner Contact Information' : 'Edit Partner Contact Information'}
            </Title>
          }
        >
          <Spin spinning={isFetching || isCreating || isUpdating}>
          <Form 
                      {...layout}
                      form={formPartnerContactInformation}
                      name="pci-hooks"
                      initialValues={data}
                      onFinish={onFinish}
                      onFieldsChange={handleFormChange}   
                    > 
                      <Form.Item name="id" label="id" hidden>
                        <Input data-testid="id"  />
                      </Form.Item>
                      <Form.Item name="contactType" label="Contact Type" rules={[{ required: true }]}>
                      <Select
                          showSearch
                          placeholder="Search..."
                          optionFilterProp="children"
                          data-testid="contactType"
                          filterOption={filterOption}
                          options={[{label:'Support', value:'Support'},{label:'Analyst', value:'Analyst'},{label:'Other', value:'Other'}]}
                        />
                      </Form.Item>        
                      <Form.Item name="name" label="Name">
                      <Input placeholder='Please enter valid name like Derik Gertken' data-testid="name" suffix={
                        <Tooltip title="Please enter valid name like Derik Gertken ">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
                      </Form.Item>
                      <Form.Item name="email" label="Email">
                      <Input placeholder='Please enter valid mail like edisupport@ats-inc.com ' data-testid="email" suffix={
                        <Tooltip title="Please enter valid mail like edisupport@ats-inc.com ">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
                      </Form.Item>
                      <Form.Item name="phone" label="Phone">
                      <Input placeholder='Please enter valid phone number like 9717088378.' data-testid="phone" suffix={
                        <Tooltip title="Please enter valid phone number like 9717088378.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
                      </Form.Item>
                      <Form.Item name="note" label="Note">
                      <Input placeholder='Please enter any notes' data-testid="phone" suffix={
                        <Tooltip title="Please enter any notes ">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
                      </Form.Item>
                      <Form.Item name="primary" label="Primary" initialValue={data?.primary}>
                      <Switch defaultChecked={data?.primary} defaultValue={data?.primary} />
                      </Form.Item>
                      <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                      <Space>
                        <Button type="primary" htmlType="submit" disabled={disabledSave && !isNewPartnerContactInformation}>
                          {isNewPartnerContactInformation ? 'Add Partner Contact Information' : 'Save Partner Contact Information'}
                        </Button>
                        <Button htmlType="button" onClick={goBack}>
                          Cancel
                        </Button>
                      </Space>
                    </Form.Item>                    
                    </Form>
          </Spin>
        </Card>
      </PageHeader>
    </div>
  );
}
