import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  PartnerRatingListRequest,
  useFetchPartnerRatingsQuery,
} from '../partnerrating-api';
import AuditLogsScreen from '../../../components/common/auditLogs';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Button,
  Card,
  Space,
  Table,
  Menu,
  Dropdown,
  Rate
} from 'antd';
import { EditOutlined,  MoreOutlined } from '@ant-design/icons';
import {  PaginatedResult, PartnerRatingListViewModel } from '../../api/models';
import { DefaultPage, DefaultPageSize } from '../../../core/defaults';
import Title from 'antd/lib/typography/Title';
import { getLink } from '../../../App-router';
import { TableState } from '../../../components/table/model';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function PartnerRatingsScreen(props: any) {
  const query = useQuery();
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const [overallRating, setOverallRating] = useState<number>(props?.overallRating);
  const [showAuditLogs, setShowAuditLogs] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<number>(0);
  // sessionStorage.setItem('overallRating', props?.overallRating);
  // const overallRating = props.overallRating;
  // console.log(overallRating);
  const PartnerRatingTable: TableState = {
    columns: [
      {
        title: 'Responsiveness',
        dataIndex: '',
        key: 'responsiveness',
        render: (PartnerRating: PartnerRatingListViewModel) => (
          <>
              <Rate disabled={true} allowHalf defaultValue={PartnerRating.responsiveness} />
          </>
        ),
      },
      {
        title: 'Documentation Clarity',
        dataIndex: '',
        key: 'documentationClarity',
        render: (PartnerRating: PartnerRatingListViewModel) => (
          <>
            <Rate disabled={true} allowHalf defaultValue={PartnerRating.documentationClarity} />
          </>
        ),
      },      
      {
        title: 'Integration Efficiency',
        dataIndex: '',
        key: 'integrationCompletionTime',
        render: (PartnerRating: PartnerRatingListViewModel) => (
          <>
          <Rate disabled={true} allowHalf defaultValue={PartnerRating.integrationEfficiency} />
          </>
        ),
      },
      {
        title: 'Cooperation and Support',
        dataIndex: '',
        key: 'cooperationAndSupport',
        render: (PartnerRating: PartnerRatingListViewModel) => (
          <>
          <Rate disabled={true} allowHalf defaultValue={PartnerRating.cooperationAndSupport} />
          </>
        ),
      },
      {
        title: 'Integration Completion Time',
        dataIndex: '',
        key: 'overallOnboardingDuration',
        fixed:'right',
        render: (PartnerRating: PartnerRatingListViewModel) => (
          <>
          <Rate disabled={true} allowHalf defaultValue={PartnerRating.integrationCompletionTime} />
          </>
        ),
      },
      {
        title: 'Overall Rating',
        dataIndex: '',
        key: 'overallRating',
        fixed:'right',
        render: (PartnerRating: PartnerRatingListViewModel) => (
          <>
          <Rate disabled={true} allowHalf defaultValue={PartnerRating.overallRating} />
          </>
        ),
      },
      {
        title: 'Notes',
        dataIndex: '',
        key: 'notes',
        render: (PartnerRating: PartnerRatingListViewModel) => (
          <>
          {PartnerRating.notes}
          </>
        ),
      },
      {
        title: 'Entered By',
        dataIndex: '',
        key: 'enteredBy',
        render: (PartnerRating: PartnerRatingListViewModel) => (
          <>
          {PartnerRating.enteredBy}
          </>
        ),
      },
      {
        title: 'Actions',
        key: 'action',
        dataIndex: '',
        fixed:'right',
        render: (PartnerRating: PartnerRatingListViewModel) => (
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            overlay={
              <Menu style={{ padding: '8px 8px' }}>
                <Menu.Item icon={<EditOutlined />} key="1">
                  <Link
                    to={getLink('PartnerRatingEdit', {
                      tenantId: params.tenantId,
                      Id: PartnerRating?.id,
                    })}
                  >
                    View
                  </Link>
                </Menu.Item>
                {/* <Menu.Item icon={<InfoCircleOutlined />} key="3">
                <Link to="#" onClick={()=>setShowAuditLogs(true)}>
                    Audit Logs
                  </Link>
                </Menu.Item> */}
              </Menu>
            }
          >
            <MoreOutlined />
          </Dropdown>
        ),
      },
    ],
  };

  const getCurrentState = (): PartnerRatingListRequest => ({
    current: Number(query.get('p')) || DefaultPage,
    pageSize: Number(query.get('size')) || DefaultPageSize,
    filter: '',
    sortBy: query.get('sortBy') || 'EnteredByDate Desc',
    partnerInformationId: props.partnershipId
  });

  const [pagination, setPagination] = useState<PartnerRatingListRequest>(getCurrentState());

  const {
    data: pagedData = {},
    isFetching,
    error,
  }: {
    data?: PaginatedResult<PartnerRatingListViewModel> | undefined;
    isFetching?: boolean | undefined;
    error?: any;
  } = useFetchPartnerRatingsQuery(pagination);

  useEffect(() => {   
    setPagination(getCurrentState());
    // setOverallRating(props?.overallRating);
    // console.log(overallRating);
   
  }, [params,props]);

  const onCreateNew = useCallback(
    () => navigate(getLink('PartnerRatingEdit', { ...params, Id: 'new' })),
    [params, navigate]
  );
  
  return (
    <div>
      <PageHeader
        extra={
          <Button htmlType="button" data-testid={'create-new-PartnerRating'} onClick={onCreateNew}>
            New Partner Rating
          </Button>
        }
        title={
          <Title
            level={4}
            style={{
              marginBottom: 0,
              display: 'inline-block',
            }}
          >
            Partner Rating
          </Title>
        }
      >
        <Card extra={
          <Space>
            <b>Rating</b>
          <Rate disabled={true} allowHalf defaultValue={Number(overallRating)} />
          </Space>
        }>
          {error?.status && (
            <Alert message="Error" description={error.data} type="error" showIcon />
          )}
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Table scroll={{ x: true }}
              dataSource={pagedData.results}
              columns={PartnerRatingTable.columns}
              loading={isFetching}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => { 
                    setEntityId(record?.id||0);
                  },
                };
              }}
            />
          </Space>
        </Card>      
        {showAuditLogs?<AuditLogsScreen entityId={entityId} entityName={'PartnerRating'} showAuditLogs={setShowAuditLogs}/>:<></>}  
      </PageHeader>
    </div>
  );
}
