import { apiSlice } from '../api/api-slice';
import { ProfileMapViewModel, PaginatedResult } from '../api/models';

export interface ProfileMapListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
}

export interface EditProfileMapRequest {
  profilemap: ProfileMapViewModel;
}

export interface ProfileMapRequest {
  Id: number;
}

export interface DeleteProfileMapRequest {
  Id: number;
}

export const profilemapsApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchProfileMaps: builder.query<PaginatedResult<ProfileMapViewModel>, ProfileMapListRequest>({
        query({ current, pageSize, filter, sortBy }) {
          return `/profilemaps?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'ProfileMap',
          ...(data?.results?.map(({ id }) => ({ type: 'ProfileMap' as const, id: `${id}` })) || [])
        ] 
      }),
      fetchSingleProfileMap: builder.query<ProfileMapViewModel, ProfileMapRequest>({
        query({ Id }) {
          return `/profilemaps/${Id}`;
        },
        providesTags: (_data, _error, arg) => [{ type: 'ProfileMap', id: `${arg.Id}` }]
      }),
      createProfileMap: builder.mutation<ProfileMapViewModel, EditProfileMapRequest>({
        query: ({ profilemap }) => {
          return {
            url: `/profilemaps`,
            method: 'POST',
            body: profilemap,
          };
        },
        invalidatesTags: ['ProfileMap']
      }),
      updateProfileMap: builder.mutation<ProfileMapViewModel, EditProfileMapRequest>({
        query: ({ profilemap }) => {
          return {
            url: `/profilemaps/${profilemap.id}`,
            method: 'PUT',
            body: profilemap,
          };
        },
        invalidatesTags: (_data, _error, arg) => [{ type: 'ProfileMap', id: `${arg.profilemap.id}` }]
      }),
      deleteProfileMap: builder.mutation<void, DeleteProfileMapRequest>({
        query: ({ Id }) => ({
          url: `/profilemaps/${Id}`,
          method: 'DELETE',
          body: {},
        }),
        invalidatesTags: (_data, _error, arg) => ['ProfileMap']
      })
    };
  },
});

export const {
  useFetchProfileMapsQuery,
  useFetchSingleProfileMapQuery,
  useCreateProfileMapMutation,
  useUpdateProfileMapMutation,
  useDeleteProfileMapMutation,
} = profilemapsApi;
