import { apiSlice } from "../api/api-slice";
import { PaginatedResult, TelemetryEventViewModel } from '../api/models';

export interface TablePageRequest {
    pageSize: number;
    filter?: string | null;
    current: number;
    sortBy?: string | null;
}

export const telemetryEventsApi = apiSlice.injectEndpoints({
    endpoints(builder) {
        return {
            fetchTelemetryEvents: builder.query<PaginatedResult<TelemetryEventViewModel>, TablePageRequest>({
                query({ current, pageSize, filter, sortBy }) {
                    return `/telemetryEvents?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
                },
                providesTags: (data, _error, arg) =>
                    [
                        'TelemetryEvent',
                        ...(data?.results?.map(({ telemetryEventId }) => ({ type: 'TelemetryEvent' as const, id: telemetryEventId })) || [])
                    ]
            }),
        }
    }
});

export const { 
    useFetchTelemetryEventsQuery, 
  } = telemetryEventsApi;