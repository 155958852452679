import { RouteDefinition } from '../../core/route';
import HomeScreen from './screens/home-screen'
export const homeRoutes: RouteDefinition[] = [
  {
    id: 'home',
    title: 'Dashboard',
    path: 'home',
    element: <HomeScreen />,
    showInNavigation: false,
  },
];
