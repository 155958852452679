import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PartnerContactInformationsScreen from '../../partnershipinformationcontact/screens/partnercontactinformations-screen'
import PartnerRatingsScreen from '../../partnershipinformationrating/screens/partnerratings-screen'

import {
  useCreatePartnershipInformationMutation,
  useFetchSinglePartnershipInformationQuery,
  useUpdatePartnershipInformationMutation,
} from '../partnershipinformation-api';

import {
  useCreatePartnerContactInformationMutation,
  useUpdatePartnerContactInformationMutation,
} from '../../partnershipinformationcontact/partnercontactinformation-api';

import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Space,
  Spin,
  Tooltip,
  Switch,
  Descriptions
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined,QuestionCircleOutlined } from '@ant-design/icons';
import { PartnershipInformationViewModel } from '../../api/models';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { getLink } from '../../../App-router';
import { DefaultTenantId } from '../../../core/defaults';
import { ConvertUTCtoCDT } from '../../../core/utils';
const { Option } = Select;

const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError =>
  error ? 'status' in error : false;

const getErrorMessage = (error: any): string | undefined => error.data;

interface PartnershipInformationScreenParams {
Id?: string | undefined;
tenantId?: string | undefined;
  [key: string]: string | undefined;
}

export default function PartnershipInformationEditScreen(props: any) {
  const { Id, tenantId = DefaultTenantId}: PartnershipInformationScreenParams =
    useParams<PartnershipInformationScreenParams>();

  const isNewPartnershipInformation = Id === 'new';
  const { data, isFetching } = useFetchSinglePartnershipInformationQuery(
    {
      Id: Number(Id),
    },
    { skip: isNewPartnershipInformation }
  );

  const [createPartnershipInformation, { isLoading: isCreating, error: createError }] = useCreatePartnershipInformationMutation();
  const [updatePartnershipInformation, { isLoading: isUpdating, error: updateError }] = useUpdatePartnershipInformationMutation();
  const [createPartnerContactInformation, { isLoading: isCreatingContact, error: createContactError }] = useCreatePartnerContactInformationMutation();
  const [updatePartnerContactInformation, { isLoading: isUpdatingContact, error: updateContactError }] = useUpdatePartnerContactInformationMutation();
  const [edit, setEdit] = useState<boolean>(false);
  const navigate = useNavigate();

  const [form] = Form.useForm();

  React.useEffect(() => {
    // form.resetFields();
    localStorage.setItem('carrierName', data?.name.trimStart()||'');
  }, [data, form, props]);

  const onFinish = (values: any | PartnershipInformationViewModel) => {
    const requestData = {
      partnershipinformation: {
        id: isNewPartnershipInformation ? 0 : Number(Id),
        name: values.name,
        type: values.type,
        partnerId: values.partnerId,
        identifierType: values.identifierType || 'SCAC',
        identifierValue: values.identifierValue|| '',
        partner_VAN: values.partner_VAN || null,
        notes: values.notes|| null,
        updatedBy:sessionStorage.getItem('userEmail')||'',
        enteredBy:isNewPartnershipInformation?sessionStorage.getItem('userEmail')||'':'',
      },
    };

    const request = isNewPartnershipInformation ? createPartnershipInformation(requestData) : updatePartnershipInformation(requestData);
    request.unwrap().then((response) => {
       
      let id = response?.id ||0;
      if(id ===-200){
        message.error(
          `This combination already exists. Please try other values.`
        );
        return;
      }
      if(isNewPartnershipInformation){
      if(id >= 0){
        // debugger;
        const requestContactData = {
          partnercontactinformation: {
            partnerInformationId:id,
            contactType: values.contactType,
            name: values.contactName||null,
            email: values.contactEmail||null,
            phone: values.contactPhone||null,            
            note: values.note||null,
            primary: values.primary === undefined?false:values.primary,
            updatedBy:sessionStorage.getItem('userEmail')||'',
            enteredBy:isNewPartnershipInformation?sessionStorage.getItem('userEmail')||'':'',
          },
        };
    
        const requestContact = isNewPartnershipInformation ? createPartnerContactInformation(requestContactData) : updatePartnerContactInformation(requestContactData);
        requestContact.unwrap().then((responseContact) => {
           
          let contactId = responseContact?.id ||0;
          if(contactId ===-200){
            message.error(
              `This contact already exists. Please try other values.`
            );
            return;
          }
          message.success(
            `Partner Information is ${isNewPartnershipInformation ? 'created' : 'saved'}`
          );
          navigate(
            getLink('PartnershipInformationEdit', {
              ...props,
              tenantId: tenantId,
              Id: id
            })
          );
          setEdit(false);
        });
      }
      else{
        message.error(
          `Partner Information  ${isNewPartnershipInformation ? 'is not created' : 'is not saved'}`
        );
      }
      }
      else if(id>0){
          message.success(
            `Partner Information ${isNewPartnershipInformation ? 'is created' : 'is saved'}`
          );
      }
      else{
        message.error(
          `Partner Information ${isNewPartnershipInformation ? 'is not created' : 'is not saved'}`
        );
      }
      navigate(getLink('PartnershipInformationEdit', { tenantId: tenantId, Id:id}))
      setEdit(false);
    });
  };
  const [disabledSave, setDisabledSave] = useState(true);
  
  const handleFormChange = () => {
  const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
  setDisabledSave(hasErrors);
  }
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };

  const handleCancel = () => {
    if(isNewPartnershipInformation && edit){
    navigate(
      getLink('PartnershipInformationList', {
        ...props,
        tenantId: tenantId,
      })
    );
    }
    else if(edit){
      setEdit(false);
    }
    else if(isNewPartnershipInformation){
      navigate(
        getLink('PartnershipInformationList', {
          ...props,
          tenantId: tenantId,
        })
      );
    }
  };

  return (
    <div>
      <PageHeader
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={getLink('PartnershipInformationList', { ...props, tenantId })}>Partner Information</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{isNewPartnershipInformation ? 'New Partner Information' : data?.name}</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {isFetchBaseQueryErrorType(createError) && (
          <Alert message="Error" description={getErrorMessage(createError)} type="error" showIcon />
        )}
        {isFetchBaseQueryErrorType(updateError) && (
          <Alert message="Error" description={getErrorMessage(updateError)} type="error" showIcon />
        )}
        <Card
      
      extra={
        <Space>
        <Button htmlType="button" type='primary' data-testid={'create-new-997rule'} onClick={()=>{setEdit(true);}} disabled={isNewPartnershipInformation || edit}>
          Edit 
        </Button>
        </Space>
      }
          title={
            <Title
              level={4}
              style={{
                marginBottom: 0,
                display: 'inline-block',
              }}
            >
              {isNewPartnershipInformation ? 'New Partner Information' : edit?'Edit Partner Information':'Partner Information'}
            </Title>
          }
        >
          <Spin spinning={isFetching || isCreating || isUpdating}>
            {isNewPartnershipInformation || edit?
            <Form
              {...layout}
              form={form}
              name="control-hooks"
              initialValues={data}
              onFinish={onFinish}
              onFieldsChange={handleFormChange}   
            >
              <Form.Item name="type" label="Type" rules={[{ required: true }]} initialValue={'Carrier'}>
                  <Select placeholder='Carrier' defaultValue="Carrier"
                  options={[{label:'Carrier', value:'Carrier'}, {label:'Customer', value:'Customer', disabled:true}]} />
              </Form.Item>

              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input placeholder='Please provide carrier/customer name valid upto 100 characters.' data-testid="name" maxLength={100} suffix={
                        <Tooltip title="Please provide carrier/customer name valid upto 100 characters.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              
              <Form.Item name="partnerId" label="Partner Id" rules={[{ required: true }]}>
                <Input placeholder='Please provide the value for the partner Id.' data-testid="partnerId" maxLength={100} suffix={
                        <Tooltip title="Please provide the value for partner Id like AVRT, AACT or SMBH.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              <Form.Item name="identifierType" label="IdentifierType" initialValue={'SCAC'}>
                <Input placeholder='Please provide valid identifer type' defaultValue={'SCAC'} data-testid="identifierType" maxLength={100} suffix={
                        <Tooltip title="Please provide identifer type for Carrier/Customer. Allowed values are SCAC/ISA08/ISA06.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              <Form.Item name="identifierValue" label="IdentifierValue">
                <Input placeholder='Please provide the value for the identifier value' data-testid="identifierValue" maxLength={100} suffix={
                        <Tooltip title="Please provide the value for the identifier value.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              
              <Form.Item name="partner_VAN" label="Partner/VAN">
                <Input placeholder='Please provide Partner/VAN.' data-testid="identifierType" maxLength={500} suffix={
                        <Tooltip title="Please provide Partner/VAN.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              <Form.Item name="notes" label="Notes">
                <Input placeholder='Please provide notes.' showCount data-testid="identifierValue" maxLength={1000} suffix={
                        <Tooltip title="Please provide notes.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              <Form.Item name="contactType" label="Contact Type" rules={[{ required: isNewPartnershipInformation }]} hidden={!isNewPartnershipInformation}>
                      <Select
                          placeholder="Search..."
                          optionFilterProp="children"
                          data-testid="contactType"
                          options={[{label:'Support', value:'Support'},{label:'Analyst', value:'Analyst'},{label:'Other', value:'Other'}]}
                        />
                      </Form.Item>        
                      <Form.Item name="contactName" label="Contact Name"  hidden={!isNewPartnershipInformation}>
                      <Input placeholder='Please enter valid name like Derik Gertken' data-testid="name" suffix={
                        <Tooltip title="Please enter valid name like Derik Gertken ">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
                      </Form.Item>
                      <Form.Item name="contactEmail" label="Contact Email" rules={[{ required: isNewPartnershipInformation }]} hidden={!isNewPartnershipInformation}>
                      <Input placeholder='Please enter valid mail like edisupport@ats-inc.com' data-testid="email" suffix={
                        <Tooltip title="Please enter valid mail like edisupport@ats-inc.com ">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
                      </Form.Item>
                      <Form.Item name="contactPhone" label="Contact Phone" hidden={!isNewPartnershipInformation}>
                      <Input placeholder='Please enter valid phone number like 9717088378' data-testid="phone" suffix={
                        <Tooltip title="Please enter valid phone number like 9717088378.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
                      </Form.Item>
                      <Form.Item name="primary" label="Primary" initialValue={true}  hidden={!isNewPartnershipInformation}>
                      <Switch defaultChecked={true} defaultValue={true} />
                      </Form.Item>
              
              <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                <Space>
                  <Button type="primary" htmlType="submit" disabled={disabledSave && !isNewPartnershipInformation}>
                    {isNewPartnershipInformation ? 'Add Partner Information' : 'Save Partner Information'}
                  </Button>
                  <Button htmlType="button" onClick={(handleCancel)}>
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </Form>
            :<Descriptions title="">
            <Descriptions.Item label="Type">{data?.type}</Descriptions.Item>
            <Descriptions.Item label="Name">{data?.name}</Descriptions.Item>
            <Descriptions.Item label="PartnerId">{data?.partnerId}</Descriptions.Item>
            <Descriptions.Item label="Identifier Type">{data?.identifierType}</Descriptions.Item>
            <Descriptions.Item label="Identifier Value">{data?.identifierValue}</Descriptions.Item>            
            <Descriptions.Item label="Partner/Van">{data?.partner_VAN}</Descriptions.Item>            
            <Descriptions.Item label="Notes">{data?.notes}</Descriptions.Item>            
            <Descriptions.Item label="Entered By">{data?.enteredBy}</Descriptions.Item>                     
            <Descriptions.Item label="Updated By">{data?.updatedBy}</Descriptions.Item>            
            <Descriptions.Item label="Entered By Date">{ConvertUTCtoCDT(data?.enteredByDate||'')}</Descriptions.Item>   
            <Descriptions.Item label="Updated By Date">{ConvertUTCtoCDT(data?.updateByDate||'')}</Descriptions.Item>
          </Descriptions>}
          </Spin>
        </Card>
        {!isNewPartnershipInformation && !isFetching?
        <Card>
          <PartnerContactInformationsScreen partnershipId = {isNewPartnershipInformation?0:Number(Id)} />
          <PartnerRatingsScreen partnershipId = {isNewPartnershipInformation?0:Number(Id)} overallRating={data?.partnerRating} />
        </Card>:<></>
        }
      </PageHeader>
    </div>
  );
}
