import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import { store } from './store';
import './styles/main.scss';

import App from './App';
import ErrorBoundary  from './components/common/ErrorBoundary';
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
        authorizationParams={{
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'read:current_user update:current_user_metadata',
          redirect_uri: window.location.origin,
        }}
      > <ErrorBoundary>
        <App />
        </ErrorBoundary>

      </Auth0Provider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
