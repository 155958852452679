import React from 'react';
import { AlertViewModel } from "../../api/models";

const getAlertDuration = (alert: AlertViewModel) => {
    var diff = '';

    const startDate = new Date(alert.startDt);
    const closeDate = alert.closedDt ? new Date(alert.closedDt) : new Date();
    
    const duration = Math.abs(startDate.getTime() - closeDate.getTime());

    const days = Math.floor(duration / (1000 * 60 * 60 * 24));
    const hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));

    if (days > 0) diff = `${days}d ${hours}h ${minutes}m`;
    else if (hours > 0) diff = `${hours}h ${minutes}m`;
    else if (minutes > 0) diff = `${minutes}m`;    
    
    return (<>{diff}</>);
}


export default getAlertDuration;