import { apiSlice } from '../api/api-slice';
import { PartnerDataOnboardingViewModel, PaginatedResult } from '../api/models';


export interface PartnerDataOnboardingListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
}

export interface PartnerDataOnboardingFiltersRequest {
  partnerName?: string | null;
  partnerId?: string | null;
  partnerType?: string | null;
}

export interface PartnerDataOnboardingRequest {
  Id: number;
}

export interface DeletePartnerDataOnboardingRequest {
  Id: number;
}


export const partnerdataonboardingApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {      
      importPartnerOnboarding: builder.mutation<FormData, FormData>({
        query: (file:FormData) => {
          return {
            url: `/CarrierOnboarding`,
            method: 'POST',
            body: file,
          };
        },
        invalidatesTags: ['CarrierOnboardingExcelImport']
      }),
      fetchPartnerOnboarding: builder.query<PaginatedResult<PartnerDataOnboardingViewModel>, PartnerDataOnboardingListRequest>({
        query({ current, pageSize, filter, sortBy }) {
          return `/CarrierOnboarding?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'CarrierOnboarding',
          ...(data?.results?.map(({ partnerName }) => ({ type: 'CarrierOnboarding' as const, partnerName: `${partnerName}` })) || [])
        ] 
      }),
      fetchPartnerOnboardingFilters: builder.query({
        query() {
          return `/CarrierOnboarding/filters`;
        },
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'CarrierOnboarding'
        ]
      }),
    };
  },
});

export const {
  useImportPartnerOnboardingMutation,
  useFetchPartnerOnboardingQuery,
  useFetchPartnerOnboardingFiltersQuery,
} = partnerdataonboardingApi;
