import { apiSlice } from '../api/api-slice';
import { EndSystemViewModel, PaginatedResult } from '../api/models';

export interface EndSystemListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
}

export interface EditEndSystemRequest {
  endsystem: EndSystemViewModel;
}

export interface EndSystemRequest {
  Id: number;
}

export interface DeleteEndSystemRequest {
  Id: number;
}

export const endsystemsApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchEndSystems: builder.query<PaginatedResult<EndSystemViewModel>, EndSystemListRequest>({
        query({ current, pageSize, filter, sortBy }) {
          return `/endsystems?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        forceRefetch({ currentArg, previousArg }) {
         return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'EndSystem',
          ...(data?.results?.map(({ id }) => ({ type: 'EndSystem' as const, id: `${id}` })) || [])
        ] 
      }),
      fetchSingleEndSystem: builder.query<EndSystemViewModel, EndSystemRequest>({
        query({ Id }) {
          return `/endsystems/${Id}`;
        },
        providesTags: (_data, _error, arg) => [{ type: 'EndSystem', id: `${arg.Id}` }]
      }),
      createEndSystem: builder.mutation<EndSystemViewModel, EditEndSystemRequest>({
        query: ({ endsystem }) => {
          return {
            url: `/endsystems`,
            method: 'POST',
            body: endsystem,
          };
        },
        invalidatesTags: ['EndSystem']
      }),
      updateEndSystem: builder.mutation<EndSystemViewModel, EditEndSystemRequest>({
        query: ({ endsystem }) => {
          return {
            url: `/endsystems/${endsystem.id}`,
            method: 'PUT',
            body: endsystem,
          };
        },
        invalidatesTags: (_data, _error, arg) => [{ type: 'EndSystem', id: `${arg.endsystem.id}` }]
      }),
      deleteEndSystem: builder.mutation<void, DeleteEndSystemRequest>({
        query: ({ Id }) => ({
          url: `/endsystems/${Id}`,
          method: 'DELETE',
          body: {},
        }),
        invalidatesTags: (_data, _error, arg) => ['EndSystem']
      })
    };
  },
});

export const {
  useFetchEndSystemsQuery,
  useFetchSingleEndSystemQuery,
  useCreateEndSystemMutation,
  useUpdateEndSystemMutation,
  useDeleteEndSystemMutation,
} = endsystemsApi;
