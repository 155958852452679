import { apiSlice } from '../api/api-slice';
import { AcknowledgmentOverrideReportEmailFilterViewModel, PaginatedResult } from '../api/models';

export interface AcknowledgmentOverrideReportEmailFilterListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
}

export interface EditAcknowledgmentOverrideReportEmailFilterRequest {
  AcknowledgmentOverrideReportEmailFilters: AcknowledgmentOverrideReportEmailFilterViewModel[];
}
export interface CreateAcknowledgmentOverrideReportEmailFilterRequest {
  AcknowledgmentOverrideReportEmailFilter: AcknowledgmentOverrideReportEmailFilterViewModel;
}
export interface AcknowledgmentOverrideReportEmailFilterRequest {
  Id: number;
}

export interface DeleteAcknowledgmentOverrideReportEmailFilterRequest {
  Id: number;
}

export const AcknowledgmentOverrideReportEmailFiltersApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchAcknowledgmentOverrideReportEmailFilters: builder.query<PaginatedResult<AcknowledgmentOverrideReportEmailFilterViewModel>, AcknowledgmentOverrideReportEmailFilterListRequest>({
        query({ current, pageSize, filter, sortBy }) {
          return `/acknowledgementReconciliationReportEmailFilters?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        forceRefetch({ currentArg, previousArg }) {
         return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'AcknowledgmentOverrideReportEmailFilter',
          ...(data?.results?.map(({ id }) => ({ type: 'AcknowledgmentOverrideReportEmailFilter' as const, id: `${id}` })) || [])
        ] 
      }),
      fetchSingleAcknowledgmentOverrideReportEmailFilter: builder.query<AcknowledgmentOverrideReportEmailFilterViewModel, AcknowledgmentOverrideReportEmailFilterRequest>({
        query({ Id }) {
          return `/acknowledgementReconciliationReportEmailFilters/${Id}`;
        },
        providesTags: (_data, _error, arg) => [{ type: 'AcknowledgmentOverrideReportEmailFilter', id: `${arg.Id}` }]
      }),
      createAcknowledgmentOverrideReportEmailFilter: builder.mutation<AcknowledgmentOverrideReportEmailFilterViewModel, CreateAcknowledgmentOverrideReportEmailFilterRequest>({
        query: ({ AcknowledgmentOverrideReportEmailFilter }) => {
          return {
            url: `/acknowledgementReconciliationReportEmailFilters`,
            method: 'POST',
            body: AcknowledgmentOverrideReportEmailFilter,
          };
        },
        invalidatesTags: ['AcknowledgmentOverrideReportEmailFilter']
      }),
      updateAcknowledgmentOverrideReportEmailFilters: builder.mutation<AcknowledgmentOverrideReportEmailFilterViewModel[], EditAcknowledgmentOverrideReportEmailFilterRequest>({        
        query: ({ AcknowledgmentOverrideReportEmailFilters }) => {
          return {
            url: `/acknowledgementReconciliationReportEmailFilters`,
            method: 'PUT',
            body: AcknowledgmentOverrideReportEmailFilters,
          };
        }
      }),
    };
  },
});

export const {
  useFetchAcknowledgmentOverrideReportEmailFiltersQuery,
  useFetchSingleAcknowledgmentOverrideReportEmailFilterQuery,
  useCreateAcknowledgmentOverrideReportEmailFilterMutation,
  useUpdateAcknowledgmentOverrideReportEmailFiltersMutation,
} = AcknowledgmentOverrideReportEmailFiltersApi;
