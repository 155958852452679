import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  useCreateAcknowledgmentOverrideReportEmailFilterMutation,
  useFetchSingleAcknowledgmentOverrideReportEmailFilterQuery,
} from '../acknowledgmentoverridereportemailfilter-api';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined, UserOutlined,QuestionCircleOutlined } from '@ant-design/icons';
import { Company, PaginatedResult, AcknowledgmentOverrideReportEmailFilterViewModel, PartnershipInformationViewModel } from '../../api/models';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ConvertUTCtoCDT, isNullEmpty } from '../../../core/utils';
import { getLink } from '../../../App-router';
import { DefaultTenantId } from '../../../core/defaults';
import { DefaultPage, DefaultPageSize, DefaultPollingInterval } from '../../../core/defaults';

  import {
    useFetchPartnershipInformationsQuery,
  } from '../../partnershipinformation/partnershipinformation-api';

const { Option } = Select;
const statusArray = [{ label: 'Processed', value: 'Processed' }, { label: 'Accepted', value: 'Accepted' },{ label: 'Partially Accepted', value: 'Partially Accepted' }
  ,{ label: 'Rejected', value: 'Rejected' },{ label: 'Errored', value: 'Errored' },{ label: 'Declined', value: 'Declined' }]
const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError =>
  error ? 'status' in error : false;

const getErrorMessage = (error: any): string | undefined => error.data;

interface AcknowledgmentOverrideReportEmailFilterScreenParams {
Id?: string | undefined;
tenantId?: string | undefined;
acknowledgementReconciliationReportId?: string | undefined;
acknowledgementReconciliationReportName?: string | undefined;
  [key: string]: string | undefined;
}
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function AcknowledgmentOverrideReportEmailFilterEditScreen(props: any) {
  const { Id,acknowledgementReconciliationReportId,acknowledgementReconciliationReportName, tenantId = DefaultTenantId}: AcknowledgmentOverrideReportEmailFilterScreenParams =
    useParams<AcknowledgmentOverrideReportEmailFilterScreenParams>();
    const query = useQuery();
    const params: any = useParams<any>();
  const isNewAcknowledgmentOverrideReportEmailFilter = Id === 'new';

  const { data, isFetching } = useFetchSingleAcknowledgmentOverrideReportEmailFilterQuery(
    {
      Id: Number(Id),
    },
    { skip: isNewAcknowledgmentOverrideReportEmailFilter }
  );

  const [createAcknowledgmentOverrideReportEmailFilter, { isLoading: isCreating, error: createError }] = useCreateAcknowledgmentOverrideReportEmailFilterMutation();

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [form] = Form.useForm();

  React.useEffect(() => {
    // form.resetFields();
  }, [data, form]);
  const [disabledSave, setDisabledSave] = useState(true);
  
  const handleFormChange = () => {
  const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
  setDisabledSave(hasErrors);
  }
  const onFinish = (values: any | AcknowledgmentOverrideReportEmailFilterViewModel) => {
    if(isNullEmpty(values.interchangeReceiverID)
      && isNullEmpty(values.partnerInformationId)
      && isNullEmpty(values.interchangeSenderID)
      && isNullEmpty(values.status)
      && isNullEmpty(values.transactionSetIdentifier)){
        message.error('Atleast one filter should be there.');
        return;
      }
    const requestData = {
      AcknowledgmentOverrideReportEmailFilter: {
        id: isNewAcknowledgmentOverrideReportEmailFilter ? 0 : (Number(Id)||0),
        acknowledgementReconciliationReportId: parseInt(acknowledgementReconciliationReportId||'0'),
        partnerInformationId: values.partnerInformationId||null,        
        transactionSetIdentifier:isNullEmpty(values.transactionSetIdentifier)? null:values.transactionSetIdentifier||null,
        interchangeSenderID: isNullEmpty(values.interchangeSenderID)? null:values.interchangeSenderID||null,
        interchangeReceiverID: isNullEmpty(values.interchangeReceiverID)? null:values.interchangeReceiverID||null,
        status:values.status !=undefined && values.status.length>0? values.status.sort().join(","):null,
        updatedBy:sessionStorage.getItem('userEmail')||'',
        enteredBy:isNewAcknowledgmentOverrideReportEmailFilter?sessionStorage.getItem('userEmail')||'':'',
      },
    };

    const request = createAcknowledgmentOverrideReportEmailFilter(requestData);
    request.unwrap().then((response) => {
      if(response?.id ===-200){
        message.error(
          `This combination already exists. Please try other values.`
        );
        return;
      }
      message.success(
        `Record is ${isNewAcknowledgmentOverrideReportEmailFilter ? 'created' : 'saved'} successfully.`
      );
      navigate(getLink('AcknowledgmentOverrideReportEmailEdit', { tenantId: tenantId,
        Id: acknowledgementReconciliationReportId}))
    });
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (
    option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const {
      data: partners = {},
    }: {
      data?: PaginatedResult<PartnershipInformationViewModel> | undefined;
    } = useFetchPartnershipInformationsQuery({
      current: 1,
      pageSize: 100000
    });
    let partnersResults = partners.results??[];
  return (
    <div>
      <PageHeader
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={getLink('AcknowledgmentOverrideReportEmailList', { ...props, tenantId })}>Acknowledgement Reconciliation Reports</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={getLink('AcknowledgmentOverrideReportEmailEdit', { ...props, tenantId, Id:Number(acknowledgementReconciliationReportId||'0') })}>{acknowledgementReconciliationReportName}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{isNewAcknowledgmentOverrideReportEmailFilter ? 'New AcknowledgmentOverrideReportEmailFilter' : data?.id}</Breadcrumb.Item>            
          </Breadcrumb>
        }
      >
        {isFetchBaseQueryErrorType(createError) && (
          <Alert message="Error" description={getErrorMessage(createError)} type="error" showIcon />
        )}
        <Card
          title={
            <Title
              level={4}
              style={{
                marginBottom: 0,
                display: 'inline-block',
              }}
            >
              {isNewAcknowledgmentOverrideReportEmailFilter ? 'New Acknowledgement Reconciliation Report Filter' : 'Edit Acknowledgement Reconciliation Report Filter'}
            </Title>
          }
        >
          <Spin spinning={isFetching || isCreating}>
            <Form
              {...layout}
              form={form}
              name="control-hooks"
              initialValues={data}
              onFinish={onFinish}
              onFieldsChange={handleFormChange}   
            > 
              <Form.Item name="partnerInformationId" label="PartnerInformationId" >
              <Select
                          loading={isFetching}
                          showSearch
                          placeholder="Search..."
                          optionFilterProp="children"
                          data-testid="partnerInformationId"
                          filterOption={filterOption}
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '')
                              .toLowerCase()
                              .localeCompare((optionB?.label ?? '').toLowerCase())
                          }
                          options={partnersResults.map((item:any) => ({ label: item.partnerId, value: item.id }))}
                        />
              </Form.Item>

              <Form.Item name="transactionSetIdentifier" label="TransactionSetIdentifier">
                <Input placeholder='Please provide a valid transactionSetIdentifier with max-length 100. for e.g. 210' data-testid="transactionSetIdentifier" maxLength={100} suffix={
                        <Tooltip title="Please provide transactionSetIdentifier with max-length 100. for e.g. 210">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      } />
              </Form.Item>
              <Form.Item name="interchangeSenderID" label="InterchangeSenderID">
                <Input placeholder='Please provide interchangeSenderID with max-length is 1000 for e.g. CLAJ' data-testid="interchangeSenderID" maxLength={100} suffix={
                        <Tooltip title="Please provide interchangeSenderID with max-length is 1000 for e.g. CLAJ">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      } />
              </Form.Item>
              <Form.Item name="interchangeReceiverID" label="InterchangeReceiverID" >
                <Input placeholder='Please provide valid interchangeReceiverID with upto 100 characters.' showCount data-testid="interchangeReceiverID" maxLength={100} suffix={
                        <Tooltip title="Please provide valid interchangeReceiverID with upto 100 characters.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              
              <Form.Item name="status" label="Status" >
              <Select
              style={{minWidth: 150}}
                  mode="multiple"
                  placeholder="Select..."
                  optionFilterProp="children"
                  filterOption={filterOption}
                  data-testid="status"
                  options={statusArray}
                  allowClear={true}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                />
              </Form.Item>

              {!isNewAcknowledgmentOverrideReportEmailFilter?
              <div>
                <Form.Item name="enteredBy" label="Entered By">
                <Space>
                  <span>{data?.enteredBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="enteredByDate" label="Entered By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.enteredByDate||'')}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updatedBy" label="Updated By">
                <Space>
                  <span>{data?.updatedBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updateByDate" label="Updated By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.updatedByDate||'')}</span>
                </Space>
              </Form.Item>      
              </div>:''
              }
              <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                <Space>
                  <Button type="primary" htmlType="submit" disabled={disabledSave && !isNewAcknowledgmentOverrideReportEmailFilter}>
                    {isNewAcknowledgmentOverrideReportEmailFilter ? 'Add Acknowledgement Reconciliation Report Filter' : 'Save Acknowledgement Reconciliation Report Filter'}
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </PageHeader>
    </div>
  );
}
