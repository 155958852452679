import React from 'react';
import { Tag } from 'antd';
import { MonitorStatusEnum } from '../../api/models';

const getMonitorStatusBadge = (status: MonitorStatusEnum) => {
    const {color, text} = getStatus(status)
    return (<Tag color={color} key={status}> {text} </Tag>);
}

const getStatus = (status: MonitorStatusEnum): any => {
  const statusOptions = [
    { key: MonitorStatusEnum.Ok, text: 'OK', color: 'green' },
    { key: MonitorStatusEnum.Warning, color: 'yellow', text: 'Warning' },
    { key: MonitorStatusEnum.Error, color: 'volcano', text: 'ERROR' }
  ]
  
  return statusOptions.find(option => option.key === status)
}

export default getMonitorStatusBadge;