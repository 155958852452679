import PartnerContactInformationsScreen from './screens/partnercontactinformations-screen';
import PartnerContactInformationEditScreen from './screens/partnercontactinformation-edit-screen';
import { RouteDefinition } from '../../core/route';

export const partnercontactinformationRoutes: RouteDefinition[] = [
  {
    id: 'PartnerContactInformationEdit',
    title: 'Routing Rule',
    path: 'partnercontactinformations/:Id',
    element: <PartnerContactInformationEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'PartnerContactInformationList',
    title: 'Routing Rules',
    path: 'partnercontactinformations',
    element: <PartnerContactInformationsScreen />,
    showInNavigation: false,
  },
];
