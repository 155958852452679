import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  useCreateEndSystemMutation,
  useFetchSingleEndSystemQuery,
  useUpdateEndSystemMutation,
} from '../endsystem-api';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined, UserOutlined,QuestionCircleOutlined } from '@ant-design/icons';
import { Company, PaginatedResult, EndSystemViewModel } from '../../api/models';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ConvertUTCtoCDT } from '../../../core/utils';
import { getLink } from '../../../App-router';
import { DefaultTenantId } from '../../../core/defaults';
const { Option } = Select;

const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError =>
  error ? 'status' in error : false;

const getErrorMessage = (error: any): string | undefined => error.data;

interface EndSystemScreenParams {
Id?: string | undefined;
tenantId?: string | undefined;
  [key: string]: string | undefined;
}

export default function EndSystemEditScreen(props: any) {
  const { Id, tenantId = DefaultTenantId}: EndSystemScreenParams =
    useParams<EndSystemScreenParams>();

  const isNewEndSystem = Id === 'new';

  const { data, isFetching } = useFetchSingleEndSystemQuery(
    {
      Id: Number(Id),
    },
    { skip: isNewEndSystem }
  );

  const [createEndSystem, { isLoading: isCreating, error: createError }] = useCreateEndSystemMutation();
  const [updateEndSystem, { isLoading: isUpdating, error: updateError }] = useUpdateEndSystemMutation();

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [form] = Form.useForm();

  React.useEffect(() => {
    form.resetFields();
  }, [data, form]);
  const [disabledSave, setDisabledSave] = useState(true);
  
  const handleFormChange = () => {
  const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
  setDisabledSave(hasErrors);
  }
  const onFinish = (values: any | EndSystemViewModel) => {
    const requestData = {
      endsystem: {
        id: isNewEndSystem ? 0 : Number(Id),
        name: values.name,
        endSystemInstance: values.endSystemInstance,
        description: values.description,
        updatedBy:sessionStorage.getItem('userEmail')||'',
        enteredBy:isNewEndSystem?sessionStorage.getItem('userEmail')||'':'',
      },
    };

    const request = isNewEndSystem ? createEndSystem(requestData) : updateEndSystem(requestData);
    request.unwrap().then((response) => {
      if(response?.id ===-200){
        message.error(
          `This combination already exists. Please try other values.`
        );
        return;
      }
      message.success(
        `EndSystem is ${isNewEndSystem ? 'created' : 'saved'}`
      );
      navigate(
        getLink('EndSystemList', {
          ...props,
          tenantId: tenantId,
        })
      );
    });
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };

  const handleCancel = () => {
    navigate(
      getLink('EndSystemList', {
        ...props,
        tenantId: tenantId,
      })
    );
  };

  return (
    <div>
      <PageHeader
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={getLink('EndSystemList', { ...props, tenantId })}>End Systems</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{isNewEndSystem ? 'New EndSystem' : data?.name}</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {isFetchBaseQueryErrorType(createError) && (
          <Alert message="Error" description={getErrorMessage(createError)} type="error" showIcon />
        )}
        {isFetchBaseQueryErrorType(updateError) && (
          <Alert message="Error" description={getErrorMessage(updateError)} type="error" showIcon />
        )}
        <Card
          title={
            <Title
              level={4}
              style={{
                marginBottom: 0,
                display: 'inline-block',
              }}
            >
              {isNewEndSystem ? 'New EndSystem' : 'Edit EndSystem'}
            </Title>
          }
        >
          <Spin spinning={isFetching || isCreating || isUpdating}>
            <Form
              {...layout}
              form={form}
              name="control-hooks"
              initialValues={data}
              onFinish={onFinish}
              onFieldsChange={handleFormChange}   
            >
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input placeholder='Please provide endsystem name with max-length 100. for e.g. MercuryGate' data-testid="name" maxLength={100} suffix={
                        <Tooltip title="Please provide endsystem name with max-length 100. for e.g. MercuryGate">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      } />
              </Form.Item>
              <Form.Item name="endSystemInstance" label="EndSystem Instance" rules={[{ required: true }]}>
                <Input placeholder='Please provide endsystem instance with max-length is 1000 for e.g. Dedicated' data-testid="endSystemInstance" maxLength={1000} suffix={
                        <Tooltip title="Please provide endsystem instance with max-length is 1000 for e.g. Dedicated">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      } />
              </Form.Item>
              <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                <Input placeholder='Please provide valid description with upto 1000 characters.' showCount data-testid="description" maxLength={1000} suffix={
                        <Tooltip title="Please provide valid description with upto 1000 characters.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              {!isNewEndSystem?
              <div>
                <Form.Item name="enteredBy" label="Entered By">
                <Space>
                  <span>{data?.enteredBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="enteredByDate" label="Entered By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.enteredByDate||'')}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updatedBy" label="Updated By">
                <Space>
                  <span>{data?.updatedBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updateByDate" label="Updated By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.updatedByDate||'')}</span>
                </Space>
              </Form.Item>      
              </div>:''
              }
              <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                <Space>
                  <Button type="primary" htmlType="submit" disabled={disabledSave && !isNewEndSystem}>
                    {isNewEndSystem ? 'Add EndSystem' : 'Save EndSystem'}
                  </Button>
                  <Button htmlType="button" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </PageHeader>
    </div>
  );
}
