import { apiSlice } from '../api/api-slice';
import { PartnerConnectionEndpointViewModel, PaginatedResult } from '../api/models';

export interface PartnerConnectionEndpointListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null
}

export interface EditPartnerConnectionEndpointRequest {
  partnerconnectionendpoint: PartnerConnectionEndpointViewModel;
}

export interface PartnerConnectionEndpointRequest {
  Id: number
}

export interface DeletePartnerConnectionEndpointRequest {
  Id: number;
}

export const partnerconnectionendpointsApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchPartnerConnectionEndpoints: builder.query<PaginatedResult<PartnerConnectionEndpointViewModel>, PartnerConnectionEndpointListRequest>({
        query({current, pageSize, filter, sortBy }) {
          return `/partnerconnectionendpoints?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'PartnerConnectionEndpoint',
          ...(data?.results?.map(({ id }) => ({ type: 'PartnerConnectionEndpoint' as const, id: `${id}` })) || [])
        ] 
      }),
      fetchSinglePartnerConnectionEndpoint: builder.query<PartnerConnectionEndpointViewModel, PartnerConnectionEndpointRequest>({
        query({ Id }) {
          return `/partnerconnectionendpoints/${Id}`;
        },
        providesTags: (_data, _error, arg) => [{ type: 'PartnerConnectionEndpoint', id: `${arg.Id}` }]
      }),
      createPartnerConnectionEndpoint: builder.mutation<PartnerConnectionEndpointViewModel, EditPartnerConnectionEndpointRequest>({
        query: ({ partnerconnectionendpoint }) => {
          return {
            url: `/partnerconnectionendpoints`,
            method: 'POST',
            body: partnerconnectionendpoint,
          };
        },
        invalidatesTags: ['PartnerConnectionEndpoint']
      }),
      updatePartnerConnectionEndpoint: builder.mutation<PartnerConnectionEndpointViewModel, EditPartnerConnectionEndpointRequest>({
        query: ({ partnerconnectionendpoint }) => {
          return {
            url: `/partnerconnectionendpoints/${partnerconnectionendpoint.id}`,
            method: 'PUT',
            body: partnerconnectionendpoint,
          };
        },
        invalidatesTags: (_data, _error, arg) => [{ type: 'PartnerConnectionEndpoint', id: `${arg.partnerconnectionendpoint.id}` }]
      }),
      deletePartnerConnectionEndpoint: builder.mutation<void, DeletePartnerConnectionEndpointRequest>({
        query: ({ Id }) => ({
          url: `/partnerconnectionendpoints/${Id}`,
          method: 'DELETE',
          body: {},
        }),
        invalidatesTags: (_data, _error, arg) => ['PartnerConnectionEndpoint']
      })
    };
  },
});

export const {
  useFetchPartnerConnectionEndpointsQuery,
  useFetchSinglePartnerConnectionEndpointQuery,
  useCreatePartnerConnectionEndpointMutation,
  useUpdatePartnerConnectionEndpointMutation,
  useDeletePartnerConnectionEndpointMutation,
} = partnerconnectionendpointsApi;
