import { createSlice } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import getMonitorStatusBadge from './components/monitor-status-badge';

interface Column {
  title: string;
  dataIndex: string;
  key: string;
  render?(item: any) : ReactNode
}

interface TableState {
  columns: Column[];
}

const initialState: TableState = {
  columns: [
    {
      title: 'Description',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: getMonitorStatusBadge
    }
  ],
};

const monitorListSlice = createSlice({
  name: 'monitorTable',
  initialState,
  reducers: {
    hideColumn(state) {},
  },
});

export const monitorsReducer = monitorListSlice.reducer;
