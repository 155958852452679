import React from 'react';
import {  useEffect, useState } from 'react';
import {
  useFetchAuditLogsQuery
} from '../../features/api/auditlogs-api';
import {  useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Modal,
  Table,
} from 'antd';
import {  AuditLogsViewModel } from '../../features/api/models';
import { TableState } from '../../components/table/model';
import { ConvertUTCtoCDT } from '../../core/utils';

export default function AuditLogsScreen(props: any) {
  console.log('props', props);
  const params: any = useParams<any>();

  const {
    data: auditLogs = [],
    isFetching,
  }: {
    data?: AuditLogsViewModel[]| undefined;
    isFetching?: boolean | undefined;
  } = useFetchAuditLogsQuery({entityId:props.entityId,entityName:props.entityName});
  
  const auditLogsTable: TableState = {
    columns: [      
      {
        title: 'Change Date Time',
        dataIndex: '',
        key: 'changeDateTime',
        render: (auditLog: AuditLogsViewModel) => (
          <>
              <span>{ConvertUTCtoCDT(auditLog.changeDateTime||'')}</span>
          </>
        )
      },
      {
        title: 'Audit Type',
        dataIndex: '',
        key: 'auditType',
        render: (auditLog: AuditLogsViewModel) => (
          <>
              <span>{auditLog.auditType}</span>
          </>
        )
      },
      {
        title: 'Field Changed',
        dataIndex: '',
        key: 'field',
        render: (auditLog: AuditLogsViewModel) => (
          <>
              <span>{auditLog.fieldChanged}</span>
          </>
        )
      },
      {
        title: 'Old Value',
        key: 'oldValue',
        dataIndex: '',
        render: (auditLog: AuditLogsViewModel) => (
          <>
              <span>{auditLog.oldValue}</span>
          </>
        )
      },

      {
        title: 'New Value',
        dataIndex: '',
        key: 'new Value',
        render: (auditLog: AuditLogsViewModel) => (
          <>
              <span>{auditLog.newValue}</span>
          </>
        )
      },
      {
        title: 'User',
        dataIndex: '',
        key: 'user',
        render: (auditLog: AuditLogsViewModel) => (
          <>
              <span>{auditLog.user}</span>
          </>
        )
      }
    ],
  };
  
  useEffect(() => {
  }, [params, props]);

  return (
    <>
      <PageHeader
      > <Modal
        title="Audit Logs"
        centered
        open
        onCancel={() => props.showAuditLogs(false)}
        footer={null}
        width={1000}
      >
        <Table scroll={{ x: true }}
              dataSource={auditLogs}
              columns={auditLogsTable.columns}
              loading={isFetching}
              pagination={{
                total: auditLogs?.length,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ["10", "20", "50","100"],
                showSizeChanger: true,
                locale: { items_per_page: " / Page" }
              }}
            />
      </Modal>
      </PageHeader>
    </>
  );
}
