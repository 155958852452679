import { Config, AntdConfig } from '@react-awesome-query-builder/antd';

const InitialConfig = AntdConfig;

const config: Config = {
  ...InitialConfig,
  fields: {
    tenant_id: {
      label: 'Tenant Id',
      type: 'text',
      valueSources: ['value'],
    },
    tenant_name: {
      label: 'Tenant Name',
      type: 'text',
      valueSources: ['value'],
    },
    job_id: {
      label: 'Job Id',
      type: 'text',
      valueSources: ['value'],
    },
    job_state: {
      label: 'Job State',
      type: 'text',
      valueSources: ['value'],
    },
    process_id: {
      label: 'Process Id',
      type: 'text',
      valueSources: ['value'],
    },
    process_name: {
      label: 'Process Name',
      type: 'text',
      valueSources: ['value'],
    },
    process_version: {
      label: 'Process Version',
      type: 'text',
      valueSources: ['value'],
    },
    stepId: {
      label: 'Step Id',
      type: 'text',
      valueSources: ['value'],
    },
    event_dateTime: {
      label: 'Event Date and Time',
      type: 'date',
      valueSources: ['value'],
    },
    epoch: {
      label: 'Epoch',
      type: 'number',
      valueSources: ['value'],
    },
    connector_instance_id: {
      label: 'Connector Instance Id',
      type: 'text',
      valueSources: ['value'],
    },
    connector_id: {
      label: 'Connector Id',
      type: 'text',
      valueSources: ['value'],
    },
    connector_name: {
      label: 'Connector Name',
      type: 'text',
      valueSources: ['value'],
    },
    origin_connector_instance_id: {
      label: 'Origin Connector Instance Id',
      type: 'text',
      valueSources: ['value'],
    },
    duration: {
      label: 'Duration',
      type: 'number',
      valueSources: ['value'],
    },
    message: {
      label: 'Message',
      type: 'text',
      valueSources: ['value'],
    },
    inbound_payload: {
      label: 'Inbound Payload',
      type: 'text',
      valueSources: ['value'],
    },
    outbound_payload: {
      label: 'Outbound Payload',
      type: 'text',
      valueSources: ['value'],
    },
    tags: {
      label: 'Tags',
      type: 'text',
      valueSources: ['value'],
    },
    username: {
      label: 'Username',
      type: 'text',
      valueSources: ['value'],
    },
  },
};

export default config;
