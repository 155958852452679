import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  AcknowledgmentOverrideListRequest,
  useDeleteAcknowledgmentOverrideMutation,
  useFetchAcknowledgmentOverridesQuery,
} from '../acknowledgmentoverride-api';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Space,
  Table,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
  MoreOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { AcknowledgmentOverrideViewModel, PaginatedResult} from '../../api/models';
import { DefaultPage, DefaultPageSize } from '../../../core/defaults';
import Title from 'antd/lib/typography/Title';
import { getLink } from '../../../App-router';
import { TableState } from '../../../components/table/model';
import getSortBy from '../../../components/table/sorter';
import AuditLogsScreen from '../../../components/common/auditLogs';
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function AcknowledgmentOverridesScreen(props: any) {
  const query = useQuery();
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const [showAuditLogs, setShowAuditLogs] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<number>(0);

  const acknowledgmentoverrideTable: TableState = {
    columns: [
      {
        title: 'Id',
        dataIndex: '',
        key: 'Id',
        render: (acknowledgmentoverride: AcknowledgmentOverrideViewModel) => (
          <>
              <Space>
                <span>{acknowledgmentoverride.id}</span>
              </Space>
          </>
        ),
        sorter:true,
        fixed: 'left',
      },
      {
        title: 'TransactionType',
        dataIndex: '',
        key: 'transactiontype',
        render: (acknowledgmentoverride: AcknowledgmentOverrideViewModel) => (
          <>
              <span>{acknowledgmentoverride.transactionType}</span>
          </>
        ),
        sorter:true
      },
      {
        title: 'InterchangeSenderQualifier',
        dataIndex: '',
        key: 'interchangesenderqualifier',
        render: (acknowledgmentoverride: AcknowledgmentOverrideViewModel) => (
          <>
              <span>{acknowledgmentoverride.interchangeSenderQualifier}</span>
          </>
        ),
        sorter:true
      },
      {
        title: 'InterchangeSenderID',
        dataIndex: '',
        key: 'interchangesenderid',
        render: (acknowledgmentoverride: AcknowledgmentOverrideViewModel) => (
          <>
              <span>{acknowledgmentoverride.interchangeSenderID}</span>
          </>
        ),
        sorter:true
      },
      {
        title: 'InterchangeReceiverQualifier',
        key: 'interchangereceiverqualifier',
        dataIndex: '',
        render: (acknowledgmentoverride: AcknowledgmentOverrideViewModel) => (
          <>
              <span>{acknowledgmentoverride.interchangeReceiverQualifier}</span>
          </>
        ),
        sorter:true
      },

      {
        title: 'InterchangeReceiverID',
        dataIndex: '',
        key: 'interchangereceiverid',
        render: (acknowledgmentoverride: AcknowledgmentOverrideViewModel) => (
          <>
              <span>{acknowledgmentoverride.interchangeReceiverID}</span>
          </>
        ),
        sorter:true
      },
      {
        title: 'GroupSenderID',
        dataIndex: '',
        key: 'groupsenderid',
        render: (acknowledgmentoverride: AcknowledgmentOverrideViewModel) => (
          <>
              <span>{acknowledgmentoverride.groupSenderID}</span>
          </>
        ),
        sorter:true
      },
      {
        title: 'GroupReceiverID',
        dataIndex: '',
        key: 'groupreceiverid',
        render: (acknowledgmentoverride: AcknowledgmentOverrideViewModel) => (
          <>
              <span>{acknowledgmentoverride.groupReceiverID}</span>
          </>
        ),
        sorter:true
      },
      {
        title: 'AcknowledgmentOverride',
        key: 'acknowledgmentoverride',
        dataIndex: '',
        render: (acknowledgmentoverride: AcknowledgmentOverrideViewModel) => (
          <>
              <span>{acknowledgmentoverride.acknowledgmentOverride?'True':'False'}</span>
          </>
        ),
        sorter:true
      },
      {
        title: 'IsActive',
        key: 'isactive',
        dataIndex: '',
        render: (acknowledgmentoverride: AcknowledgmentOverrideViewModel) => (
          <>
              <span>{acknowledgmentoverride.isActive?'True':'False'}</span>
          </>
        ),
        sorter:true
      },      
      {
        title: 'Actions',
        key: 'action',
        dataIndex: '',
        fixed:'right',
        render: (acknowledgmentoverride: AcknowledgmentOverrideViewModel) => (
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            overlay={
              <Menu style={{ padding: '8px 8px' }}>
                <Menu.Item icon={<EditOutlined />} key="1">
                  <Link
                    to={getLink('AcknowledgmentOverrideEdit', {
                      tenantId: params.tenantId,
                      Id: acknowledgmentoverride.id
                    })}
                  >
                    Edit
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<InfoCircleOutlined />} key="3">
                <Link to="#" onClick={()=>setShowAuditLogs(true)}>
                    Audit Logs
                  </Link>
                </Menu.Item>
                <Menu.Item disabled={true}
                  icon={<DeleteOutlined />}
                  key="2"
                  onClick={() => {
                    confirm({
                      title: (
                        <span>
                          Delete acknowledgment override <b>{acknowledgmentoverride.id}</b>?
                        </span>
                      ),
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        handleDelete(acknowledgmentoverride);
                      },
                    });
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
          >
            <MoreOutlined />
          </Dropdown>
        ),
      },
    ],
  };

  const getCurrentState = (): AcknowledgmentOverrideListRequest => ({
    current: Number(query.get('p')) || DefaultPage,
    pageSize: Number(query.get('size')) || DefaultPageSize,
    filter: query.get('q'),
    sortBy: query.get('sortBy') || 'EnteredByDate Desc',
  });

  const [pagination, setPagination] = useState<AcknowledgmentOverrideListRequest>(getCurrentState());

  const {
    data: pagedData = {},
    isFetching,
    error,
  }: {
    data?: PaginatedResult<AcknowledgmentOverrideViewModel> | undefined;
    isFetching?: boolean | undefined;
    error?: any;
  } = useFetchAcknowledgmentOverridesQuery(pagination);

  useEffect(() => {
    setPagination(getCurrentState());
  }, [params, props]);

  const [deleteAcknowledgmentOverride, { isLoading: isDeleteLoading, error: deleteError }] =
    useDeleteAcknowledgmentOverrideMutation();
  const { confirm } = Modal;

  const onCreateNew = useCallback(
    () => navigate(getLink('AcknowledgmentOverrideEdit', { ...params, Id: 'new' })),
    [params, navigate]
  );

  const handleTableChange = (
    paginationConfig: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<AcknowledgmentOverrideViewModel> | SorterResult<AcknowledgmentOverrideViewModel>[],
    extra: TableCurrentDataSource<AcknowledgmentOverrideViewModel>
  ) => {
    const { columnKey, order } = sorter as SorterResult<AcknowledgmentOverrideViewModel>;
    if (columnKey && order) {
      query.set('sortBy', getSortBy(columnKey.toString(), order));
    } else {
      query.set('sortBy', '');
    }

    navigate(
      getLink(
        'AcknowledgmentOverrideList',
        { ...params },
        {
          ...query,
          sortBy: query.get('sortBy'),
          p: paginationConfig.current,
          size: paginationConfig.pageSize,
          q: pagination.filter || '',
        }
      )
    );
  };

  const handleDelete = async (acknowledgmentoverride: AcknowledgmentOverrideViewModel) => {
    try {
      await deleteAcknowledgmentOverride({
        Id: acknowledgmentoverride.id ?? 0,
      }).unwrap();
      message.success(`Acknowledgment Override ${acknowledgmentoverride.transactionType} deleted`);
    } catch (error) {
      console.error(error);
    }
  };
  const handleSearch = (searchText: string) => {
    navigate(
      getLink(
        'AcknowledgmentOverrideList',
        { ...params },
        {
          ...query,
          p: DefaultPage,
          q: encodeURIComponent(searchText?.length ? `InterchangeSenderID:*${searchText.replaceAll(" ", "\\ ")}* OR InterchangeReceiverID:*${searchText.replaceAll(" ", "\\ ")}* 
          OR GroupSenderID:*${searchText.replaceAll(" ", "\\ ")}* OR GroupReceiverID:*${searchText.replaceAll(" ", "\\ ")}*` : ''),
        }
      )
    );
  };
  return (
    <>
      <PageHeader
        extra={
            <Space>
                  <Button htmlType="button" data-testid={'create-new-acknowledgmentoverride'} onClick={onCreateNew}>
                  New Acknowledgment Override
                  </Button>
                </Space>
        }
        title={
          <Title
            level={4}
            style={{
              marginBottom: 0,
              display: 'inline-block',
            }}
          >
            Acknowledgment Override
          </Title>
        }
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: params.tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: params.tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="acknowledgmentoverrides">
              <Link to={getLink('AcknowledgmentOverrideList', { tenantId: params.tenantId })}>Acknowledgment Override</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Card>
          {error?.status && (
            <Alert message="Error" description={error.data} type="error" showIcon />
          )}
          {deleteError && (
            <Alert message="Error" description={deleteError.toString()} type="error" showIcon />
          )}
          <Space direction="vertical" style={{ width: '100%' }} size="large">
          <Input.Search
              placeholder="Search by InterchangeSenderID, InterchangeReceiverID, GroupSenderID, GroupReceiverID"
              onSearch={handleSearch}
              enterButton
              style={{ width: 780 }}
              loading={isFetching}
              allowClear
            />
            <Table scroll={{ x: true }}
              dataSource={pagedData.results}
              onChange={handleTableChange}
              rowKey={'id'}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => { 
                    setEntityId(record?.id||0);
                  },
                };
              }}
              pagination={{
                ...pagination,
                total: pagedData.totalCount,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ["10", "20", "50","100"],
                showSizeChanger: true,
                locale: { items_per_page: " / Page" }
              }}
              columns={acknowledgmentoverrideTable.columns}
              loading={isFetching}
            />
          </Space>
        </Card>
        {showAuditLogs?<AuditLogsScreen entityId={entityId} entityName={'AcknowledgmentOverride'} showAuditLogs={setShowAuditLogs}/>:<></>}
      </PageHeader>
    </>
  );
}
