import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  useCreatePartnerRatingMutation,
  useFetchSinglePartnerRatingQuery,
} from '../partnerrating-api';

import {
  useFetchSinglePartnershipInformationQuery,
} from '../../partnershipinformation/partnershipinformation-api';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Space,
  Spin,
  Rate
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined,QuestionCircleOutlined } from '@ant-design/icons';
import { PartnerRatingViewModel} from '../../api/models';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { getLink } from '../../../App-router';
import { DefaultTenantId } from '../../../core/defaults';
const { Option } = Select;

const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError =>
  error ? 'status' in error : false;

const getErrorMessage = (error: any): string | undefined => error.data;

interface PartnerRatingScreenParams {
Id?: string | undefined;
tenantId?: string | undefined;
  [key: string]: string | undefined;
}

export default function PartnerRatingEditScreen(props: any) {
  const { Id, tenantId = DefaultTenantId}: PartnerRatingScreenParams =
    useParams<PartnerRatingScreenParams>();

  const isNewPartnerRating = Id === 'new';

  const { data, isFetching } = useFetchSinglePartnerRatingQuery(
    {
      Id: Number(Id),
    },
    { skip: isNewPartnerRating }
  );
  const partnerInfo = useFetchSinglePartnershipInformationQuery(
    {
      Id: Number(localStorage.getItem('partnerInformationId')),
    }
  );
  const [createPartnerRating, { isLoading: isCreating, error: createError }] = useCreatePartnerRatingMutation();
  
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [formPartnerRating] = Form.useForm();

  React.useEffect(() => {
    // localStorage.setItem('overallRating',partnerInfo.data?.overallRating?.toString()||'0');
    formPartnerRating.resetFields();
  }, [data, formPartnerRating, props]);


  const onFinish = (values: any | PartnerRatingViewModel) => {
    // debugger;
    const requestData = {
      PartnerRating: {
            id: values.id === undefined?0:values.id,
            partnerInformationId:localStorage.getItem('partnerInformationId')||'0',
            responsiveness: values.responsiveness||0,
            documentationClarity: values.documentationClarity||0,
            cooperationAndSupport: values.cooperationAndSupport||0,
            integrationCompletionTime: values.integrationCompletionTime||0,            
            integrationEfficiency: values.integrationEfficiency||0,
            notes: values.notes,
            updatedBy:sessionStorage.getItem('userEmail')||'',
            enteredBy:isNewPartnerRating?sessionStorage.getItem('userEmail')||'':'',
      },
    };

    const request = createPartnerRating(requestData);
    request.unwrap().then((response:any) => {
      if(response?.id ===-200){
        message.error(
          `This combination already exists. Please try other values.`
        );
        return;
      }
      message.success(
        `Partner Rating is created.`
      );
      navigate(
        getLink('PartnershipInformationEdit', {
          ...props,
          tenantId: tenantId,
          Id:localStorage.getItem('partnerInformationId')||'0'
        })
      );
    });
  };
 // Filter `option.label` match the user type `input`
 const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (
  option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };
  const [disabledSave, setDisabledSave] = useState(true);
  
  const handleFormChange = () => {
  const hasErrors = formPartnerRating.getFieldsError().some(({ errors }) => errors.length);
  setDisabledSave(hasErrors);
  }
  return (
    <div>
      <PageHeader
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={getLink('PartnershipInformationList', { ...props, tenantId })}>Partner Information</Link>
            </Breadcrumb.Item>            
            <Breadcrumb.Item>
              <Link to={getLink('PartnershipInformationEdit', { ...props, tenantId, Id:Number(localStorage.getItem('partnerInformationId')||'0') })}>{localStorage.getItem('carrierName')||''}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{isNewPartnerRating ? 'New Partner Rating' : data?.partnerInformationId}</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {isFetchBaseQueryErrorType(createError) && (
          <Alert message="Error" description={getErrorMessage(createError)} type="error" showIcon />
        )}
        <Card
          title={
            <Title
              level={4}
              style={{
                marginBottom: 0,
                display: 'inline-block',
              }}
            >
              {isNewPartnerRating ? 'New Partner Rating' : 'Partner Rating'}
            </Title>
          }
        >
          <Spin spinning={isFetching || isCreating}>
          <Form 
                      {...layout}
                      form={formPartnerRating}
                      name="pci-hooks"
                      initialValues={data}
                      onFinish={onFinish}
                      onFieldsChange={handleFormChange}   
                    > 
                      <Form.Item name="responsiveness" label="Responsiveness" >
                      <Rate allowHalf defaultValue={isNewPartnerRating ?0:Number(data?.responsiveness)} disabled={!isNewPartnerRating} />
                      </Form.Item>        
                      <Form.Item name="documentationClarity" label="Documentation Clarity" >
                      <Rate allowHalf defaultValue={isNewPartnerRating ?0:Number(data?.documentationClarity)} disabled={!isNewPartnerRating} />
                      </Form.Item>
                      <Form.Item name="integrationEfficiency" label="Integration Efficiency" >
                      <Rate allowHalf defaultValue={isNewPartnerRating ?0:Number(data?.integrationEfficiency)} disabled={!isNewPartnerRating} />
                      </Form.Item>
                      <Form.Item name="cooperationAndSupport" label="Cooperation And Support" >
                      <Rate allowHalf defaultValue={isNewPartnerRating ?0:Number(data?.cooperationAndSupport)} disabled={!isNewPartnerRating} />
                      </Form.Item>
                      <Form.Item name="integrationCompletionTime" label="Integration Completion Time"  >
                      <Rate allowHalf defaultValue={isNewPartnerRating ?0:Number(data?.integrationCompletionTime)} disabled={!isNewPartnerRating} />
                      </Form.Item>
                      <Form.Item name="overallRating" label="Overall Rating"  hidden={isNewPartnerRating}>
                      <Rate allowHalf defaultValue={isNewPartnerRating ?0:Number(data?.overallRating)} disabled={!isNewPartnerRating} />
                      </Form.Item>
                      <Form.Item name="notes" label="Notes">
                      <TextArea rows={5} showCount disabled={!isNewPartnerRating} />
                      </Form.Item>
                      
                      <Form.Item name="notes" label="Entered By" hidden={isNewPartnerRating}>
                        {data?.enteredBy}
                      </Form.Item>
                      <Form.Item name="enteredByDate" label="Entered By Date" hidden={isNewPartnerRating}>
                      {data?.enteredByDate}
                      </Form.Item>
                      <Form.Item name="updatedBy" label="Updated By" hidden={isNewPartnerRating}>
                      {data?.updatedBy}
                      </Form.Item>
                      <Form.Item name="updateByDate" label="Update By Date" hidden={isNewPartnerRating}>
                      {data?.updateByDate}
                      </Form.Item>

                      <Form.Item wrapperCol={{ offset: 4, span: 12 }} hidden={!isNewPartnerRating}>
                      <Space>
                        <Button type="primary" htmlType="submit">
                          Save Partner Rating
                        </Button>
                      </Space>
                    </Form.Item>                    
                    </Form>
          </Spin>
        </Card>
      </PageHeader>
    </div>
  );
}
