import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  useCreateCodeDecodeMapMutation,
  useFetchSingleCodeDecodeMapQuery,
  useUpdateCodeDecodeMapMutation,
} from '../codedecodemap-api';

import {
  useFetchProfileMapsQuery,
  ProfileMapListRequest,
} from '../../profilemaps/profilemap-api';

import {
  useFetchCustomersQuery,
} from '../../customers/customer-api';

import {
  useFetchCodeDecodeValuesQuery,
} from '../../codedecodevalue/codedecodevalue-api';

import {
  useFetchCodeDecodeTypesQuery,
} from '../../codedecodetype/codedecodetype-api';


import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Space,
  Spin,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { PaginatedResult, CodeDecodeMapViewModel, ProfileMapViewModel,CustomerViewModel, CodeDecodeValueViewModel ,CodeDecodeTypeViewModel} from '../../api/models';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { getLink } from '../../../App-router';
import { DefaultTenantId, DefaultPage, DefaultPageSize, DefaultPollingInterval } from '../../../core/defaults';
import { ConvertUTCtoCDT } from '../../../core/utils';

const { Option } = Select;

const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError =>
  error ? 'status' in error : false;

const getErrorMessage = (error: any): string | undefined => error.data;

interface CodeDecodeMapScreenParams {
Id?: string | undefined;
tenantId?: string | undefined;
  [key: string]: string | undefined;
}
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function CodeDecodeMapEditScreen(props: any) {
  const { Id, tenantId = DefaultTenantId, codedecodevalueid}: CodeDecodeMapScreenParams =
    useParams<CodeDecodeMapScreenParams>();

  const isNewCodeDecodeMap = Id === 'new';

  const { data, isFetching } = useFetchSingleCodeDecodeMapQuery(
    {
      Id: Number(Id)
    },
    { skip: isNewCodeDecodeMap }
  );

  const [createCodeDecodeMap, { isLoading: isCreating, error: createError }] = useCreateCodeDecodeMapMutation();
  const [updateCodeDecodeMap, { isLoading: isUpdating, error: updateError }] = useUpdateCodeDecodeMapMutation();
  const query = useQuery();
  const navigate = useNavigate();
  
  const [selectedCodeDecodeType, setSelectedCodeDecodeType] = useState<number>(0);
  const [profileMapOptions, setProfileMapOptions] = useState<{ value: string; label: string }[]>([]);
  const [customerOptions, setCustomerOptions] = useState<{ value: string; label: string }[]>([]);
  const [codedecodeValuesOptions, setCodedecodeValuesOptions] = useState<{ value: string; label: string }[]>([]);

  const {
    data: codeDecodeTypes = {},
    error,
  }: {
    data?: PaginatedResult<CodeDecodeTypeViewModel> | undefined;
    isFetching?: boolean | undefined;
    error?: any;
  } = useFetchCodeDecodeTypesQuery({
    current:1,
    pageSize:100000,
    filter:'',
    sortBy: 'Type Asc'});
    let codeDecodeTypesResults = codeDecodeTypes.results??[];

  const {
    data: profileMaps = {},
  }: {
    data?: PaginatedResult<ProfileMapViewModel> | undefined;
    error?: any;
  } = useFetchProfileMapsQuery({
    current:1,
    pageSize:1000,
    filter:'',
    sortBy: 'Name Asc',
        
});
  let profileMapsResults = profileMaps.results??[];

  const {
    data: codeDecodeValues = {},
    isFetching: isFetchingCodeDecodeValues = false,
  }: {
    data?: PaginatedResult<CodeDecodeValueViewModel> | undefined;
    isFetching?: boolean | undefined;
  } = useFetchCodeDecodeValuesQuery({
      codeDecodeTypeId: 0,
      current:1,
      pageSize:100000,
      filter:'',
      sortBy: 'Code Asc',
          
  });
  let codeDecodeValuesResults = codeDecodeValues.results??[];

  const {
    data: customers = {},
  }: {
    data?: PaginatedResult<CustomerViewModel> | undefined;
  } = useFetchCustomersQuery({
    current:1,
    pageSize:1000,
    filter:'',
    sortBy: 'Name Asc',
        
});
  let customersResults = customers.results??[];

  const [form] = Form.useForm();

  React.useEffect(() => {    
    setSelectedCodeDecodeType(Number(data?.codeDecodeTypeId));
    form.resetFields();
     
  }, [data, form]);

  const handleProfileMapSearch = (value: string) => {
     
    let res: { value: string; label: string }[] = [];    
    if (!value || value===undefined) {
      res = [];
    } else {
      res = profileMapsResults.map((item:any) => ({
        value: item.id,
        label: `${item.name}`,
      })).filter((x:any) => x.label.toUpperCase().indexOf(value.toUpperCase()) !== -1);
    }
    setProfileMapOptions(res);
  };
 
  const handleCustomerSearch = (value: string) => {
    let res: { value: string; label: string }[] = [];    
    if (!value || value===undefined) {
      res = [];
    } else {
      res = customersResults.map((item:any) => ({
        value: item.id,
        label: `${item.name}`,
      })).filter((x:any) => x.label.toUpperCase().indexOf(value.toUpperCase()) !== -1);
    }
    setCustomerOptions(res);
  };
  const handleCodeDecodeTypesChange = (value: number) => {     
    setSelectedCodeDecodeType(value);
  };

  const handleCodeDecodeValuesSearch = (value: string) => {
     
    let res: { value: string; label: string }[] = [];  
    // console.log(typeof value);  
    if(typeof value == "string"){
      if (!value || value===undefined) {
        res = [];
      } else {
        res = codeDecodeValuesResults.map((item:any) => ({
          value: item.id,
          label: `${item.code +' - '+ item.decode}`,
        })).filter((x:any) => x.label.toUpperCase().indexOf(value.toUpperCase()) !== -1);
      }
      setCodedecodeValuesOptions(res);
    }    
  };

  const [disabledSave, setDisabledSave] = useState(true);
  
  const handleFormChange = () => {
  const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
  setDisabledSave(hasErrors);
  }

  const onFinish = (values: any | CodeDecodeMapViewModel) => {
    if(form.isFieldsTouched()){
    const requestData = {
      codedecodemap: {
        id: isNewCodeDecodeMap ? 0 : Number(Id),
        codeDecodeValueId: values.codeDecodeValueId,
        mapId: values.mapId,
        customerId: values.customerId,
        codeDecodeTypeId:values.codeDecodeTypeId,
        updatedBy:sessionStorage.getItem('userEmail')||'',
        enteredBy:isNewCodeDecodeMap?sessionStorage.getItem('userEmail')||'':'',
      },
    };

    const request = isNewCodeDecodeMap ? createCodeDecodeMap(requestData) : updateCodeDecodeMap(requestData);
    request.unwrap().then((response) => {
       
      if(response?.id ===-200){
        message.error(
          `This combination already exists. Please try other values.`
        );
        return;
      }
      message.success(
        `Code Decode Map is ${isNewCodeDecodeMap ? 'created' : 'saved'}`
      );
      navigate(
        getLink('CodeDecodeMapList', {
          ...props,
          tenantId: tenantId
        })
      );
    });
  }
  else{
    message.error(
      `No data is changed.`
    );
    return;
  }
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };

  const handleCancel = () => {
    navigate(
      getLink('CodeDecodeMapList', {
        ...props,
        tenantId: tenantId
      })
    );
  };


    // Filter `option.label` match the user type `input`
    const filterOption = (
      input: string,
      option?: { label: string; value: string }
    ) => (
      option?.label ?? "").toLowerCase().includes(input.toLowerCase());


  return (
    <div>
      <PageHeader
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={getLink('CodeDecodeMapList', { ...props, tenantId})}>Code Decode Maps</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{isNewCodeDecodeMap ? 'New Code Decode Map' : data?.id}</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {isFetchBaseQueryErrorType(createError) && (
          <Alert message="Error" description={getErrorMessage(createError)} type="error" showIcon />
        )}
        {isFetchBaseQueryErrorType(updateError) && (
          <Alert message="Error" description={getErrorMessage(updateError)} type="error" showIcon />
        )}
        <Card
          title={
            <Title
              level={4}
              style={{
                marginBottom: 0,
                display: 'inline-block',
              }}
            >
              {isNewCodeDecodeMap ? 'New Code Decode Map' : 'Edit Code Decode Map'}
            </Title>
          }
        >
          <Spin spinning={isFetching || isCreating || isUpdating}>
            <Form
              {...layout}
              form={form}
              name="control-hooks"
              initialValues={data}
              onFinish={onFinish}
              onFieldsChange={handleFormChange}
            >
              <Form.Item name="codeDecodeTypeId" label="Code Decode Type"> 
              <Select
                  showSearch
                  placeholder="Search..."
                  optionFilterProp="children"
                  data-testid="codeDecodeTypeId"
                  onChange={handleCodeDecodeTypesChange}
                  filterOption={filterOption}
                  options={codeDecodeTypesResults.map((item:any) => ({ label: item.type, value: item.id }))}
                />
              </Form.Item>  

              <Form.Item name="codeDecodeValueId" label="Code Decode Value" rules={[{ required: true }]} >
              <Select 
                  showSearch
                  placeholder="Search..."
                  optionFilterProp="children"
                  data-testid="codeDecodeValueId"
                  loading={isFetchingCodeDecodeValues}
                  onSearch={handleCodeDecodeValuesSearch}
                  filterOption={filterOption}
                  options={selectedCodeDecodeType || Number(selectedCodeDecodeType) >0 ?codeDecodeValuesResults.filter(x=>x.codeDecodeTypeId===Number(selectedCodeDecodeType)).map((item:any) => ({ label: item.code +' - '+ item.decode, value: item.id })): codeDecodeValuesResults.map((item:any) => ({ label: item.code +' - '+ item.decode, value: item.id }))}
                />
              </Form.Item>              
              <Form.Item name="mapId" label="Map" rules={[{ required: true }]}>
              <Select
                showSearch
                placeholder="Search..."
                optionFilterProp="children"
                data-testid="mapId"
                onSearch={handleProfileMapSearch}
                filterOption={filterOption}
                options={profileMapsResults.map((item:any) => ({ label: item.name, value: item.id }))}
              />
              </Form.Item>              
              <Form.Item name="customerId" label="Customer" rules={[{ required: true }]}>
              <Select
                showSearch
                placeholder="Search..."
                optionFilterProp="children"
                data-testid="customerId"
                onSearch={handleCustomerSearch}
                filterOption={filterOption}
                options={customersResults.map((item:any) => ({ label: item.name, value: item.id }))}
              />
              </Form.Item>
              {!isNewCodeDecodeMap?
              <div>
                <Form.Item name="enteredBy" label="Entered By">
                <Space>
                  <span>{data?.enteredBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="enteredByDate" label="Entered By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.enteredByDate||'')}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updatedBy" label="Updated By">
                <Space>
                  <span>{data?.updatedBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updateByDate" label="Updated By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.updatedByDate||'')}</span>
                </Space>
              </Form.Item>      
              </div>:''
              }
              <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                <Space>
                  <Button type="primary" htmlType="submit" disabled={disabledSave && !isNewCodeDecodeMap}>
                    {isNewCodeDecodeMap ? 'Add Code Decode Map' : 'Save Code Decode Map'}
                  </Button>
                  <Button htmlType="button" onClick={handleCancel}> 
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </PageHeader>
    </div>
  );
}
