import { apiSlice } from '../api/api-slice';
import { RoutingRuleViewModel, RoutingRuleListViewModel, PaginatedResult } from '../api/models';

export interface RoutingRuleListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;  
  splittingRuleId:number;
}

export interface EditRoutingRuleRequest {
  routingrule: RoutingRuleViewModel;
}

export interface RoutingRuleRequest {
  Id: number;
}

export interface DeleteRoutingRuleRequest {
  Id: number;
}

export const routingRuleApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchRoutingRules: builder.query<PaginatedResult<RoutingRuleListViewModel>, RoutingRuleListRequest>({
        query({ current, pageSize, filter, sortBy, splittingRuleId }) {
          return `/routingRules?splittingRuleId=${splittingRuleId}&pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'RoutingRule',
          ...(data?.results?.map(({ id }) => ({ type: 'RoutingRule' as const, id: `${id}` })) || [])
        ] 
      }),
      fetchSingleRoutingRule: builder.query<RoutingRuleViewModel, RoutingRuleRequest>({
        query({ Id }) {
          return `/routingRules/${Id}`;
        },
        providesTags: (_data, _error, arg) => [{ type: 'RoutingRule', id: `${arg.Id}` }]
      }),
      createRoutingRule: builder.mutation<RoutingRuleViewModel, EditRoutingRuleRequest>({
        query: ({ routingrule }) => {
          return {
            url: `/routingRules`,
            method: 'POST',
            body: routingrule,
          };
        },
        invalidatesTags: ['RoutingRule']
      }),
      updateRoutingRule: builder.mutation<RoutingRuleViewModel, EditRoutingRuleRequest>({
        query: ({ routingrule }) => {
          return {
            url: `/routingRules/${routingrule.id}`,
            method: 'PUT',
            body: routingrule,
          };
        },
        invalidatesTags: (_data, _error, arg) => [{ type: 'RoutingRule', id: `${arg.routingrule.id}` }]
      }),
      deleteRoutingRule: builder.mutation<void, DeleteRoutingRuleRequest>({
        query: ({ Id }) => ({
          url: `/routingRules/${Id}`,
          method: 'DELETE',
          body: {},
        }),
        invalidatesTags: (_data, _error, arg) => ['RoutingRule']
      })
    };
  },
});

export const {
  useFetchRoutingRulesQuery,
  useFetchSingleRoutingRuleQuery,
  useCreateRoutingRuleMutation,
  useUpdateRoutingRuleMutation,
  useDeleteRoutingRuleMutation,
} = routingRuleApi;
