import ConnectionEndPointsScreen from './screens/connectionendpoints-screen';
import ConnectionEndPointEditScreen from './screens/connectionendpoint-edit-screen';
import { RouteDefinition } from '../../core/route';

export const connectionendpointRoutes: RouteDefinition[] = [
  {
    id: 'ConnectionEndPointEdit',
    title: 'Connection Endpoint',
    path: 'connectionendpoints/:Id',
    element: <ConnectionEndPointEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'ConnectionEndPointList',
    title: 'Connection Endpoints',
    path: 'connectionendpoints',
    element: <ConnectionEndPointsScreen />,
    showInNavigation: true,
  },
];
