import React, { useEffect } from 'react';
// import './style/main.less';

import { useAppSelector, useAppDispatch } from './hooks';
import { login } from './auth/auth-slice';
import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter as Router, Routes, Route, RouterProvider } from 'react-router-dom';
import { appRoutes } from './App-router';

if (process.env.REACT_APP_ENABLE_MOCK === 'true' && process.env.NODE_ENV !== 'test') {
  const { mockWorker } = require('./mocks/mockWorker');
  mockWorker.start();
}

function App() {
  const { loginWithRedirect, isAuthenticated, user, isLoading, getAccessTokenSilently, logout } =
    useAuth0();

  const hasAccess = useAppSelector((state) => state.auth.hasAccess);
  const dispatch = useAppDispatch();
  // console.log('isAuthenticated', isAuthenticated);
  
  useEffect(() => {
    if (user) {
      getAccessTokenSilently().then((token) => {
        dispatch(
          login({
            hasAccess: isAuthenticated,
            token: token,
            user: user,
          })
        );
        const decodedToken = JSON.parse(atob(token.split('.')[1])); // Decode JWT token
        sessionStorage.setItem('userEmail',decodedToken['https://redwoodprofile.com/email'])
      });
      
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !user) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, user]);

  useEffect(() => {
    if (!isLoading && isAuthenticated && user && !hasAccess) {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  }, [hasAccess]);

  if (isLoading) {
    return <>Loading</>;
  } else if (isAuthenticated && hasAccess) {
    return <RouterProvider router={appRoutes} />;
  } else {
    return <></>;
  }
}

export default App;
