import RoutingRulesScreen from './screens/routingrules-screen';
import RoutingRuleEditScreen from './screens/routingrule-edit-screen';
import Rule997EditScreen from './screens/997rule-edit-screen';
import { RouteDefinition } from '../../core/route';

export const routingruleRoutes: RouteDefinition[] = [
  {
    id: '997RuleEdit',
    title: '997 Rule ',
    path: 'routingrules997/:Id',
    element: <Rule997EditScreen />,
    showInNavigation: false,
  },
  {
    id: 'RoutingRuleEdit',
    title: 'Routing Rule',
    path: 'routingrules/:Id',
    element: <RoutingRuleEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'RoutingRuleList',
    title: 'Routing Rules',
    path: 'routingrules',
    element: <RoutingRulesScreen />,
    showInNavigation: false,
  },
];
