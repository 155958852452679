import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  CarrierOnboardingListRequest,
  useDeleteCarrierOnboardingMutation,
  useFetchCarrierOnboardingsQuery,
} from '../carrieronboarding-api';
import {
  useFetchPartnershipInformationsQuery
} from '../../partnershipinformation/partnershipinformation-api';
import AuditLogsScreen from '../../../components/common/auditLogs';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Badge,
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Space,
  Table,
  Row,
  Col,
  Select
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
  MoreOutlined,InfoCircleOutlined
} from '@ant-design/icons';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { CarrierOnboardingViewModel, PaginatedResult, PartnershipInformationViewModel } from '../../api/models';
import { DefaultPage, DefaultPageSize } from '../../../core/defaults';
import Title from 'antd/lib/typography/Title';
import { getLink } from '../../../App-router';
import { TableState } from '../../../components/table/model';
import getSortBy from '../../../components/table/sorter';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function CarrierOnboardingsScreen(props: any) {
  const query = useQuery();
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const [partnershipId, setPartnershipId] = useState<string>(query.get('id') || '');
  const [carrierSelect, setCarrierSelect] = useState<string>(query.get('carrierName') || '');
  const [partnerIdSelect, setPartnerIdSelect] = useState<string>(query.get('partnerId') || '');  
  const [scacSelect, setScacSelect] = useState<string>(query.get('identifierValue') || '');
  const [hideNewCarrierOnboarding, setHideNewCarrierOnboarding] = useState<boolean>(false);
  const [showAuditLogs, setShowAuditLogs] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<number>(0);

  useEffect(() => {
    setPagination(getCurrentState());
  }, [params, query]);

  const carrieronboardingTable: TableState = {
    columns: [
      {
        title: 'Id',
        dataIndex: '',
        key: 'Id',
        render: (carrieronboarding: CarrierOnboardingViewModel) => (
          <>
              <Space>
                <span>{carrieronboarding.id}</span>
              </Space>
          </>
        ),
        sorter: true,
        fixed: 'left',
      },
      {
        title: 'Name',
        dataIndex: '',
        key: 'carrierName',
        render: (carrieronboarding: CarrierOnboardingViewModel) => (
          <>
              <span>{carrieronboarding.carrierName}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'Partner Id',
        dataIndex: '',
        key: 'partnerId',
        render: (carrieronboarding: CarrierOnboardingViewModel) => (
          <>
              <span>{carrieronboarding.partnerId}</span>
          </>
        ),
      },
      {
        title: 'Scac',
        dataIndex: '',
        key: 'scac',
        render: (carrieronboarding: CarrierOnboardingViewModel) => (
          <>
              <span>{carrieronboarding.identifierValue}</span>
          </>
        ),
      },
      {
        title: 'DocumentType',
        dataIndex: '',
        key: 'documentType',
        render: (carrieronboarding: CarrierOnboardingViewModel) => (
          <>
              <span>{carrieronboarding.documentType}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'Source',
        dataIndex: '',
        key: 'source',
        render: (carrieronboarding: CarrierOnboardingViewModel) => (
          <>
              <span>{carrieronboarding.source}</span>
          </>
        ),
      },
      {
        title: 'Target',
        dataIndex: '',
        key: 'target',
        render: (carrieronboarding: CarrierOnboardingViewModel) => (
          <>
              <span>{carrieronboarding.target}</span>
          </>
        ),
      },
      {
        title: 'TestFlag',
        dataIndex: '',
        key: 'testFlag',
        render: (carrieronboarding: CarrierOnboardingViewModel) => (
          <>
              <span>{carrieronboarding.testFlag}</span>
          </>
        ),
      },
      
      {
        title: 'Format',
        dataIndex: '',
        key: 'format',
        render: (carrieronboarding: CarrierOnboardingViewModel) => (
          <>
              <span>{carrieronboarding.format}</span>
          </>
        ),
      },
      {
        title: 'Version',
        dataIndex: '',
        key: 'version',
        render: (carrieronboarding: CarrierOnboardingViewModel) => (
          <>
              <span>{carrieronboarding.version}</span>
          </>
        ),
      },
      {
        title: 'Standard',
        dataIndex: '',
        key: 'standard',
        render: (carrieronboarding: CarrierOnboardingViewModel) => (
          <>
              <span>{carrieronboarding.standard}</span>
          </>
        ),
      },
      {
        title: 'Priority',
        dataIndex: '',
        key: 'priority',
        render: (carrieronboarding: CarrierOnboardingViewModel) => (
          <>
              <span>{carrieronboarding.priority}</span>
          </>
        ),
      }, 
      {
        title: 'Actions',
        key: 'action',
        dataIndex: '',
        fixed:'right',
        render: (carrieronboarding: CarrierOnboardingViewModel) => (
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            overlay={
              <Menu style={{ padding: '8px 8px' }}>
                <Menu.Item icon={<EditOutlined />} >
                  <Link onClick={() => {localStorage.clear();}}
                    to={getLink('CarrierOnboardingEdit', {
                      tenantId: params.tenantId,
                      Id: carrieronboarding.id,
                      partnershipId:params.id,
                    })}
                  >
                    View
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<InfoCircleOutlined />} key="3">
                <Link to="#" onClick={()=>setShowAuditLogs(true)}>
                    Audit Logs
                  </Link>
                </Menu.Item>
                <Menu.Item disabled={true}
                  icon={<DeleteOutlined />}
                  
                  onClick={() => {
                    confirm({
                      title: (
                        <span>
                          Delete Partner Onboarding <b>{carrieronboarding.documentType} - {carrieronboarding.source} - {carrieronboarding.target}</b>?
                        </span>
                      ),
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        handleDelete(carrieronboarding);
                      },
                    });
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
          >
            <MoreOutlined />
          </Dropdown>
        ),
      },
    ],
  };
  
  const getCurrentState = (): CarrierOnboardingListRequest => ({
    current: Number(query.get('p')) || DefaultPage,
    carrierName:encodeURIComponent(params.carrierName||''),//encodeURIComponent(query.get('carrierName') || ''),
    partnerId:encodeURIComponent(params.partnerId||''),//encodeURIComponent(query.get('partnerId') || ''),
    identifierValue:encodeURIComponent(params.identifierValue||''),//encodeURIComponent(query.get('identifierValue') || ''),
    pageSize: Number(query.get('size')) || DefaultPageSize,
    filter: query.get('q'),
    sortBy: query.get('sortBy') || 'Id Desc',
  });
  // console.log(getCurrentState());
  const [pagination, setPagination] = useState<CarrierOnboardingListRequest>(getCurrentState());

  const {
    data: pagedData = {},
    isFetching,
    error,
  }: {
    data?: PaginatedResult<CarrierOnboardingViewModel> | undefined;
    isFetching?: boolean | undefined;
    error?: any;
  } = useFetchCarrierOnboardingsQuery(getCurrentState());
  
  const [deleteCarrierOnboarding, { isLoading: isDeleteLoading, error: deleteError }] =
    useDeleteCarrierOnboardingMutation();
  const { confirm } = Modal;

  const onCreateNew = useCallback(
    () => navigate(getLink('CarrierOnboardingEdit', { ...params, Id: 'new',partnershipId:params.id })),
    [params, navigate]
  );
  const onGoBack = useCallback(
    () => navigate(getLink('PartnershipInformationList', { ...params })),
    [params, navigate]
  );
  const handleDelete = async (carrieronboarding: CarrierOnboardingViewModel) => {
    try {
      await deleteCarrierOnboarding({
        Id: carrieronboarding.id ?? 0,
      }).unwrap();
      message.success(`Partner Onboarding ${carrieronboarding.documentType} - ${carrieronboarding.source} - ${carrieronboarding.target} deleted`);
    } catch (error) {
      console.error(error);
    }
  };
  const {
    data: partnershipInfoValues = {},
  }: {
    data?: PaginatedResult<PartnershipInformationViewModel> | undefined;
  } = useFetchPartnershipInformationsQuery({
      current:1,
      pageSize:1000,
      filter:'',
      sortBy: 'Name Asc',
          
  });
  let partnershipInfoResults = partnershipInfoValues.results??[];
// Filter `option.label` match the user type `input`
const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (
  option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleTableChange = (
    paginationConfig: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<CarrierOnboardingViewModel> | SorterResult<CarrierOnboardingViewModel>[],
    extra: TableCurrentDataSource<CarrierOnboardingViewModel>
  ) => {
    const { columnKey, order } = sorter as SorterResult<CarrierOnboardingViewModel>;
    if (columnKey && order) {
      query.set('sortBy', getSortBy(columnKey.toString(), order));
    } else {
      query.set('sortBy', '');
    }

    navigate(
      getLink(
        'CarrierOnboardingList',
        { ...params },
        {
          ...query,
          sortBy: query.get('sortBy'),
          p: paginationConfig.current,
          size: paginationConfig.pageSize,
          id:partnershipId,
          carrierName:query.get('carrierName') || '',
          partnerId:query.get('partnerId') || '',
          identifierValue:query.get('identifierValue') || '',
          q: pagination.filter || '',
        }
      )
    );
  };
  
  return (
    <div>
      <PageHeader
        extra={
            <Space>
                {/* <Button  htmlType="button" onClick={onGoBack}>
                  Go to Patner Information
                  </Button> */}

                  <Button  htmlType="button" disabled={hideNewCarrierOnboarding} data-testid={'create-new-carrieronboarding'} onClick={onCreateNew}>
                  New Partner Onboarding
                  </Button>
                </Space>
        }
        title={
          <Title
            level={4}
            style={{
              marginBottom: 0,
              display: 'inline-block',
            }}
          >
            Partner Onboarding
          </Title>
        }
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: params.tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={getLink('cob', { tenantId: params.tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="partnerinfo">
              <Link to={getLink('PartnershipInformationList', { ...params })}>Partner Information</Link>
            </Breadcrumb.Item>
            {/* <Breadcrumb.Item key="carrieronboardings">
              <Link to={getLink('CarrierOnboardingList', { ...params })}>Partner Onboarding</Link>
            </Breadcrumb.Item> */}
            <Breadcrumb.Item key="carrierName">
              <Link to={getLink('CarrierOnboardingList', { ...params })}>{params.carrierName}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Card>
          {error?.status && (
            <Alert message="Error" description={error.data} type="error" showIcon />
          )}
          {deleteError && (
            <Alert message="Error" description={deleteError.toString()} type="error" showIcon />
          )}
          
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <Table
          scroll={{ x: true }}
            dataSource={pagedData.results}
            rowKey={'id'}
            onChange={handleTableChange}
            pagination={{
              ...pagination,
              total: pagedData.totalCount,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ["10", "20", "50","100"],
              showSizeChanger: true,
              locale: { items_per_page: " / Page" }
            }}
            columns={carrieronboardingTable.columns}
            loading={isFetching}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => { 
                  setEntityId(record?.id||0);
                },
              };
            }}
          />
        </Space>
        
        </Card>
        {showAuditLogs?<AuditLogsScreen entityId={entityId} entityName={'SplittingRule'} showAuditLogs={setShowAuditLogs}/>:<></>}
      </PageHeader>
    </div>
  );
}
