import { RouteDefinition } from '../../core/route';
import TimezonesScreen from './screens/timezones-screen';

export const settingsRoutes: RouteDefinition[] = [
  {
    id: 'SettingsTimezones',
    title: 'Timezones',
    path: 'settings/timezones',
    element: <TimezonesScreen />,
    showInNavigation: false,
  },
];
