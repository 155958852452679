import PartnerControlNumbersScreen from './screens/partnercontrolnumbers-screen';
import PartnerControlNumberEditScreen from './screens/partnercontrolnumber-edit-screen';
import { RouteDefinition } from '../../core/route';

export const partnercontrolnumberRoutes: RouteDefinition[] = [
  {
    id: 'PartnerControlNumberEdit',
    title: 'Partner Control Number',
    path: 'partnercontrolnumbers/:Id',
    element: <PartnerControlNumberEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'PartnerControlNumberList',
    title: 'Partner Control Number',
    path: 'partnercontrolnumber',
    element: <PartnerControlNumbersScreen />,
    showInNavigation: true,
  },
];
