import React, { useState, useEffect } from 'react';
import { Result, Button } from 'antd';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const handleRefresh = () => {
    // Reload the current page
    window.location.reload();
  };
  useEffect(() => {
    const handleError = (error: Event | ErrorEvent) => {
      // console.error('Error caught by error boundary:', error);
      setHasError(true);
    };

    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);

  if (hasError) {
    return <Result status="500" title="500" subTitle="Sorry, Something went wrong. Please try again later." extra={<Button type="primary" onClick={handleRefresh}>Reload</Button>} />;
  }

  return <>{children}</>;
};

export default ErrorBoundary;