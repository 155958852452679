import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  useCreateConnectionEndPointMutation,
  useFetchSingleConnectionEndPointQuery,
  useUpdateConnectionEndPointMutation,
} from '../connectionendpoint-api';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {   ConnectionEndPointViewModel } from '../../api/models';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ConvertUTCtoCDT } from '../../../core/utils';
import { getLink } from '../../../App-router';
import { DefaultTenantId } from '../../../core/defaults';
const { Option } = Select;

const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError =>
  error ? 'status' in error : false;

const getErrorMessage = (error: any): string | undefined => error.data;

interface ConnectionEndPointScreenParams {
Id?: string | undefined;
tenantId?: string | undefined;
  [key: string]: string | undefined;
}

export default function ConnectionEndPointEditScreen(props: any) {
  const { Id, tenantId = DefaultTenantId}: ConnectionEndPointScreenParams =
    useParams<ConnectionEndPointScreenParams>();

  const isNewConnectionEndPoint = Id === 'new';

  const { data, isFetching } = useFetchSingleConnectionEndPointQuery(
    {
      Id: Number(Id),
    },
    { skip: isNewConnectionEndPoint }
  );

  const [createConnectionEndPoint, { isLoading: isCreating, error: createError }] = useCreateConnectionEndPointMutation();
  const [updateConnectionEndPoint, { isLoading: isUpdating, error: updateError }] = useUpdateConnectionEndPointMutation();

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [form] = Form.useForm();

  React.useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const onFinish = (values: any | ConnectionEndPointViewModel) => {
    const requestData = {
      connectionendpoint: {
        id: isNewConnectionEndPoint ? 0 : Number(Id),
        protocol: values.protocol,
        serviceType: values.serviceType,
        host: values.host,
        serviceName: values.serviceName,
        processID: values.processID,
        environmentKey: values.environmentKey,
        description: values.description,
        isActive: values.isActive==undefined?true:values.isActive,
        updatedBy:sessionStorage.getItem('userEmail')||'',
        enteredBy:isNewConnectionEndPoint?sessionStorage.getItem('userEmail')||'':'',
      },
    };
    const request = isNewConnectionEndPoint ? createConnectionEndPoint(requestData) : updateConnectionEndPoint(requestData);
    request.unwrap().then((response) => {
      if(response?.id ===-200){
        message.error(
          `This combination already exists. Please try other values.`
        );
        return;
      }
      message.success(
        `ConnectionEndPoint is ${isNewConnectionEndPoint ? 'created' : 'saved'}`
      );
      navigate(
        getLink('ConnectionEndPointList', {
          ...props,
          tenantId: tenantId,
        })
      );

    });
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };
  const [disabledSave, setDisabledSave] = useState(true);
  
  const handleFormChange = () => {
  const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
  setDisabledSave(hasErrors);
  }
  const handleCancel = () => {
    navigate(
      getLink('ConnectionEndPointList', {
        ...props,
        tenantId: tenantId,
      })
    );
  };

  return (
    <div>
      <PageHeader
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={getLink('ConnectionEndPointList', { ...props, tenantId })}>Connection Endpoints</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{isNewConnectionEndPoint ? 'New Connection Endpoint' : data?.host}</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {isFetchBaseQueryErrorType(createError) && (
          <Alert message="Error" description={getErrorMessage(createError)} type="error" showIcon />
        )}
        {isFetchBaseQueryErrorType(updateError) && (
          <Alert message="Error" description={getErrorMessage(updateError)} type="error" showIcon />
        )}
        <Card
          title={
            <Title
              level={4}
              style={{
                marginBottom: 0,
                display: 'inline-block',
              }}
            >
              {isNewConnectionEndPoint ? 'New Connection Endpoint' : 'Edit Connection Endpoint'}
            </Title>
          }
        >
          <Spin spinning={isFetching || isCreating || isUpdating}>
            <Form
              {...layout}
              form={form}
              name="control-hooks"
              initialValues={data}
              onFinish={onFinish}
              onFieldsChange={handleFormChange}
            >
              <Form.Item name="protocol" label="Protocol" rules={[{ required: true }]}>
                <Input placeholder='Please provide the communication protocol like http/ftp.' data-testid="protocol" maxLength={100} suffix={
                        <Tooltip title="Please provide the communication protocol like http/ftp.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              
              <Form.Item name="serviceType" label="Service Type" rules={[{ required: true }]}>
                <Input placeholder='Please provide a name explaining the connection endpoint type' data-testid="serviceType" maxLength={100} suffix={
                        <Tooltip title="Please provide a name explaining the connection endpoint type for eg. OutboundEDI997ToRedwoodConnect">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>

              <Form.Item name="host" label="Host" rules={[{ required: true }]}>
                <Input placeholder='Please provide the endpoint for communication' data-testid="host" maxLength={1000} suffix={
                        <Tooltip title="Please provide the endpoint for communication with maxlength upto 1000 characters.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              <Form.Item name="serviceName" label="Service Name" rules={[{ required: true }]}>
                <Input placeholder='Please provide redwoodconnect process name' data-testid="serviceName" maxLength={2000} suffix={
                        <Tooltip title="Please provide redwoodconnect process name for eg. qa/run/execute_process">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              <Form.Item name="processID" label="ProcessID" rules={[{ required: true }]}>
                <Input placeholder='Please provide redwoodconnect processId' data-testid="processID" maxLength={1000} suffix={
                        <Tooltip title="Please provide redwoodconnect processId.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>
              <Form.Item name="environmentKey" label="EnvironmentKey" rules={[{ required: true }]}>
                <Input placeholder='Please provide environment key for redwoodconnect' data-testid="environmentKey" maxLength={1000} suffix={
                        <Tooltip title="Please provide environment key for redwoodconnect.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      } />
              </Form.Item>
              <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                <Input placeholder='Please provide valid description valid upto 1000 characters.' showCount data-testid="description" maxLength={1000} suffix={
                        <Tooltip title="Please provide valid description valid upto 1000 characters.">
                         <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }/>
              </Form.Item>

              {!isNewConnectionEndPoint?
              <div>
                <Form.Item name="enteredBy" label="Entered By">
                <Space>
                  <span>{data?.enteredBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="enteredByDate" label="Entered By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.enteredByDate||'')}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updatedBy" label="Updated By">
                <Space>
                  <span>{data?.updatedBy}</span>
                </Space>
              </Form.Item>
              <Form.Item name="updateByDate" label="Updated By Date">
                <Space>
                  <span>{ConvertUTCtoCDT(data?.updatedByDate||'')}</span>
                </Space>
              </Form.Item>      
              </div>:''
              }

              <Form.Item name="isActive" label="IsActive" rules={[{ required: true }]} initialValue={isNewConnectionEndPoint ?true : data?.isActive}>
              <Switch />
              </Form.Item>
              
              <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                <Space>
                  <Button type="primary" htmlType="submit" disabled={disabledSave && !isNewConnectionEndPoint}>
                    {isNewConnectionEndPoint ? 'Add Connection Endpoint' : 'Save Connection Endpoint'}
                  </Button>
                  <Button htmlType="button" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </PageHeader>
    </div>
  );
}
