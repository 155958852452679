import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  PartnerEDIX12ListRequest,
  useDeletePartnerEDIX12Mutation,
  useFetchPartnerEDIX12sQuery,
  useCreatePartnerEDIX12Mutation,
} from '../partneredix12-api';
import AuditLogsScreen from '../../../components/common/auditLogs';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Badge,
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Space,
  Table,
} from 'antd';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
  MinusCircleFilled,
  MoreOutlined,
  UserOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { PartnerEDIX12ViewModel, PaginatedResult } from '../../api/models';
import { DefaultPage, DefaultPageSize, DefaultPollingInterval } from '../../../core/defaults';
import Title from 'antd/lib/typography/Title';
import { getLink } from '../../../App-router';
import { TableState } from '../../../components/table/model';
import getSortBy from '../../../components/table/sorter';
import PartnerConnectionEndpointsScreen from '../../partnerconnectionendpoint/screens/partnerconnectionendpoints-screen'

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function PartnerEDIX12sScreen(props: any) {
  const query = useQuery();
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const [showAuditLogs, setShowAuditLogs] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<number>(0);
  // console.log('PartnerEDIX12sScreen', props);
  const partneredix12Table: TableState = {
    columns: [
      {
        title: 'Id',
        dataIndex: '',
        key: 'Id',
        fixed: 'left',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <Space>
                <span>{partneredix12.id}</span>
              </Space>
          </>
        ),
      },
      {
        title: 'PartnerID',
        dataIndex: '',
        key: 'partnerID',
        fixed: 'right',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.partnerID}</span>
          </>
        ),
        
      },
      {
        title: 'RoutingRuleID',
        key: 'routingRuleID',
        dataIndex: '',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.routingRuleID}</span>
          </>
        ),
      },
      {
        title: 'CarrierName',
        dataIndex: '',
        key: 'carrierName',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.carrierName}</span>
          </>
        ),
        
      },
      {
        title: 'SourceSCAC',
        key: 'sourceSCAC',
        dataIndex: '',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.sourceSCAC}</span>
          </>
        ),
        
      },
      {
        title: 'SourceSender',
        dataIndex: '',
        key: 'sourceSender',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.sourceSender}</span>
          </>
        ),
        
      },
      {
        title: 'SourceReceiver',
        key: 'sourceReceiver',
        dataIndex: '',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.sourceReceiver}</span>
          </>
        ),
      },
      {
        title: 'DocumentEncoding',
        dataIndex: '',
        key: 'documentEncoding',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.documentEncoding}</span>
          </>
        ),
        
      },
      {
        title: 'TransactionSetIdentifierCode',
        key: 'transactionSetIdentifierCode',
        dataIndex: '',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.transactionSetIdentifierCode}</span>
          </>
        ),
      },
      {
        title: 'InterchangeAckOverdueTime',
        dataIndex: '',
        key: 'interchangeAckOverdueTime',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.interchangeAckOverdueTime}</span>
          </>
        ),
        
      },
      {
        title: 'InterchangeAckOverdueTimeMinutes',
        key: 'interchangeAckOverdueTimeMinutes',
        dataIndex: '',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.interchangeAckOverdueTimeMinutes}</span>
          </>
        ),
      },
      {
        title: 'InterchangeAuthorizationInformationQualifier',
        dataIndex: '',
        key: 'interchangeAuthorizationInformationQualifier',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.interchangeAuthorizationInformationQualifier}</span>
          </>
        ),
        
      },
      {
        title: 'InterchangeAuthorizationInformation',
        key: 'interchangeAuthorizationInformation',
        dataIndex: '',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.interchangeAuthorizationInformation}</span>
          </>
        ),
      },
      {
        title: 'InterchangeSecurityInformationQualifier',
        dataIndex: '',
        key: 'interchangeSecurityInformationQualifier',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.interchangeSecurityInformationQualifier}</span>
          </>
        ),
        
      },
      {
        title: 'InterchangeSecurityInformation',
        key: 'interchangeSecurityInformation',
        dataIndex: '',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.interchangeSecurityInformation}</span>
          </>
        ),
      },
      {
        title: 'InterchangeSenderQualifier',
        dataIndex: '',
        key: 'interchangeSenderQualifier',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.interchangeSenderQualifier}</span>
          </>
        ),
        
      },
      {
        title: 'InterchangeSenderID',
        key: 'interchangeSenderID',
        dataIndex: '',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.interchangeSenderID}</span>
          </>
        ),
      },
      {
        title: 'InterchangeReceiverQualifier',
        dataIndex: '',
        key: 'interchangeReceiverQualifier',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.interchangeReceiverQualifier}</span>
          </>
        ),
        
      },
      {
        title: 'InterchangeReceiverID',
        key: 'interchangeReceiverID',
        dataIndex: '',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.interchangeReceiverID}</span>
          </>
        ),
      },
      {
        title: 'InterchangeStandard',
        dataIndex: '',
        key: 'interchangeStandard',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.interchangeStandard}</span>
          </>
        ),
        
      },
      {
        title: 'InterchangeVersionNumber',
        key: 'interchangeVersionNumber',
        dataIndex: '',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.interchangeVersionNumber}</span>
          </>
        ),
      },
      {
        title: 'InterchangeAcknowledgmentRequested',
        dataIndex: '',
        key: 'interchangeAcknowledgmentRequested',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.isInterchangeAcknowledgmentRequested?'True':'False'}</span>
          </>
        ),
        
      },
      {
        title: 'InterchangeTestIndicator',
        key: 'interchangeTestIndicator',
        dataIndex: '',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.interchangeTestIndicator}</span>
          </>
        ),
      },
      {
        title: 'GroupAckOverdueTimeMinutes',
        dataIndex: '',
        key: 'groupAckOverdueTimeMinutes',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.groupAckOverdueTimeMinutes}</span>
          </>
        ),
        
      },
      {
        title: 'GroupAckOverdueTime',
        key: 'groupAckOverdueTime',
        dataIndex: '',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.groupAckOverdueTime}</span>
          </>
        ),
      },
      {
        title: 'GroupExpectAck',
        dataIndex: '',
        key: 'groupExpectAck',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.groupExpectAck}</span>
          </>
        ),
        
      },
      {
        title: 'GroupFunctionalIdentifierCode',
        key: 'groupFunctionalIdentifierCode',
        dataIndex: '',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.groupFunctionalIdentifierCode}</span>
          </>
        ),
      },
      {
        title: 'GroupSenderID',
        dataIndex: '',
        key: 'groupSenderID',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.groupSenderID}</span>
          </>
        ),
        
      },
      {
        title: 'GroupReceiverID',
        key: 'groupReceiverID',
        dataIndex: '',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.groupReceiverID}</span>
          </>
        ),
      },
      {
        title: 'GroupResponsibleAgencyCode',
        dataIndex: '',
        key: 'groupResponsibleAgencyCode',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.groupResponsibleAgencyCode}</span>
          </>
        ),
        
      },
      {
        title: 'GroupVersionNumber',
        key: 'groupVersionNumber',
        dataIndex: '',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.groupVersionNumber}</span>
          </>
        ),
      },
      {
        title: 'TransactionSCAC',
        dataIndex: '',
        key: 'transactionSCAC',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.transactionSCAC}</span>
          </>
        ),
        
      },
      {
        title: 'ElementSeparator',
        key: 'elementSeparator',
        dataIndex: '',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.elementSeparator}</span>
          </>
        ),
      },
      {
        title: 'ComponentElementSeparator',
        dataIndex: '',
        key: 'componentElementSeparator',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.componentElementSeparator}</span>
          </>
        ),
        
      },
      {
        title: 'SegmentTerminator',
        key: 'segmentTerminator',
        dataIndex: '',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.segmentTerminator}</span>
          </>
        ),
      },
      {
        title: 'FileFormat',
        dataIndex: '',
        key: 'fileFormat',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.fileFormat}</span>
          </>
        ),
        
      },
      {
        title: 'IsActive',
        dataIndex: '',
        key: 'isActive',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <>
              <span>{partneredix12.isActive?'True':'False'}</span>
          </>
        ),
        
      },      
      {
        title: 'Actions',
        key: 'Action',
        dataIndex: '',
        fixed: 'right',
        render: (partneredix12: PartnerEDIX12ViewModel) => (
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            overlay={
              <Menu style={{ padding: '8px 8px' }}>
                <Menu.Item icon={<EditOutlined />} key="1">
                  <Link
                    to={getLink('PartnerEDIX12Edit', {
                      tenantId: params.tenantId,
                      Id: partneredix12.id,
                    })}
                  >
                    Edit
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<InfoCircleOutlined />} key="3">
                <Link to="#" onClick={()=>setShowAuditLogs(true)}>
                    Audit Logs
                  </Link>
                </Menu.Item>
                <Menu.Item disabled={true}
                  icon={<DeleteOutlined />}
                  key="2"
                  onClick={() => {
                    confirm({
                      title: (
                        <span>
                          Delete partneredix12 <b>{partneredix12.carrierName}</b>?
                        </span>
                      ),
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        handleDelete(partneredix12);
                      },
                    });
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
          >
            <MoreOutlined />
          </Dropdown>
        ),
      },
    ],
  };
 
  //TODO: need to fill source and target from splitting rule as well here.
  let identifierValue = localStorage.getItem('identifierValue')||'';
  let source = localStorage.getItem('source')||'';
  let target = localStorage.getItem('target')||'';
  let documentType = localStorage.getItem('documentType')||'';
  // console.log('documentType:', documentType);
  const getCurrentState = (): PartnerEDIX12ListRequest => ({
    current: Number(localStorage.getItem('x12PageIndex')) || DefaultPage,
    pageSize: Number(localStorage.getItem('x12PageSize')) || DefaultPageSize,
    // filter: query.get('q'),
    filter:(documentType.toLowerCase()=='edi' || identifierValue.length<=0?'sourceSender:"'+source.trimStart()+'"':'sourceSender:"'+identifierValue+'"')
    +' AND sourceReceiver:"'+target.trimStart()+'"',
    
    
    // (identifierValue?.length>0?'(sourceSender:"'+identifierValue+'" OR sourceSCAC:"'+ source.trimStart()+'")':'sourceSCAC:"'+source.trimStart()+'"') 
    // + ' AND sourceReceiver:"'+target.trimStart()+'"',
    
    sortBy: query.get('sortBy') || 'EnteredByDate Desc',
  });

  const [createPartnerEDIX12, { isLoading: isCreating, error: createError }] = useCreatePartnerEDIX12Mutation();
  const [pagination, setPagination] = useState<PartnerEDIX12ListRequest>(getCurrentState());

  const {
    data: pagedData = {},
    isFetching,
    error,
  }: {
    data?: PaginatedResult<PartnerEDIX12ViewModel> | undefined;
    isFetching?: boolean | undefined;
    error?: any;
  } = useFetchPartnerEDIX12sQuery(pagination);

  useEffect(() => {
    setPagination(getCurrentState());
    if(Number(localStorage.getItem('partnerEDIX12Id'))>0){
      setSelectedPartnerEDIX12Id(Number(localStorage.getItem('partnerEDIX12Id')));
      setShowPartnerConnectionEP(true);
    }
    else{setShowPartnerConnectionEP(false);}
    if(localStorage.getItem('transactionType')=="204"||localStorage.getItem('transactionType')=="211" ||localStorage.getItem('transactionType')=="300"){
      setDisable997Rule(true);
      setDisableX12Rule(false);
    }
    else if(localStorage.getItem('transactionType') != "204"||localStorage.getItem('transactionType')!="211" ||localStorage.getItem('transactionType')!="300"){
      setDisable997Rule(false);
      setDisableX12Rule(true);
    }
  }, [params,props]);

  const [deletePartnerEDIX12, { isLoading: isDeleteLoading, error: deleteError }] =
    useDeletePartnerEDIX12Mutation();
  const { confirm } = Modal;

  const onCreateNew = useCallback(
    () => navigate(getLink('PartnerEDIX12Edit', { ...params, Id: 'new' })),
    [params, navigate]
  );
  const onCreate997ObRuleNew = useCallback(
    () => navigate(getLink('997OBRule', { ...params, Id: 'new' })),
    [params, navigate]
  );

  const handleTableChange = (
    paginationConfig: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<PartnerEDIX12ViewModel> | SorterResult<PartnerEDIX12ViewModel>[],
    extra: TableCurrentDataSource<PartnerEDIX12ViewModel>
  ) => {
    const { columnKey, order } = sorter as SorterResult<PartnerEDIX12ViewModel>;
    if (columnKey && order) {
      query.set('sortBy', getSortBy(columnKey.toString(), order));
    } else {
      query.set('sortBy', '');
    }
    localStorage.setItem('x12PageSize',(paginationConfig.pageSize||DefaultPageSize).toString());
    localStorage.setItem('x12PageIndex',paginationConfig.current?.toString()||'1');
    navigate(
      getLink('CarrierOnboardingEdit', {
        tenantId: params.tenantId,
        Id: localStorage.getItem('splittingRuleID'),
        partnershipId: Number(localStorage.getItem('partnershipId')||0)
      })
    );
  };
  const [showPartnerConnectionEP, setShowPartnerConnectionEP] = useState(false);
  const [selectedPartnerEDIX12Id, setSelectedPartnerEDIX12Id] = useState(0);
  const [disable997Rule, setDisable997Rule] = useState(false);
  const [disableX12Rule, setDisableX12Rule] = useState(false);
  const [selectedPartnerEDIX12, setSelectedPartnerEDIX12] = useState<PartnerEDIX12ViewModel>({});

  const handleDelete = async (partneredix12: PartnerEDIX12ViewModel) => {
    try {
      await deletePartnerEDIX12({
        Id: partneredix12.id ?? 0,
      }).unwrap();
      message.success(`PartnerEDIX12 ${partneredix12.carrierName} deleted`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <PageHeader
        extra={
          <Space>
          <Button htmlType="button" data-testid={'create-new-997rule'} onClick={onCreate997ObRuleNew} disabled={disable997Rule }>
            New 997 Outbound Rule
          </Button> 
          <Button htmlType="button" data-testid={'create-new-partneredix12'} onClick={onCreateNew} 
          disabled={disableX12Rule}>
            New Partner EDIX12
          </Button>
          </Space>
        }
        title={
          <Title
            level={4}
            style={{
              marginBottom: 0,
              display: 'inline-block',
            }}
          >
            Partner EDI X12
          </Title>
        }>
        <Card>
          {error?.status && (
            <Alert message="Error" description={error.data} type="error" showIcon />
          )}
          {deleteError && (
            <Alert message="Error" description={deleteError.toString()} type="error" showIcon />
          )}
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Table scroll={{ x: true }}
              dataSource={pagedData.results}
              onChange={handleTableChange}
              rowKey={'id'}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => { 
                    setEntityId(record?.id||0);
                    // console.log(record); 
                    setShowPartnerConnectionEP(true);
                    let id:any = record.id;
                    // id=1327;
                    setSelectedPartnerEDIX12Id(Number(id));
                    localStorage.setItem('partnerEDIX12Id', id);
                    // let interchangeReceiverID:any = record.interchangeReceiverID;
                    // interchangeReceiverID='REDWOOD';
                    setSelectedPartnerEDIX12((record));
                    localStorage.setItem('partnerEDIX12Item', JSON.stringify(record));
                  },
                };
              }}
              pagination={{
                ...pagination,
                total: pagedData.totalCount,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ["10", "20", "50","100"],
                showSizeChanger: true,
                locale: { items_per_page: " / Page" }
              }}
              columns={partneredix12Table.columns}
              loading={isFetching}
            />
          </Space>
        </Card>
        { showPartnerConnectionEP?
        <div>
          <PartnerConnectionEndpointsScreen partnerEDIX12Id={selectedPartnerEDIX12Id} />
        </div> :''
        }
        {showAuditLogs?<AuditLogsScreen entityId={entityId} entityName={'PartnerEDIX12'} showAuditLogs={setShowAuditLogs}/>:<></>}
      </PageHeader>
    </div>
  );
}
