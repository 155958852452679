import React, { useEffect, useState } from 'react';
import { TablePageRequest, useFetchAlertsQuery } from '../alert-api';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Card,
  Table,
  Input,
  Form,
  Select,
  Button,
  DatePicker,
  Dropdown,
  Menu,
} from 'antd';
import { HomeOutlined, AlertOutlined, MoreOutlined, DashboardOutlined } from '@ant-design/icons';

import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { AlertViewModel, PaginatedResult } from '../../api/models';
import { DefaultPage, DefaultPageSize } from '../../../core/defaults';
import Title from 'antd/lib/typography/Title';
import { getLink } from '../../../App-router';
import { TableState } from '../../../components/table/model';
import getAlertStartDate from '../components/alert-start-date';
import getAlertStatusBadge from '../components/alert-status-badge';
import getAlertDuration from '../components/alert-duration';
import getSortBy from '../../../components/table/sorter';
import moment, { Moment } from 'moment';

const { Search } = Input;
const { Option } = Select;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function AlertsScreen(props: any) {
  const query = useQuery();
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const getCurrentState = (): TablePageRequest => ({
    tenantId: params.tenantId,
    current: Number(query.get('p')) || DefaultPage,
    pageSize: Number(query.get('size')) || DefaultPageSize,
    filter: query.get('q') ?? 'ClosedDt: "NULL"',
    sortBy: query.get('sortBy'),
  });

  const [pagination, setPagination] = useState<TablePageRequest>(getCurrentState());

  const {
    data: pagedData = {},
    isFetching,
    error,
  }: {
    data?: PaginatedResult<AlertViewModel> | undefined;
    isFetching?: boolean | undefined;
    error?: any;
  } = useFetchAlertsQuery(pagination);

  useEffect(() => {
    setPagination(getCurrentState());
  }, [params]);

  const renderDropDown = (alert: AlertViewModel) => (
    <Dropdown
      trigger={['click']}
      placement="bottomRight"
      overlay={
        <Menu style={{ padding: '8px 8px' }}>
          <Menu.Item icon={<DashboardOutlined />} key="1">
            <Link
              to={getLink(
                'TelemetryEvents',
                { tenantId: params.tenantId },
                { monitorId: alert.partnerMonitorId, partnerId: Number(query.get('partnerId')) }
              )}
            >
              Telemetry Events
            </Link>
          </Menu.Item>
        </Menu>
      }
    >
      <MoreOutlined />
    </Dropdown>
  );

  const tableAlerts: TableState = {
    columns: [
      {
        title: 'Time',
        dataIndex: 'startDt',
        key: 'startDt',
        render: getAlertStartDate,
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: getAlertStatusBadge,
      },
      {
        title: 'Partner',
        dataIndex: '',
        key: 'partnerName',
        render: (alert: AlertViewModel) => (
          <Link to={getLink('MonitorList', { ...params }, { partnerId: alert.partnerId })}>
            <span>{alert.partnerName}</span>
          </Link>
        ),
        sorter: true,
      },
      {
        title: 'Monitor',
        dataIndex: '',
        key: 'partnerMonitorName',
        render: (alert: AlertViewModel) => (
          <Link to={getLink('MonitorItem', { ...params, monitorId: alert.partnerMonitorId })}>
            <span>{alert.partnerMonitorName}</span>
          </Link>
        ),
        sorter: true,
      },
      {
        title: 'Duration',
        dataIndex: '',
        key: '',
        render: getAlertDuration,
      },
      {
        title: 'Closed Date',
        dataIndex: 'closedDt',
        key: 'closedDt',
        render: (date: any) => date && getAlertStartDate(date),
        sorter: true,
      },
      {
        title: '',
        key: 'action',
        dataIndex: '',
        render: renderDropDown,
      },
    ],
  };

  const handleTableChange = (
    paginationConfig: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<AlertViewModel> | SorterResult<AlertViewModel>[],
    extra: TableCurrentDataSource<AlertViewModel>
  ) => {
    const { columnKey, order } = sorter as SorterResult<AlertViewModel>;
    if (columnKey && order) {
      query.set('sortBy', getSortBy(columnKey.toString(), order));
    } else {
      query.set('sortBy', '');
    }

    navigate(
      getLink(
        'AlertList',
        { ...params },
        {
          ...query,
          sortBy: query.get('sortBy'),
          p: paginationConfig.current,
          size: paginationConfig.pageSize,
          q: pagination.filter || '',
        }
      )
    );
  };

  const onFilterSubmit = (values: any) => {
    console.log('Filtering results....', values);
    navigate(
      getLink(
        'AlertList',
        { ...params },
        {
          ...query,
          p: DefaultPage,
          q: constructFilterQuery(
            values.searchText,
            values.timeRange ? values.timeRange[0] : null,
            values.timeRange ? values.timeRange[1] : null,
            values.status
          ),
        }
      )
    );
  };

  const constructFilterQuery = (
    searchText: string | null | undefined,
    timeRangeStart: Moment | null | undefined,
    timeRangeEnd: Moment | null | undefined,
    status: number | null | undefined
  ) => {
    const textFilter = searchText
      ? `PartnerMonitor.Description: "*${searchText}*" OR Partner.Description: "*${searchText}*"`
      : '';
    const dateFilter =
      timeRangeStart && timeRangeEnd
        ? `StartDt:[${timeRangeStart.utc().format().replaceAll(':', '\\:')} TO ${timeRangeEnd
            .utc()
            .format()
            .replaceAll(':', '\\:')}]`
        : '';
    const statusFilter = status ? (status == 1 ? 'ClosedDt:"NULL"' : 'NOT ClosedDt:"NULL"') : '';
    const filters = [dateFilter, statusFilter, textFilter];
    const filterQuery = filters.filter((x) => x.length > 0).join(' AND ');
    return filterQuery;
  };

  return (
    <div>
      <PageHeader
        title={
          <Title
            level={4}
            style={{
              marginBottom: 0,
              display: 'inline-block',
            }}
          >
            Alerts
          </Title>
        }
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: params.tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
            <Link to={getLink('home', { tenantId: params.tenantId })}>
            Alerts
              </Link>
              {/* <span>Alerts</span> */}
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Card>
          {error?.status && (
            <Alert message="Error" description={error.data} type="error" showIcon />
          )}
          <div style={{ marginBottom: 8, padding: 8 }}>
            <Form layout="inline" form={form} onFinish={onFilterSubmit}>
              <Form.Item name="searchText" label="Search">
                <Input data-testid="searchText" placeholder="Partner/Monitor Name" />
              </Form.Item>
              <Form.Item name="timeRange" label="Time Range">
                <DatePicker.RangePicker showTime />
              </Form.Item>
              <Form.Item name="status" label="Status" initialValue={1}>
                <Select style={{ width: '100px' }}>
                  <Option key="1" value={1}>
                    Open
                  </Option>
                  <Option key="2" value={2}>
                    Closed
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Filter Results
                </Button>
              </Form.Item>
            </Form>
          </div>
          <Table
            dataSource={pagedData.results}
            onChange={handleTableChange}
            rowKey={'startDt'}
            pagination={{
              ...pagination,
              total: pagedData.totalCount,
              showTotal: (total: number) => `Total ${total} items`,
            }}
            columns={tableAlerts.columns}
            loading={isFetching}
          />
        </Card>
      </PageHeader>
    </div>
  );
}
