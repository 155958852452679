import React from 'react';
import { useLocation, useNavigate, useParams, Outlet, Link } from 'react-router-dom';
// import { useCallback, useEffect, useState } from 'react';
import Sidenav from "../../components/common/Sidenav";
import { Col, Layout, Breadcrumb, Row, Select } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import Title from 'antd/lib/typography/Title';
import { getLink } from '../../App-router';
import {
  HomeOutlined,
  UserOutlined,
} from '@ant-design/icons';

const { Header, Footer, Content, Sider } = Layout;
const { Option } = Select;
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function COBLayout(props: any) {
  const query = useQuery();
  const params: any = useParams<any>();
  const routes: any = props.getRoutes ? props.getRoutes()[0].children! : [];
  // console.log(routes);
  return (
    <Layout className="layout">
      <Row>
        <Col span={4}>
        <Sidenav props = {routes} />
        </Col>
        <Col span={20}>
      <Content>
        <Outlet />
      </Content>
        </Col>
      </Row>
    </Layout>
  );
}
