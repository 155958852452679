import { apiSlice } from '../api/api-slice';
import { CustomerViewModel, PaginatedResult } from '../api/models';

export interface CustomerListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
}

export interface EditCustomerRequest {
  customer: CustomerViewModel;
}

export interface CustomerRequest {
  Id: number;
}

export interface DeleteCustomerRequest {
  Id: number;
}

export const customersApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchCustomers: builder.query<PaginatedResult<CustomerViewModel>, CustomerListRequest>({
        query({ current, pageSize, filter, sortBy }) {
          return `/customers?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'Customer',
          ...(data?.results?.map(({ id }) => ({ type: 'Customer' as const, id: `${id}` })) || [])
        ] 
      }),
      fetchSingleCustomer: builder.query<CustomerViewModel, CustomerRequest>({
        query({ Id }) {
          return `/customers/${Id}`;
        },
        providesTags: (_data, _error, arg) => [{ type: 'Customer', id: `${arg.Id}` }]
      }),
      createCustomer: builder.mutation<CustomerViewModel, EditCustomerRequest>({
        query: ({ customer }) => {
          return {
            url: `/customers`,
            method: 'POST',
            body: customer,
          };
        },
        invalidatesTags: ['Customer']
      }),
      updateCustomer: builder.mutation<CustomerViewModel, EditCustomerRequest>({
        query: ({ customer }) => {
          return {
            url: `/customers/${customer.id}`,
            method: 'PUT',
            body: customer,
          };
        },
        invalidatesTags: (_data, _error, arg) => [{ type: 'Customer', id: `${arg.customer.id}` }]
      }),
      deleteCustomer: builder.mutation<void, DeleteCustomerRequest>({
        query: ({ Id }) => ({
          url: `/customers/${Id}`,
          method: 'DELETE',
          body: {},
        }),
        invalidatesTags: (_data, _error, arg) => ['Customer']
      })
    };
  },
});

export const {
  useFetchCustomersQuery,
  useFetchSingleCustomerQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
} = customersApi;
