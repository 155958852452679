import { apiSlice } from '../api/api-slice';
import { Tenant } from '../api/models';

export interface EditTenantRequest {
  tenantId: string,
  rwGeoLocationTimezoneId: number,
  timezoneStandardName?: string | null,
  isDisabled: boolean
}

export const tenantApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchTenants: builder.query<Tenant[], number | void>({
        query() {
          return `/tenants`;
        },
      }),
      fetchSingleTenant: builder.query<Tenant, string>({
        query(tenantId) {
          return `/tenants/${tenantId}`;
        }
      }),
      updateTenant: builder.mutation<Tenant, EditTenantRequest>({
        query: ({ tenantId, rwGeoLocationTimezoneId, timezoneStandardName, isDisabled }) => {
          return {
            url: `/tenants/${tenantId}`,
            method: 'PUT',
            body: { timezoneStandardName, rwGeoLocationTimezoneId, isDisabled },
          };
        }
      }),
    };
  },
});

export const { useFetchTenantsQuery, useUpdateTenantMutation } = tenantApi;
