/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useCallback, useEffect, useState } from "react";
import { Menu, Button } from "antd";
import { getLink } from '../../App-router';
import { MenuInfo } from 'rc-menu/lib/interface';
import { NavLink, useLocation } from "react-router-dom";
import { DefaultLinkName, DefaultTenantId } from '../../core/defaults';
import { RouteDefinition } from '../../core/route';
import { useAppDispatch } from '../../hooks';
import { setTenant } from '../../features/tenant/tenant-store';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams} from 'react-router-dom';
import { useFetchTenantsQuery } from '../../features/tenant/tenant-api';

function Sidenav( props: any ) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const auth0 = useAuth0();
  const routes: any = props.props;
  const params: any = useParams<any>();
  const { tenantId }: { tenantId: string } = params;
  const [selectedMenuItem, setSelectedMenuItem] = useState(DefaultLinkName);
  
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: tenants = [], isFetching } = useFetchTenantsQuery();
   
  // console.log(routes);

  const handleMenuClick = useCallback(
    (e: MenuInfo) => {
      const linkParams = {
        ...params,
        tenantId: params.tenantId ?? DefaultTenantId,
      };
      const menuLink = getLink(e.key, linkParams);
      setSelectedMenuItem(e.key);
      navigate(menuLink);
    },
    [navigate]
  );

  useEffect(() => {
    if (!isFetching && tenants.length > 0) {
      // If no tenant id parameter provided in the url, get the first available tenant and redirect
      if (!tenantId) {
        dispatch(setTenant(tenants[0]));
        navigate(getLink(DefaultLinkName, { ...params, tenantId: tenants[0].tenantId }));
      }
    }
  }, [tenants, isFetching]);

  return (
    <>
      {tenantId && (
              <Menu 
                onClick={handleMenuClick}
                defaultSelectedKeys={['1']}
                selectedKeys={[selectedMenuItem]}
              >
                {routes.filter((x:any) => x.showInNavigation)
                  .map((item:any, i:any) => (
                    <Menu.Item key={item.id}>
                    <NavLink to={item.path} key={item.id - i}>
                      <span
                        className="icon"
                      >
                      </span>
                      <span className="label">{item.title}</span>
                    </NavLink>

                    </Menu.Item>
                  ))}
              </Menu>
            )}
    </>
  );
}

export default Sidenav;
