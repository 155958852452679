import { Link, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import { Breadcrumb, Button, Card, Form, Select, Space, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined, SettingOutlined } from '@ant-design/icons';

import { getLink } from '../../../App-router';
import { useFetchTimezonesQuery } from '../settings-api';
import { useAppSelector } from '../../../hooks';
import { DefaultTenantId } from '../../../core/defaults';
import { useUpdateTenantMutation } from '../../tenant/tenant-api';

const { Option } = Select;

interface TimezoneSettingsScreenParams {
  tenantId?: string | undefined;
  [key: string]: string | undefined;
}

export default function TimezonesScreen(props: any) {
  const { tenantId = DefaultTenantId }: TimezoneSettingsScreenParams =
    useParams<TimezoneSettingsScreenParams>();

  const { name, rwGeoLocationTimezoneId, timezoneStandardName, isDisabled } = useAppSelector(
    (state) => state.tenant
  );

  const navigate = useNavigate();

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };

  const { data: timezones = [], isFetching } = useFetchTimezonesQuery();

  const [updateTenant] = useUpdateTenantMutation();

  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    var timeZone = timezones.find((t) => t.id === values.Timezone);

    const requestData = {
      tenantId: tenantId!,
      rwGeoLocationTimezoneId: values.Timezone,
      timezoneStandardName: timeZone?.standardName || null,
      isDisabled: isDisabled!,
    };

    const request = updateTenant(requestData);
    request.unwrap().then(() => {
      navigate(
        getLink('SettingsTimezones', {
          ...props,
          tenantId: tenantId,
        })
      );
    });
  };

  return (
    <div>
      <PageHeader
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <SettingOutlined />
              <span>Settings</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Timezone</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Card
          title={
            <Title
              level={4}
              style={{
                marginBottom: 0,
                display: 'inline-block',
              }}
            >
              Timezone - {name}
            </Title>
          }
        >
          <Spin spinning={false}>
            <Form
              {...layout}
              form={form}
              name="control-hooks"
              initialValues={{ Timezone: rwGeoLocationTimezoneId }}
              onFinish={onFinish}
            >
              <Form.Item name="Timezone" label="Timezone" rules={[{ required: true }]}>
                <Select className="form-select" disabled={isFetching}>
                  {timezones.map((timezone, index) => (
                    <Option key={index} value={timezone.id || ''}>
                      {timezone.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                <Space>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </PageHeader>
    </div>
  );
}
