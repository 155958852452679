import { apiSlice } from '../api/api-slice';
import { OutboundTransactionViewModel, PaginatedResult, OutboundTransactionFiltersViewModel } from '../api/models';

export interface OutboundTransactionListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
  hours?: number;
  referenceValue?: string | null;
}

export interface OutboundTransactionFiltersRequest {
  senderId?: string | null;
  receiverId?: string | null;
  tsCode?: string | null;
  status?: string | null;
  referenceValue?: string | null;
  time?: number;
}

export interface OutboundTransactionRequest {
  Id: number;
}

export interface DeleteOutboundTransactionRequest {
  Id: number;
}

export const outboundtransactionsApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchOutboundTransactions: builder.query<PaginatedResult<OutboundTransactionViewModel>, OutboundTransactionListRequest>({
        query({ current, pageSize, filter, sortBy, hours, referenceValue }) {
          return `/outboundtransactions?pageNumber=${current}&pageSize=${pageSize}&hours=${hours}&referenceValue=${referenceValue}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'OutboundTransaction',
          ...(data?.results?.map(({ id }) => ({ type: 'OutboundTransaction' as const, id: `${id}` })) || [])
        ] 
      }),
      fetchOutboundFilters: builder.query<OutboundTransactionFiltersViewModel, OutboundTransactionFiltersRequest>({
        query({senderId, receiverId, tsCode, status, time, referenceValue}) {
          return `/OutBoundTransactions/filters?interchangeSenderId=${senderId}&interchangeReceiverId=${receiverId}&transactionSetIdentifierCode=${tsCode}&Status:${status}&time=${time}&referenceValue=${referenceValue}`;
        },
        providesTags: (data, _error, arg) => 
        [
          'OutboundTransaction'
        ]
      }),
    };    
  },
});

export const {
  useFetchOutboundTransactionsQuery,
  useFetchOutboundFiltersQuery,
} = outboundtransactionsApi;
