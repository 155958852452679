import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  useCreatePartnerMutation,
  useFetchSinglePartnerQuery,
  useUpdatePartnerMutation,
} from '../partner-api';
import { DefaultTenantId } from '../../../core/defaults';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Space,
  Spin,
  Switch,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { Company, PaginatedResult, PartnerViewModel } from '../../api/models';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { CompanySearchRequest, useFetchCompaniesSearchQuery } from '../../companies/companies-api';
import { getLink } from '../../../App-router';

const { Option } = Select;

const isFetchBaseQueryErrorType = (error: any): error is FetchBaseQueryError =>
  error ? 'status' in error : false;

const getErrorMessage = (error: any): string | undefined => error.data;

interface PartnerScreenParams {
  partnerId?: string | undefined;
  tenantId?: string | undefined;
  [key: string]: string | undefined;
}

export default function PartnerEditScreen(props: any) {
  const { partnerId, tenantId = DefaultTenantId }: PartnerScreenParams =
    useParams<PartnerScreenParams>();

  const isNewPartner = partnerId === 'new';

  const { data, isFetching } = useFetchSinglePartnerQuery(
    {
      partnerId: Number(partnerId),
      tenantId,
    },
    { skip: isNewPartner }
  );

  const [companySearch, setCompanySearch] = useState<CompanySearchRequest>({
    name: '',
    pageNumber: 1,
    pageSize: 20,
  });

  const { data: companies }: { data?: PaginatedResult<Company> | undefined } =
    useFetchCompaniesSearchQuery(companySearch);

  const [createPartner, { isLoading: isCreating, error: createError }] = useCreatePartnerMutation();
  const [updatePartner, { isLoading: isUpdating, error: updateError }] = useUpdatePartnerMutation();

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [form] = Form.useForm();

  React.useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const onFinish = (values: any | PartnerViewModel) => {
    const requestData = {
      tenantId: tenantId,
      partner: {
        tenantId: tenantId,
        companyId: Number(values.companyId),
        partnerId: isNewPartner ? 0 : Number(partnerId),
        description: values.description,
        isActive: values.isActive,
        monitors: [],
      },
    };

    const request = isNewPartner ? createPartner(requestData) : updatePartner(requestData);
    request.unwrap().then(() => {
      message.success(
        `Partner ${requestData.partner.description} ${isNewPartner ? 'created' : 'saved'}`
      );
      navigate(
        getLink('PartnerList', {
          ...props,
          tenantId: tenantId,
        })
      );
    });
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };

  const handleSearch = (value: any): any => {
    setCompanySearch({
      name: value,
      pageNumber: 1,
      pageSize: 20,
    });
  };

  const handleCancel = () => {
    navigate(
      getLink('PartnerList', {
        ...props,
        tenantId: tenantId,
      })
    );
  };

  return (
    <div>
      <PageHeader
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={getLink('PartnerList', { ...props, tenantId })}>Partners</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{isNewPartner ? 'New Partner' : data?.companyName}</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {isFetchBaseQueryErrorType(createError) && (
          <Alert message="Error" description={getErrorMessage(createError)} type="error" showIcon />
        )}
        {isFetchBaseQueryErrorType(updateError) && (
          <Alert message="Error" description={getErrorMessage(updateError)} type="error" showIcon />
        )}
        <Card
          title={
            <Title
              level={4}
              style={{
                marginBottom: 0,
                display: 'inline-block',
              }}
            >
              {isNewPartner ? 'New Partner' : 'Edit Partner'}
            </Title>
          }
        >
          <Spin spinning={isFetching || isCreating || isUpdating}>
            <Form
              {...layout}
              form={form}
              name="control-hooks"
              initialValues={data}
              onFinish={onFinish}
            >
              <Form.Item name="companyId" label="Company" rules={[{ required: true }]}>
                <Select
                  placeholder="Search Company"
                  showSearch={true}
                  onSearch={handleSearch}
                  filterOption={false}
                  allowClear
                >
                  {[
                    data?.companyId && (
                      <Option key={data?.companyId} value={data?.companyId}>
                        {data?.companyName}
                      </Option>
                    ),
                    ...(companies?.results
                      ? companies?.results?.map((d: any) => (
                          <Option key={d.id} value={d.id}>
                            {d.name}
                          </Option>
                        ))
                      : []),
                  ]}
                </Select>
              </Form.Item>
              <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                <Input data-testid="partner-description" />
              </Form.Item>
              <Form.Item name="isActive" label="Active" valuePropName="checked" initialValue={true}>
                <Switch defaultChecked={true} />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                <Space>
                  <Button type="primary" htmlType="submit">
                    {isNewPartner ? 'Add Partner' : 'Save Partner'}
                  </Button>
                  <Button htmlType="button" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </PageHeader>
    </div>
  );
}
