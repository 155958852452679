import PartnerEDIX12sScreen from './screens/partneredix12s-screen';
import PartnerEDIX12EditScreen from './screens/partneredix12-edit-screen';

import OBRule997Screen from './screens/997OBRule-screen';
import { RouteDefinition } from '../../core/route';

export const partneredix12Routes: RouteDefinition[] = [
  {
    id: '997OBRule',
    title: '997 Outbound Rule',
    path: '997obrule/:Id',
    element: <OBRule997Screen />,
    showInNavigation: false,
  },
  {
    id: 'PartnerEDIX12Edit',
    title: 'Partner EDI X12',
    path: 'partneredix12s/:Id',
    element: <PartnerEDIX12EditScreen />,
    showInNavigation: false,
  },
  {
    id: 'PartnerEDIX12List',
    title: 'Partner EDI X12s',
    path: 'partneredix12s',
    element: <PartnerEDIX12sScreen />,
    showInNavigation: false,
  },
];
