import { apiSlice } from '../api/api-slice';
import { PartnerControlNumberViewModel, PaginatedResult } from '../api/models';

export interface PartnerControlNumberListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
}

export interface EditPartnerControlNumberRequest {
  partnercontrolnumber: PartnerControlNumberViewModel;
}

export interface PartnerControlNumberRequest {
  Id: number;
}

export interface DeletePartnerControlNumberRequest {
  Id: number;
}

export const partnercontrolnumbersApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchPartnerControlNumbers: builder.query<PaginatedResult<PartnerControlNumberViewModel>, PartnerControlNumberListRequest>({
        query({ current, pageSize, filter, sortBy }) {
          return `/partnercontrolnumbers?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'PartnerControlNumber',
          ...(data?.results?.map(({ id }) => ({ type: 'PartnerControlNumber' as const, id: `${id}` })) || [])
        ] 
      }),
      fetchSinglePartnerControlNumber: builder.query<PartnerControlNumberViewModel, PartnerControlNumberRequest>({
        query({ Id }) {
          return `/partnercontrolnumbers/${Id}`;
        },
        providesTags: (_data, _error, arg) => [{ type: 'PartnerControlNumber', id: `${arg.Id}` }]
      }),
      createPartnerControlNumber: builder.mutation<PartnerControlNumberViewModel, EditPartnerControlNumberRequest>({
        query: ({ partnercontrolnumber }) => {
          return {
            url: `/partnercontrolnumbers`,
            method: 'POST',
            body: partnercontrolnumber,
          };
        },
        invalidatesTags: ['PartnerControlNumber']
      }),
      updatePartnerControlNumber: builder.mutation<PartnerControlNumberViewModel, EditPartnerControlNumberRequest>({
        query: ({ partnercontrolnumber }) => {
          return {
            url: `/partnercontrolnumbers/${partnercontrolnumber.id}`,
            method: 'PUT',
            body: partnercontrolnumber,
          };
        },
        invalidatesTags: (_data, _error, arg) => [{ type: 'PartnerControlNumber', id: `${arg.partnercontrolnumber.id}` }]
      }),
      deletePartnerControlNumber: builder.mutation<void, DeletePartnerControlNumberRequest>({
        query: ({ Id }) => ({
          url: `/partnercontrolnumbers/${Id}`,
          method: 'DELETE',
          body: {},
        }),
        invalidatesTags: (_data, _error, arg) => ['PartnerControlNumber']
      })
    };
  },
});

export const {
  useFetchPartnerControlNumbersQuery,
  useFetchSinglePartnerControlNumberQuery,
  useCreatePartnerControlNumberMutation,
  useUpdatePartnerControlNumberMutation,
  useDeletePartnerControlNumberMutation,
} = partnercontrolnumbersApi;
