import { AntdConfig, Config } from '@react-awesome-query-builder/antd';

const InitialConfig = AntdConfig;

const config: Config = {
  ...InitialConfig,
  fields: {
    customerId: {
      label: 'CustomerID',
      type: 'number',
      valueSources: ['value'],
    },
    sourceId: {
      label: 'SourceID',
      type: 'number',
      valueSources: ['value'],
    },
    quoteStatusTypeId: {
      label: 'QuoteStatusTypeID',
      type: 'number',
      valueSources: ['value'],
    },
  },
};

export default config;
