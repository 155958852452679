import React from 'react';
import { Tag } from 'antd';
import { AlertLevelEnum } from '../../api/models';

const getAlertStatusBadge = (status: AlertLevelEnum) => {
    const { color, text } = getAlertLevelDetails(status)
    return (<Tag color={color} key={status}> {text} </Tag>);
}

const getAlertLevelDetails = (status: AlertLevelEnum): any => {
    const statusOptions = [
        { key: AlertLevelEnum.Ok, color: 'green', text: 'OK' },
        { key: AlertLevelEnum.Warning, color: 'yellow', text: 'WARNING' },
        { key: AlertLevelEnum.Error, color: 'volcano', text: 'ERROR' }
    ]

    return statusOptions.find(option => option.key === status)
};

export default getAlertStatusBadge;