import { apiSlice } from '../api/api-slice';
import { CodeDecodeMapViewModel, PaginatedResult } from '../api/models';

export interface CodeDecodeMapListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
}

export interface EditCodeDecodeMapRequest {
  codedecodemap: CodeDecodeMapViewModel;
}

export interface CodeDecodeMapRequest {
  Id: number;
}

export interface DeleteCodeDecodeMapRequest {
  Id: number;
}

export const codedecodemapsApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchCodeDecodeMaps: builder.query<PaginatedResult<CodeDecodeMapViewModel>, CodeDecodeMapListRequest>({
        query({ current, pageSize, filter, sortBy }) {
          return `/codedecodemaps?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        
        forceRefetch({ currentArg, previousArg }) {
          return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'CodeDecodeMap',
          ...(data?.results?.map(({ id }) => ({ type: 'CodeDecodeMap' as const, id: `${id}` })) || [])
        ] 
      }),
      fetchSingleCodeDecodeMap: builder.query<CodeDecodeMapViewModel, CodeDecodeMapRequest>({
        query({ Id }) {
          return `/codedecodemaps/${Id}`;
        },
        providesTags: (_data, _error, arg) => [{ type: 'CodeDecodeMap', id: `${arg.Id}` }]
      }),
      createCodeDecodeMap: builder.mutation<CodeDecodeMapViewModel, EditCodeDecodeMapRequest>({
        query: ({ codedecodemap }) => {
          return {
            url: `/codedecodemaps`,
            method: 'POST',
            body: codedecodemap,
          };
        },
        invalidatesTags: ['CodeDecodeMap']
      }),
      updateCodeDecodeMap: builder.mutation<CodeDecodeMapViewModel, EditCodeDecodeMapRequest>({
        query: ({ codedecodemap }) => {
          return {
            url: `/codedecodemaps/${codedecodemap.id}`,
            method: 'PUT',
            body: codedecodemap,
          };
        },
        invalidatesTags: (_data, _error, arg) => [{ type: 'CodeDecodeMap', id: `${arg.codedecodemap.id}` }]
      }),
      deleteCodeDecodeMap: builder.mutation<void, DeleteCodeDecodeMapRequest>({
        query: ({ Id }) => ({
          url: `/codedecodemaps/${Id}`,
          method: 'DELETE',
          body: {},
        }),
        invalidatesTags: (_data, _error, arg) => ['CodeDecodeMap']
      })
    };
  },
});

export const {
  useFetchCodeDecodeMapsQuery,
  useFetchSingleCodeDecodeMapQuery,
  useCreateCodeDecodeMapMutation,
  useUpdateCodeDecodeMapMutation,
  useDeleteCodeDecodeMapMutation,
} = codedecodemapsApi;
