import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  AcknowledgmentOverrideReportEmailListRequest,
  useFetchAcknowledgmentOverrideReportEmailsQuery,
} from '../acknowledgmentoverridereportemail-api';
import AuditLogsScreen from '../../../components/common/auditLogs';
import dayjs from "dayjs";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Badge,
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Space,
  Table,
  Tooltip
} from 'antd';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
  MinusCircleFilled,
  MoreOutlined,
  UserOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { AcknowledgmentOverrideReportEmailViewModel, PaginatedResult } from '../../api/models';
import { DefaultPage, DefaultPageSize, DefaultPollingInterval } from '../../../core/defaults';
import Title from 'antd/lib/typography/Title';
import { getLink } from '../../../App-router';
import { TableState } from '../../../components/table/model';
import getSortBy from '../../../components/table/sorter';
import { ConvertUTCtoCDT } from '../../../core/utils';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function AcknowledgmentOverrideReportEmailsScreen(props: any) {
  const query = useQuery();
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const [showAuditLogs, setShowAuditLogs] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<number>(0);
  // console.log('AcknowledgmentOverrideReportEmailsScreen', params);
  const AcknowledgmentOverrideReportEmailTable: TableState = {
    columns: [
      {
        title: 'Id',
        dataIndex: '',
        key: 'Id',
        render: (AcknowledgmentOverrideReportEmail: AcknowledgmentOverrideReportEmailViewModel) => (
          <>
              <Space>
                <span>{AcknowledgmentOverrideReportEmail.id}</span>
              </Space>
          </>
        ),
        sorter: true,
      },
      {
        title: 'Name',
        dataIndex: '',
        key: 'name',
        render: (AcknowledgmentOverrideReportEmail: AcknowledgmentOverrideReportEmailViewModel) => (
          <>
              <Tooltip title={AcknowledgmentOverrideReportEmail.name} >
            <span>{AcknowledgmentOverrideReportEmail.name}</span>
            </Tooltip>
          </>
        ),
        sorter: true,
      },
      {
        title: 'ReceipientEmail',
        dataIndex: '',
        key: 'receipientEmail',
        render: (AcknowledgmentOverrideReportEmail: AcknowledgmentOverrideReportEmailViewModel) => (
          <>
          <Tooltip title={AcknowledgmentOverrideReportEmail.receipientEmail} >
            <span>{AcknowledgmentOverrideReportEmail.receipientEmail}</span>
            </Tooltip>
          </>
        ),
        sorter: true,
      },
      {
        title: 'Frequency',
        key: 'frequency',
        dataIndex: '',
        render: (AcknowledgmentOverrideReportEmail: AcknowledgmentOverrideReportEmailViewModel) => (
          <>
           <Tooltip title={AcknowledgmentOverrideReportEmail.frequency} >
            <span>{AcknowledgmentOverrideReportEmail.frequency}</span>
            </Tooltip>
          </>
        ),
        sorter: true,
      },
      {
        title: 'StartDate',
        dataIndex: '',
        key: 'startDate',
        render: (AcknowledgmentOverrideReportEmail: AcknowledgmentOverrideReportEmailViewModel) => (
          <>
              <Tooltip title={dayjs(AcknowledgmentOverrideReportEmail?.startDate).format('MM/DD/YYYY')} >
            <span>{dayjs(AcknowledgmentOverrideReportEmail?.startDate).format('MM/DD/YYYY')}</span>
            </Tooltip>
          </>
        ),
        sorter: true,
      },
      {
        title: 'LastExecutionDate',
        dataIndex: '',
        key: 'lastExecutionDate',
        render: (AcknowledgmentOverrideReportEmail: AcknowledgmentOverrideReportEmailViewModel) => (
          <>
          <Tooltip title={dayjs(AcknowledgmentOverrideReportEmail?.lastExecutionDate).format('MM/DD/YYYY')} >
            <span>
              {AcknowledgmentOverrideReportEmail?.lastExecutionDate=='' || AcknowledgmentOverrideReportEmail?.lastExecutionDate==null?null:dayjs(AcknowledgmentOverrideReportEmail?.lastExecutionDate).format('MM/DD/YYYY')}
              {/* {dayjs(AcknowledgmentOverrideReportEmail?.lastExecutionDate).format('MM/DD/YYYY')} */}
              </span>
            </Tooltip>
          </>
        ),
        sorter: true,
      },
      {
        title: 'IsActive',
        dataIndex: '',
        key: 'isActive',
        render: (AcknowledgmentOverrideReportEmail: AcknowledgmentOverrideReportEmailViewModel) => (
          <>
            <span>{AcknowledgmentOverrideReportEmail.isActive?'Yes':'No'}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'Actions',
        key: 'action',
        dataIndex: '',
        fixed:'right',
        render: (AcknowledgmentOverrideReportEmail: AcknowledgmentOverrideReportEmailViewModel) => (
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            overlay={
              <Menu style={{ padding: '8px 8px' }}>
                <Menu.Item icon={<EditOutlined />} key="1">
                  <Link
                    to={getLink('AcknowledgmentOverrideReportEmailEdit', {
                      tenantId: params.tenantId,
                      Id: AcknowledgmentOverrideReportEmail.id,
                    })}
                  >
                    View
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<InfoCircleOutlined />} key="2">
                <Link to="#" onClick={()=>setShowAuditLogs(true)}>
                    Audit Logs
                  </Link>
                </Menu.Item>
              </Menu>
            }
          >
            <MoreOutlined />
          </Dropdown>
        ),
      },
    ],
  };

  const getCurrentState = (): AcknowledgmentOverrideReportEmailListRequest => ({
    current: Number(query.get('p')) || DefaultPage,
    pageSize: Number(query.get('size')) || DefaultPageSize,
    filter: query.get('q'),
    sortBy: query.get('sortBy') || 'EnteredByDate Desc',
  });

  const [pagination, setPagination] = useState<AcknowledgmentOverrideReportEmailListRequest>(getCurrentState());

  const {
    data: pagedData = {},
    isFetching,
    error,
  }: {
    data?: PaginatedResult<AcknowledgmentOverrideReportEmailViewModel> | undefined;
    isFetching?: boolean | undefined;
    error?: any;
  } = useFetchAcknowledgmentOverrideReportEmailsQuery(pagination, { pollingInterval: DefaultPollingInterval });

  useEffect(() => {
    setPagination(getCurrentState());
  }, [params]);

  const onCreateNew = useCallback(
    () => navigate(getLink('AcknowledgmentOverrideReportEmailEdit', { ...params, Id: 'new' })),
    [params, navigate]
  );

  const handleTableChange = (
    paginationConfig: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<AcknowledgmentOverrideReportEmailViewModel> | SorterResult<AcknowledgmentOverrideReportEmailViewModel>[],
    extra: TableCurrentDataSource<AcknowledgmentOverrideReportEmailViewModel>
  ) => {
    const { columnKey, order } = sorter as SorterResult<AcknowledgmentOverrideReportEmailViewModel>;
    if (columnKey && order) {
      query.set('sortBy', getSortBy(columnKey.toString(), order));
    } else {
      query.set('sortBy', '');
    }

    navigate(
      getLink(
        'AcknowledgmentOverrideReportEmailList',
        { ...params },
        {
          ...query,
          sortBy: query.get('sortBy'),
          p: paginationConfig.current,
          size: paginationConfig.pageSize,
          q: pagination.filter || '',
        }
      )
    );
  };

  const handleSearch = (searchText: string) => {
    navigate(
      getLink(
        'AcknowledgmentOverrideReportEmailList',
        { ...params },
        {
          ...query,
          p: DefaultPage,
          q:  encodeURIComponent(searchText?.length ? `Name:*${searchText.replaceAll(" ", "\\ ")}*` : ''),
        }
      )
    );
  };

  return (
    <div>
      <PageHeader
        extra={
          <Button htmlType="button" data-testid={'create-new-AcknowledgmentOverrideReportEmail'} onClick={onCreateNew}>
            New Acknowledgement Reconciliation Report
          </Button>
        }
        title={
          <Title
            level={4}
            style={{
              marginBottom: 0,
              display: 'inline-block',
            }}
          >
            Acknowledgement Reconciliation Reports
          </Title>
        }
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: params.tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: params.tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="AcknowledgmentOverrideReportEmails">
              <Link to={getLink('AcknowledgmentOverrideReportEmailList', { tenantId: params.tenantId })}>Acknowledgement Reconciliation Reports</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Card>
          {error?.status && (
            <Alert message="Error" description={error.data} type="error" showIcon />
          )}          
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Input.Search
              placeholder="Search by Name"
              onSearch={handleSearch}
              enterButton
              style={{ width: 400 }}
              loading={isFetching}
              allowClear
            />
            <Table
              scroll={{ x: true }}
              dataSource={pagedData.results}
              onChange={handleTableChange}
              rowKey={'id'}
              pagination={{
                ...pagination,
                total: pagedData.totalCount,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ["10", "20", "50","100"],
                showSizeChanger: true,
                locale: { items_per_page: " / Page" }
              }}
              columns={AcknowledgmentOverrideReportEmailTable.columns}
              loading={isFetching}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => { 
                    setEntityId(record?.id||0);
                  },
                };
              }}
            />
          </Space>
        </Card>
        {showAuditLogs?<AuditLogsScreen entityId={entityId} entityName={'AcknowledgementReconciliationReport'} showAuditLogs={setShowAuditLogs}/>:<></>}
      </PageHeader>
    </div>
  );
}
