import { apiSlice } from '../api/api-slice';
import { AcknowledgmentOverrideReportEmailViewModel, PaginatedResult } from '../api/models';

export interface AcknowledgmentOverrideReportEmailListRequest {
  pageSize: number;
  filter?: string | null;
  current: number;
  total?: number;
  sortBy?: string | null;
}

export interface EditAcknowledgmentOverrideReportEmailRequest {
  AcknowledgmentOverrideReportEmail: AcknowledgmentOverrideReportEmailViewModel;
}

export interface AcknowledgmentOverrideReportEmailRequest {
  Id: number;
}

export interface DeleteAcknowledgmentOverrideReportEmailRequest {
  Id: number;
}

export const AcknowledgmentOverrideReportEmailsApi = apiSlice.injectEndpoints({
  
  endpoints(builder) {
    return {
      fetchAcknowledgmentOverrideReportEmails: builder.query<PaginatedResult<AcknowledgmentOverrideReportEmailViewModel>, AcknowledgmentOverrideReportEmailListRequest>({
        query({ current, pageSize, filter, sortBy }) {
          return `/acknowledgementReconciliationReportEmails?pageNumber=${current}&pageSize=${pageSize}${filter ? '&filter=' + filter : ''}${sortBy ? '&sortBy=' + sortBy : ''}`;
        },
        forceRefetch({ currentArg, previousArg }) {
         return true;
        },
        providesTags: (data, _error, arg) => 
        [
          'AcknowledgmentOverrideReportEmail',
          ...(data?.results?.map(({ id }) => ({ type: 'AcknowledgmentOverrideReportEmail' as const, id: `${id}` })) || [])
        ] 
      }),
      fetchSingleAcknowledgmentOverrideReportEmail: builder.query<AcknowledgmentOverrideReportEmailViewModel, AcknowledgmentOverrideReportEmailRequest>({
        query({ Id }) {
          return `/acknowledgementReconciliationReportEmails/${Id}`;
        },        
        forceRefetch({ currentArg, previousArg }) {
          return true;
         },
        providesTags: (_data, _error, arg) => [{ type: 'AcknowledgmentOverrideReportEmail', id: `${arg.Id}` }]
      }),
      createAcknowledgmentOverrideReportEmail: builder.mutation<AcknowledgmentOverrideReportEmailViewModel, EditAcknowledgmentOverrideReportEmailRequest>({
        query: ({ AcknowledgmentOverrideReportEmail }) => {
          return {
            url: `/acknowledgementReconciliationReportEmails`,
            method: 'POST',
            body: AcknowledgmentOverrideReportEmail,
          };
        },
        invalidatesTags: ['AcknowledgmentOverrideReportEmail']
      }),
      updateAcknowledgmentOverrideReportEmail: builder.mutation<AcknowledgmentOverrideReportEmailViewModel, EditAcknowledgmentOverrideReportEmailRequest>({
        query: ({ AcknowledgmentOverrideReportEmail }) => {
          return {
            url: `/acknowledgementReconciliationReportEmails/${AcknowledgmentOverrideReportEmail.id}`,
            method: 'PUT',
            body: AcknowledgmentOverrideReportEmail,
          };
        },
        invalidatesTags: (_data, _error, arg) => [{ type: 'AcknowledgmentOverrideReportEmail', id: `${arg.AcknowledgmentOverrideReportEmail.id}` }]
      }),
    };
  },
});

export const {
  useFetchAcknowledgmentOverrideReportEmailsQuery,
  useFetchSingleAcknowledgmentOverrideReportEmailQuery,
  useCreateAcknowledgmentOverrideReportEmailMutation,
  useUpdateAcknowledgmentOverrideReportEmailMutation,
} = AcknowledgmentOverrideReportEmailsApi;
