import React, { useCallback, useEffect, useState } from 'react';
import { TablePageRequest, useDeleteMonitorMutation, useFetchMonitorsQuery } from '../monitor-api';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Table,
  Input,
  Dropdown,
  Menu,
  Modal,
  message,
} from 'antd';
import {
  DashboardOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
  MonitorOutlined,
  MoreOutlined,
} from '@ant-design/icons';

import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { MonitorViewModel, PaginatedResult, PartnerViewModel } from '../../api/models';
import { DefaultPage, DefaultPageSize, DefaultPollingInterval } from '../../../core/defaults';
import Title from 'antd/lib/typography/Title';
import { getLink } from '../../../App-router';
import { TableState } from '../../../components/table/model';
import getSortBy from '../../../components/table/sorter';
import getMonitorStatusBadge from '../components/monitor-status-badge';
import { useFetchSinglePartnerQuery } from '../../partners/partner-api';
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function MonitorsScreen(props: any) {
  const query = useQuery();
  const params: any = useParams<any>();
  const navigate = useNavigate();

  const { Search } = Input;
  const [deleteMonitor, { isLoading: isDeleteLoading, error: deleteError }] =
    useDeleteMonitorMutation();
  const { confirm } = Modal;

  const getCurrentState = (): TablePageRequest => ({
    tenantId: params.tenantId,
    current: Number(query.get('p')) || DefaultPage,
    pageSize: Number(query.get('size')) || DefaultPageSize,
    partnerId: Number(query.get('partnerId')),
    filter: query.get('q'),
    sortBy: query.get('sortBy'),
  });

  const [pagination, setPagination] = useState<TablePageRequest>(getCurrentState());

  const {
    data: pagedData = {},
    isFetching,
    error,
  }: {
    data?: PaginatedResult<MonitorViewModel> | undefined;
    isFetching?: boolean | undefined;
    error?: any;
  } = useFetchMonitorsQuery(pagination, { pollingInterval: DefaultPollingInterval });

  const {
    data: partnerData = {},
  }: {
    data?: PartnerViewModel;
  } = useFetchSinglePartnerQuery({
    tenantId: params.tenantId,
    partnerId: Number(query.get('partnerId')),
  });

  useEffect(() => {
    setPagination(getCurrentState());
  }, [params]);

  const renderDropDown = (monitor: MonitorViewModel) => (
    <Dropdown
      trigger={['click']}
      placement="bottomRight"
      overlay={
        <Menu style={{ padding: '8px 8px' }}>
          <Menu.Item icon={<EditOutlined />} key="1">
            <Link
              to={getLink('MonitorItem', {
                tenantId: params.tenantId,
                monitorId: monitor.monitorId,
              })}
            >
              Edit
            </Link>
          </Menu.Item>
          <Menu.Item
            icon={<DeleteOutlined />}
            key="2"
            onClick={() => {
              confirm({
                title: (
                  <span>
                    Delete monitor <b>{monitor.description}</b>?
                  </span>
                ),
                icon: <ExclamationCircleOutlined />,
                onOk() {
                  handleDelete(monitor);
                },
              });
            }}
          >
            Delete
          </Menu.Item>
          <Menu.Item icon={<DashboardOutlined />} key="3">
            <Link
              to={getLink(
                'TelemetryEvents',
                { tenantId: params.tenantId },
                { monitorId: monitor.monitorId, partnerId: Number(query.get('partnerId')) }
              )}
            >
              Telemetry Events
            </Link>
          </Menu.Item>
        </Menu>
      }
    >
      <MoreOutlined />
    </Dropdown>
  );

  const tableMonitors: TableState = {
    columns: [
      {
        title: 'Description',
        dataIndex: '',
        key: 'description',
        render: (monitor: MonitorViewModel) => (
          <>
            <Link to={getLink('MonitorItem', { ...params, monitorId: monitor.monitorId })}>
              <span>{monitor.description}</span>
            </Link>
          </>
        ),
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: getMonitorStatusBadge,
      },
      {
        title: '',
        key: 'action',
        dataIndex: '',
        render: renderDropDown,
      },
    ],
  };

  const onCreateNew = useCallback(() => {
    console.log('Partner', pagination.partnerId);
    navigate(
      getLink(
        'MonitorItem',
        { ...props, ...params, monitorId: 'new' },
        { partnerId: pagination.partnerId }
      )
    );
  }, [params, navigate]);

  const handleTableChange = (
    paginationConfig: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<MonitorViewModel> | SorterResult<MonitorViewModel>[],
    extra: TableCurrentDataSource<MonitorViewModel>
  ) => {
    const { columnKey, order } = sorter as SorterResult<MonitorViewModel>;
    if (columnKey && order) {
      query.set('sortBy', getSortBy(columnKey.toString(), order));
    } else {
      query.set('sortBy', '');
    }

    navigate(
      getLink(
        'MonitorList',
        { ...params },
        {
          ...query,
          partnerId: query.get('partnerId'),
          sortBy: query.get('sortBy'),
          p: paginationConfig.current,
          size: paginationConfig.pageSize,
          q: pagination.filter || '',
        }
      )
    );
  };

  function onSearch(searchText: string) {
    navigate(
      getLink(
        'MonitorList',
        { ...params },
        {
          ...query,
          partnerId: query.get('partnerId'),
          p: DefaultPage,
          q: searchText,
        }
      )
    );
  }

  const handleDelete = async (monitor: MonitorViewModel) => {
    try {
      await deleteMonitor({ tenantId: params.tenantId, monitor: monitor }).unwrap();
      message.success(`Monitor ${monitor.description} deleted`);
    } catch (error) {
      console.error(error);
      message.error(`Error deleting monitor ${monitor.description}`);
    }
  };

  return (
    <div>
      <PageHeader
        title={
          <Title
            level={4}
            style={{
              marginBottom: 0,
              display: 'inline-block',
            }}
          >
            Monitors
          </Title>
        }
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: params.tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <BreadcrumbItem>
              <span>{partnerData.companyName}</span>
            </BreadcrumbItem>
            <Breadcrumb.Item>
              <MonitorOutlined />
              <span>Monitor List</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Card>
          {error?.status && (
            <Alert message="Error" description={error.data} type="error" showIcon />
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
            <Search
              data-testid="search-monitors"
              placeholder="Search"
              onSearch={onSearch}
              style={{ width: 463 }}
            />
            <Button
              type="default"
              data-testid="create-new-monitor"
              style={{}}
              onClick={onCreateNew}
            >
              New Monitor
            </Button>
          </div>
          <Table
            dataSource={pagedData.results}
            onChange={handleTableChange}
            rowKey={'monitorId'}
            pagination={{
              ...pagination,
              total: pagedData.totalCount,
              showTotal: (total: number) => `Total ${total} items`,
            }}
            columns={tableMonitors.columns}
            loading={isFetching}
          />
        </Card>
      </PageHeader>
    </div>
  );
}
