import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Breadcrumb,
  Button,
  Card,
  Row,
  Col,
  ConfigProvider
} from 'antd';
import {
  HomeOutlined,
  TruckOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import { getLink } from '../../../App-router';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function HomeScreen(props: any) {
  const query = useQuery();
  const params: any = useParams<any>();
  const navigate = useNavigate();
  // console.log('HomeScreen', params);

  return (
    <div>
      <PageHeader >
        <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#BC0001',
                            colorText: '#FFFFFF',
                            fontSize: 15
                        },
                        components: {
                            Card: {
                                colorBgContainer: '#BC0001',
                            }
                        }
                    }}
                >
        <Row gutter={[16, 16]} style={{
            textAlign: 'center',
            justifyContent: 'center',
            minHeight: '80vh',
            alignItems: 'center'
          }} >
                        <Col flex="12.5rem">
                            <Link to={getLink('PartnerList', { tenantId: params.tenantId })}>
                                <Card style={{
                  height: '122px', alignItems: 'center',
                  display: 'flex'
                }}>
                                    <ClockCircleOutlined style={{ display: 'block', margin: '0 auto 0.5rem', fontSize: '24px' }} />
                                    Partner Management
                                </Card>
                            </Link>
                        </Col>
                        <Col flex="12.5rem">
                            <Link to={getLink('cob', { tenantId: params.tenantId })}>
                                <Card style={{
                  height: '122px', alignItems: 'center',
                  display: 'flex'
                }}>
                                    <TruckOutlined style={{ display: 'block', margin: '0 auto 0.5rem', fontSize: '24px' }} />
                                    Partner Integrations
                                </Card>
                            </Link>
                        </Col>
                    </Row>
      </ConfigProvider>
      </PageHeader>
    </div>
  );
}
