import React, { Component, useState, useCallback, useEffect } from 'react';
import {
  Query,
  Builder,
  Utils,
  ImmutableTree,
  Config,
  BuilderProps,
  JsonTree,
  JsonLogicTree,
} from '@react-awesome-query-builder/antd';
import '@react-awesome-query-builder/antd/css/styles.css';
import {
  useFetchSingleMonitorQuery,
  useCreateMonitorMutation,
  useUpdateMonitorMutation,
  useTestMonitorMutation,
  CreateOrEditMonitorRequest,
} from '../monitor-api';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Select,
  Spin,
  Switch,
  Space,
  TimePicker,
  Divider,
  message,
  Breadcrumb,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import {
  DeleteOutlined,
  PlusOutlined,
  HomeOutlined,
  MonitorOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import { AlertLevelEnum, MonitorThresholdWindow, MonitorViewModel } from '../../api/models';
import { getLink } from '../../../App-router';
import { DefaultTenantId } from '../../../core/defaults';
import moment, { Moment } from 'moment';
import rwcQueryBuilderConfig from './rwcQueryBuilderConfig';
import quoteQueryBuilderConfig from './quoteQueryBuilderConfig';
import TextArea from 'antd/lib/input/TextArea';
import { PageHeader } from '@ant-design/pro-layout';

const { Option } = Select;
const { sqlFormat, getTree, checkTree, loadTree } = Utils;

interface MonitorScreenParams {
  [key: string]: string | undefined;
  monitorId: string | undefined;
  tenantId?: string | undefined;
  partnerId: string | undefined;
}

interface MonitorFormValues {
  name?: string | undefined;
  frequency?: number;
  isDisabled?: boolean;
  lastRun?: Date;
  queryTree: ImmutableTree;
  sqlRaw: string;
  isSqlMode: boolean;
  rwConnectTenantIds?: string[] | undefined;
  rwConnectEnvironment?: string | undefined;
  thresholdMon?: MonitorFormThresholdValues[] | undefined;
  thresholdTue?: MonitorFormThresholdValues[] | undefined;
  thresholdWed?: MonitorFormThresholdValues[] | undefined;
  thresholdThu?: MonitorFormThresholdValues[] | undefined;
  thresholdFri?: MonitorFormThresholdValues[] | undefined;
  thresholdSat?: MonitorFormThresholdValues[] | undefined;
  thresholdSun?: MonitorFormThresholdValues[] | undefined;
  monitorSourceId: number;
  thresholdExclusive?: number;
}

interface MonitorFormThresholdValues {
  thresholdWindowId?: number | undefined;
  monitorId?: number | undefined;
  timeRange?: Moment[] | undefined;
  minThreshold?: number | undefined;
  maxThreshold?: number | undefined;
  rangeTimeframe?: number | undefined;
  alertLevelId?: AlertLevelEnum | undefined;
  thresholdExclusive?: number | undefined;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const formItemLayout: any = { offset: 4, span: 8 };

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

const thresholds = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const defaultMonitorSourceId = 2;

//set the initial config and tree for the query builder
const emptyInitValue: JsonTree = { id: '00000000-0000-0000-0000-000000000000', type: 'group' };
const initTree: ImmutableTree = checkTree(loadTree(emptyInitValue), quoteQueryBuilderConfig);
const initialFormValues: MonitorFormValues = {
  name: '',
  frequency: 5,
  isDisabled: false,
  lastRun: undefined,
  queryTree: initTree,
  sqlRaw: '',
  isSqlMode: false,
  rwConnectTenantIds: [],
  rwConnectEnvironment: 'Prod',
  thresholdMon: [],
  thresholdTue: [],
  thresholdWed: [],
  thresholdThu: [],
  thresholdFri: [],
  thresholdSat: [],
  thresholdSun: [],
  monitorSourceId: defaultMonitorSourceId,
};

export default function MonitorScreen(props: any) {
  const [deletedThresholds, setDeletedThresholds] = useState<number[]>([]);

  const [isSqlMode, setIsSqlMode] = useState(false);
  const [isRWCMonitor, setIsRWCMonitor] = useState(false);
  const [queryBuilderConfig, setQueryBuilderConfig] = useState(quoteQueryBuilderConfig);
  const [currentMonitorSourceId, setCurrentMonitorSourceId] = useState(defaultMonitorSourceId);

  const urlQuery = new URLSearchParams(useLocation().search);

  const { monitorId, tenantId = DefaultTenantId, partnerId } = useParams<MonitorScreenParams>();

  const isNewMonitor = monitorId === 'new';

  const { data: monitorData, isFetching } = useFetchSingleMonitorQuery(
    { tenantId, monitorId: Number(monitorId) },
    {
      skip: isNewMonitor,
    }
  );

  const [createMonitor, { isLoading: isCreating, error: createError }] = useCreateMonitorMutation();
  const [updateMonitor, { isLoading: isUpdating, error: updateError }] = useUpdateMonitorMutation();
  const [testMonitor, { isLoading: isTesting, error: testError }] = useTestMonitorMutation();

  const navigate = useNavigate();
  const goBack = useCallback(() => {
    //go back using the browser history
    navigate(-1);
  }, [navigate]);

  const [form] = Form.useForm();

  useEffect(() => {
    setIsSqlMode(
      monitorData?.jsonClause === '' &&
        monitorData?.sqlClause !== '' &&
        monitorData.monitorSourceId === 1
    );
    setIsRWCMonitor(monitorData?.monitorSourceId === 1);
    setQueryBuilderConfig(
      getQueryBuilderConfig(monitorData?.monitorSourceId ?? currentMonitorSourceId)
    );
    setCurrentMonitorSourceId(defaultMonitorSourceId);
  }, [monitorData]);

  useEffect(() => {
    if (monitorData) {
      let tree =
        monitorData.jsonClause !== ''
          ? checkTree(
              loadTree(JSON.parse(monitorData!.jsonClause!) as JsonTree),
              queryBuilderConfig
            )
          : initTree;

      const isSqlMode = monitorData?.jsonClause === '' && monitorData?.sqlClause !== '';

      let newFormValues: MonitorFormValues = {
        ...monitorData,
        sqlRaw: monitorData!.sqlClause!,
        queryTree: tree,
        isSqlMode: isSqlMode,
      };

      if (monitorData.thresholdWindows && monitorData.thresholdWindows.length > 0) {
        newFormValues.thresholdMon = groupThresholdWindowsByDay(
          'monday',
          monitorData.thresholdWindows
        );
        newFormValues.thresholdTue = groupThresholdWindowsByDay(
          'tuesday',
          monitorData.thresholdWindows
        );
        newFormValues.thresholdWed = groupThresholdWindowsByDay(
          'wednesday',
          monitorData.thresholdWindows
        );
        newFormValues.thresholdThu = groupThresholdWindowsByDay(
          'thursday',
          monitorData.thresholdWindows
        );
        newFormValues.thresholdFri = groupThresholdWindowsByDay(
          'friday',
          monitorData.thresholdWindows
        );
        newFormValues.thresholdSat = groupThresholdWindowsByDay(
          'saturday',
          monitorData.thresholdWindows
        );
        newFormValues.thresholdSun = groupThresholdWindowsByDay(
          'sunday',
          monitorData.thresholdWindows
        );
      }

      form.setFieldsValue(newFormValues);
    }
  }, [monitorData, form]);

  const onQueryChange = (immutableTree: ImmutableTree, config: Config) => {
    form.setFieldsValue({ queryTree: immutableTree });
  };

  const onIsSqlModeChange = (checked: boolean) => {
    setIsSqlMode(checked);
  };

  function getQueryBuilderConfig(monitorSourceId: number) {
    switch (monitorSourceId) {
      case 1: {
        return rwcQueryBuilderConfig;
      }
      case 2: {
        return quoteQueryBuilderConfig;
      }
      default: {
        return quoteQueryBuilderConfig;
      }
    }
  }

  function getPartnerId(): number {
    return Number(urlQuery.get('partnerId') ?? partnerId ?? monitorData?.partnerId);
  }

  const onFinish = (values: any | MonitorViewModel) => {
    const formValues = form.getFieldsValue();
    const jsonTree = getTree(formValues.queryTree);
    const sqlClause = isSqlMode
      ? values.sqlRaw
      : sqlFormat(formValues.queryTree, queryBuilderConfig);

    if (!sqlClause) {
      message.error('The query field is required');
      return;
    }

    const monitorRequestData: CreateOrEditMonitorRequest = {
      tenantId: tenantId,
      monitor: {
        monitorId: isNewMonitor ? 0 : Number(monitorId),
        partnerId: getPartnerId(),
        description: values.description,
        rwConnectTenantIds: values.rwConnectTenantIds?.map((t: string) => parseInt(t)),
        frequency: values.frequency,
        isDisabled: values.isDisabled,
        lastRun: new Date(),
        sqlClause: sqlClause,
        jsonClause: jsonTree && !isSqlMode ? JSON.stringify(jsonTree, undefined, 2) : '',
        thresholdWindows: getThresholdWindows(values),
        notificationList:
          values.notificationList && values.notificationList.length > 0
            ? values.notificationList
            : null,
        rwConnectEnvironment: values.rwConnectEnvironment,
        monitorSourceId: currentMonitorSourceId,
      },
    };

    if (deletedThresholds && deletedThresholds.length > 0) {
      if (!monitorRequestData.monitor.thresholdWindows) {
        monitorRequestData.monitor.thresholdWindows = [];
      }

      deletedThresholds.forEach((id) => {
        let record = monitorData?.thresholdWindows?.find((window) => {
          return window.thresholdWindowId === id;
        });

        monitorRequestData?.monitor?.thresholdWindows?.push({ ...record, isDeleted: true });
      });

      const deletedRecords = monitorData?.thresholdWindows?.filter((window) => {
        return deletedThresholds.includes(window?.thresholdWindowId!);
      });

      monitorRequestData.monitor.thresholdWindows = [
        ...monitorRequestData.monitor.thresholdWindows,
        ...deletedRecords!,
      ];
    }

    const request = isNewMonitor
      ? createMonitor(monitorRequestData)
      : updateMonitor(monitorRequestData);
    request.unwrap().then(() => {
      message.success(
        `Monitor ${monitorRequestData.monitor.description} ${isNewMonitor ? 'created' : 'saved'}`
      );
      navigate(
        getLink('MonitorList', { ...props, tenantId: tenantId }, { partnerId: getPartnerId() })
      );
    });
  };

  const groupThresholdWindowsByDay = (
    day: string,
    thresholdsList: MonitorThresholdWindow[]
  ): MonitorFormThresholdValues[] => {
    const groupedThresholds: MonitorFormThresholdValues[] = thresholdsList
      .filter((window) => {
        return moment(window.startDayTime).format('dddd').toLowerCase() === day;
      })
      .map((window: MonitorThresholdWindow) => {
        return {
          thresholdWindowId: window.thresholdWindowId,
          monitorId: window.monitorId,
          timeRange: [moment(window.startDayTime), moment(window.endDayTime)],
          minThreshold: window.minThreshold,
          maxThreshold: window.maxThreshold,
          rangeTimeframe: window.rangeTimeframe,
          alertLevelId: window.alertLevelId,
          thresholdExclusive: window.thresholdExclusive ? 1 : 0,
        };
      });

    return groupedThresholds;
  };

  const getThresholdWindows = (formValues: any): MonitorThresholdWindow[] => {
    let thresholdWindows: MonitorThresholdWindow[] = [];

    thresholdWindows = thresholdWindows.concat(
      getThresholdWindowsForDay('Sunday', formValues.thresholdSun)
    );
    thresholdWindows = thresholdWindows.concat(
      getThresholdWindowsForDay('Monday', formValues.thresholdMon)
    );
    thresholdWindows = thresholdWindows.concat(
      getThresholdWindowsForDay('Tuesday', formValues.thresholdTue)
    );
    thresholdWindows = thresholdWindows.concat(
      getThresholdWindowsForDay('Wednesday', formValues.thresholdWed)
    );
    thresholdWindows = thresholdWindows.concat(
      getThresholdWindowsForDay('Thursday', formValues.thresholdThu)
    );
    thresholdWindows = thresholdWindows.concat(
      getThresholdWindowsForDay('Friday', formValues.thresholdFri)
    );
    thresholdWindows = thresholdWindows.concat(
      getThresholdWindowsForDay('Saturday', formValues.thresholdSat)
    );

    return thresholdWindows;
  };

  const getThresholdWindowsForDay = (dayName: string, thresholdData: any) => {
    let thresholdWindows: MonitorThresholdWindow[] = [];

    if (!thresholdData || !(thresholdData.length > 0)) return thresholdWindows;

    let validThresholds = thresholdData.filter(
      (t: any) =>
        t.timeRange &&
        t.minThreshold !== undefined &&
        t.rangeTimeframe !== undefined &&
        t.alertLevelId !== undefined
    );

    validThresholds.forEach((windowData: any) => {
      let startDateTime = getDateForDaysOfWeek(dayName);
      startDateTime.setUTCHours(windowData.timeRange[0].hour());
      startDateTime.setUTCMinutes(windowData.timeRange[0].minutes());

      let endDateTime = getDateForDaysOfWeek(dayName);
      endDateTime.setUTCHours(windowData.timeRange[1].hour());
      endDateTime.setUTCMinutes(windowData.timeRange[1].minutes());

      const window: MonitorThresholdWindow = {
        thresholdWindowId: windowData.thresholdWindowId,
        monitorId: isNewMonitor ? 0 : monitorData?.monitorId,
        startDayTime: startDateTime,
        endDayTime: endDateTime,
        minThreshold: windowData.minThreshold,
        maxThreshold: windowData.maxThreshold,
        rangeTimeframe: windowData.rangeTimeframe,
        alertLevelId: windowData.alertLevelId,
        thresholdExclusive: windowData.thresholdExclusive ? 1 : 0,
      };
      thresholdWindows.push(window);
    });

    return thresholdWindows;
  };

  //gets the Date for first occurence of the given day in a month and year
  const getDateForDaysOfWeek = (dayName: string): Date => {
    var d = moment().set('date', 1);

    // Get date the first occurence of the selected day in the month
    while (d.format('dddd').toLowerCase() !== dayName.toLowerCase()) {
      d.add(1, 'days');
    }

    return d.toDate();
  };

  //checks if to given datetime ranges overlap
  const isTimeOverlap = (timeSegments: any, currentSegment: any) => {
    let isOverlap = false;
    timeSegments.map((segment: any) => {
      if (
        moment(currentSegment[0].format('YYYY-MM-DD h:mm a')) <=
          moment(segment[1].format('YYYY-MM-DD h:mm a')) &&
        moment(currentSegment[1].format('YYYY-MM-DD h:mm a')) >=
          moment(segment[0].format('YYYY-MM-DD h:mm a'))
      ) {
        isOverlap = true;
        return;
      }
    });

    return isOverlap;
  };

  const queryRenderBuilder = (props: BuilderProps) => <Builder {...props} />;

  const removeThreshold = (
    thresholdDay: string,
    formListIndex: number,
    removeFromFormListCallback: any
  ) => {
    if (isNewMonitor) {
      removeFromFormListCallback(formListIndex);
    } else {
      const thresholdDayList = form.getFieldValue(thresholdDay);
      const removedItem = form.getFieldValue(thresholdDay)[formListIndex];
      if (removedItem.thresholdWindowId && removedItem.thresholdWindowId > 0) {
        setDeletedThresholds([...deletedThresholds, removedItem.thresholdWindowId]);
      }
      removeFromFormListCallback(formListIndex);
    }
  };

  const testThreshold = (day: string, index: number, form: any) => {
    var values = form.getFieldsValue();

    if (
      currentMonitorSourceId === 1 &&
      (!values.rwConnectTenantIds || values.rwConnectTenantIds.length === 0)
    ) {
      message.error('You must fill at least one tenant for testing the threshold.');
      return;
    }

    var thresholdsFound = getThresholdWindows(values);
    var thresholdDay = thresholds.indexOf(day) + 1;
    var clickedThreshold = thresholdsFound.filter(
      (t) => t.startDayTime?.getUTCDay() == thresholdDay
    );
    var thresholdToTest = clickedThreshold == null ? [] : [clickedThreshold[index]];

    if (thresholdToTest.length === 0 || !thresholdToTest[0]) {
      message.error('You must fill all the information in the threshold for testing it.');
      return;
    }

    const testRequestData: CreateOrEditMonitorRequest = {
      tenantId: tenantId,
      monitor: {
        partnerId: getPartnerId(),
        rwConnectTenantIds: values.rwConnectTenantIds?.map((t: string) => parseInt(t)),
        sqlClause: isSqlMode ? values.sqlRaw : sqlFormat(values.queryTree, rwcQueryBuilderConfig),
        thresholdWindows: thresholdToTest,
        rwConnectEnvironment: values.rwConnectEnvironment,
        monitorSourceId: currentMonitorSourceId,
        monitorId: isNewMonitor ? 0 : Number(monitorId),
      },
    };
    const request = testMonitor(testRequestData);

    request
      .unwrap()
      .then((o) => {
        message.success(`Results: ${o.alertValue} (${o.status.toUpperCase()}) `);
      })
      .catch((o: any) => {
        console.log(`Response received: ${JSON.stringify(o)}`);
        message.error('Error testing monitor, try again later.');
      });
  };

  const emailValidator =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  const isValidNotificationEmail = async (_: any, values: any) => {
    if (
      !values || // no emails entered
      values.every((value: any) => value.toString().toLocaleLowerCase().match(emailValidator))
    ) {
      return;
    }
    throw new Error('You should provide a valid email address');
  };

  return (
    <PageHeader
      breadcrumb={
        <Breadcrumb>
          <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
          <Breadcrumb.Item>
            <MonitorOutlined />
            <Link
              to={getLink(
                'MonitorList',
                { ...props, tenantId: tenantId },
                { partnerId: getPartnerId() }
              )}
            >
              Monitors
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {isNewMonitor ? 'New Monitor' : monitorData?.description}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
    >
      <Card
        title={
          <Title level={4} style={{ marginBottom: 0, display: 'inline-block' }}>
            {isNewMonitor ? 'New Quote Monitor' : 'Edit Quote Monitor'}
          </Title>
        }
      >
        <Spin spinning={isFetching || isCreating || isTesting || isUpdating}>
          <Form
            form={form}
            name="monitor-form"
            initialValues={initialFormValues}
            onFinish={onFinish}
            {...layout}
          >
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: 'Monitor description is required.' }]}
            >
              <Input style={{ width: '50%' }} placeholder="Monitor Description" />
            </Form.Item>

            {isRWCMonitor ? (
              <Form.Item
                name="rwConnectTenantIds"
                label="Connect Tenants"
                rules={[{ required: true, message: 'Connect tenant ids are required.' }]}
              >
                <Select mode="tags" style={{ width: '50%' }} placeholder="Tenants Account" />
              </Form.Item>
            ) : null}

            {isRWCMonitor ? (
              <Form.Item
                name="rwConnectEnvironment"
                label="Connect Environment"
                rules={[{ required: true, message: 'Connect Environment is required.' }]}
              >
                <Input style={{ width: '50%' }} placeholder="Connect Environment" />
              </Form.Item>
            ) : null}

            <Form.Item
              name="frequency"
              label="Interval (min)"
              rules={[{ required: true, message: 'Interval is required.' }]}
            >
              <InputNumber min={1} />
            </Form.Item>

            {!isSqlMode ? (
              <Form.Item
                name="queryTree"
                label="Query"
                rules={[{ required: !isSqlMode, message: 'Query is required.' }]}
              >
                <Query
                  {...queryBuilderConfig}
                  value={initialFormValues.queryTree}
                  onChange={onQueryChange}
                  renderBuilder={queryRenderBuilder}
                />
              </Form.Item>
            ) : (
              <Form.Item
                name="sqlRaw"
                label="SQL Query"
                rules={[{ required: isSqlMode, message: 'Query is required' }]}
              >
                <TextArea rows={5} />
              </Form.Item>
            )}
            {isRWCMonitor ? (
              <Form.Item
                name="isSqlMode"
                label="SQL Mode"
                valuePropName="checked"
                style={{ marginLeft: '100px' }}
              >
                <Switch checked={isSqlMode} onChange={onIsSqlModeChange}></Switch>
              </Form.Item>
            ) : null}
            <Form.Item
              name="isDisabled"
              label="Disabled"
              rules={[{ required: true, message: 'Monitior active status is required.' }]}
              valuePropName="checked"
            >
              <Switch></Switch>
            </Form.Item>

            {thresholds.map((thresholdDay) => (
              <Form.Item
                key={thresholdDay}
                label={thresholdDay}
                style={{ marginBottom: 0, paddingBottom: 0 }}
              >
                <Form.List name={'threshold' + thresholdDay}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Space key={key} style={{ display: 'flex' }}>
                          <Form.Item hidden name="thresholdWindowId">
                            <Input type="hidden" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'timeRange']}
                            rules={[
                              { required: true, message: 'Time range is required' },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    value &&
                                    moment(value[0].format('YYYY-MM-DD h:mm:ss: a')).isBefore(
                                      moment(value[1].format('YYYY-MM-DD h:mm:ss: a'))
                                    )
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error('Start time should be less than end time.')
                                  );
                                },
                              }),
                              //validatopr to check time overlap
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  const thisThreshold = getFieldValue(`threshold${thresholdDay}`);
                                  let times: any[] = [];
                                  thisThreshold.map((windowData: any, index: any) => {
                                    if (
                                      index != name &&
                                      windowData.timeRange &&
                                      windowData.timeRange.length > 1
                                    ) {
                                      times.push(windowData.timeRange);
                                    }
                                  });

                                  if (value && !isTimeOverlap(times, value)) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error('Time range overlap.'));
                                },
                              }),
                            ]}
                          >
                            <TimePicker.RangePicker format="hh:mm A" />
                          </Form.Item>
                          <Form.Item
                            label="Range"
                            {...restField}
                            name={[name, 'minThreshold']}
                            rules={[{ required: true, message: 'Min threshold is required' }]}
                            style={{ marginLeft: 32 }}
                          >
                            <InputNumber min={0} />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'maxThreshold']}
                            rules={[
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    getFieldValue(`threshold${thresholdDay}`)[name].minThreshold <=
                                      value
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error('Min threshold should be less Max threshold')
                                  );
                                },
                              }),
                            ]}
                          >
                            <InputNumber min={0} />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'rangeTimeframe']}
                            rules={[{ required: true, message: 'Range timeframe is required' }]}
                            style={{ width: 120 }}
                          >
                            <Select>
                              <Option key="5" value={5}>
                                5m
                              </Option>
                              <Option key="10" value={10}>
                                10m
                              </Option>
                              <Option key="15" value={15}>
                                15m
                              </Option>
                              <Option key="30" value={30}>
                                30m
                              </Option>
                              <Option key="60" value={60}>
                                1h
                              </Option>
                              <Option key="120" value={120}>
                                2h
                              </Option>
                              <Option key="240" value={240}>
                                4h
                              </Option>
                              <Option key="480" value={480}>
                                8h
                              </Option>
                              <Option key="720" value={720}>
                                12h
                              </Option>
                              <Option key="1440" value={1440}>
                                24h
                              </Option>
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'thresholdExclusive']}
                            rules={[
                              {
                                required: true,
                                message: 'Must choose inside or outside of count range',
                              },
                            ]}
                            style={{ width: 120 }}
                            initialValue={0}
                          >
                            <Select>
                              <Option key="inside" value={0}>
                                Inside Count
                              </Option>
                              <Option key="outside" value={1}>
                                Outside Count
                              </Option>
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'alertLevelId']}
                            rules={[{ required: true, message: 'Alert level is required' }]}
                            style={{ width: 120 }}
                            initialValue={3}
                          >
                            <Select>
                              <Option key="1" value={1}>
                                Ok
                              </Option>
                              <Option key="2" value={2}>
                                Warning
                              </Option>
                              <Option key="3" value={3}>
                                Error
                              </Option>
                            </Select>
                          </Form.Item>
                          <Form.Item>
                            <Space>
                              <DeleteOutlined
                                onClick={() =>
                                  removeThreshold('threshold' + thresholdDay, name, remove)
                                }
                              />
                              {index == fields.length - 1 && <PlusOutlined onClick={() => add()} />}
                              {isRWCMonitor || !isNewMonitor ? (
                                <PlayCircleOutlined
                                  title="Test Threshold"
                                  onClick={(e) => testThreshold(thresholdDay, index, form)}
                                />
                              ) : null}
                            </Space>
                          </Form.Item>
                        </Space>
                      ))}
                      {!fields ||
                        (fields.length == 0 && (
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                              style={{ width: 160 }}
                            >
                              Add Threshold
                            </Button>
                          </Form.Item>
                        ))}
                    </>
                  )}
                </Form.List>
                <Divider />
              </Form.Item>
            ))}

            <Divider />

            <Form.Item
              name="notificationList"
              label="Notify"
              rules={[
                () => ({
                  validator: isValidNotificationEmail,
                }),
              ]}
            >
              <Select mode="tags" style={{ width: '50%' }} placeholder="Emails to notify" />
            </Form.Item>

            <Divider />

            <Form.Item label=" " colon={false}>
              <Space>
                <Button type="primary" htmlType="submit">
                  {isNewMonitor ? 'Add Monitor' : 'Save Monitor'}
                </Button>
                <Button htmlType="button" onClick={() => goBack()}>
                  Cancel
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </PageHeader>
  );
}
