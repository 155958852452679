import PartnerConnectionEndpointsScreen from './screens/partnerconnectionendpoints-screen';
import PartnerConnectionEndpointEditScreen from './screens/partnerconnectionendpoint-edit-screen';
import { RouteDefinition } from '../../core/route';

export const partnerconnectionendpointRoutes: RouteDefinition[] = [
  {
    id: 'PartnerConnectionEndpointEdit',
    title: 'Partner Connection Endpoint',
    path: 'partnerconnectionendpoints/:Id',
    element: <PartnerConnectionEndpointEditScreen />,
    showInNavigation: false,
  },
  {
    id: 'PartnerConnectionEndpointList',
    title: 'Partner Connection Endpoints',
    path: 'partnerconnectionendpoints',
    element: <PartnerConnectionEndpointsScreen />,
    showInNavigation: false,
  },
];
