import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  PartnerControlNumberListRequest,
  useDeletePartnerControlNumberMutation,
  useFetchPartnerControlNumbersQuery,
} from '../partnercontrolnumber-api';
import AuditLogsScreen from '../../../components/common/auditLogs';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Badge,
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Space,
  Table,
  Tooltip
} from 'antd';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
  MinusCircleFilled,
  MoreOutlined,
  UserOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { PartnerControlNumberViewModel, PaginatedResult } from '../../api/models';
import { DefaultPage, DefaultPageSize, DefaultPollingInterval } from '../../../core/defaults';
import Title from 'antd/lib/typography/Title';
import { getLink } from '../../../App-router';
import { TableState } from '../../../components/table/model';
import getSortBy from '../../../components/table/sorter';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function PartnerControlNumbersScreen(props: any) {
  const query = useQuery();
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const [showAuditLogs, setShowAuditLogs] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<number>(0);
  // console.log('PartnerControlNumbersScreen', params);
  const partnercontrolnumberTable: TableState = {
    columns: [
      {
        title: 'Id',
        dataIndex: '',
        key: 'Id',
        render: (partnercontrolnumber: PartnerControlNumberViewModel) => (
          <>
              <Space>
                <span>{partnercontrolnumber.id}</span>
              </Space>
          </>
        ),
        sorter: true,
        fixed: 'left',
      },
      {
        title: 'Key',
        dataIndex: '',
        key: 'key',
        render: (partnercontrolnumber: PartnerControlNumberViewModel) => (
          <><Tooltip title={partnercontrolnumber.key} >
          <span>{partnercontrolnumber.key}</span>
          </Tooltip>
             
          </>
        ),
        sorter: true,
      },
      {
        title: 'ControlNumber',
        key: 'controlNumber',
        dataIndex: '',
        render: (partnercontrolnumber: PartnerControlNumberViewModel) => (
          <>
              <span>{partnercontrolnumber.controlNumber}</span>
          </>
        ),
        sorter: true,
      },      
      {
        title: 'Actions',
        key: 'action',
        dataIndex: '',
        fixed:'right',
        render: (partnercontrolnumber: PartnerControlNumberViewModel) => (
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            overlay={
              <Menu style={{ padding: '8px 8px' }}>
                <Menu.Item icon={<EditOutlined />} key="1">
                  <Link
                    to={getLink('PartnerControlNumberEdit', {
                      tenantId: params.tenantId,
                      Id: partnercontrolnumber.id,
                    })}
                  >
                    Edit
                  </Link>
                </Menu.Item>
                {/* <Menu.Item icon={<InfoCircleOutlined />} key="3">
                <Link to="#" onClick={()=>setShowAuditLogs(true)}>
                    Audit Logs
                  </Link>
                </Menu.Item> */}
                <Menu.Item disabled={true}
                  icon={<DeleteOutlined />}
                  key="2"
                  onClick={() => {
                    confirm({
                      title: (
                        <span>
                          Delete partnercontrolnumber <b>{partnercontrolnumber.key}</b>?
                        </span>
                      ),
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        handleDelete(partnercontrolnumber);
                      },
                    });
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
          >
            <MoreOutlined />
          </Dropdown>
        ),
      },
    ],
  };

  const getCurrentState = (): PartnerControlNumberListRequest => ({
    current: Number(query.get('p')) || DefaultPage,
    pageSize: Number(query.get('size')) || DefaultPageSize,
    filter: query.get('q'),
    sortBy: query.get('sortBy') || 'EnteredByDate Desc',
  });

  const [pagination, setPagination] = useState<PartnerControlNumberListRequest>(getCurrentState());

  const {
    data: pagedData = {},
    isFetching,
    error,
  }: {
    data?: PaginatedResult<PartnerControlNumberViewModel> | undefined;
    isFetching?: boolean | undefined;
    error?: any;
  } = useFetchPartnerControlNumbersQuery(pagination, { pollingInterval: DefaultPollingInterval });

  useEffect(() => {
    setPagination(getCurrentState());
  }, [params]);

  const [deletePartnerControlNumber, { isLoading: isDeleteLoading, error: deleteError }] =
    useDeletePartnerControlNumberMutation();
  const { confirm } = Modal;

  const onCreateNew = useCallback(
    () => navigate(getLink('PartnerControlNumberEdit', { ...params, Id: 'new' })),
    [params, navigate]
  );

  const handleTableChange = (
    paginationConfig: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<PartnerControlNumberViewModel> | SorterResult<PartnerControlNumberViewModel>[],
    extra: TableCurrentDataSource<PartnerControlNumberViewModel>
  ) => {
    const { columnKey, order } = sorter as SorterResult<PartnerControlNumberViewModel>;
    if (columnKey && order) {
      query.set('sortBy', getSortBy('['+columnKey.toString()+']', order));
    } else {
      query.set('sortBy', '');
    }

    navigate(
      getLink(
        'PartnerControlNumberList',
        { ...params },
        {
          ...query,
          sortBy: query.get('sortBy'),
          p: paginationConfig.current,
          size: paginationConfig.pageSize,
          q: pagination.filter || '',
        }
      )
    );
  };

  const handleSearch = (searchText: string) => {
    navigate(
      getLink(
        'PartnerControlNumberList',
        { ...params },
        {
          ...query,
          p: DefaultPage,
          q:encodeURIComponent(searchText?.length ? `Key:*${searchText}*` : ''),
        }
      )
    );
  };

  const handleDelete = async (partnercontrolnumber: PartnerControlNumberViewModel) => {
    try {
      await deletePartnerControlNumber({
        Id: partnercontrolnumber.id ?? 0,
      }).unwrap();
      message.success(`PartnerControlNumber ${partnercontrolnumber.key} deleted`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <PageHeader
        // extra={
        //   <Button htmlType="button" data-testid={'create-new-partnercontrolnumber'} onClick={onCreateNew}>
        //     New Partner Control Number
        //   </Button>
        // }
        title={
          <Title
            level={4}
            style={{
              marginBottom: 0,
              display: 'inline-block',
            }}
          >
            Partner Control Number
          </Title>
        }
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: params.tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: params.tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="partnercontrolnumber">
              <Link to={getLink('PartnerControlNumberList', { tenantId: params.tenantId })}>Partner Control Number</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Card>
          {error?.status && (
            <Alert message="Error" description={error.data} type="error" showIcon />
          )}
          {deleteError && (
            <Alert message="Error" description={deleteError.toString()} type="error" showIcon />
          )}
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Input.Search
              placeholder="Search by Name"
              onSearch={handleSearch}
              enterButton
              style={{ width: 400 }}
              loading={isFetching}
              allowClear
            />
            <Table
            scroll={{ x: true }}
              dataSource={pagedData.results}
              onChange={handleTableChange}
              rowKey={'id'}
              pagination={{
                ...pagination,
                total: pagedData.totalCount,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ["10", "20", "50","100"],
                showSizeChanger: true,
                locale: { items_per_page: " / Page" }
              }}
              columns={partnercontrolnumberTable.columns}
              loading={isFetching}
              // onRow={(record, rowIndex) => {
              //   return {
              //     onClick: event => { 
              //       setEntityId(record?.id||0);
              //     },
              //   };
              // }}
            />
          </Space>
        </Card>
        {/* {showAuditLogs?<AuditLogsScreen entityId={entityId} entityName={'PartnerControlNumber'} showAuditLogs={setShowAuditLogs}/>:<></>} */}
      </PageHeader>
    </div>
  );
}
