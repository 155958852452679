import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  PartnershipInformationListRequest,
  useDeletePartnershipInformationMutation,
  useFetchPartnershipInformationsQuery,
} from '../partnershipinformation-api';

import AuditLogsScreen from '../../../components/common/auditLogs';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Badge,
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Space,
  Table,
  Tooltip,
  Select,
  Rate,
  Upload,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleFilled,
  ExclamationCircleOutlined,
  HomeOutlined,
  MoreOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { PartnershipInformationViewModel, PaginatedResult } from '../../api/models';
import { DefaultPage, DefaultPageSize, DefaultPollingInterval } from '../../../core/defaults';
import Title from 'antd/lib/typography/Title';
import { getLink } from '../../../App-router';
import { TableState } from '../../../components/table/model';
import getSortBy from '../../../components/table/sorter';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function PartnershipInformationsScreen(props: any) {
  const query = useQuery();
  const params: any = useParams<any>();
  const navigate = useNavigate();
  const [showAuditLogs, setShowAuditLogs] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<number>(0);  
  const partnershipInformationTable: TableState = {
    columns: [
      {
        title: 'Id',
        dataIndex: '',
        key: 'Id',
        render: (partnershipInformation: PartnershipInformationViewModel) => (
          <>
              <Space>
                <span>{partnershipInformation.id}</span>
              </Space>
          </>
        ),
        sorter: true,
        fixed: 'left',
      },
      {
        title: 'Name',
        dataIndex: '',
        key: 'name',
        render: (partnershipInformation: PartnershipInformationViewModel) => (
          <>
              <span>{partnershipInformation.name}</span>
          </>
        ),
        sorter: true,
      },      
      {
        title: 'Type',
        dataIndex: '',
        key: 'type',
        render: (partnershipInformation: PartnershipInformationViewModel) => (
          <>
              <span>{partnershipInformation.type}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'Partner Id',
        dataIndex: '',
        key: 'partnerId',
        render: (partnershipInformation: PartnershipInformationViewModel) => (
          <>
              <span>{partnershipInformation.partnerId}</span>
          </>
        ),
        sorter: true,
      }, 
      {
        title: 'IdentifierType',
        dataIndex: '',
        key: 'identifierType',
        render: (partnershipInformation: PartnershipInformationViewModel) => (
          <>
              <span>{partnershipInformation.identifierType}</span>
          </>
        ),
        sorter: true,
      }, 
      {
        title: 'IdentifierValue',
        dataIndex: '',
        key: 'identifierValue',
        render: (partnershipInformation: PartnershipInformationViewModel) => (
          <>
              <span>{partnershipInformation.identifierValue}</span>
          </>
        ),
        sorter: true,
      },      
      {
        title: 'Partner/VAN',
        dataIndex: '',
        key: 'partner_Van',
        render: (partnershipInformation: PartnershipInformationViewModel) => (
          <>
              <span>{partnershipInformation.partner_VAN}</span>
          </>
        ),
        sorter: true,
      },      
      
      {
        title: 'Notes',
        dataIndex: '',
        key: 'notes',
        render: (partnershipInformation: PartnershipInformationViewModel) => (
          <>
          <Tooltip title={partnershipInformation.notes}>
          <span>{partnershipInformation.notes}</span>
         </Tooltip>
              
          </>
        )
      }, 
      {
        title: 'Partner Rating',
        dataIndex: '',
        key: 'partnerRating',
        fixed:'right',
        render: (partnershipInformation: PartnershipInformationViewModel) => (
          <>
          <Rate disabled={true} allowHalf value={partnershipInformation.partnerRating} />
          </>
        ),
        sorter: true,
      },      
      {
        title: 'Actions',
        key: 'action',
        dataIndex: '',
        fixed:'right',
        render: (partnershipInformation: PartnershipInformationViewModel) => (
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            overlay={
              <Menu style={{ padding: '8px 8px' }}>
                <Menu.Item icon={<InfoCircleFilled />} key="2">
                  <Link
                    to={getLink('CarrierOnboardingList', {
                      tenantId: params.tenantId,
                      id: partnershipInformation.id,
                      partnerId: partnershipInformation.partnerId,
                      identifierValue:partnershipInformation.identifierValue,
                      carrierName:partnershipInformation.name,
                    })}
                  >
                    View Partner Onboarding
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<EditOutlined />} key="1">
                  <Link
                    to={getLink('PartnershipInformationEdit', {
                      tenantId: params.tenantId,
                      Id: partnershipInformation.id,
                    })}
                  >
                    View
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<InfoCircleOutlined />} key="4">
                <Link to="#" onClick={()=>setShowAuditLogs(true)}>
                    Audit Logs
                  </Link>
                </Menu.Item>
                <Menu.Item disabled={true}
                  icon={<DeleteOutlined />}
                  key="3"
                  onClick={() => {
                    confirm({
                      title: (
                        <span>
                          Delete partnership Information <b>{partnershipInformation.name}</b>?
                        </span>
                      ),
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        handleDelete(partnershipInformation);
                      },
                    });
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
          >
            <MoreOutlined />
          </Dropdown>
        ),
      },
    ],
  };

  const getCurrentState = (): PartnershipInformationListRequest => ({
    current: Number(query.get('p')) || DefaultPage,
    pageSize: Number(query.get('size')) || DefaultPageSize,
    filter: query.get('q'),
    sortBy: query.get('sortBy') || 'EnteredByDate Desc',
    searchText:query.get('searchText')||'',
    type:query.get('type')||'',
  });

  const [pagination, setPagination] = useState<PartnershipInformationListRequest>(getCurrentState());

  const {
    data: pagedData = {},
    isFetching,
    error,
  }: {
    data?: PaginatedResult<PartnershipInformationViewModel> | undefined;
    isFetching?: boolean | undefined;
    error?: any;
  } = useFetchPartnershipInformationsQuery(pagination);
  
  useEffect(() => {
    setPagination(getCurrentState());
  }, [params]);

  const [deletePartnershipInformation, { isLoading: isDeleteLoading, error: deleteError }] =
    useDeletePartnershipInformationMutation();
  const { confirm } = Modal;

  const onCreateNew = useCallback(
    () => navigate(getLink('PartnershipInformationEdit', { ...params, Id: 'new' })),
    [params, navigate]
  );
  const handleCarrierChange = (value: string) => {
      value = value===undefined?'':value;
      localStorage.setItem('type',value||'');
      query.set('type', value);
      let searchText =query.get('searchText')||'';
      navigate(
        getLink(
          'PartnershipInformationList',
          { ...params },
          {
            ...query,
            sortBy: query.get('sortBy')||'EnteredByDate Desc',
            p: DefaultPage,
            size: DefaultPageSize,            
            searchText:query.get('searchText')||'',
            type:query.get('type')||'',
            q:encodeURIComponent(searchText?.length>0 ? `(Name:*${searchText.replaceAll(" ", "\\ ")}* OR identifierValue:*${searchText.replaceAll(" ", "\\ ")}* OR partnerId:*${searchText.replaceAll(" ", "\\ ")}*) ${value.length>0?'AND Type:'+value:''}` : value?.length >0 ?`Type:"${value}"`:''),
          }
        )
      );

  };
  const handleTableChange = (
    paginationConfig: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<PartnershipInformationViewModel> | SorterResult<PartnershipInformationViewModel>[],
    extra: TableCurrentDataSource<PartnershipInformationViewModel>
  ) => {
    const { columnKey, order } = sorter as SorterResult<PartnershipInformationViewModel>;
    if (columnKey && order) {
      query.set('sortBy', getSortBy(columnKey.toString(), order));
    } else {
      query.set('sortBy', '');
    }
    navigate(
      getLink(
        'PartnershipInformationList',
        { ...params },
        {
          ...query,
          sortBy: query.get('sortBy'),
          p: paginationConfig.current,
          size: paginationConfig.pageSize,
          q: pagination.filter || '',            
          searchText:query.get('searchText')||'',
          type:query.get('type')||'',
        }
      )
    );
  };

  const handleSearch = (searchText: string) => {
    // debugger
    let q = query.get('q');
    let type = query.get('type')||'';
    query.set('searchText', searchText);
    navigate(
      getLink(
        'PartnershipInformationList',
        { ...params },
        {
          ...query,
          p: DefaultPage,            
          searchText:query.get('searchText')||'',
          type:query.get('type')||'',
          q:encodeURIComponent(searchText?.length>0 ? `(Name:*${searchText.replaceAll(" ", "\\ ")}* OR identifierValue:*${searchText.replaceAll(" ", "\\ ")}* OR partnerId:*${searchText.replaceAll(" ", "\\ ")}*) ${type.length>0?'AND Type:'+type:''}` : type?.length >0 ?`Type:"${type}"`:''),
        }
      )
    );
  };

  const handleDelete = async (partnershipInformation: PartnershipInformationViewModel) => {
    try {
      await deletePartnershipInformation({
        Id: partnershipInformation.id ?? 0,
      }).unwrap();
      message.success(`Partner Information ${partnershipInformation.name} deleted`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <PageHeader
        extra={
          <Space>
          <Button htmlType="button" data-testid={'create-new-partnershipInformation'} onClick={onCreateNew}>
            New Partner Information
          </Button>
          
          </Space>
        }
        title={
          <Title
            level={4}
            style={{
              marginBottom: 0,
              display: 'inline-block',
            }}
          >
            Partner Information
          </Title>
        }
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: params.tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: params.tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="partnershipInformation">
              <Link to={getLink('PartnershipInformationList', { tenantId: params.tenantId })}>Partner Information</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Card>
          {error?.status && (
            <Alert message="Error" description={error.data} type="error" showIcon />
          )}
          {deleteError && (
            <Alert message="Error" description={deleteError.toString()} type="error" showIcon />
          )}
          <Space direction="vertical" style={{width: '100%'}}  size="large">
            <Space size="large" direction="horizontal">
            <div style={{minWidth: 200}}>
              <b>Type: </b>
            </div>
              <Select defaultValue={query.get('type')||''}  onChange={handleCarrierChange} allowClear placeholder='Type' style={{minWidth: 150}} options={[{label:'Carrier', value:'Carrier'},{label:'Customer', value:'Customer'}]} />
              <div style={{minWidth: 150}}>
              <b>Search: </b>
              </div>
              <Input.Search
              placeholder="Search by Name or IdentifierValue or Partner Id"
              onSearch={handleSearch}
              enterButton
              style={{ width: 400 }}
              loading={isFetching}
              allowClear
              defaultValue={query.get('searchText')||''}
            />
            </Space>
            
            <Table scroll={{ x: true }}
              dataSource={pagedData.results}
              onChange={handleTableChange}
              rowKey={'id'}
              pagination={{
                ...pagination,
                total: pagedData.totalCount,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ["10", "20", "50","100"],
                showSizeChanger: true,
                locale: { items_per_page: " / Page" }
              }}
              onRow={(record:any, rowIndex) => {
                return {
                  onClick: event => {
                    setEntityId(record?.id||0);
                    localStorage.setItem('partnershipId', record?.id??0);
                    // sessionStorage.setItem('overallRating', record?.partnerRating);
                    sessionStorage.setItem('carrierName', record?.name.trimStart()||'');
                    localStorage.setItem('partnerInformationId', record?.id??0);
                    sessionStorage.setItem('partnerId',record?.partnerId);
                    sessionStorage.setItem('identifierValue',record?.identifierValue);
                  },
                };
              }}
              columns={partnershipInformationTable.columns}
              loading={isFetching}
            />
          </Space>
        </Card>
        {showAuditLogs?<AuditLogsScreen entityId={entityId} entityName={'PartnerInformation'} showAuditLogs={setShowAuditLogs}/>:<></>}
      </PageHeader>
    </div>
  );
}
