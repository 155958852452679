import React from 'react';
import { useEffect, useState } from 'react';
import {
  OutboundTransactionListRequest,
  OutboundTransactionFiltersRequest,
  useFetchOutboundTransactionsQuery,
  useFetchOutboundFiltersQuery
} from '../outboundtransaction-api';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Alert,
  Breadcrumb,
  Card,
  Select,
  Space,
  Table,
  Spin,
  Form,
  Button,
  Row,
  Col,
  Tooltip
} from 'antd';
import {
  HomeOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { OutboundTransactionViewModel, PaginatedResult } from '../../api/models';
import { DefaultPage, DefaultPageSize } from '../../../core/defaults';
import { ConvertUTCtoCDT } from '../../../core/utils';
import Title from 'antd/lib/typography/Title';
import { getLink } from '../../../App-router';
import { TableState } from '../../../components/table/model';
import getSortBy from '../../../components/table/sorter';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function OutboundTransactionsScreen(props: any) {
  const query = useQuery();
  const params: any = useParams<any>();
  const navigate = useNavigate();
  // console.log('OutboundTransactionsScreen', params);
  const outboundtransactionTable: TableState = {
    columns: [
      {
        title: 'Id',
        dataIndex: '',
        key: 'Id',
        render: (outboundtransaction: OutboundTransactionViewModel) => (
          <>
              <Space>
                <span>{outboundtransaction.id}</span>
              </Space>
          </>
        ),
        sorter: true,
        fixed: 'left',
      },
      {
        title: 'PartnerName',
        dataIndex: '',
        key: 'partnerName',
        render: (outboundtransaction: OutboundTransactionViewModel) => (
          <>
              <span>{outboundtransaction.partnerName}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'InterchangeSenderId',
        dataIndex: '',
        key: 'interchangeSenderId',
        render: (outboundtransaction: OutboundTransactionViewModel) => (
          <>
              <span>{outboundtransaction.interchangeSenderId}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'InterchangeReceiverId',
        dataIndex: '',
        key: 'interchangeReceiverId',
        render: (outboundtransaction: OutboundTransactionViewModel) => (
          <>
              <span>{outboundtransaction.interchangeReceiverId}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'TransactionSetIdentifierCode',
        key: 'transactionSetIdentifierCode',
        dataIndex: '',
        render: (outboundtransaction: OutboundTransactionViewModel) => (
          <>
              <span>{outboundtransaction.transactionSetIdentifierCode}</span>
          </>
        ),
        sorter: true,
      },      


      {
        title: 'GroupControlNumber',
        dataIndex: '',
        key: 'groupControlNumber',
        render: (outboundtransaction: OutboundTransactionViewModel) => (
          <>
              <span>{outboundtransaction.groupControlNumber}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'InterchangeControlNumber',
        dataIndex: '',
        key: 'interchangeControlNumber',
        render: (outboundtransaction: OutboundTransactionViewModel) => (
          <>
              <span>{outboundtransaction.interchangeControlNumber}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'GroupFunctionalIdentifierCode',
        key: 'groupFunctionalIdentifierCode',
        dataIndex: '',
        render: (outboundtransaction: OutboundTransactionViewModel) => (
          <>
              <span>{outboundtransaction.groupFunctionalIdentifierCode}</span>
          </>
        ),
        sorter: true,
      }, 
      {
        title: 'TransactionSetControlNumber',
        dataIndex: '',
        key: 'transactionSetControlNumber',
        render: (outboundtransaction: OutboundTransactionViewModel) => (
          <>
              <span>{outboundtransaction.transactionSetControlNumber}</span>
          </>
        ),
        sorter: true,
      }, 
      {
        title: 'ReferenceValue',
        dataIndex: '',
        key: 'referenceValue',
        render: (outboundtransaction: OutboundTransactionViewModel) => (
          <>
              <span>{outboundtransaction.referenceValue}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'InterchangeAcknowledgmentCode',
        dataIndex: '',
        key: 'interchangeAcknowledgmentCode',
        render: (outboundtransaction: OutboundTransactionViewModel) => (
          <>
              <span>{outboundtransaction.interchangeAcknowledgmentCode}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: '',
        key: 'status',
        render: (outboundtransaction: OutboundTransactionViewModel) => (
          <>
              <span>{outboundtransaction.status}</span>
          </>
        ),
        sorter: true,
      },
      {
        title: 'InterchangeAcknowledgmentRequested',
        key: 'interchangeAcknowledgmentRequested',
        dataIndex: '',
        render: (outboundtransaction: OutboundTransactionViewModel) => (
          <>
              <span>{outboundtransaction.interchangeAcknowledgmentRequested}</span>
          </>
        )
      }, 
      {
        title: 'DocumentId',
        key: 'documentId',
        dataIndex: '',
        render: (outboundtransaction: OutboundTransactionViewModel) => (
          <>
          <Tooltip title={outboundtransaction.documentId}>
          <span>{outboundtransaction.documentId}</span>
         </Tooltip>
              
          </>
        ),
      },
      {
        title: 'EnteredByDate',
        key: 'enteredByDate',
        dataIndex: '',
        render: (outboundtransaction: OutboundTransactionViewModel) => (
          <>
              <span>{ConvertUTCtoCDT(outboundtransaction.enteredByDate)}</span>
          </>
        ),
        sorter: true,
      },

    ],
  };

  const getCurrentState = (): OutboundTransactionListRequest => ({
    current: Number(query.get('p')) || DefaultPage,
    pageSize: Number(query.get('size')) || DefaultPageSize,
    filter: query.get('q'),
    sortBy: query.get('sortBy'),
    hours: Number(query.get('hours')),    
    referenceValue: query.get('referenceValue')||''
  });

  const [interchangeSenderId, setInterchangeSenderId] = useState<string>('');
  const [interchangeReceiverId, setInterchangeReceiverId] = useState<string>('');
  const [transactionSetIdentifierCode, setTransactionSetIdentifierCode] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [hours, setHours] = useState<string>('0');  
  // const [referenceValue, setReferenceValue] = useState<string>('');

  const getFiltersState = (): OutboundTransactionFiltersRequest => ({
    senderId: interchangeSenderId||'',
    receiverId: interchangeReceiverId||'',
    tsCode: transactionSetIdentifierCode||'',
    status: status||'',
    referenceValue: query.get('referenceValue')||'',
    time: Number(query.get('hours'))
  });
  const [pagination, setPagination] = useState<OutboundTransactionListRequest>(getCurrentState());
  const [selectFilters, setSelectFilters] = useState<OutboundTransactionFiltersRequest>(getFiltersState());

  const {
    data: pagedData = {},
    isFetching,
    error,
  }: {
    data?: PaginatedResult<OutboundTransactionViewModel> | undefined;
    isFetching?: boolean | undefined;
    error?: any;
  } = useFetchOutboundTransactionsQuery(pagination);
  
  const partnershipInfoResults = useFetchOutboundFiltersQuery(selectFilters);
  

  const [form] = Form.useForm();
  
  useEffect(() => {
    setPagination(getCurrentState());
    setSelectFilters(getFiltersState());
  }, [params,query]);

  const handleTableChange = (
    paginationConfig: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<OutboundTransactionViewModel> | SorterResult<OutboundTransactionViewModel>[],
    extra: TableCurrentDataSource<OutboundTransactionViewModel>
  ) => {
    const { columnKey, order } = sorter as SorterResult<OutboundTransactionViewModel>;
    if (columnKey && order) {
      query.set('sortBy', getSortBy(columnKey.toString(), order));
    } else {
      query.set('sortBy', '');
    }
     
    navigate(
      getLink(
        'OutboundTransactionList',
        { ...params },
        {
          ...query,
          sortBy: query.get('sortBy'),
          p: paginationConfig.current,
          size: paginationConfig.pageSize,
          hours:query.get('hours')||0,
          referenceValue:query.get('referenceValue')||'',
          q: pagination.filter || '',
        }
      )
    );
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (
    option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleTimeChange = (searchText: string) => {
    setHours(searchText||'0');
    navigate(
      getLink(
        'OutboundTransactionList',
        { ...params },
        {
          ...query,
          p: DefaultPage,
          hours:searchText||'0',
          referenceValue:query.get('referenceValue')||'',
          q: `interchangeSenderId:*${interchangeSenderId===undefined || interchangeSenderId===null?'':interchangeSenderId}* 
          AND interchangeReceiverId:*${interchangeReceiverId ===undefined || interchangeReceiverId ===null?'':interchangeReceiverId}* 
          AND transactionSetIdentifierCode:*${transactionSetIdentifierCode ===undefined || transactionSetIdentifierCode ===null?'':transactionSetIdentifierCode}* 
          AND status:*${status ===undefined || status ===null?'':status}*`,
        }
      )
    );
  };
    const handleSenderChange = (searchText: string) => {
      setInterchangeSenderId(searchText||'');
      navigate(
        getLink(
          'OutboundTransactionList',
          { ...params },
          {
            ...query,
            p: DefaultPage,
            size: DefaultPageSize,
            hours:query.get('hours')||'0',
            referenceValue:query.get('referenceValue')||'',
            q: `interchangeSenderId:*${searchText===undefined || searchText===null?'':searchText}* 
            AND interchangeReceiverId:*${interchangeReceiverId ===undefined || interchangeReceiverId ===null?'':interchangeReceiverId}* 
            AND transactionSetIdentifierCode:*${transactionSetIdentifierCode ===undefined || transactionSetIdentifierCode ===null?'':transactionSetIdentifierCode}* 
            AND status:*${status ===undefined || status ===null?'':status}*`,
          }
        )
      );
    };
    
    const handleReceiverChange = (value: string) => {
      setInterchangeReceiverId(value||'');
      navigate(
        getLink(
          'OutboundTransactionList',
          { ...params },
          {
            ...query,
            p: DefaultPage,
            size: DefaultPageSize,
            hours:query.get('hours')||'0',
            referenceValue:query.get('referenceValue')||'',
            q: `interchangeSenderId:*${interchangeSenderId===undefined || interchangeSenderId===null?'':interchangeSenderId}* 
            AND interchangeReceiverId:*${value ===undefined || value ===null?'':value}* 
            AND transactionSetIdentifierCode:*${transactionSetIdentifierCode ===undefined || transactionSetIdentifierCode ===null?'':transactionSetIdentifierCode}* 
            AND status:*${status ===undefined || status ===null?'':status}*`,
          }
        )
      );
    };

    const handleTransactionTypeChange = (value: string) => {
      setTransactionSetIdentifierCode(value||'');
      navigate(
        getLink(
          'OutboundTransactionList',
          { ...params },
          {
            ...query,
            p: DefaultPage,
            size: DefaultPageSize,
            hours:query.get('hours')||'0',
            referenceValue:query.get('referenceValue')||'',
            q: `interchangeSenderId:*${interchangeSenderId===undefined || interchangeSenderId===null?'':interchangeSenderId}* 
            AND interchangeReceiverId:*${interchangeReceiverId ===undefined || interchangeReceiverId ===null?'':interchangeReceiverId}* 
            AND transactionSetIdentifierCode:*${value ===undefined || value ===null?'':value}* 
            AND status:*${status ===undefined || status ===null?'':status}*`,
          }
        )
      );
    };

    const handleStatusChange = (value: string) => {       
      setStatus(value||'');      
      navigate(
        getLink(
          'OutboundTransactionList',
          { ...params },
          {
            ...query,
            p: DefaultPage,
            size: DefaultPageSize,
            hours:query.get('hours')||'0',
            referenceValue:query.get('referenceValue')||'',
            q: `interchangeSenderId:*${interchangeSenderId===undefined || interchangeSenderId===null?'':interchangeSenderId}* 
            AND interchangeReceiverId:*${interchangeReceiverId ===undefined || interchangeReceiverId ===null?'':interchangeReceiverId}* 
            AND transactionSetIdentifierCode:*${transactionSetIdentifierCode ===undefined || transactionSetIdentifierCode ===null?'':transactionSetIdentifierCode}* 
            AND status:*${value ===undefined || value ===null?'':value}*`,
          }
        )
      );
    };
    const handleReferenceValueChange = (value: string) => {       
    // setReferenceValue(value);
    navigate(
      getLink(
        'OutboundTransactionList',
        { ...params },
        {
          ...query,
          p: DefaultPage,
          hours:query.get('hours')||'0',
          referenceValue: value==undefined?'':value,
          q: `interchangeSenderId:*${interchangeSenderId===undefined || interchangeSenderId===null?'':interchangeSenderId}* 
          AND interchangeReceiverId:*${interchangeReceiverId ===undefined || interchangeReceiverId ===null?'':interchangeReceiverId}* 
          AND transactionSetIdentifierCode:*${transactionSetIdentifierCode ===undefined || transactionSetIdentifierCode ===null?'':transactionSetIdentifierCode}* 
          AND status:*${status ===undefined || status ===null?'':status}*`,
        }
      )
    );
    };
  return (
    <div>
      <PageHeader
        title={
          <Title
            level={4}
            style={{
              marginBottom: 0,
              display: 'inline-block',
            }}
          >
            Acknowledgement Reconciliation
          </Title>
        }
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={getLink('home', { tenantId: params.tenantId })}>
              <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>              
              <Link to={getLink('cob', { tenantId: params.tenantId })}>
              Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="Acknowledgement Reconciliation">
              <Link to={getLink('OutboundTransactionList', { tenantId: params.tenantId })}>Acknowledgement Reconciliation</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Card>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={8}>
              <Space size="large">
                <div style={{minWidth: 150}} >
                  InterchangeSender
                </div>
              <Select
                style={{minWidth: 150}}
                  showSearch
                  placeholder="Search..."
                  optionFilterProp="children"
                  filterOption={filterOption}
                  value={interchangeSenderId ===undefined || interchangeSenderId ===null?'':interchangeSenderId}
                  onChange={handleSenderChange}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  allowClear={true}
                  loading={isFetching}
                  options={partnershipInfoResults.data?.interchangeSenders.map((item:any) => ({ label: item, value: item }))}
                  />
                  </Space>
              </Col>            
              <Col className="gutter-row" span={8}>
              <Space size="large">
              <div style={{minWidth: 150}}>
                  InterchangeReceiver
                </div>
              <Select
              style={{minWidth: 150}}
                  showSearch
                  placeholder="Search..."
                  optionFilterProp="children"
                  value={interchangeReceiverId ===undefined || interchangeReceiverId ===null?'':interchangeReceiverId}
                  filterOption={filterOption}
                  onChange={handleReceiverChange}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  allowClear={true}
                  loading={isFetching}
                  options={partnershipInfoResults.data?.interchangeReceiver.map((item:any) => ({ label: item, value: item }))}
                  />
                  </Space>
              </Col>              
              <Col className="gutter-row" span={8}>
              <Space size="large">
              <div style={{minWidth: 150}}>
                  TransactionSetIdentifier
                </div>
              <Select
              style={{minWidth: 150}}
                  showSearch
                  placeholder="Search..."
                  optionFilterProp="children"
                  filterOption={filterOption}
                  value={transactionSetIdentifierCode ===undefined || transactionSetIdentifierCode ===null?'':transactionSetIdentifierCode}
                  onChange={handleTransactionTypeChange}
                  options={partnershipInfoResults.data?.transactionTypes.map((item:any) => ({ label: item, value: item }))}
                  allowClear={true}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  loading={isFetching}
                />
                </Space>
              </Col>
              <Col className="gutter-row" span={8}>
              <Space size="large">
              <div style={{minWidth: 150}}>
                  Status
                </div>
              <Select
              style={{minWidth: 150}}
                  // showSearch
                  placeholder="Select..."
                  optionFilterProp="children"
                  filterOption={filterOption}
                  defaultValue={status ===undefined || status ===null?'':status}
                  onChange={handleStatusChange}
                  options={partnershipInfoResults.data?.statuses.map((item:any) => ({ label: item, value: item }))}
                  allowClear={true}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  loading={isFetching}
                />
                </Space>
              </Col>
              <Col className="gutter-row" span={8}>
              <Space size="large">
              <div style={{minWidth: 150}}>
                  Reference Value
                </div>
              <Select
              style={{minWidth: 150}}
                  // showSearch
                  placeholder="Select..."
                  optionFilterProp="children"
                  filterOption={filterOption}
                  defaultValue={query.get('referenceValue')||''}
                  onChange={handleReferenceValueChange}
                  options={partnershipInfoResults.data?.referenceValues.map((item:any) => ({ label: item=='null'?'No Reference':item, value: item }))}
                  allowClear={true}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  loading={isFetching}
                />
                </Space>
              </Col>
              <Col className="gutter-row" span={8}>
              <Space size="large">
              <div style={{minWidth: 150}}>
                  Time(EnteredByDate)
                </div>
                <Select
                placeholder="Select..."
                style={{minWidth: 150}}
                allowClear={true}
                onChange={handleTimeChange}
                defaultValue={Number(query.get('hours'))>0 ?query.get('hours'):''}
                options={[{label:"12 hr", value:"12"},{label:"24 hr", value:"24"},{label:"48 hr", value:"48"}]}
              />
                </Space>
              </Col>
          </Row>
        </Card>

        <Card>
          {error?.status && (
            <Alert message="Error" description={error.data} type="error" showIcon />
          )}
          
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Table scroll={{ x: true }}
              dataSource={pagedData.results}
              onChange={handleTableChange}
              rowKey={'id'}
              pagination={{
                ...pagination,
                total: pagedData.totalCount,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ["10", "20", "50","100"],
                showSizeChanger: true,
                locale: { items_per_page: " / Page" }
              }}
              columns={outboundtransactionTable.columns}
              loading={isFetching}
            />
          </Space>
        </Card>
      </PageHeader>
    </div>
  );
}
