import { apiSlice } from '../api/api-slice';
import { Company, PaginatedResult } from '../api/models';

export interface CompanySearchRequest {
  name: string;
  pageNumber: number;
  pageSize: number;
}

export const companiesApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchCompaniesSearch: builder.query<
        PaginatedResult<Company>,
        CompanySearchRequest | any
      >({
        query(request) {
          const urlParams = new URLSearchParams(request);
          return `/companies/search?${urlParams.toString()}`;
        },
      }),
    };
  },
});

export const { useFetchCompaniesSearchQuery } = companiesApi;
