import { createSlice } from '@reduxjs/toolkit';
import { TableState } from '../../components/table/model';

const initialState: TableState = {
  columns: [],
};

const sourcetypeListSlice = createSlice({
  name: 'sourcetypeTable',
  initialState,
  reducers: {
    hideColumn(state) {},
  },
});

export const sourcetypesReducer = sourcetypeListSlice.reducer;
